<div id="engagement-team" class="container-fluid border container-border-color container-section"
[formGroup]="formGroup">
  <div class="row bg-title">
    <span class="title-section-print ">Engagement Team</span>
  </div>
  <div id="engagement-team-section" class="content-section">
      <div class="row">
        <div class="col-xs-12">
          <span
            [class.highlight-warning]="highlightRequiredField('dealteamTable')"
            [class.edited-externally]="editedExternally('dealteamTable')"
            [class.conflict-on-merge]="hasMergeConflict('dealteamTable')">
              Deal Team
          </span>
          <span *ngIf="showAsterisk('dealteamTable')" class="red-asterik"></span>
        </div>
      </div>
      <div class="row">
        <!-- <app-deal-team-table #dealTeam
          [readonly]="!hasPermission('AddDealTeamMember')"
          [editable]="true"
          [dealTeamTableFormGroup]="formGroup.get('dealteamTable')"
          [dealTeamValues]="dealTeamValues"
          [firstColumnVisible]="false"
          [secondColumnVisible]="false"
          (dealTeamRowToStore)="onDealTeamRowSelection($event)">
        </app-deal-team-table> -->
        <app-deal-team-table #dealTeam
        [readonly]="!hasPermission('AddDealTeamMember')"
        [editable]="true"
        [dealTeamTableFormGroup]="formGroup.get('dealteamTable')"
        [dealTeamValues]="dealTeamValues"
        [firstColumnVisible]="false"
        [secondColumnVisible]="false"
        (dealTeamRowToStore)="onDealTeamRowSelection($event)"
        [allowToDuplicateUser]="allowToDuplicateUser">
      </app-deal-team-table>
      </div>
  </div>
</div>

