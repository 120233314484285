<div class="toolbar" role="banner">
  <img width="40" alt="Angular Logo" src="/assets/img/Deloitte_SEC.png" />
  <span class="app-name">DCF Automation</span>
  <!-- <span class="home"><a id="homeLink" href="/" style="color:inherit; text-decoration:none">Home</a></span> -->
  <span class="headerLink"><a id="dashboardLink" href="/Dashboard" style="color:inherit; text-decoration:none">XB Dashboard</a></span>
  <span class="headerLink" *ngIf="isUserHasAdminAccess"><a id="adminLink" href="/admin" style="color:inherit; text-decoration:none">User Management</a></span>
  <span class="headerLink" *ngIf="isContentManagementUser"><a id="contentLink" href="/content" style="color:inherit; text-decoration:none">Content Management</a></span>
  <!--<span class="headerLink"><a id="newDealLink" href="/newdeal?id=99" style="color:inherit; text-decoration:none">New Deal</a></span>-->
  <span class="headerLink" *ngIf="isDashboardUser"><a id="dashboardLink" href="/engagementDashboard" style="color:inherit; text-decoration:none">Engagement Dashboard</a></span>  
  <!-- <span class="headerLink"><a id="dashboardLink" href="/closingCheckListForm" style="color:inherit; text-decoration:none">Closing Checklist Form</a></span> -->
  <span class="headerLink" *ngIf="isTFAdminUser"><a id="terminationFormLink" href="/terminationFormDashboard" style="color:inherit; text-decoration:none">Termination Dashboard</a></span>
  <span class="headerLink"><a id="closingCheckListFormLink" href="/closingChecklistDashboard" style="color:inherit; text-decoration:none">Closing Checklist Dashboard</a></span>
  <span class="headerLink" *ngIf="isTFAdminUser"><a id="newhireform" href="/onboardingFormDashboard" style="color:inherit; text-decoration:none">Onboarding Form Dashboard</a></span>



  <div class="spacer"></div>

  <div *ngIf="isAuthorized" id="header_search_main">
    <div class="table-responsive" header_topnav_div_main="">

    </div>
    <div id="header_deloittenet_text_name" align="right">
      <a aria-label="" title="" style="margin-right: -15px;">
        <img class="user-pic" [src]="imagePath" />
      </a>
      <span style="margin-left: -5px;">Hi, <b>{{user}}</b>&nbsp;</span>
    </div>

  </div>
  <div *ngIf="!isAuthorized" id="header_search_main">

  </div>

</div>
  

                 
 
 
