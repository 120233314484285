<div class="col-xs-12">
    <app-peoplepicker-dynamic-table #DealTeam
    [header]="pS24NameHeader"
    [firstColumnItemList]="firstColumnVisible ? pS24RoleColumn : null"
    [secondColumnItemList]="secondColumnVisible ? pS24PursuitRoleColumn : null"
    [thirdColumnItemList]="thirdColumnVisible ? pS24SwiftRoleColumn : null"
    [valuesList]="pS24Values"
    [readonly]="readonlyS24"
    [placeHolderForLastTable]="placeholderPreviousS24"
    [ignoreUserLeftDCF]="false"
    (selectedRow)="onDealTeamRowSelection($event)"
    [readonly]="readonly">
    </app-peoplepicker-dynamic-table>
</div>
<closing-checklist-app-notify-popup #notifypopupComponent>
</closing-checklist-app-notify-popup>