<div id="documents" class="container-fluid border container-border-color container-section">
    <div class="row bg-title">
        <span class="title-section-print">Documents</span>
    </div>
    <div id="documents-section" class="content-section">
        <div class="row">
            <div class="col-xs-12">
                <span [class.highlight-warning]="highlightRequiredField('documentTypesAttachments')"
                    [class.edited-externally]="editedExternally('documentTypesAttachments')"
                     [class.conflict-on-merge]="hasMergeConflict('documentTypesAttachments')">Attach required documentation below.</span>
                <span *ngIf="showAsterisk('documentTypesAttachments')" class="red-asterik"></span>
            </div>
        </div>
        <div class="col-xs-12 templates-header">
            <div class="col-xs-12 document-name templates">
                <div class="col-xs-3 border-right-2 first-col-3-width">
                    <span class="top-30 title-section-print">Document Type</span>
                </div>
                <div class="col-xs-9 col-9-width">
                    <span class="top-30 title-section-print">Document Name</span>
                </div>
            </div>
        </div>
        <div class="attachments-rows attachment-section col-xs-12">
            <div *ngFor="let documentType of documentTypesToShow; let i = index" class="col-xs-12 display-inline-flex">
                <div class="row">
                    <hr>
                    <div class="col-xs-3 align-center">
                        <app-file-uploader
                            [invalidFileNameCharactersList]="invalidFileNameCharactersList"
                            [blockedFileTypes]="blockedFileTypes"
                            [buttonTitle]="documentType.documentTypeTitle"
                            [filesAssociated]="documentType.documentTypeAttachments.length > 0"
                            [disabled]="disabled"                          
                            (fileSelectedEvent)="onFileSelected($event, documentType.documentTypeItemId, documentType.documentTypeId,documentType.documentTypeTitle)"
                            [showNewDealpopup]="showNewDealpopup"
                            [showDealWBSpopup]="showDealWBSpopup"
                            [showCCLListpopup]="showCCLListpopup"
                            >
                        </app-file-uploader>
                    </div>
                    <div class="col-xs-9">
                        <div *ngFor="let attachment of documentType.documentTypeAttachments; let attachmentIndex = index" class="col-xs-12">
                            <div class="col-xs-11">
                                <div class="display-inline-flex file-row">
                                    <a (click)="downloadAttachment(attachment.attachmentName)" target="_blank" download="{{attachment.attachmentName}}" class="word-break-all" style="cursor: pointer;">
                                        <span class="search-name break-filename">{{attachment.attachmentName}}</span>
                                    </a>
                                    <i *ngIf="attachment.attachmentUploading" class="fa fa-spinner uploading fa-spin font-size-24"></i>
                                </div>
                            </div>
                            <div class="col-xs-1">
                                <span *ngIf="removeDocuments && !attachment.attachmentUploading" class="delete-uploaded-file-style glyphicon glyphicon-remove float-right"
                                    (click)="deleteAttachment(attachment.attachmentName, documentType.documentTypeItemId, attachmentIndex)">
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!addAdditionalDocumentTypes" class="col-xs-12">
            <div class="row">
                <hr>
            </div>
        </div>
        <div *ngIf="addAdditionalDocumentTypes" class="col-xs-12">
            <div class="row">
                <hr>
                <div class="col-xs-12 margin-bottom-5">
                    <div class="col-xs-3 align-center" style="margin-bottom: 5px;">
                        <button type="button" id="add-additional-document-types"
                            class="btn btn-sm save-rework-cancel-btn btn-primary additional-doc-type"
                            (click)="openAdditionalDocumentTypesModal()">
                            Add Additional Document Types
                        </button>
                    </div>
                    <div class="col-xs-9">
                    </div>
                </div>
                <hr>
            </div>
        </div>
    </div>
</div>
<newdeal-app-notify-popup 
[additionalDocumentTypesAvailable]="additionalDocumentTypesAvailable"
[additionaldocumentTypesToAdd]="additionaldocumentTypesToAdd"
(addAdditionalDocumentClicked) ="addAdditionalDocumentType($event)" 
(deleteConfirmDetails)="ConfirmDetails($event)">
</newdeal-app-notify-popup>

<deal-wbs-setup-app-notify-popup 
[additionalDocumentTypesAvailable]="additionalDocumentTypesAvailable"
[additionaldocumentTypesToAdd]="additionaldocumentTypesToAdd"
(addAdditionalDocumentClicked) ="addAdditionalDocumentType($event)" 
(deleteConfirmDetails)="ConfirmDetails($event)">
</deal-wbs-setup-app-notify-popup>