<div id="contentToConvert" #contentToConvert>
<div class="headerManagement">Closing Checklist Form</div>
<form class="formStyle" [formGroup]="appFormGroup" (keydown.enter)="$event.preventDefault()">
  <div id="form-body">
    <div id="form-sections" class="col-xs-10">
      <button style="height: 33px;"
            type="button" 
            class="btn btn-success"
            id="printFormButtonClosingChecklist"
            (click)="generatePDF()">Print
      </button> 

      <!-- <div class="container-fluid border container-border-color container-section"> -->
      <!-- <div class="row bg-title">
        <span>Compliance ClosingCheckList Integration</span>
      </div> -->
      <app-project-overview [readOnly]="(this.hasPermission('ReadOnly') && !this.hasPermission('Edit'))"
                            (initiateForm)="initiateFormValueEventHandler($event)"
                            [formGroups]="[appFormGroup.get('projectOverViewFormGroup'), appFormGroup.get('myDealFormGroup')]"
                            [formSubmitted]="formSubmitted"
                            (dealTeamRowToStore)="dealTeamRowHandler($event,  dealTeamList)">
      </app-project-overview>

      <app-client-overview [ngClass]="!showInitiate ? 'visibility-hidden' : ''"
                           [formGroups]="[appFormGroup.get(formGroups.clientOverViewFormGroup),appFormGroup.get(formGroups.myDealFormGroup)]"
                           [formSubmitted]="formSubmitted">
      </app-client-overview>

      <app-deal-overview [ngClass]="!showInitiate ? 'visibility-hidden' : ''"
                         [formGroups]="[appFormGroup.get(formGroups.dealOverViewFormGroup),appFormGroup.get(formGroups.myDealFormGroup),appFormGroup.get(formGroups.financeFormGroup)]"
                         [formSubmitted]="formSubmitted"
                         (dealTypeValue)="dealTypeEventHandler($event)">
      </app-deal-overview>

      <app-dates-timeline [ngClass]="!showInitiate ? 'visibility-hidden' : ''"
                          [formGroups]="[appFormGroup.get(formGroups.timelineContentFormGroup)]"
                          [formSubmitted]="formSubmitted">
      </app-dates-timeline>

      <app-dcf-fee-structure [ngClass]="!showInitiate ? 'visibility-hidden' : ''" [formGroups]="[appFormGroup.get(formGroups.dcfFeeStrcutureFormGroup),
              appFormGroup.get(formGroups.valuationMetricsFormGroup)]"
                             [readOnly]="(this.hasPermission('ReadOnly') && !this.hasPermission('Edit'))"
                             [formSubmitted]="formSubmitted"
                             (breakPointMultiValue)="breakPointValueEventHandler($event)">

      </app-dcf-fee-structure>

      <app-finance [ngClass]="!showInitiate ? 'visibility-hidden' : ''"
                   [formGroups]="[appFormGroup.get(formGroups.financeFormGroup)]"
                   [formSubmitted]="formSubmitted"
                   (selectedFile)="selectedFileHandler($event)"
                   [isFinanceForm]="isFinanceForm"
                   (uploadedAttachments)="uploadedAttachmentsHandler($event)"
                   (providerNameType)="providerNameTypeEventHandler($event)"
                   [readOnly]="(this.hasPermission('ReadOnly') && !this.hasPermission('Edit'))">
      </app-finance>

      <app-valuation-metrics [ngClass]="!showInitiate ? 'visibility-hidden' : ''"
                             [formGroups]="[appFormGroup.get(formGroups.valuationMetricsFormGroup)]"
                             [formSubmitted]="formSubmitted"
                             (valuationMetricsStore)="valuationMetricsStore($event)">
      </app-valuation-metrics>

      <app-buyer-deal-marketing [ngClass]="!showInitiate ? 'visibility-hidden' : ''"
                                [formGroups]="[appFormGroup.get(formGroups.buyerDealMarketingFormGroup),appFormGroup.get(formGroups.myDealFormGroup),appFormGroup.get(formGroups.valuationMetricsFormGroup)]"
                                [formSubmitted]="formSubmitted"
                                (buyerTypeValue)="buyerTypeEventHandler($event)">
      </app-buyer-deal-marketing>

      <app-deal-structure [ngClass]="!showInitiate ? 'visibility-hidden' : ''"
                          [formGroups]="[appFormGroup.get(formGroups.dealStructureFormGroup),appFormGroup.get(formGroups.valuationMetricsFormGroup)]"
                          [formSubmitted]="formSubmitted">
      </app-deal-structure>

      <app-indemnification [ngClass]="!showInitiate ? 'visibility-hidden' : ''"
                           [formGroups]="[appFormGroup.get(formGroups.indemnificationFormGroup),appFormGroup.get(formGroups.valuationMetricsFormGroup)]"
                           [formSubmitted]="formSubmitted">
      </app-indemnification>

      <app-quality-of-earnings [ngClass]="!showInitiate ? 'visibility-hidden' : ''"
                               [formGroups]="[appFormGroup.get(formGroups.qualityOfEarningsFormGroup)]"
                               [formSubmitted]="formSubmitted">
      </app-quality-of-earnings>

      <div class="container-fluid border container-border-color container-section" [ngClass]="!showInitiate ? 'visibility-hidden' : ''">
        <app-engagement-overview [readOnly]="(this.hasPermission('ReadOnly') && !this.hasPermission('Edit'))"
                                 [formGroups]="[appFormGroup.get(formGroups.engagementOverviewFormGroup), appFormGroup.get(formGroups.myDealFormGroup)]"
                                 [formSubmitted]="formSubmitted"
                                 [S24DeletionActive]="S24DeletionActive"
                                 [PPMDDeletionActive]="PPMDDeletionActive"
                                 (pS24RowToStore)="dealTeamRowHandler($event, previousSeries24List)"
                                 (ppmdRowToStore)="dealTeamRowHandler($event, previousPPMDList)"
                                 (dttlRowToStore)="dttlRowHandler($event, counterpartiesList)"
                                 (dealTeamRowToDelete)="onPeoplePickerToDelete($event, previousSeries24List)"
                                 (ppmdRowToDelete)="onPeoplePickerToDelete($event, previousPPMDList)"
                                 (dealTypeChange)="dealTypeEvent($event)">
        </app-engagement-overview>



        <app-cross-border [readOnly]="(this.hasPermission('ReadOnly') && !this.hasPermission('Edit'))"
                          [formGroups]="[appFormGroup.get(formGroups.crossBorderFormGroup)]"
                          (dttlRowToStore)="dttlRowHandler($event, dttlList)"
                          (dttlRowToDelete)="onDttlToDelete($event, dttlList)"
                          [formSubmitted]="formSubmitted">
        </app-cross-border>

        <app-capital-raised [readOnly]="(this.hasPermission('ReadOnly') && !this.hasPermission('Edit'))"
                            [formGroups]="[appFormGroup.get(formGroups.capitalRaisedFormGroup)]"
                            [formSubmitted]="formSubmitted">
        </app-capital-raised>

        <app-marketing-and-communications [formGroups]="[appFormGroup.get(formGroups.marketingAndComunicationsFormGroup)]"
                                          [formSubmitted]="formSubmitted">
        </app-marketing-and-communications>



        <app-fees [formGroups]="[appFormGroup.get(formGroups.feesFormGroup)]"
                  [formSubmitted]="formSubmitted">
        </app-fees>

        <app-books-and-records [formGroups]="[appFormGroup.get(formGroups.booksAndRecordsFormGroup)]"
                               [formSubmitted]="formSubmitted">
        </app-books-and-records>

        <!-- <app-supervisory-signoff *ngIf="checkVisibilitySupervisorySignoffSetion()"
        [formGroups]="[appFormGroup.get(formGroups.supervisorySignoffFormGroup)]"
        [formSubmitted]="formSubmitted">
        </app-supervisory-signoff> -->
      </div>

      <app-additional-commentary [ngClass]="!showInitiate ? 'visibility-hidden' : ''"
                                 [formGroups]="[appFormGroup.get(formGroups.additionalCommentaryFormGroup)]"
                                 [readOnly]="(formStatus === 'Discontinued' || formStatus === 'Completed')"
                                 [currentUser]="currentUser"
                                 [formId]="formId"
                                 [formSubmitted]="formSubmitted"
                                 [maxNumOfCharacters]="maxNumOfCharacters">
      </app-additional-commentary>

      <app-deal-team-signoffs [ngClass]="!showInitiate ? 'visibility-hidden' : ''"
                              [dealTeamSignoffsSection]="showDealTeamSignoffsSection"
                              [currentUser]="currentUser"
                              [discontinuePermission]="hasPermission('Discontinue')"
                              [formActionGroup]="formActionGroup"
                              [formStatus]="formStatus"
                              [hasDealTeamSignoffApprovalAccess]="hasDealTeamSignoffApprovalAccess"
                              [savePermission]="hasPermission('Edit') && saveButtonVisible"
                              (onDiscontinue)="onDiscontinue()"
                              (onReturnForWork)="onReturnForWork()"
                              (onSave)="onSave()"
                              (onDealTeamApproval)="onDealTeamApproval($event)">
      </app-deal-team-signoffs>

      <!-- <div [ngClass]="!showSeries24FinalApprovalSection ? 'showSeries24FinalApprovalSection' : ''"  id="series-24-final-approval" class="container-fluid border container-border-color container-section"> TODO: Uncomment when the component is ready-->
      <div [ngClass]="!showSeries24FinalApprovalSection ? 'showSeries24FinalApprovalSection' : ''" id="series-24-final-approval" class="container-fluid border container-border-color container-section">
        <div class="row bg-title">
          <span class="title-section-print">Series 24 Final Approval</span>
        </div>
        <app-supervisory-signoff [formGroups]="[appFormGroup.get(formGroups.supervisorySignoffFormGroup)]"
                                 [formSubmitted]="formSubmitted">
        </app-supervisory-signoff>
        <app-series-24-approval [series24Signature]="series24Signature"
                                [series24FinalApproval]="series24FinalApproval"
                                [series24FinalApprovalSection]="showSeries24FinalApprovalSection"
                                [discontinuePermission]="hasPermission('Discontinue')"
                                [approvePermission]="hasSeries24ApprovalAccess"
                                (onDiscontinue)="onDiscontinue()"
                                (onApprove)="onApprove()"
                                (onReturnForWork)="onReturnForWork()">
        </app-series-24-approval>

      </div>
      <app-reactivate-form *ngIf="hasPermission('Reactivate')"
                           [canceledOn]="DiscontinuedOn" [canceledBy]="DiscontinuedBy"
                           (reactivateForm)=reactivateFormHandler()>
      </app-reactivate-form>
      <div *ngIf="ShowSubmitForReviewSection" id="submit-for-review" class="container-fluid border container-border-color container-section">
        <div class="row bg-title">
          <span class="title-section-print">Submit Form for Review</span>
        </div>
        <div class="submit-buttons content-section">

          <!-- *ngIf="hasPermission('Submit')" -->
          <button type="submit"
                  (click)="onSubmit()"
                  class="btn btn-primary submit-btn">
            Submit and Approve
          </button>
          <!-- <button *ngIf="hasPermission('Edit') && saveButtonVisible" -->
          <button *ngIf="!isAutoSaveEnabled"
                  class="btn btn-outline-primary save-rework-cancel-btn margin-left-10"
                  [disabled]="saveInProgress"
                  (click)="onSave()">
            Save
          </button>
          <!-- zTODO for next US add  && !isPendingForAdminReview() to the ngIf -->
          <!-- *ngIf="hasPermission('Discontinue')" -->
          <button type="submit"
                  (click)="onDiscontinue()"
                  class="btn btn-outline-primary save-rework-cancel-btn margin-left-10"
                  [disabled]="saveInProgress">
            Discontinue
          </button>

        </div>
      </div>
    </div>
    <div class="col-xs-2" [ngClass]="!showInitiate ? 'visibility-hidden' : ''">
      <app-sidebar [isFinanceForm]="isFinanceForm" [isAutoSaveEnabled]="isAutoSaveEnabled" [saveInProgress]="saveInProgress" (SaveCCLFormDetails)="SaveCCLFormDetails($event)">
      </app-sidebar>
    </div>
  </div>
  <alert></alert>
  <app-alert-message [type]="'success'" #Alert></app-alert-message>
</form>
</div>
<closing-checklist-app-notify-popup (addNotifyDetails)="ConfirmDetails($event)"
                                    (deleteConfirmDetails)="ConfirmDetails($event)">
</closing-checklist-app-notify-popup>
