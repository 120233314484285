<div id="contentToConvert" #contentToConvert>
<div class="headerManagement">New Deal Setup Form</div>
<form class="formStyle" [formGroup]="appFormGroup"
(keydown.enter)="$event.preventDefault()" >
    <div id="form-body">
        <div id="form-sections" class="col-xs-10"> 
            <button
            type="button" 
            class="btn btn-success"
            id="printFormButtonNewDeal"
            (click)="generatePDF()">Print</button>                       
            <app-project-overview
                [formGroups]="[appFormGroup.get('projectOverViewFormGroup'), appFormGroup.get('myDealFormGroup')]"
                [formSubmitted]="formSubmitted"      
                [s24GroupUsers]="s24GroupUsers"        
                (dealTeamRowToStore)="dealTeamRowHandler($event)"
                (sicCodeChange)="sicCodeEvent($event)"
                class="grade"
                >
            </app-project-overview>              
          <app-client-overview
              [formGroups]="[appFormGroup.get('clientOverViewFormGroup'), appFormGroup.get('myDealFormGroup')]"
              [formSubmitted]="formSubmitted"
              class="grade"
              >
          </app-client-overview>
          <app-client-setup
              [formGroups]="[appFormGroup.get('clientSetupFormGroup'), appFormGroup.get('myDealFormGroup')]"
              [formSubmitted]="formSubmitted"
              class="grade"
              >
          </app-client-setup>
            <app-new-deal
                [formGroups]="[appFormGroup.get('newDealFormGroup'), appFormGroup.get('myDealFormGroup')]"
                [formSubmitted]="formSubmitted"
                class="grade"
                >
          </app-new-deal>
          <app-mercury-opportunity
              [formGroups]="[appFormGroup.get('mercuryOpportunityFormGroup'), appFormGroup.get('myDealFormGroup')]"
              [formSubmitted]="formSubmitted"
              (recurringOpportunityChange)="recurringOpportunityEvent($event)"
              (offeringChange)="offeringEvent($event)"
              (buyerNameChange)="buyerNameEvent($event)" class="grade">
          </app-mercury-opportunity>
          <app-prd #prdComponent
              [formGroup]="appFormGroup.get('myDealFormGroup')"
              [formSubmitted]="formSubmitted"
              (deloitteUSEntityChange)="deloitteUsEntityEvent($event)"
              (salesOfficeChange)="salesOfficeEvent($event)" class="grade">
          </app-prd>
          
          <app-documents
              [formType] ="formType"
              [formGroup]="appFormGroup.get('documentsFormGroup')"
              [formSubmitted]="formSubmitted"
              [disabled]="!hasPermission('AttachDocuments')"
              [addAdditionalDocumentTypes]="hasPermission('AddAdditionalDocumentTypes')"
              [removeDocuments]="hasPermission('RemoveDocuments')"
              (selectedFile)="selectedFileHandler($event)"
              (additionalDocumentTypeListItemToStore)="additionalDocumentTypItemHandler($event)"
              (uploadedAttachments)="uploadedAttachmentsHandler($event)"
              [showNewDealpopup]="showNewDealpopup"
              [showDealWBSpopup]="showDealWBSpopup"
              [showCCLListpopup]="showCCLListpopup"
              class="grade"
              >
          </app-documents>
          
          <app-comments-section  (usercomment)= "getusercomments($event)" class="grade">
              
        </app-comments-section>
          <app-admin-review *ngIf="checkVisibilityAdminReviewSetion()"
              [formGroups]="[appFormGroup.get('adminReviewFormGroup'), appFormGroup.get('myDealFormGroup')]"
              [formSubmitted]="formSubmitted"
              [saveInProgress]="saveInProgress"
              [saveButtonVisible]="saveButtonVisible"
              (adminAction)="adminActionHandler($event)" class="grade">
          </app-admin-review>
        <app-active-new-deal
                              *ngIf="checkVisibilityActiveNewDealSetion()"
            [isNotificationSent]="isNotificationSent"
            (adminActiveSwiftCodeAction)="adminActiveNewDealHandler($event)" class="grade">
        </app-active-new-deal>
          <app-reactivate-form *ngIf="hasPermission('Reactivate')"
          [canceledOn]="DiscontinuedOn" [canceledBy]="DiscontinuedBy"
              (reactivateForm)=reactivateFormHandler() class="grade">
          </app-reactivate-form>
          <div class="submit-buttons floatStyle grade" *ngIf="buttonsVisible">
               <button *ngIf="hasPermission('Submit')"  
                type="submit"
                (click)="onSubmit()"
                class="btn btn-primary submit-btn"
                >
                Submit
              </button>
              
              <button *ngIf="saveButtonVisible && !isPendingForAdminReview()"
                class="btn btn-outline-primary save-rework-cancel-btn margin-left-10"
                [disabled]="saveInProgress"
                (click)="onSaveForm()">
                Save
              </button>
              
               <button *ngIf="hasPermission('Discontinue') && !isPendingForAdminReview()" 
                type="submit"
                (click)="onDiscontinue()"
                class="btn btn-outline-primary save-rework-cancel-btn margin-left-10"
                [disabled]="saveInProgress">
                Discontinue
              </button>
          </div>
      </div>
      <div class="col-xs-2">
          <app-sidebar [isAutoSaveEnabled]="isAutoSaveEnabled" [saveButtonVisible]="saveButtonVisible"   [saveInProgress]="saveInProgress" [isAdminReviewEditable]="isAdminReviewEditable"  (SaveNewDealFormDetails)="SaveNewDealFormDetails($event)" (BindCurrentSection)="BindCurrentSection($event)"></app-sidebar>
      </div>
  </div>
  <alert></alert> 
  <app-alert-message [type]="'success'" #Alert></app-alert-message>
</form>
</div>
<newdeal-app-notify-popup 
(addNotifyDetails)="ConfirmDetails($event)"
(deleteConfirmDetails)="ConfirmDetails($event)">
</newdeal-app-notify-popup>
<newdeal-app-notify-popup></newdeal-app-notify-popup>
