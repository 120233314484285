import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppSettings } from 'src/shared/app-settings';
import { MainService } from 'src/shared/services/main.service';
import { CommonHelperService } from 'src/app/commonHelper/common-helper.service';
@Component({
  selector: 'closing-checklist-app-notify-popup',
  templateUrl: './closing-checklist-notify-popup.component.html',
  styleUrls: ['./closing-checklist-notify-popup.component.scss']
})
export class ClosingChecklistNotifyPopupComponent implements OnInit {

  displayStyle:any = "none";
  isUserDelete:any=false;
  showNewDealAdditionalDocumentTypes:boolean=false;
  MessageHeader:any="";
  headerMsg:any="";
  showMissingEHOMsg:boolean=false;
  Msg:any;messageType:any;
  modalType:any="";
  cancelText:any=""
  UserDeleteCancelText ="No";
  UserDeleteOkText ="Yes";
  showMissingRolesMsg:any=false;
  showReturnForReworkMsg:boolean=false;
  returnForReworkReason:any=""
  showDiscontinueMsg:boolean=false;
  discontinueReason:any="";
  bigMsgStyle:any=false;
  sessionExpired:boolean = false;
  @Input() additionalDocumentTypesAvailable: boolean;
  @Input() additionaldocumentTypesToAdd: any=[];
  @Input() maxAllowedFileNameLength: number;
  @Input() invalidFileNameCharactersList: Array<string> = [];
  @Output()  addNotifyDetails:EventEmitter<any>=new EventEmitter<any>();
  @Output()  deleteConfirmDetails:EventEmitter<any>=new EventEmitter<any>();
  @Output()  addAdditionalDocumentClicked:EventEmitter<any>=new EventEmitter<any>();  
  constructor(private mainservice:MainService,private commonHelperService:CommonHelperService) { }

  ngOnInit(): void {
  }

  openPopup(msg:any,msgType:any='') { 
    document.body.style.overflow="hidden"
    this.MessageHeader=msg;
    this.headerMsg = "";
    this.messageType=msgType;
    this.isUserDelete=false;
    this.showNewDealAdditionalDocumentTypes=false;    
    this.UserDeleteCancelText ="Cancel";
    this.UserDeleteOkText ="OK";
    this.showMissingRolesMsg=false;
    this.showReturnForReworkMsg=false;
    this.showDiscontinueMsg=false;   
    this.cancelText="OK";
    this.bigMsgStyle = false;
    this.showMissingEHOMsg=false;
    this.sessionExpired = false;
    if(msg=="ClosingChecklist")
    {
      this.modalType = msgType;
      if(msgType=="additional-document-types")
      {
        this.showNewDealAdditionalDocumentTypes =true;
        this.headerMsg = "Available Document Types";
        this.isUserDelete=false;
      }
      if(msgType == "delete-attachment"){
        this.isUserDelete=true;   
        this.Msg="Are you sure you want to remove the attachment?"
      }
      if(msgType == "file-name-uploading")
      {
        this.isUserDelete=false;        
        this.Msg="Unable to attach the selected file. The upload of a file with the same name is already in progress.";
        this.bigMsgStyle = true;
        this.cancelText="Cancel";
      }
      if(msgType == "file-overwrite"){
        this.isUserDelete=true; 
        this.bigMsgStyle = true;
        this.Msg="A file with that name already exists.<br>Do you wish to overwrite it?";
        this.cancelText="Cancel";
      }
      if(msgType == "file-name-blocked"){
        this.isUserDelete=false;        
        this.Msg="A file with the same name already exists in the list of attached documents.";
        this.bigMsgStyle = true;
        this.cancelText="Cancel";
      }
      if(msgType == "file-name-too-long"){
        this.isUserDelete=false;        
        this.Msg="The file name exceeds the characters limit.";
        this.cancelText="Cancel";
      }   
      if(msgType == "file-size-exceeded"){
        this.isUserDelete=false;        
        this.Msg="Unable to upload the file. The filesize must be lower than 100MB.";
        this.bigMsgStyle = true;
        this.cancelText="Cancel";
      }  
      if(msgType == "file-name-invalid"){
        this.isUserDelete=false;        
        this.Msg="A file name cannot contain any of the following characters: "+ this.invalidFileNameCharactersList;
        this.bigMsgStyle = true;
        this.cancelText="OK";
      }  
      if(msgType == "file-name-long"){
        this.isUserDelete=false;        
        this.Msg="The filename must be shorter than " + this.maxAllowedFileNameLength + " characters.";
        this.bigMsgStyle = true;
        this.cancelText="OK";
      }  
      if(msgType == "file-type-invalid"){
        this.isUserDelete=false;        
        this.Msg="The file name exceeds the characters limit.";
        this.cancelText="Cancel";
      }     
      if(msgType == "file-empty"){
        this.isUserDelete=false;        
        this.Msg="The file cannot be empty.";
        this.bigMsgStyle = true;
        this.cancelText="Cancel";
      }    
      if(msgType == "file-encrypted"){
        this.isUserDelete=false;        
        this.Msg="File cannot be encrypted.";
        this.cancelText="Cancel";
      }   
      if(msgType == "retrieve"){
        this.isUserDelete=false;        
        this.Msg="The data has been successfully retrieved.";        
      }   
      if(msgType == "initiated-form"){
        this.isUserDelete=false;        
        this.Msg="The form has been initiated.";        
      }   
      if(msgType == "mandatory-fields"){
        this.isUserDelete=false;        
        this.Msg="All mandatory fields need to be completed.<br> Please review them and try again.";   
        this.bigMsgStyle = true;     
      }   
      if(msgType == "submit")
      {
        document.body.style.overflow="hidden"
        this.isUserDelete=false;        
        this.Msg="The form has been submitted.";        
      }   
      if(msgType == "discontinue"){
        this.isUserDelete=true;        
        this.showDiscontinueMsg=true;
      }   
      if(msgType == "discontinue-reason"){
        this.isUserDelete=false;        
        this.Msg="Before discontinuing the form, you need to provide a reason.";
        this.bigMsgStyle = true;
      }   
      if(msgType == "return-for-rework"){
        this.isUserDelete=true;        
        this.showReturnForReworkMsg=true;
      }   
      if(msgType == "return-for-rework-confirmed"){
        this.isUserDelete=false;        
        this.Msg="The form has been returned for rework.";        
      }   
      if(msgType == "return-for-rework-reason"){
        this.isUserDelete=false;        
        this.Msg="Before returning the form for rework, you need to provide a reason.";
        this.bigMsgStyle = true;
      }   
      if(msgType == "approval"){
        this.isUserDelete=false;        
        this.Msg="The form has been approved.";
      }  
      if(msgType == "all-DealTeamMember-Approval"){
        this.isUserDelete=false;        
        this.Msg="Sign-off process has been completed for all Deal Team Members.";
        this.bigMsgStyle = true;
      }  
      if(msgType == "compliance-sign-Off"){
        this.isUserDelete=false;     
        this.bigMsgStyle = true;   
        this.Msg="Before approving this form, you need to complete the Compliance Supervisory Signoff";
      }  
      if(msgType == "sign-Off-Approved"){
        this.isUserDelete=false;        
        this.Msg="Your approval has been completed.";
      }  
      if(msgType == "lcsp-mismatch"){
        this.isUserDelete=false;        
        this.Msg="LCSP Details mismatch";
      }   
      if(msgType == "no-dcf-member"){
        this.isUserDelete=false;        
        this.Msg="You are not allowed to access this form.";
      }
      if(msgType == "user-left-dcf-member"){
        this.isUserDelete=false;        
        this.Msg="At least one user is no longer with DCF group. Please review the Deal Team or Series 24";
        this.bigMsgStyle = true;
      }
      if(msgType == "reactivate-form"){
        this.isUserDelete=true;        
        this.Msg="Are you sure you want to reactivate this form?";
      }
      if(msgType == "reactivate-confirmed"){
        this.isUserDelete=false;        
        this.Msg="The form has been reactivated.";
      }
      if(msgType == "error"){
        this.isUserDelete=false;        
        this.Msg="There has been an error. Please reload the page and try again.";
        this.bigMsgStyle = true;
      }
      if(msgType == "files-uploading"){
        this.isUserDelete=false;        
        this.Msg="Please wait for the items to be uploaded.";          
      }
      if(msgType == "save")
      {
        document.body.style.overflow="hidden"
        this.isUserDelete=false;        
        this.Msg="The data has been successfully saved.";
      }
      if(msgType == "no-valid-form"){
        this.isUserDelete=false;        
        this.Msg="This form is not associated to a valid Engagement. Please use an Engagement Health Object to create any new forms.";
        this.bigMsgStyle = true;
        this.showMissingEHOMsg=true;
      }
      if(msgType == "form-updated"){
        this.isUserDelete=false;        
        this.Msg="This form has been updated.";
      }
      if(msgType == "retry-workflow"){
        this.isUserDelete=false;        
        this.Msg="This workflow is being updated. Please try again later.";
      }
      if(msgType == "validate-swift-roles"){
        this.isUserDelete=false;        
        this.showMissingRolesMsg=true;    
      }
      if(msgType == "activeNewDealNotification"){
        this.isUserDelete=false;        
        this.Msg="Active Swift code Notification Sent.";
      }
      if(msgType=="discontinue-confirmed")
      {
        this.isUserDelete=false;        
        this.Msg="The form has been discontinued.";
      }
        // if (msgType == "DealTeamTable") {
        if (msgType == "deal-team-row-delete-valid") {
            this.Msg = "Atleast one deal team member is required.";
        }
        if (msgType == "save")
        {
            document.body.style.overflow="hidden"
            this.Msg = "The data has been successfully saved.";
        }  
        if (msgType == "deal-team-row-added") {
            this.isUserDelete = true;
            this.Msg = "By adding new Deal Team members, an email<br>notification will be sent to them.";
            this.bigMsgStyle = true;
        }
        if (msgType == "swift-role-valid") {
            this.Msg = "Each swift role can only be chosen once.";
        }
        if (msgType == "access") {
          this.isUserDelete=false;
          this.Msg = "You are not allowed to access this form.";
        }
        if (msgType == "activeWBSNotification") {
          this.isUserDelete=false;
          this.Msg = "Active WBS Notification Sent.";
        }
        
    }
    else if(msg==AppSettings.tokenExpiredText)
      {
        this.isUserDelete=false;        
        this.Msg=AppSettings.sessionExpiredMessage; 
        this.sessionExpired = true;
      }
    if(this.additionaldocumentTypesToAdd) this.additionaldocumentTypesToAdd.sort((a:any,b:any)=>(a.documentTypeItemId>b.documentTypeItemId?1:-1))
    this.displayStyle = "block";
    
   }
  
   closePopup() {
    if(this.sessionExpired)
      {
        this.commonHelperService.isTokenExpired();
      }
      else
      {
    this.ApplyBrowserScroll();
    this.displayStyle = "none";
    const data = {
      MessageHeader:this.MessageHeader,
      isOkayClicked: true,
      modalType: this.modalType
    };
    if(this.messageType==="access" || this.messageType==='no-valid-form' ){
      window.location.href= AppSettings.Engagementdashboard;
      return; 
    }
    if(this.messageType=="mandatory-fields"){
      document.getElementById("dashboardLink")?.scrollIntoView({behavior:'smooth'})
    }

    if(this.messageType==="initiated-form"){
      window.location.href= AppSettings.ClosingCheckListForm+"?id="+this.mainservice.formId;
    }

    if(this.messageType==="discontinue-confirmed"){
      window.location.href= AppSettings.ClosingCheckListForm+"?id="+this.mainservice.formId;
    }

    if(this.messageType==="reactivate-confirmed"){
      window.location.href= AppSettings.ClosingCheckListForm+"?id="+this.mainservice.formId;
    }
    // if(this.messageType=="mandatory-fields"){
    //   document.getElementById("dashboardLink")?.scrollIntoView({behavior:'smooth'})
    // }
    this.addNotifyDetails.emit(data);
  }
}
   ConfirmToDelete(){ //OK
   this.ApplyBrowserScroll();
    this.displayStyle = "none";
    const data = {
      isOkayClicked: true,
      modalType: this.modalType,
      returnForReworkReason:this.returnForReworkReason,
      discontinueReason:this.discontinueReason
    };
    this.deleteConfirmDetails.emit(data);
   }
   ConfirmToCancel(){ // Cancel
    this.ApplyBrowserScroll();
    const data = {
      isOkayClicked: false,
      modalType: this.modalType,
      isCancelClicked: true,
    };
    this.deleteConfirmDetails.emit(data);
    this.displayStyle = "none";
    this.returnForReworkReason='';
    this.discontinueReason='';
   }

   ApplyBrowserScroll(){
    document.body.style.overflow="auto";
  }

   addAdditionalDocumentType(documentTypeId:any,index:any)
   {
    const data = {
      documentTypeId: documentTypeId,
      index: index
    };
    this.addAdditionalDocumentClicked.emit(data); 
    this.closePopup();   
   }

   getModalStyles()
   { 

    if(this.displayStyle=="block") document.body.style.overflow="hidden"
    var width='35%'; //defualt width
    var height='160px'; //defualt height
     if(this.showNewDealAdditionalDocumentTypes||this.showReturnForReworkMsg||this.showDiscontinueMsg)
     {
        
        width='600px';
        if(this.showNewDealAdditionalDocumentTypes){height='320px'}
        else
        {
          height='300px';
        }
     }   
     
     if(this.showMissingRolesMsg){
      height = "220px"
     }
     if(this.showMissingEHOMsg){
      height="178px"
     }
    return {'width':width,'height':height};
   }

}

