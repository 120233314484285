import { Component, EventEmitter, OnInit, Output, Input, ViewChild } from '@angular/core';
import { ContentManagement} from 'src/app/models/contentManagement';
import { ContentService } from 'src/app/services/content.service';
import { NotifyPopupComponent } from 'src/shared/components/alerts/notify-popup/notify-popup.component';
import { ContentManagementItem } from 'src/app/models/contentManagementItem';
import { AdminService } from 'src/app/services/admin.service';
import { ContentManagementComponent } from '../content-management/content-management.component';
import { CommonHelperService } from 'src/app/commonHelper/common-helper.service';
import { SpinnerService } from 'src/shared/services/spinner.service';

import { NewDealSetupSharepointMapper } from 'src/shared/utils/new-deal-setup-sharepoint';
import { FormGroup } from '@angular/forms';
import { FormValidations } from 'src/shared/utils/form-validations';
import { DropdownItem } from '../../../shared/models/dropdown-item';
import { SecurityService } from '../../../shared/services/security.service';
import { NewdealService } from 'src/app/services/newdeal.service';    
import {MainService} from '../../../shared/services/main.service';
import { PeoplepickerService } from 'src/shared/services/peoplepicker.service';
import { PeoplePickerUser } from 'src/shared/models/people-picker-user';
import { PeoplepickerComponent } from 'src/shared/components/peoplepicker/peoplepicker.component';
import { Guid } from 'guid-typescript';
const modalTypes  = {
  lcspmismatch:'lcsp-mismatch'
};
const formGroups = {
  clientOverViewFormGroup: 'clientOverViewFormGroup',
};

@Component({
  selector: 'app-add-content-popup',
  templateUrl: './add-content-popup.component.html',
  styleUrls: ['./add-content-popup.component.scss']
})
export class AddContentPopupComponent implements OnInit {
  
  contentManagementData:any;
  oldContentManagementData:any;
  sharePointMapper = new NewDealSetupSharepointMapper();
  currents24Options: Array<DropdownItem> = [];
  series24LeftDCF: boolean;
  DealTeamSearchText:any;
  DealADData:any=[];
  leadClientServicePartner: Array<PeoplePickerUser> = [];
  myDealFormGroup: FormGroup;
  contentDataArray:Array<any>=[];
  displayStyle = "none";
  displayVerifyStyle="none";
  verifierData:any;
  modifiedBy:any="";
  isTextControl=true;
  verifyDescription:any="";
  ITSAdminComments:any="";
  buttonType:any="Save";
  DocTypeTitle:any="";
  isEncrypted:any=true;
  RepresenatativeList:any=[];
  uploadFileData:any;
  isExistUpdated:any=false;
  DocTypeList:any=[];
  isDisabled=false;
  appFormGroup: any;
  attachmentsMaxSize:any = 104857600;
 maxAllowedFileLength:any = 128;
 initialNewDealList:any;
 modalType: string;
  @Input() TableName: string;
  @Input() ContentType: string;
  @Input() showDynamicTable:boolean;
  @Input() editTemplate:any;
  @Input() initialContentManagementList: Array<ContentManagementItem> = [];
  @Input() initialContentManagementData: Array<any> = [];
  @Output() SaveAndUpdateContentDetails: EventEmitter<any> = new EventEmitter<any>();
  @Input() formGroups: Array<FormGroup>;
  @Output() CloseContentDetails: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(NotifyPopupComponent, { static: true }) notifypopupComponent: NotifyPopupComponent;
  @ViewChild('PeoplePicker')
  peoplepickerComponent: PeoplepickerComponent;
  @ViewChild(PeoplepickerComponent) peoplePicker: PeoplepickerComponent;
  excludedUsers: Array<PeoplePickerUser> = [];

  newContentManagementData: any;
  newContentManagementOldData: any;
  constructor(public contentService:ContentService,private peoplepickerService: PeoplepickerService, private newDealService:NewdealService, private securityService: SecurityService,private mainservice:MainService,private newdealservice:NewdealService,public adminService:AdminService,public contentManagementService:ContentManagementComponent,private spinnerService:SpinnerService,private commonHelperService:CommonHelperService) { }

  ngOnInit(): void {    
    if(!this.showDynamicTable)
    {
      this.contentManagementData = new ContentManagement();
      this.oldContentManagementData = new ContentManagement();
    }
    this.clearText();
  }

  clearText() {
    if(!this.showDynamicTable)
    {
      this.contentManagementData = new ContentManagement();
      this.oldContentManagementData = new ContentManagement();
      this.isDisabled=false;
    }
    else
    {
      this.contentManagementData = {}; 
      this.oldContentManagementData = {};
    }
    this.isDisabled=false;
  }

  openPopup(operationType:any,editData?:any) {
    this.GetS24Users();
    //this.loadADGroupData(searchText,isDealTeamTable)
    //var leadClientServicePartnerResponse= this._getLCSPPeoplepickerUserData(editData);
    // this.setLeadClientServicePartner(leadClientServicePartnerResponse);
    //if(editData!=undefined) {editData=this.appendLCSPValues(editData);}
    if (this.TableName === "NewDealSetupForm" && editData !== undefined) {
      var leadClientServicePartnerResponse = this._getLCSPPeoplepickerUserData(editData);
      this.setLeadClientServicePartner(leadClientServicePartnerResponse);
    }
    this.loadDropdownValues(editData);
    this.isTextControl=true;
    this.displayStyle = "block";
    this.clearText(); 
    // Call clearSearchInput from PeoplepickerComponent
   if (this.peoplePicker) {
       this.peoplePicker.clearSearchInput();
      }   
    if(operationType=="Update"){
     this.buttonType="Update";
     this.LoadControls(editData);
     this.loadOldData();
     //dynamically load data   
     //this.newContentManagementData = JSON.parse(JSON.stringify(editData)); // Create a deep copy
     //store data for comparison
     //this.newContentManagementOldData = JSON.parse(JSON.stringify(this.newContentManagementData));
    }
    else{
      this.buttonType="Save";            
    }
   
  }
  private _getLCSPPeoplepickerUserData(editData:any): Array<PeoplePickerUser>{

    const peoplePickerUserList = new Array<PeoplePickerUser>();
    let lcspEmail:any,lcspName:any;
    if ((editData.LeadClientServicePartnerDisplayNames!=undefined && editData.LeadClientServicePartnerDisplayNames!=null) &&
    (editData.LeadClientServicePartner!=undefined && editData.LeadClientServicePartner!=null)) 
    {
      if(editData.LeadClientServicePartner.includes(";#"))
      {
        lcspName = editData.LeadClientServicePartnerDisplayNames?.split(";#");
        lcspEmail = editData.LeadClientServicePartner?.split(";#");

        if (lcspEmail.length==lcspName.length) 
        {
          for (let i=0;i<=lcspEmail.length-1;i++) 
          {
            let user=new PeoplePickerUser();
            user.displayName=lcspName[i];
            user.mail=lcspEmail[i];
            user.DisplayText=lcspName[i];
            peoplePickerUserList.push(user);
          }
        } 
        else 
        {
          this._openModal(modalTypes.lcspmismatch)
        }
      }
      else
      {
          let user = new PeoplePickerUser();
          user.displayName = editData.LeadClientServicePartnerDisplayNames;
          user.mail = editData.LeadClientServicePartner;
          user.DisplayText = editData.LeadClientServicePartnerDisplayNames;
      
          if (user.displayName && user.displayName !== '-' && user.mail && user.mail !== '-') {
              peoplePickerUserList.push(user);
          }
      }
    }
    return peoplePickerUserList;
}
    appendLCSPValues(editData:any){
    //  this.sharePointMapper.fillFormGroup(this.appFormGroup.get(formGroups.clientOverViewFormGroup) as FormGroup, editData);
    this.initialNewDealList.LeadClientServicePartnerId.editData=[];
   this.initialNewDealList.LeadClientServicePartnerId.editData=  this._getLCSPPeoplepickerUserDataForNewDeal(editData.LeadClientServicePartner,editData.LeadClientServicePartnerDisplayNames);
   if(this.initialNewDealList.LeadClientServicePartner==""||this.initialNewDealList.LeadClientServicePartnerDisplayNames==""){this.initialNewDealList.LeadClientServicePartner=null;this.initialNewDealList.LeadClientServicePartnerDisplayNames=null;}
   return this.initialNewDealList;
  }
  private _getLCSPPeoplepickerUserDataForNewDeal(responseemail:any,responseName:any): any{

    const peoplePickerUserList = new Array<PeoplePickerUser>();
    if(responseemail!=null&&responseemail!=undefined&&responseemail!=""){
    if(responseemail.includes(";#")){
      let lcspemail=responseemail.split(";#");
      let lcspname=responseName.split(";#");
    if (lcspemail.length==lcspname.length) {
      for (let i=0;i<=lcspemail.length-1;i++) {
        let user=new PeoplePickerUser();
        user.displayName=lcspname[i];
        user.mail=lcspemail[i];
        user.DisplayText=lcspname[i];
        peoplePickerUserList.push(user);
        //user=
      }
    } else {
      this._openModal(modalTypes.lcspmismatch)
      //resolve(peoplePickerUserList);
    }
  }
  else{
    let user=new PeoplePickerUser();
    user.displayName=responseName;
    user.mail=responseemail;
    user.DisplayText=responseName;
    peoplePickerUserList.push(user);
  }
}
    return peoplePickerUserList;
}
private _openModal(modalType: string) {
  this.modalType = modalType;
  this.notifypopupComponent.openPopup('NewDeal',modalType);

}
  hasPermission(action: string): boolean {
    return !!this.securityService.hasPermission(action);
  }
  GetS24Users() {
    this.newdealservice.GetS24Users().subscribe(
      {
        next: (result: any) => {
           this.mainservice.myDealS24List=result;
          const s24dropdownItems = result.map((item: any) => {
            const dropdownItem = new DropdownItem();
            dropdownItem.key = item.Email;
            dropdownItem.value = item.LastName+', '+item.FirstName; 
            return dropdownItem;
          }).sort((a:any,b:any)=>a.value > b.value?1:-1);
          this.currents24Options = s24dropdownItems;  
        },
        error: (err: any) => {
          if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
          //this.spinnerService.hideAutoSaveSpinner();
          console.log("Error" + err);
        } 
      })
  }
  setLeadClientServicePartner(leadClientServicePartner:any) {
    this.leadClientServicePartner = leadClientServicePartner;
  }
  loadADGroupData(searchText:any,isDealTeamTable:any):any{
    if(isDealTeamTable){
     this.DealTeamSearchText=searchText;
      if (this.DealADData.length == 0)
        return this.DealADData=this.newDealService.GetDealADGroupDetails();
      else
        return this.DealADData;
    }
    else{
      return this.newDealService.GetLCSPADGroupDetails(searchText);
    }
    }
  onSelectedPeoplePickerUser(user: PeoplePickerUser, inputName: string) {
    if(this.contentManagementData.LeadClientServicePartner!=undefined &&
      this.contentManagementData.LeadClientServicePartner!="" &&
      this.contentManagementData.LeadClientServicePartner!="-")
    {
      this.contentManagementData.LeadClientServicePartner= this.contentManagementData.LeadClientServicePartner +
        ";#"+ user.mail;
    }
    else
    {
      this.contentManagementData.LeadClientServicePartner= user.mail;
    }
    
    if(this.contentManagementData.LeadClientServicePartnerDisplayNames!=undefined && 
      this.contentManagementData.LeadClientServicePartnerDisplayNames!="" &&
      this.contentManagementData.LeadClientServicePartnerDisplayNames!="-")
    {
      this.contentManagementData.LeadClientServicePartnerDisplayNames = this.contentManagementData.LeadClientServicePartnerDisplayNames +
      ";#"+user.displayName;
    }
    else
    {
      this.contentManagementData.LeadClientServicePartnerDisplayNames = user.displayName;
    }    
  }

  onRemovedPeoplePickerUser(user: PeoplePickerUser, inputName: string) {

    let userEmails = this.contentManagementData.LeadClientServicePartner.split(";#");
    if(userEmails!=null && userEmails.length > 0)
    {
      userEmails = userEmails.filter((x: any)=>x.toLowerCase() != user.mail.toLowerCase());
      this.contentManagementData.LeadClientServicePartner = userEmails.join(";#");
    }
    let userDisplayNames = this.contentManagementData.LeadClientServicePartnerDisplayNames.split(";#");
    if(userDisplayNames!=null && userDisplayNames.length > 0)
    {
      userDisplayNames = userDisplayNames.filter((x:any)=>x.toLowerCase() != user.displayName.toLowerCase());
      this.contentManagementData.LeadClientServicePartnerDisplayNames = userDisplayNames.join(";#");
    }
  }
  updatedExcludedUsers(currentUsers: Array<PeoplePickerUser>) {
    this.excludedUsers = currentUsers;
  }
  
  onCurrentSeries24Selection(event : any ) {
    this.contentManagementData.Series24 = event.key;
    this.contentManagementData.Series24DisplayNames = event.value;
  }
  editedExternally(formControlName: string): boolean {
    const formGroup = this._getFormGroup(formControlName);
    if (formGroup) {
      return FormValidations.editedExternally(formGroup, formControlName);
    }
    return false;
  }
    // TODO, create a validation message for Previous PPMD when a User lefts the List.
    setSeries24WarningMessage(value:any) {
      this.series24LeftDCF = value;
    }

  private _getFormGroup(formControlName: string): FormGroup {
    return this.sharePointMapper.getFormGroup(formControlName, this.formGroups);
  }
 
  clearApprovalText(){
    this.verifyDescription="";  
    this.ITSAdminComments="";
  }
  openVerificationPopup(operationType:any,verifyData?:any) {
     this.displayVerifyStyle = "block";
     this.verifierData=verifyData;
     this.clearApprovalText();  
     this.DocTypeTitle=verifyData.DocumentTypeTitle;
     if(verifyData.VerificationDescription!=null)
     this.verifyDescription=verifyData.VerificationDescription.includes("#;")?this.formatDescription(verifyData.VerificationDescription):verifyData.VerificationDescription;
     this.modifiedBy=verifyData.ModifiedBy?.toString();
     this.spinnerService.hideAutoSaveSpinner();   
  }
  formatDescription(desc:any){
   return desc=desc.split("#;").join("\n");
  }
  closeVerificationPopup() {
    this.CloseContentDetails.emit("true");
    this.displayVerifyStyle = "none";
  }
  loadOldData(){
  {
    if(this.showDynamicTable)
    {
      this.oldContentManagementData = JSON.parse(JSON.stringify(this.contentManagementData));
    }
    else
    {
      switch(this.TableName)
      {
          case "MyDeal":
              this.oldContentManagementData.ID = this.contentManagementData.ID;
              this.oldContentManagementData.EngagementName = this.contentManagementData.EngagementName;
              this.oldContentManagementData.Series24 = this.contentManagementData.Series24;
              this.oldContentManagementData.Series24DisplayNames = this.contentManagementData.Series24DisplayNames;
              this.oldContentManagementData.WbsCode = this.contentManagementData.WbsCode;
              this.oldContentManagementData.rangeKey = this.contentManagementData.rangeKey;
              break;
          case "NewDealSetupForm":
              this.oldContentManagementData.MyDealEngagementName = this.contentManagementData.MyDealEngagementName;
              this.oldContentManagementData.ID = this.contentManagementData.ID;
              this.oldContentManagementData.MyDealEngagementName = this.contentManagementData.MyDealEngagementName;
              this.oldContentManagementData.LeadClientServicePartner = this.contentManagementData.LeadClientServicePartner;
              this.oldContentManagementData.LeadClientServicePartnerDisplayNames = this.contentManagementData.LeadClientServicePartnerDisplayNames;
              this.oldContentManagementData.SwiftCode = this.contentManagementData.SwiftCode;
              this.oldContentManagementData.FormStatus = this.contentManagementData.FormStatus;
              break;
      default :
        this.oldContentManagementData.ID=this.contentManagementData.ID;
        this.oldContentManagementData.Title=this.contentManagementData.Title;
        this.oldContentManagementData.Value=this.contentManagementData.Value;
        this.oldContentManagementData.Name=this.contentManagementData.Name;
        this.oldContentManagementData.DocumentTypeOrder=this.contentManagementData.DocumentTypeOrder;
        this.oldContentManagementData.DocumentTypeTitle=this.contentManagementData.DocumentTypeTitle;
        this.oldContentManagementData.DependentDocumentTypes=this.contentManagementData.DependentDocumentTypes;
        this.oldContentManagementData.Required=this.contentManagementData.Required;
        this.oldContentManagementData.Guidance=this.contentManagementData.Guidance;
        this.oldContentManagementData.BusinessReviewApplies=this.contentManagementData.BusinessReviewApplies;
        this.oldContentManagementData.QRMReviewApplies=this.contentManagementData.QRMReviewApplies;
        this.oldContentManagementData.ComplianceReviewApplies=this.contentManagementData.ComplianceReviewApplies;
        this.oldContentManagementData.IndustryTitle=this.contentManagementData.IndustryTitle;
        this.oldContentManagementData.Representative=this.contentManagementData.Representative;
        this.oldContentManagementData.DocumentType=this.contentManagementData.DocumentType;
        this.oldContentManagementData.RequiredGroupFields=this.contentManagementData.RequiredGroupFields;
        this.oldContentManagementData.RequiredFields=this.contentManagementData.RequiredFields;
        this.oldContentManagementData.CreatedBy=this.contentManagementData.CreatedBy;
        this.oldContentManagementData.FormID=this.contentManagementData.FormID;
        this.oldContentManagementData.BusinessReviewer=this.contentManagementData.BusinessReviewer;
        this.oldContentManagementData.BusinessReviewStatus=this.contentManagementData.BusinessReviewStatus;
        this.oldContentManagementData.QRMReviewer=this.contentManagementData.QRMReviewer;
        this.oldContentManagementData.RequiresFinalVersion=this.contentManagementData.RequiresFinalVersion;
        this.oldContentManagementData.QRMReviewStatus=this.contentManagementData.QRMReviewStatus;
        this.oldContentManagementData.ComplianceReviewer=this.contentManagementData.ComplianceReviewer;
        this.oldContentManagementData.ComplianceReviewStatus=this.contentManagementData.ComplianceReviewStatus;
        this.oldContentManagementData.CommentAuthor=this.contentManagementData.CommentAuthor;
        this.oldContentManagementData.Comment=this.contentManagementData.Comment;
        this.oldContentManagementData.CommentType=this.contentManagementData.CommentType;
        this.oldContentManagementData.ReviewStatus=this.contentManagementData.ReviewStatus;
        this.oldContentManagementData.Role=this.contentManagementData.Role;
        this.oldContentManagementData.Created=this.contentManagementData.Created;
        this.oldContentManagementData.Modified=this.contentManagementData.Modified;
        this.oldContentManagementData.ModifiedBy=this.contentManagementData.ModifiedBy;
        this.oldContentManagementData.isActive=this.contentManagementData.isActive;
        this.oldContentManagementData.isApproved=this.contentManagementData.isApproved;
        this.oldContentManagementData.isRejected=this.contentManagementData.isRejected;
        this.oldContentManagementData.VerificationDescription=this.contentManagementData.VerificationDescription;
        // Cross BorderForm 
        this.oldContentManagementData.EnterpriseValue=this.contentManagementData.EnterpriseValue;
        this.oldContentManagementData.ExpectedCompanyValueComments=this.contentManagementData.ExpectedCompanyValueComments;
        this.oldContentManagementData.ProjectName=this.contentManagementData.ProjectName;
        this.oldContentManagementData.CompanySubsectorComments=this.contentManagementData.CompanySubsectorComments;
        this.oldContentManagementData.GeographConcentrationComments=this.contentManagementData.GeographConcentrationComments;
        this.oldContentManagementData.USCounterpartiesContactComments=this.contentManagementData.USCounterpartiesContactComments;
        this.oldContentManagementData.ShareholderObjectivesComments=this.contentManagementData.ShareholderObjectivesComments;
        break;
      }
    }    
  }
}
  loadDropdownValues(editData:any){
    if(this.TableName=="CrossBorderIndustries"){
      this.contentService.GetContentDetails("api/FormGroup").subscribe(
        {
          next: (result: any) => {
            result.sort((a:any, b:any) => (a.Name < b.Name ? -1 : 1));
            this.RepresenatativeList = this.commonHelperService.getActiveRecords(result);
            try {
              if(editData!=undefined){
              this.contentManagementData.Representative=editData.Representative;
              }
            } catch (error) {
              
            }         
          },
          error: (err: any) => {
            this.RepresenatativeList = [];
            if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
            this.spinnerService.hideAutoSaveSpinner();
            console.log("Error" + err)
          }
        })
    }
    else if(this.TableName=="CrossBorderTemplates"){
      this.contentService.GetContentDetails("api/CrossBorderDocumentTypes").subscribe(
        {
          next: (result: any) => {
            result.sort((a:any, b:any) => (a.DocumentTypeTitle < b.DocumentTypeTitle ? -1 : 1));
            this.DocTypeList = this.commonHelperService.getActiveRecords(result);
            try {
              if(editData!=undefined){
              this.contentManagementData.DocumentType=editData.DocumentType;
              }
            } catch (error) {
              
            }           
          },
          error: (err: any) => {
            this.RepresenatativeList = [];
            if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
            this.spinnerService.hideAutoSaveSpinner();
            console.log("Error" + err)
          }
        })
    }
  }
  LoadControls(data:any){
    this.isDisabled= this.EnableAndDisable();
    this.contentManagementData.ID=data?.ID;
    this.contentManagementData.CreatedBy=data?.CreatedBy;
    this.contentManagementData.Modified=data?.Modified;
    this.contentManagementData.ModifiedBy=data?.ModifiedBy;
    this.contentManagementData.Created=data?.Created;
    this.contentManagementData.isActive=data?.isActive;
    

    if(this.showDynamicTable)
    {
      if(this.editTemplate!=undefined && this.editTemplate!=null)
        {
          //get fields data and bind to the controls
          this.editTemplate.Fields.forEach((element:any) => {
            switch(element.controltype)
            {
              case "checkbox":
                let isTrue:Boolean = false;
                if(data[element.field]!="" && 
                  (data[element.field].toLowerCase()=="true") || data[element.field].toLowerCase()=="required")
                {
                  isTrue = true;
                }
                this.contentManagementData[element.field] = isTrue;
                break;
              default:
                this.contentManagementData[element.field] = data[element.field];
                break;
            }                        
          });
        }
    }
    else
    {
      switch (this.TableName) {
        case "ValidationFieldsCrossBorder":
          this.contentManagementData.RequiredFields=data.RequiredFields;
          this.contentManagementData.Title=data.Title;
          this.contentManagementData.RequiredGroupFields=data.RequiredGroupFields;
          break;
        case "CrossBorderTemplates":
          this.contentManagementData.Name=data.Name;
          this.contentManagementData.Title=data.Title;
          this.contentManagementData.DocumentType=data.DocumentType;
          break;
        case "CrossBorderIndustries":
          this.contentManagementData.IndustryTitle=data.Title;
          this.contentManagementData.Representative=data.Representative;
          break;
        case "CrossBorderDocumentTypes":
          this.contentManagementData.DocumentTypeOrder=data.DocumentTypeOrder;
          this.contentManagementData.DocumentTypeTitle=data.DocumentTypeTitle;
          this.contentManagementData.Guidance=data.Guidance;
          this.contentManagementData.DependentDocumentTypes=data.DependentDocumentTypes;
          this.contentManagementData.Required=(data.Required == "TRUE" ? true : false);
          this.contentManagementData.BusinessReviewApplies=(data.BusinessReviewApplies == "TRUE" ? true : false);
          this.contentManagementData.QRMReviewApplies=(data.QRMReviewApplies == "TRUE" ? true : false);
          this.contentManagementData.ComplianceReviewApplies=(data.ComplianceReviewApplies == "TRUE" ? true : false);
          this.contentManagementData.RequiresFinalVersion=(data.RequiresFinalVersion == "TRUE" ? true : false);
          this.contentManagementData.isApproved=data.isApproved?.toString();
          this.contentManagementData.isRejected=data.isRejected?.toString();
          this.contentManagementData.VerificationDescription=data.VerificationDescription?.toString();
          break;
        case "CrossBorderCountries":
          this.contentManagementData.Title=data.Title;
          break;
  
        case "CrossBorderForm":
          this.contentManagementData.FormID=data.ID;
          this.contentManagementData.ProjectName=data.ProjectName;
          this.contentManagementData.GeographConcentrationComments=data.GeographConcentrationComments;
          this.contentManagementData.USCounterpartiesContactComments=data.USCounterpartiesContactComments;
          this.contentManagementData.ShareholderObjectivesComments=data.ShareholderObjectivesComments;
          this.contentManagementData.CompanySubsectorComments=data.CompanySubsectorComments;
          this.contentManagementData.ExpectedCompanyValueComments=data.ExpectedCompanyValueComments;
          this.contentManagementData.EnterpriseValue=data.EnterpriseValue;
                break;
  
         case "MyDeal":
          this.contentManagementData.ID=data.ID;
          this.contentManagementData.EngagementName=data.EngagementName;
          this.contentManagementData.Series24=data.Series24;
          this.contentManagementData.Series24DisplayNames=data.Series24DisplayNames;
          this.contentManagementData.WbsCode=data.WbsCode;
          this.contentManagementData.rangeKey=data.rangeKey;
              break; 

          case "NewDealSetupForm":
          this.contentManagementData.ID = data.ID;
          this.contentManagementData.MyDealEngagementName = data.MyDealEngagementName;
          this.contentManagementData.LeadClientServicePartner = data.LeadClientServicePartner;
          this.contentManagementData.LeadClientServicePartnerDisplayNames = data.LeadClientServicePartnerDisplayNames;
          this.contentManagementData.SwiftCode = data.SwiftCode;
          this.contentManagementData.FormStatus = data.FormStatus;
          this.contentManagementData.rangeKey = data.rangeKey;
              break; 
  
        case "CrossBorderAdditionalDocumentTypes":
          this.contentManagementData.FormID=data.FormID;
          this.contentManagementData.DocumentType=data.DocumentType;      
          break;
  
        case "CrossBorderApprovalReviewStatus":
          this.contentManagementData.FormID=data.FormID;
          this.contentManagementData.DocumentType=data.DocumentType;
          this.contentManagementData.BusinessReviewer=data.BusinessReviewer;
          this.contentManagementData.BusinessReviewStatus=data.BusinessReviewStatus;
          this.contentManagementData.ComplianceReviewer=data.ComplianceReviewer;
          this.contentManagementData.ComplianceReviewStatus=data.ComplianceReviewStatus;
          this.contentManagementData.QRMReviewer=data.QRMReviewer;
          this.contentManagementData.QRMReviewStatus=data.QRMReviewStatus;
          //this.contentManagementData.BusinessReviewDate=data.BusinessReviewDate;
          //this.contentManagementData.ComplianceReviewDate=data.ComplianceReviewDate;
          //this.contentManagementData.QRMReviewDate=data.QRMReviewDate;
          //this.contentManagementData.AdditionalReviewNeeded=data.AdditionalReviewNeeded;
          //this.contentManagementData.AutomaticReviewDate=data.AutomaticReviewDate;
          //this.contentManagementData.CrossBorderreviewprocessworkflow=data.CrossBorderreviewprocessworkflow;
          break;
  
        case "CrossBorderAttachments":
          this.contentManagementData.FormID=data.FormID;
          this.contentManagementData.DocumentType=data.DocumentType;
          this.contentManagementData.Title=data.Title;
          this.contentManagementData.Name=data.Name;
          break;
  
        case "CrossBorderComments":
          this.contentManagementData.FormID=data.FormID;
          this.contentManagementData.DocumentType=data.DocumentType;
          this.contentManagementData.CommentAuthor=data.CommentAuthor;
          this.contentManagementData.CommentType=data.CommentType;
          this.contentManagementData.Comment=data.Comment;
          this.contentManagementData.ReviewStatus=data.ReviewStatus;
          break;
  
        case "CrossBorderRecommendedTemplates":
          this.contentManagementData.FormID=data.FormID;
          this.contentManagementData.DocumentType=data.DocumentType;
          this.contentManagementData.Title=data.Title;
          this.contentManagementData.Name=data.Name;
          break;
  
        case "CrossBorderRedLineAttachments":
          this.contentManagementData.FormID=data.FormID;
          this.contentManagementData.DocumentType=data.DocumentType;
          this.contentManagementData.Name=data.Name;
          break;
  
        case "UserGroups":
          this.contentManagementData.Role=data.Role;
          break;
  
        case "FormGroups":
            this.contentManagementData.Name=data.Name;
            this.contentManagementData.FormTypeID = data.FormTypeID;
            break;
  
          case "FormType":
            this.contentManagementData.Name=data.Name;
            break;
            case "AppConfigurations":
              this.contentManagementData.Title=data.Title;
              this.contentManagementData.Value=this.BindConfigValue(data.Value);
              this.GetControlType(data.Value);
              break;
  
        default:
          break;
      }
    }    
  }
  BindConfigValue(Value:any){
    let res;
  if(Value.toLowerCase()=="false"){
    res=false;
  }
  else if(Value.toLowerCase()=="true"){
    res=true;
  }
  else{
    res=Value;
  }
  return res;
  }
  GetControlType(data:any){
    try{
      if(data.toLowerCase()=="true"||data.toLowerCase()=="false"){
        this.isTextControl=false;
      }
      else{
        this.isTextControl=true;
      }
    }
    catch(e){

    }

  }
  handleFileInput(event:any){
    if(this.isFileValid(event.files[0])){
      this.uploadFileData=[];
    this.uploadFileData=event.files[0];
    this.contentManagementData.Name=event.files[0].name.toString();
    }
  }
  closePopup() {
    this.CloseContentDetails.emit("true");
    this.displayStyle = "none";
  }
  isFileValid(fileData:any){
    let isFileValid=true;
    if(fileData!=undefined){
    //Max FileName Length
    if(fileData.name.length >this.maxAllowedFileLength){this.notifypopupComponent.openPopup('Content','maxFileName');isFileValid=false;}  
    //Max File Size 
    else if(fileData.size >this.attachmentsMaxSize){ this.notifypopupComponent.openPopup('Content','maxFileSize');isFileValid=false;}
    //File Empty
    else if(fileData.size <=0){ this.notifypopupComponent.openPopup('Content','fileEmpty');isFileValid=false;}
    //File Name Empty
    else if(fileData.name.length <=0){this.notifypopupComponent.openPopup('Content','fileNotSelected');isFileValid=false;} 
    //Duplicate File Upload
   else if(fileData.name==this.oldContentManagementData.Name){this.notifypopupComponent.openPopup('Content','fileDuplicate');isFileValid=false;}
    //Encrypted File 
   else if(!this.IsEncryptedFile(fileData)){ this.notifypopupComponent.openPopup('Content','fileEncrypted'); isFileValid=false;}
    }
    else{
      //  this.notifypopupComponent.openPopup('Content','fileNotSelected');
      isFileValid=false;
    }
    return isFileValid;
  }
  
  IsEncryptedFile(fileSource:any){  
    this.isEncrypted=true;
    const fileData=new FormData();
    fileData.append('file',fileSource);
    this.spinnerService.showAutoSaveSpinner();
    this.contentService.isDocumentEncrypted(fileData).subscribe({
      next: (result: any) => {
       this.isEncrypted=result; 
       this.spinnerService.hideAutoSaveSpinner();
       if(!result){
        this.contentManagementData.Name="";
        this.notifypopupComponent.openPopup('Content','fileEncrypted');
       }
      },
      error: (err: any) => {
        this.contentManagementData.Name="";
        if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
        this.spinnerService.hideAutoSaveSpinner();
        console.log("Error" + err)
        this.isEncrypted=true;
      }
    })
  return this.isEncrypted;
  }
  submitContentData() {
    this.contentDataArray=[];
    
    //Add ID field values for dynamic tables if required
    if(this.showDynamicTable && this.buttonType.toUpperCase()=="SAVE")
    {
      this.editTemplate.Fields.forEach((element:any) => {
        if(element.field == this.editTemplate.partitionKey && element.showInAdd == false)
        {
          this.contentManagementData[this.editTemplate.partitionKey] = Guid.create().toString();
        }
      });
    }

    this.contentDataArray.push(this.oldContentManagementData);
    this.contentDataArray.push(this.contentManagementData);
    this.contentDataArray.push(this.buttonType);
    this.contentDataArray.push("IsRecordAlreadyExist");
    if(this.TableName=="CrossBorderTemplates"){this.contentDataArray.push(this.uploadFileData);}
    if (this.isValidated(this.contentDataArray[1])) {
      switch (this.buttonType) {
        case "Save":
          if (!this.isRecordAlreadyExists(this.contentDataArray[1])) {
            this.SaveAndUpdateContentDetails.emit(this.contentDataArray);
            this.clearText();
            this.displayStyle = "none";
          }
          else {
            if(this.isExistUpdated){
              this.contentDataArray[3]="RecordAlreadyExist";
              this.SaveAndUpdateContentDetails.emit(this.contentDataArray);
              this.clearText();
              this.displayStyle = "none";
            }
            else{
            this.notifypopupComponent.openPopup("Content", "AlreadyExisted");
            return;
            }
          }
          break;
        case "Update":
          if(this.isDetailsUpdated(this.contentDataArray)){
          this.SaveAndUpdateContentDetails.emit(this.contentDataArray);
          this.clearText();
          this.displayStyle = "none";
          }
          else{
            this.notifypopupComponent.openPopup("Content", "isNotUpdated");
            return;
          }
          break;
        default:
          break;
      }
    }
    else {
      this.notifypopupComponent.openPopup("Content", "Info");
      return;
    }
  }
   //If user click directly the update button without modifying anything
  isDetailsUpdated(resultantData: any) {
    let isUpdated = false;
  
    // Define the fields to check for new deal setup form transactional table and My deal Transactional table
    const fieldsToCheck = ['SwiftCode', 'LeadClientServicePartnerDisplayNames', 'LeadClientServicePartner','WbsCode','Series24','Series24DisplayNames','EngagementName',];
  
    // Check if the condition is met
    if (this.TableName === 'NewDealSetupForm'||this.TableName === 'MyDeal') {
      for (let Array1 in resultantData[0]) {
        for (let Array2 in resultantData[1]) {
          if (Array1 === Array2 && fieldsToCheck.includes(Array1)) {
            if (resultantData[0][Array1] != resultantData[1][Array2]) {
              isUpdated = true;
            }
          }
        }
      }
    } else {
      // Default comparison logic if the condition is not met
      for (let Array1 in resultantData[0]) {
        for (let Array2 in resultantData[1]) {
          if (Array1 != "isActive" && Array1 == Array2) {
            if (resultantData[0][Array1] != resultantData[1][Array2]) {
              isUpdated = true;
            }
          }
        }
      }
    }
  
    return isUpdated;
  }

  isITSApproved(event:any)
  {
    if(this.showDynamicTable)
    {
      var appendURL = this.contentService.getAppendURL(this.TableName);
      let UpdateRequestBody = this.prepareDynamicITSRequest(this.verifierData, "Approved"); 
      this.contentManagementService.UpdateContentDetails(appendURL,UpdateRequestBody,"ApprovedSuccess");
      this.AuditITSAdminActions("Approved");
      this.displayVerifyStyle="none";
    }
    else
    {
      if (this.verifierData != null) 
      {
        var appendURL = this.contentService.getAppendURL(this.TableName);
        let UpdateRequestBody = this.prepareITSRequest(this.verifierData, "Approved"); 
        this.contentManagementService.UpdateContentDetails(appendURL,UpdateRequestBody,"ApprovedSuccess");
        this.AuditITSAdminActions("Approved");
        this.displayVerifyStyle="none";
      }
    }    
  }

  isITSRejected(event:any)
  {
    if(this.showDynamicTable)
    {
      var appendURL = this.contentService.getAppendURL(this.TableName);
      let UpdateRequestBody = this.prepareDynamicITSRequest(this.verifierData, "Rejected"); 
      this.contentManagementService.UpdateContentDetails(appendURL,UpdateRequestBody,"RejectedSuccess");
      this.AuditITSAdminActions("Rejected");
      this.displayVerifyStyle="none";
    }
    else
    {
      if (this.verifierData != null) 
      {
        var appendURL = this.contentService.getAppendURL(this.TableName);
        let UpdateRequestBody = this.prepareITSRequest(this.verifierData, "Rejected"); 
        this.contentManagementService.UpdateContentDetails(appendURL,UpdateRequestBody,"RejectedSuccess");
        this.AuditITSAdminActions("Rejected");
        this.displayVerifyStyle="none";
       }
    }
   
  }
  prepareITSRequest(event:any,operation:any){
    let RequestBody={};
    return RequestBody = {
      ID: event.ID,
      ModifiedBy: event.ModifiedBy,
      CreatedBy: event.CreatedBy,
      isActive: event.isActive,
      DocumentTypeOrder: event.DocumentTypeOrder.toString(),
      DocumentTypeId: event.DocumentTypeOrder.toString(),
      DocumentTypeTitle: event.DocumentTypeTitle,
      Required: event.Required,
      Guidance: event.Guidance,
      BusinessReviewApplies: event.BusinessReviewApplies,
      QRMReviewApplies: event.QRMReviewApplies,
      ComplianceReviewApplies: event.ComplianceReviewApplies,
      RequiresFinalVersion: event.RequiresFinalVersion,
      DependentDocumentTypes: event.DependentDocumentTypes,
      isApproved:operation == "Approved" ? "Yes" : "No",
      isRejected:operation == "Rejected" ? "Yes" : "No",
      VerificationDescription: event.VerificationDescription
    };
  }

  prepareDynamicITSRequest(event:any,operation:any)
  {
    let RequestBody:any={};
    let data:any = {};
    data[this.editTemplate.partitionKey] = event[this.editTemplate.partitionKey];
    if(this.editTemplate.rangeKey!="")
    {
      data[this.editTemplate.rangeKey] = event[this.editTemplate.rangeKey];
    }
    RequestBody.isApproved = (operation == "Approved" ? "Yes" : "No"),
    RequestBody.isRejected = (operation == "Rejected" ? "Yes" : "No"),
    data.updateData = this.cleanJsonObject(RequestBody);  
    return data;  
  }

  cleanJsonObject(obj:any) {
    for (const key in obj) {
      if (obj[key] === null||obj[key] === undefined) {
        obj[key] = '';
      } else if (typeof obj[key] === 'object') {
        this.cleanJsonObject(obj[key]);
      }
    }
    return obj;
  }
  AuditITSAdminActions(action:any){
  let AdminComments=this.ITSAdminComments.length>0?" #; Below are comments provided by ITS Admin #;"+this.ITSAdminComments:"";
  let AuditRequest={};
     AuditRequest = {
      ID: "",
      ModifiedBy: this.verifierData.ModifiedBy,
      ModifiedOn: "",
      Status: "Yes",
      TableName: this.TableName,
      ContentType:this.ContentType,
      Description: "Below action has been "+action+" by the ITSAdmin #;"+this.verifierData.VerificationDescription+ AdminComments
    }; 
    this.contentManagementService.SaveAuditDetails(AuditRequest);
  }
  AuditDynamicITSAdminActions(action:any)
  {
    let AdminComments=this.ITSAdminComments.length>0?" #; Below are comments provided by ITS Admin #;"+this.ITSAdminComments:"";
    let AuditRequest={};
       AuditRequest = {
        ID: "",
        ModifiedBy: this.verifierData.ModifiedBy,
        ModifiedOn: "",
        Status: "Yes",
        TableName: this.TableName,
        ContentType:this.ContentType,
        Description: "Below action has been "+action+" by the ITSAdmin #;"+this.verifierData.VerificationDescription+ AdminComments
      }; 
      this.contentManagementService.SaveAuditDetails(AuditRequest);
  }
  isValidated(data:any)
  {
    let isValid=true;
    if(this.showDynamicTable)
    {
      // for save form the data object will be empty hence added this check.
      if (Object.keys(data).length === 0) {
        return false;
      }  
    
      this.editTemplate.Fields.forEach((element: any) => {
        if (element.required && element.controltype !== "checkbox" &&
            ((this.buttonType.toUpperCase()=="SAVE" && element.showInAdd) || 
             (this.buttonType.toUpperCase()=="UPDATE" && element.showInEdit))
        ) 
        {
          if (!(element.field in data) || data[element.field].toString().trim() == "" || data[element.field] === null || data[element.field] === undefined) {
            isValid = false;
          }
        }
      });
      return isValid;
    }
    else
    {
      switch(this.TableName){
        case "CrossBorderCountries": { 
             return isValid=data.Title==""?false:true;
          break; 
        } 
        case "CrossBorderDocumentTypes": { 
          if(data.DocumentTypeOrder==""){isValid=false;}
          if(data.DocumentTypeTitle==""){isValid=false;}
          if(data.Guidance==""){isValid=false;}
          return isValid;
          break; 
        } 
      case "CrossBorderIndustries": { 
        if(data.Representative==""){isValid=false;}
        if(data.IndustryTitle==""){isValid=false;}
        return  isValid;
      break; 
      } 
      case "CrossBorderTemplates": { 
        if(data.Name==""){isValid=false;}
        if(data.Title==""){isValid=false;}
       // if(data.DocumentType==""){isValid=false;}
        return isValid; 
      break; 
      } 
      case "ValidationFieldsCrossBorder": { 
        if(data.Title==""){isValid=false;}
        if(data.RequiredGroupFields==""){isValid=false;}
        if(data.RequiredFields==""){isValid=false;}
        return  isValid;
        break; 
      } 
      case "FormGroups":
      case "FormType": { 
        return isValid=data.Name==""?false:true;
        break; 
      } 
      case "CrossBorderForm":{
        if(data.ID=="" || data.EnterpriseValue=="" || data.CompanySubsectorComments==""||data.ShareholderObjectivesComments==""||data.GeographConcentrationComments==""){isValid=false;}      
        return isValid;
      }
      case "MyDeal":{
        if(data.ID=="" || data.EngagementName=="" || data.Series24DisplayNames==""||data.WbsCode==""){isValid=false;}      
        return isValid;
      }
      case "NewDealSetupForm":{
        if(data.LeadClientServicePartnerDisplayNames=="" || data.SwiftCode==""){isValid=false;}      
        return isValid;
      }
      case "CrossBorderApprovalReviewStatus":
        if(data.BusinessReviewStatus=="-" && data.ComplianceReviewStatus=="-" && data.QRMReviewStatus=="-") isValid = false;
        return isValid;
      case "AppConfigurations": { 
        return isValid=data.Value.toString()==""?false:true;
        break; 
      } 
      default: { 
            //statements; 
            return false;
            break; 
      }
    }     
  }
    
  }
  EnableAndDisable(){
    let isdisabled=false;
    switch(this.TableName){
      case "CrossBorderCountries": { 
           return isdisabled=true;
        break; 
      } 
      case "CrossBorderDocumentTypes": { 
        return isdisabled=true;
        break; 
      } 
    case "CrossBorderIndustries": { 
      return isdisabled=true;
    break; 
    } 
    case "CrossBorderTemplates": { 
      return isdisabled=true;
    break; 
    } 
    case "ValidationFieldsCrossBorder": { 
      return isdisabled=true;
      break; 
    } 
    case "FormGroups":
    case "FormType": { 
      return isdisabled=true;
      break; 
    }
      case "AppConfigurations": { 
        return isdisabled=this.buttonType=="Update"?true:false;
        break; 
    } 
    default: { 
          //statements; 
          return false;
          break; 
    } 
  }
  
}
  isRecordAlreadyExists(event:any){
    let isExist=false;this.isExistUpdated=false;
    if (this.showDynamicTable) {
      // Filter fields where isDuplicateCheckfield is true
    const duplicateCheckFields = this.editTemplate.Fields.filter((field: any) => field.isDuplicateCheckfield);

    // Check if an object with matching fields exists in initialContentManagementData
    isExist = this.initialContentManagementData.some((item: any) => {
        return duplicateCheckFields.every((field: any) => {
            return item[field.field].trim() === event[field.field].trim();
        });
    });

    // If a duplicate is found, update isExistUpdated and contentDataArray
    if (isExist) {
        const duplicateItem = this.initialContentManagementData.find((item: any) => {
            return duplicateCheckFields.every((field: any) => {
                return item[field.field].trim() === event[field.field].trim();
            });
        });

        if (duplicateItem && duplicateItem.isActive === "No") {
            this.isExistUpdated = true;
            this.contentDataArray[1].ID = duplicateItem.ID;
        }
    }
    return isExist; 
    }
    else
    {
        switch(this.TableName){
          case "CrossBorderCountries": { 
            this.initialContentManagementList.forEach(x=>{
            if(x.Title==event.Title){isExist=true;if(x.isActive=="No"){this.isExistUpdated=true;this.contentDataArray[1].ID=x.ID;}}
            })
          return isExist;
            break; 
          } 
          case "CrossBorderDocumentTypes": { 
              this.initialContentManagementList.forEach(x=>{
              if(x.DocumentTypeTitle==event.DocumentTypeTitle){isExist=true;if(x.isActive=="No"){this.isExistUpdated=true;this.contentDataArray[1].ID=x.ID;}}
            })
            return isExist;
            break; 
          } 
        case "CrossBorderIndustries": { 
            this.initialContentManagementList.forEach(x=>{
            if(x.Industry==event.IndustryTitle){isExist=true;if(x.isActive=="No"){this.isExistUpdated=true;this.contentDataArray[1].ID=x.ID;}}
          })
          return isExist;
        break; 
        } 
        case "CrossBorderTemplates": { 
            this.initialContentManagementList.forEach(x=>{
            if(x.Name==event.Name&&x.DocumentType==event.DocumentType){isExist=true;if(x.isActive=="No"){this.isExistUpdated=true;this.contentDataArray[1].ID=x.ID;}}
          })
          return isExist;
        break; 
        } 
        case "ValidationFieldsCrossBorder": { 
            this.initialContentManagementList.forEach(x=>{
            if(x.RequiredFields==event.RequiredFields){isExist=true;if(x.isActive=="No"){this.isExistUpdated=true;this.contentDataArray[1].ID=x.ID;}}
          })
          return isExist;
          break; 
        } 
        case "FormGroups":
        case "FormType": { 
            this.initialContentManagementList.forEach(x=>{
            if(x.Name==event.Name){isExist=true;if(x.isActive=="No"){this.isExistUpdated=true;this.contentDataArray[1].ID=x.ID;}}
          })
          return isExist;
          break; 
        } 
        default: { 
              //statements; 
              return false;
              break; 
        } 
        }
    }
  }
}
