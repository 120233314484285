import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges, ViewChild} from '@angular/core';
// import { PeoplepickerService } from 'src/app/services/peoplepicker.service';
import { Observable, of } from 'rxjs';
import { debounceTime, map, switchMap, distinctUntilChanged, catchError } from 'rxjs/operators';
import { LcsprUser, PeoplePickerUser } from 'src/shared/models/people-picker-user';
import { SandboxImages } from '../../utils/sandbox-images';
import { PeoplePickerService } from 'src/app/services/peoplepicker.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NewdealService } from 'src/app/services/newdeal.service';
import { GlobalHelperService } from 'src/app/globalHelper/global-helper.service';
import { AppSettings } from 'src/shared/app-settings';
import { NewDealNotifyPopupComponent } from 'src/shared/components/alerts/newdeal-notify-popup/newdeal-notify-popup.component';
@Component({
  selector: 'app-peoplepicker',
  templateUrl: './peoplepicker.component.html',
  styleUrls: ['./peoplepicker.component.scss']
})
export class PeoplepickerComponent implements OnChanges {
  // allowAddUsers : boolean = true;

  @Input()
  peoplePickerUserList: any = [];

  @Input()
  sharePointGroupId = 0;
  DealTeamSearchText:any;
  @Input()
  allowMultiple = true;

  @Input()
  disabled:any = false;
  adData:any;

  @Input()
  readonly: boolean;

  @Input()
  allowToDuplicateUser = false;

  @Input()
  placeholder = 'Enter a name or email address.';

  @Input()
  excludedUsers: Array<any> = [];
  @Input()
  userLeftDCF: boolean;
  @Input()
  dealTeamTable:boolean;

  @Output()
  selectedPeoplePickerUser: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  removedPeoplePickerUser: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(NewDealNotifyPopupComponent, { static: true }) notifypopupComponent: NewDealNotifyPopupComponent;

  DealADData:any=[];
  constructor(
     private peoplepickerService: PeoplePickerService,
     private newDealService:NewdealService,
    private sandboxImages: SandboxImages,
    public globalHelperService:GlobalHelperService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.userLeftDCF) {
      this.userLeftDCF = changes.userLeftDCF.currentValue;
    }
  }


  getUnderlinedImage(): string {
    return this.sandboxImages.getUnderlineImage();
  }
  // search = (text$: Observable<any>) =>
  // text$.pipe(
  //   debounceTime(300),
  //   distinctUntilChanged(),
  //   switchMap(term => term.trim().length>2 ? this.peoplepickerService.getPeoplePickerUsers(term, this.sharePointGroupId,this.dealTeamTable) : of([])),

  //   map((results: any) => {
  //     if (this.allowToDuplicateUser) {
  //       return results;
  //     } else {

  //       if(results!=undefined)
  //       {
  //         const removeUsersSelectedOnPicker = this.allowToDuplicateUser ? results :
  //         results.filter(user => !this.peoplePickerUserList.find(userSelected => userSelected.EntityData.Email === user.mail));
  //       const removeUsersSelected =
  //         removeUsersSelectedOnPicker.filter(user => !this.excludedUsers.find(userExcluded => userExcluded.EntityData.Email=== user.mail));
  //       return removeUsersSelected;
  //       }
  //     }
  //   })
  // )
  compareUserWithSecondName(data:any){
    if(data.displayName.toString().toLowerCase().includes(',')){
     if(data.displayName.toString().toLowerCase().split(',')[1]?.toString().trim().startsWith(this.DealTeamSearchText.toLowerCase())){return true;}
    }
    return false;
  }
  private CheckSearchDataExistInNewDealADData(groups:Array<any>): any {
    let matchedUsersInAD:any=[];
    if(groups!=undefined){
    groups.forEach((grp:any)=>{
      if(grp.userPrincipalName.toString().toLowerCase().startsWith(this.DealTeamSearchText.toLowerCase())||
      grp.displayName.toString().toLowerCase().startsWith(this.DealTeamSearchText.toLowerCase())||
      this.compareUserWithSecondName(grp)){
        matchedUsersInAD.push(grp);
      }
    })
  }
    return matchedUsersInAD;
  }
  loadADGroupData(searchText:any,isDealTeamTable:any):any{
    if(isDealTeamTable){
     this.DealTeamSearchText=searchText;
      if (this.DealADData.length == 0)      
        return this.DealADData= this.newDealService.GetDealADGroupDetails();
      else
        return this.DealADData;
    }
    else{
      return this.newDealService.GetLCSPADGroupDetails(searchText);
    }
  }

  private _openSessionExpiredModal() {
    this.notifypopupComponent.openPopup(AppSettings.tokenExpiredText);
  }
  
  search = (text$:Observable<any>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term:any) => term.trim().length>2 ? this.loadADGroupData(term,this.dealTeamTable).pipe(
        catchError((err: any) => {
          console.error('Error occurred while fetching AD group data', err);
          if ( err.code=="InvalidAuthenticationToken"|| err.error.code == "InvalidAuthenticationToken") {
            this._openSessionExpiredModal();
          }
          return of([]); // Return an empty array or any default value to handle the error gracefully
        })
      ) : of([])),
      map((results: any) => {
       this.adData=this.dealTeamTable?this.CheckSearchDataExistInNewDealADData(results.value):results;
        if (this.allowToDuplicateUser) {
          return this.adData;
        } else {
          if(this.adData!=undefined && this.adData.length>0)
          {
            const removeUsersSelectedOnPicker = this.allowToDuplicateUser ? this.adData :
            this.adData.filter((user:any) => !this.peoplePickerUserList.find((userSelected:any)=> userSelected.mail === user.mail));
            var removeUsersSelected;
            // if(localStorage.getItem('CurrentAccessPage')=="DealWBSForm"||localStorage.getItem('CurrentAccessPage')=="ClosingChecklistForm"){
              if(this.globalHelperService.currentAccessPage=="DealWBSForm"||this.globalHelperService.currentAccessPage=="ClosingChecklistForm"){
              removeUsersSelected = removeUsersSelectedOnPicker.filter((user:any) => !this.excludedUsers.find(userExcluded => userExcluded.mail === user.userPrincipalName || userExcluded.mail === user.mail));
            }
            if(this.globalHelperService.currentAccessPage=="ContentManagement"){
              removeUsersSelected = removeUsersSelectedOnPicker.filter((user:any) => !this.excludedUsers.find(userExcluded => userExcluded.displayName === user.displayName));
            }
            else{
              removeUsersSelected = removeUsersSelectedOnPicker.filter((user:any) => !this.excludedUsers.find(userExcluded => userExcluded.mail=== user.mail));
            }
          return removeUsersSelected;
          }
        }
      })
    )

  
    onSelectItem(event: any) {
      this.newDealService.GetSelectedADGroupDetails(event.item.userPrincipalName ? event.item.userPrincipalName : event.item.mail).subscribe(
        {
          next: (result: any) => {          
            if (result) {
              if (!this.allowMultiple && this.peoplePickerUserList.length > 0) {
                return;
              }
              this.peoplePickerUserList.push(result.value[0]);
               this.selectedPeoplePickerUser.emit(result.value[0]);
            }
         
          },
          error: (err: any) => {

            console.log("Error" + err) 
          }
        })   
    }

  removeItem(user: any,index?:number
  ) {
    if (!this.disabled && !this.readonly) {
      this.peoplePickerUserList = this.peoplePickerUserList.filter((u:any) => u!== user);
      //user.LeftDCF = false;
      this.removedPeoplePickerUser.emit(user);
    }
  }

  formatter = (x: { DisplayText: string }) => '';

  get allowAddUsers() {
    return ((this.peoplePickerUserList?.length > 0) && (!this.allowMultiple)) ? false : true;
  }
  get currentaccessPage() {
  // return localStorage.getItem('CurrentAccessPage')?.toString()==="ClosingChecklistForm";
  return (this.globalHelperService.currentAccessPage?.toString()==="ClosingChecklistForm"||this.globalHelperService.currentAccessPage==="DealWBSForm");
  }

  clearSearchInput() { 
    // Clears the value of the search input field by setting it to an empty string
    const inputElement = document.getElementById('typeahead-template') as HTMLInputElement;
    if (inputElement) {
      inputElement.value = '';
    }
  }
}
