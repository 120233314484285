import { Component, EventEmitter, OnInit, Output, Input, ViewChild } from '@angular/core';
import { ContentManagement } from 'src/app/models/contentManagement';
import { ContentService } from 'src/app/services/content.service';
import { NotifyPopupComponent } from 'src/shared/components/alerts/notify-popup/notify-popup.component';
import { ContentManagementItem } from 'src/app/models/contentManagementItem';
import { AdminService } from 'src/app/services/admin.service';
import { ContentManagementComponent } from '../content-management/content-management.component';
import { CommonHelperService } from 'src/app/commonHelper/common-helper.service';
import { SpinnerService } from 'src/shared/services/spinner.service';

@Component({
  selector: 'app-add-content-popup',
  templateUrl: './add-content-popup.component.html',
  styleUrls: ['./add-content-popup.component.scss']
})
export class AddContentPopupComponent implements OnInit {
  
  contentManagementData=new ContentManagement();
  oldContentManagementData=new ContentManagement();
  contentDataArray:Array<any>=[];
  displayStyle = "none";
  displayVerifyStyle="none";
  verifierData:any;
  modifiedBy:any="";
  isTextControl=true;
  verifyDescription:any="";
  ITSAdminComments:any="";
  buttonType:any="Save";
  DocTypeTitle:any="";
  isEncrypted:any=true;
  RepresenatativeList:any=[];
  uploadFileData:any;
  isExistUpdated:any=false;
  DocTypeList:any=[];
  isDisabled=false;
  attachmentsMaxSize:any = 104857600;
 maxAllowedFileLength:any = 128;
  @Input() TableName: string;
  @Input() ContentType: string;
  @Input() initialContentManagementList: Array<ContentManagementItem> = [];
  @Output() SaveAndUpdateContentDetails: EventEmitter<any> = new EventEmitter<any>();
  @Output() CloseContentDetails: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(NotifyPopupComponent, { static: true }) notifypopupComponent: NotifyPopupComponent;

  constructor(public contentService:ContentService,public adminService:AdminService,public contentManagementService:ContentManagementComponent,private spinnerService:SpinnerService,private commonHelperService:CommonHelperService) { }

  ngOnInit(): void {
    this.clearText();
  }

  clearText() {
    this.contentManagementData = new ContentManagement();
    this.oldContentManagementData = new ContentManagement();
    this.isDisabled=false;
  }

  openPopup(operationType:any,editData?:any) {
    this.loadDropdownValues(editData);
    this.isTextControl=true;
    this.displayStyle = "block";
    this.clearText();
    if(operationType=="Update"){
     this.buttonType="Update";
     this.LoadControls(editData);
     this.loadOldData();
    }
    else{
      this.buttonType="Save";
    }
   
  }
  clearApprovalText(){
    this.verifyDescription="";  
    this.ITSAdminComments="";
  }
  openVerificationPopup(operationType:any,verifyData?:any) {
     this.displayVerifyStyle = "block";
     this.verifierData=verifyData;
     this.clearApprovalText();  
     this.DocTypeTitle=verifyData.DocumentTypeTitle;
     if(verifyData.VerificationDescription!=null)
     this.verifyDescription=verifyData.VerificationDescription.includes("#;")?this.formatDescription(verifyData.VerificationDescription):verifyData.VerificationDescription;
     this.modifiedBy=verifyData.ModifiedBy?.toString();
     this.spinnerService.hideAutoSaveSpinner();
   
  }
  formatDescription(desc:any){
   return desc=desc.split("#;").join("\n");
  }
  closeVerificationPopup() {
    this.CloseContentDetails.emit("true");
    this.displayVerifyStyle = "none";
  }
  loadOldData(){
    this.oldContentManagementData.ID=this.contentManagementData.ID;
    this.oldContentManagementData.Title=this.contentManagementData.Title;
    this.oldContentManagementData.Value=this.contentManagementData.Value;
    this.oldContentManagementData.Name=this.contentManagementData.Name;
    this.oldContentManagementData.DocumentTypeOrder=this.contentManagementData.DocumentTypeOrder;
    this.oldContentManagementData.DocumentTypeTitle=this.contentManagementData.DocumentTypeTitle;
    this.oldContentManagementData.DependentDocumentTypes=this.contentManagementData.DependentDocumentTypes;
    this.oldContentManagementData.Required=this.contentManagementData.Required;
    this.oldContentManagementData.Guidance=this.contentManagementData.Guidance;
    this.oldContentManagementData.BusinessReviewApplies=this.contentManagementData.BusinessReviewApplies;
    this.oldContentManagementData.QRMReviewApplies=this.contentManagementData.QRMReviewApplies;
    this.oldContentManagementData.ComplianceReviewApplies=this.contentManagementData.ComplianceReviewApplies;
    this.oldContentManagementData.IndustryTitle=this.contentManagementData.IndustryTitle;
    this.oldContentManagementData.Representative=this.contentManagementData.Representative;
    this.oldContentManagementData.DocumentType=this.contentManagementData.DocumentType;
    this.oldContentManagementData.RequiredGroupFields=this.contentManagementData.RequiredGroupFields;
    this.oldContentManagementData.RequiredFields=this.contentManagementData.RequiredFields;
    this.oldContentManagementData.CreatedBy=this.contentManagementData.CreatedBy;
    this.oldContentManagementData.FormID=this.contentManagementData.FormID;
    this.oldContentManagementData.BusinessReviewer=this.contentManagementData.BusinessReviewer;
    this.oldContentManagementData.BusinessReviewStatus=this.contentManagementData.BusinessReviewStatus;
    this.oldContentManagementData.QRMReviewer=this.contentManagementData.QRMReviewer;
    this.oldContentManagementData.RequiresFinalVersion=this.contentManagementData.RequiresFinalVersion;
    this.oldContentManagementData.QRMReviewStatus=this.contentManagementData.QRMReviewStatus;
    this.oldContentManagementData.ComplianceReviewer=this.contentManagementData.ComplianceReviewer;
    this.oldContentManagementData.ComplianceReviewStatus=this.contentManagementData.ComplianceReviewStatus;
    this.oldContentManagementData.CommentAuthor=this.contentManagementData.CommentAuthor;
    this.oldContentManagementData.Comment=this.contentManagementData.Comment;
    this.oldContentManagementData.CommentType=this.contentManagementData.CommentType;
    this.oldContentManagementData.ReviewStatus=this.contentManagementData.ReviewStatus;
    this.oldContentManagementData.Role=this.contentManagementData.Role;
    this.oldContentManagementData.Created=this.contentManagementData.Created;
    this.oldContentManagementData.Modified=this.contentManagementData.Modified;
    this.oldContentManagementData.ModifiedBy=this.contentManagementData.ModifiedBy;
    this.oldContentManagementData.isActive=this.contentManagementData.isActive;
    this.oldContentManagementData.isApproved=this.contentManagementData.isApproved;
    this.oldContentManagementData.isRejected=this.contentManagementData.isRejected;
    this.oldContentManagementData.VerificationDescription=this.contentManagementData.VerificationDescription;
  // Cross BorderForm 
    this.oldContentManagementData.EnterpriseValue=this.contentManagementData.EnterpriseValue;
    this.oldContentManagementData.ExpectedCompanyValueComments=this.contentManagementData.ExpectedCompanyValueComments;
    this.oldContentManagementData.ProjectName=this.contentManagementData.ProjectName;
    this.oldContentManagementData.CompanySubsectorComments=this.contentManagementData.CompanySubsectorComments;
    this.oldContentManagementData.GeographConcentrationComments=this.contentManagementData.GeographConcentrationComments;
    this.oldContentManagementData.USCounterpartiesContactComments=this.contentManagementData.USCounterpartiesContactComments;
    this.oldContentManagementData.ShareholderObjectivesComments=this.contentManagementData.ShareholderObjectivesComments;
  }
  loadDropdownValues(editData:any){
    if(this.TableName=="CrossBorderIndustries"){
      this.contentService.GetContentDetails("api/FormGroup").subscribe(
        {
          next: (result: any) => {
            result.sort((a:any, b:any) => (a.Name < b.Name ? -1 : 1));
            this.RepresenatativeList = this.commonHelperService.getActiveRecords(result);
            try {
              if(editData!=undefined){
              this.contentManagementData.Representative=editData.Representative;
              }
            } catch (error) {
              
            }         
          },
          error: (err: any) => {
            this.RepresenatativeList = [];
            if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
            this.spinnerService.hideAutoSaveSpinner();
            console.log("Error" + err)
          }
        })
    }
    else if(this.TableName=="CrossBorderTemplates"){
      this.contentService.GetContentDetails("api/CrossBorderDocumentTypes").subscribe(
        {
          next: (result: any) => {
            result.sort((a:any, b:any) => (a.DocumentTypeTitle < b.DocumentTypeTitle ? -1 : 1));
            this.DocTypeList = this.commonHelperService.getActiveRecords(result);
            try {
              if(editData!=undefined){
              this.contentManagementData.DocumentType=editData.DocumentType;
              }
            } catch (error) {
              
            }           
          },
          error: (err: any) => {
            this.RepresenatativeList = [];
            if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
            this.spinnerService.hideAutoSaveSpinner();
            console.log("Error" + err)
          }
        })
    }
  }
  LoadControls(data:any){
   this.isDisabled= this.EnableAndDisable();
    this.contentManagementData.ID=data?.ID;
    this.contentManagementData.CreatedBy=data?.CreatedBy;
    this.contentManagementData.Modified=data?.Modified;
    this.contentManagementData.ModifiedBy=data?.ModifiedBy;
    this.contentManagementData.Created=data?.Created;
    this.contentManagementData.isActive=data?.isActive;
    
    switch (this.TableName) {
      case "ValidationFieldsCrossBorder":
        this.contentManagementData.RequiredFields=data.RequiredFields;
        this.contentManagementData.Title=data.Title;
        this.contentManagementData.RequiredGroupFields=data.RequiredGroupFields;
        break;
      case "CrossBorderTemplates":
        this.contentManagementData.Name=data.Name;
        this.contentManagementData.Title=data.Title;
        this.contentManagementData.DocumentType=data.DocumentType;
        break;
      case "CrossBorderIndustries":
        this.contentManagementData.IndustryTitle=data.Title;
        this.contentManagementData.Representative=data.Representative;
        break;
      case "CrossBorderDocumentTypes":
        this.contentManagementData.DocumentTypeOrder=data.DocumentTypeOrder;
        this.contentManagementData.DocumentTypeTitle=data.DocumentTypeTitle;
        this.contentManagementData.Guidance=data.Guidance;
        this.contentManagementData.DependentDocumentTypes=data.DependentDocumentTypes;
        this.contentManagementData.Required=(data.Required == "TRUE" ? true : false);
        this.contentManagementData.BusinessReviewApplies=(data.BusinessReviewApplies == "TRUE" ? true : false);
        this.contentManagementData.QRMReviewApplies=(data.QRMReviewApplies == "TRUE" ? true : false);
        this.contentManagementData.ComplianceReviewApplies=(data.ComplianceReviewApplies == "TRUE" ? true : false);
        this.contentManagementData.RequiresFinalVersion=(data.RequiresFinalVersion == "TRUE" ? true : false);
        this.contentManagementData.isApproved=data.isApproved?.toString();
        this.contentManagementData.isRejected=data.isRejected?.toString();
        this.contentManagementData.VerificationDescription=data.VerificationDescription?.toString();
        break;
      case "CrossBorderCountries":
        this.contentManagementData.Title=data.Title;
        break;

      case "CrossBorderForm":
        this.contentManagementData.FormID=data.ID;
        this.contentManagementData.ProjectName=data.ProjectName;
        this.contentManagementData.GeographConcentrationComments=data.GeographConcentrationComments;
        this.contentManagementData.USCounterpartiesContactComments=data.USCounterpartiesContactComments;
        this.contentManagementData.ShareholderObjectivesComments=data.ShareholderObjectivesComments;
        this.contentManagementData.CompanySubsectorComments=data.CompanySubsectorComments;
        this.contentManagementData.ExpectedCompanyValueComments=data.ExpectedCompanyValueComments;
        this.contentManagementData.EnterpriseValue=data.EnterpriseValue;
              break;

      case "CrossBorderAdditionalDocumentTypes":
        this.contentManagementData.FormID=data.FormID;
        this.contentManagementData.DocumentType=data.DocumentType;      
        break;

      case "CrossBorderApprovalReviewStatus":
        this.contentManagementData.FormID=data.FormID;
        this.contentManagementData.DocumentType=data.DocumentType;
        this.contentManagementData.BusinessReviewer=data.BusinessReviewer;
        this.contentManagementData.BusinessReviewStatus=data.BusinessReviewStatus;
        this.contentManagementData.ComplianceReviewer=data.ComplianceReviewer;
        this.contentManagementData.ComplianceReviewStatus=data.ComplianceReviewStatus;
        this.contentManagementData.QRMReviewer=data.QRMReviewer;
        this.contentManagementData.QRMReviewStatus=data.QRMReviewStatus;
        //this.contentManagementData.BusinessReviewDate=data.BusinessReviewDate;
        //this.contentManagementData.ComplianceReviewDate=data.ComplianceReviewDate;
        //this.contentManagementData.QRMReviewDate=data.QRMReviewDate;
        //this.contentManagementData.AdditionalReviewNeeded=data.AdditionalReviewNeeded;
        //this.contentManagementData.AutomaticReviewDate=data.AutomaticReviewDate;
        //this.contentManagementData.CrossBorderreviewprocessworkflow=data.CrossBorderreviewprocessworkflow;
        break;

      case "CrossBorderAttachments":
        this.contentManagementData.FormID=data.FormID;
        this.contentManagementData.DocumentType=data.DocumentType;
        this.contentManagementData.Title=data.Title;
        this.contentManagementData.Name=data.Name;
        break;

      case "CrossBorderComments":
        this.contentManagementData.FormID=data.FormID;
        this.contentManagementData.DocumentType=data.DocumentType;
        this.contentManagementData.CommentAuthor=data.CommentAuthor;
        this.contentManagementData.CommentType=data.CommentType;
        this.contentManagementData.Comment=data.Comment;
        this.contentManagementData.ReviewStatus=data.ReviewStatus;
        break;

      case "CrossBorderRecommendedTemplates":
        this.contentManagementData.FormID=data.FormID;
        this.contentManagementData.DocumentType=data.DocumentType;
        this.contentManagementData.Title=data.Title;
        this.contentManagementData.Name=data.Name;
        break;

      case "CrossBorderRedLineAttachments":
        this.contentManagementData.FormID=data.FormID;
        this.contentManagementData.DocumentType=data.DocumentType;
        this.contentManagementData.Name=data.Name;
        break;

      case "UserGroups":
        this.contentManagementData.Role=data.Role;
        break;

      case "FormGroups":
          this.contentManagementData.Name=data.Name;
          break;

        case "FormType":
          this.contentManagementData.Name=data.Name;
          break;
          case "AppConfigurations":
            this.contentManagementData.Title=data.Title;
            this.contentManagementData.Value=this.BindConfigValue(data.Value);
            this.GetControlType(data.Value);
            break;

      default:
        break;
    }
  }
  BindConfigValue(Value:any){
    let res;
  if(Value.toLowerCase()=="false"){
    res=false;
  }
  else if(Value.toLowerCase()=="true"){
    res=true;
  }
  else{
    res=Value;
  }
  return res;
  }
  GetControlType(data:any){
    try{
      if(data.toLowerCase()=="true"||data.toLowerCase()=="false"){
        this.isTextControl=false;
      }
      else{
        this.isTextControl=true;
      }
    }
    catch(e){

    }

  }
  handleFileInput(event:any){
    if(this.isFileValid(event.files[0])){
      this.uploadFileData=[];
    this.uploadFileData=event.files[0];
    this.contentManagementData.Name=event.files[0].name.toString();
    }
  }
  closePopup() {
    this.CloseContentDetails.emit("true");
    this.displayStyle = "none";
  }
  isFileValid(fileData:any){
    let isFileValid=true;
    if(fileData!=undefined){
    //Max FileName Length
    if(fileData.name.length >this.maxAllowedFileLength){this.notifypopupComponent.openPopup('Content','maxFileName');isFileValid=false;}  
    //Max File Size 
    else if(fileData.size >this.attachmentsMaxSize){ this.notifypopupComponent.openPopup('Content','maxFileSize');isFileValid=false;}
    //File Empty
    else if(fileData.size <=0){ this.notifypopupComponent.openPopup('Content','fileEmpty');isFileValid=false;}
    //File Name Empty
    else if(fileData.name.length <=0){this.notifypopupComponent.openPopup('Content','fileNotSelected');isFileValid=false;} 
    //Duplicate File Upload
   else if(fileData.name==this.oldContentManagementData.Name){this.notifypopupComponent.openPopup('Content','fileDuplicate');isFileValid=false;}
    //Encrypted File 
   else if(!this.IsEncryptedFile(fileData)){ this.notifypopupComponent.openPopup('Content','fileEncrypted'); isFileValid=false;}
    }
    else{
      //  this.notifypopupComponent.openPopup('Content','fileNotSelected');
      isFileValid=false;
    }
    return isFileValid;
  }
  
  IsEncryptedFile(fileSource:any){  
    this.isEncrypted=true;
    const fileData=new FormData();
    fileData.append('file',fileSource);
    this.spinnerService.showAutoSaveSpinner();
    this.contentService.isDocumentEncrypted(fileData).subscribe({
      next: (result: any) => {
       this.isEncrypted=result; 
       this.spinnerService.hideAutoSaveSpinner();
       if(!result){
        this.contentManagementData.Name="";
        this.notifypopupComponent.openPopup('Content','fileEncrypted');
       }
      },
      error: (err: any) => {
        this.contentManagementData.Name="";
        if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
        this.spinnerService.hideAutoSaveSpinner();
        console.log("Error" + err)
        this.isEncrypted=true;
      }
    })
  return this.isEncrypted;
  }
  submitContentData() {
    this.contentDataArray=[];
    this.contentDataArray.push(this.oldContentManagementData);
    this.contentDataArray.push(this.contentManagementData);
    this.contentDataArray.push(this.buttonType);
    this.contentDataArray.push("IsRecordAlreadyExist");
    if(this.TableName=="CrossBorderTemplates"){this.contentDataArray.push(this.uploadFileData);}
    if (this.isValidated(this.contentDataArray[1])) {
      switch (this.buttonType) {
        case "Save":
          if (!this.isRecordAlreadyExists(this.contentDataArray[1])) {
            this.SaveAndUpdateContentDetails.emit(this.contentDataArray);
            this.clearText();
            this.displayStyle = "none";
          }
          else {
            if(this.isExistUpdated){
              this.contentDataArray[3]="RecordAlreadyExist";
              this.SaveAndUpdateContentDetails.emit(this.contentDataArray);
              this.clearText();
              this.displayStyle = "none";
            }
            else{
            this.notifypopupComponent.openPopup("Content", "AlreadyExisted");
            return;
            }
          }
          break;
        case "Update":
          if(this.isDetailsUpdated(this.contentDataArray)){
          this.SaveAndUpdateContentDetails.emit(this.contentDataArray);
          this.clearText();
          this.displayStyle = "none";
          }
          else{
            this.notifypopupComponent.openPopup("Content", "isNotUpdated");
            return;
          }
          break;
        default:
          break;
      }
    }
    else {
      this.notifypopupComponent.openPopup("Content", "Info");
      return;
    }
  }
   //If user click directly the update button without modifying anything
   isDetailsUpdated(resultantData:any){
    let isUpdated=false;
    for (let Array1 in resultantData[0]) {
      for(let Array2 in resultantData[1]){
        if(Array1!="isActive"&&Array1==Array2){
         if(resultantData[0][Array1]!=resultantData[1][Array2]){isUpdated=true;}
        }
      }
    }
    return isUpdated;
  }
  isITSApproved(event:any){
    if (this.verifierData != null) {
    var appendURL = this.contentService.getAppendURL(this.TableName);
	  let UpdateRequestBody = this.prepareITSRequest(this.verifierData, "Approved"); 
      this.contentManagementService.UpdateContentDetails(appendURL,UpdateRequestBody,"ApprovedSuccess");
     this.AuditITSAdminActions("Approved");
     this.displayVerifyStyle="none";
    }
  }
  isITSRejected(event:any){
    if (this.verifierData != null) {
      var appendURL = this.contentService.getAppendURL(this.TableName);
	   let UpdateRequestBody = this.prepareITSRequest(this.verifierData, "Rejected"); 
	   this.contentManagementService.UpdateContentDetails(appendURL,UpdateRequestBody,"RejectedSuccess");
     this.AuditITSAdminActions("Rejected");
     this.displayVerifyStyle="none";
    }
  }
  prepareITSRequest(event:any,operation:any){
    let RequestBody={};
    return RequestBody = {
      ID: event.ID,
      ModifiedBy: event.ModifiedBy,
      CreatedBy: event.CreatedBy,
      isActive: event.isActive,
      DocumentTypeOrder: event.DocumentTypeOrder.toString(),
      DocumentTypeId: event.DocumentTypeOrder.toString(),
      DocumentTypeTitle: event.DocumentTypeTitle,
      Required: event.Required,
      Guidance: event.Guidance,
      BusinessReviewApplies: event.BusinessReviewApplies,
      QRMReviewApplies: event.QRMReviewApplies,
      ComplianceReviewApplies: event.ComplianceReviewApplies,
      RequiresFinalVersion: event.RequiresFinalVersion,
      DependentDocumentTypes: event.DependentDocumentTypes,
      isApproved:operation == "Approved" ? "Yes" : "No",
      isRejected:operation == "Rejected" ? "Yes" : "No",
      VerificationDescription: event.VerificationDescription
    };
  }
  AuditITSAdminActions(action:any){
  let AdminComments=this.ITSAdminComments.length>0?" #; Below are comments provided by ITS Admin #;"+this.ITSAdminComments:"";
  let AuditRequest={};
     AuditRequest = {
      ID: "",
      ModifiedBy: this.verifierData.ModifiedBy,
      ModifiedOn: "",
      Status: "Yes",
      TableName: this.TableName,
      ContentType:this.ContentType,
      Description: "Below action has been "+action+" by the ITSAdmin #;"+this.verifierData.VerificationDescription+ AdminComments
    }; 
    this.contentManagementService.SaveAuditDetails(AuditRequest);
  }
  isValidated(data:any){
    let isValid=true;
    switch(this.TableName){
      case "CrossBorderCountries": { 
           return isValid=data.Title==""?false:true;
        break; 
      } 
      case "CrossBorderDocumentTypes": { 
        if(data.DocumentTypeOrder==""){isValid=false;}
        if(data.DocumentTypeTitle==""){isValid=false;}
        if(data.Guidance==""){isValid=false;}
        return isValid;
        break; 
      } 
    case "CrossBorderIndustries": { 
      if(data.Representative==""){isValid=false;}
      if(data.IndustryTitle==""){isValid=false;}
      return  isValid;
    break; 
    } 
    case "CrossBorderTemplates": { 
      if(data.Name==""){isValid=false;}
      if(data.Title==""){isValid=false;}
     // if(data.DocumentType==""){isValid=false;}
      return isValid; 
    break; 
    } 
    case "ValidationFieldsCrossBorder": { 
      if(data.Title==""){isValid=false;}
      if(data.RequiredGroupFields==""){isValid=false;}
      if(data.RequiredFields==""){isValid=false;}
      return  isValid;
      break; 
    } 
    case "FormGroups":
    case "FormType": { 
      return isValid=data.Name==""?false:true;
      break; 
    } 
    case "CrossBorderForm":{
      if(data.ID=="" || data.EnterpriseValue=="" || data.CompanySubsectorComments==""||data.ShareholderObjectivesComments==""||data.GeographConcentrationComments==""){isValid=false;}      
      return isValid;
    }

    case "CrossBorderApprovalReviewStatus":
      if(data.BusinessReviewStatus=="-" && data.ComplianceReviewStatus=="-" && data.QRMReviewStatus=="-") isValid = false;
      return isValid;
    case "AppConfigurations": { 
      return isValid=data.Value.toString()==""?false:true;
      break; 
    } 
    default: { 
          //statements; 
          return false;
          break; 
    } 
  }
    
  }
  EnableAndDisable(){
    let isdisabled=false;
    switch(this.TableName){
      case "CrossBorderCountries": { 
           return isdisabled=true;
        break; 
      } 
      case "CrossBorderDocumentTypes": { 
        return isdisabled=true;
        break; 
      } 
    case "CrossBorderIndustries": { 
      return isdisabled=true;
    break; 
    } 
    case "CrossBorderTemplates": { 
      return isdisabled=true;
    break; 
    } 
    case "ValidationFieldsCrossBorder": { 
      return isdisabled=true;
      break; 
    } 
    case "FormGroups":
    case "FormType": { 
      return isdisabled=true;
      break; 
    }
      case "AppConfigurations": { 
        return isdisabled=this.buttonType=="Update"?true:false;
        break; 
    } 
    default: { 
          //statements; 
          return false;
          break; 
    } 
  }
    
  }
  isRecordAlreadyExists(event:any){
    let isExist=false;this.isExistUpdated=false;
switch(this.TableName){
  case "CrossBorderCountries": { 
    this.initialContentManagementList.forEach(x=>{
     if(x.Title==event.Title){isExist=true;if(x.isActive=="No"){this.isExistUpdated=true;this.contentDataArray[1].ID=x.ID;}}
    })
   return isExist;
    break; 
  } 
  case "CrossBorderDocumentTypes": { 
      this.initialContentManagementList.forEach(x=>{
      if(x.DocumentTypeTitle==event.DocumentTypeTitle){isExist=true;if(x.isActive=="No"){this.isExistUpdated=true;this.contentDataArray[1].ID=x.ID;}}
     })
     return isExist;
    break; 
  } 
case "CrossBorderIndustries": { 
    this.initialContentManagementList.forEach(x=>{
    if(x.Industry==event.IndustryTitle){isExist=true;if(x.isActive=="No"){this.isExistUpdated=true;this.contentDataArray[1].ID=x.ID;}}
   })
   return isExist;
break; 
} 
case "CrossBorderTemplates": { 
    this.initialContentManagementList.forEach(x=>{
    if(x.Name==event.Name&&x.DocumentType==event.DocumentType){isExist=true;if(x.isActive=="No"){this.isExistUpdated=true;this.contentDataArray[1].ID=x.ID;}}
   })
   return isExist;
break; 
} 
case "ValidationFieldsCrossBorder": { 
    this.initialContentManagementList.forEach(x=>{
    if(x.RequiredFields==event.RequiredFields){isExist=true;if(x.isActive=="No"){this.isExistUpdated=true;this.contentDataArray[1].ID=x.ID;}}
   })
   return isExist;
  break; 
} 
case "FormGroups":
case "FormType": { 
    this.initialContentManagementList.forEach(x=>{
    if(x.Name==event.Name){isExist=true;if(x.isActive=="No"){this.isExistUpdated=true;this.contentDataArray[1].ID=x.ID;}}
   })
   return isExist;
  break; 
} 
default: { 
      //statements; 
      return false;
      break; 
} 
}
  }
}
