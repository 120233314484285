import { HttpClient,HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core"; 
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AppSettings } from "src/shared/app-settings";
@Injectable()

@Injectable({
    providedIn: 'root'
  })
  
export class NewHireFormService{

  constructor(private myhttp: HttpClient){

  }

  SaveFormDetails(data:any):Observable<any>{
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.OnboardingForm)
    const url = envURL +'/api/OnboardingForm/Add'
    return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json'
    ,'Authorization' : "Bearer "+localStorage.getItem("idToken") })});    
  }
  UpdateFormDetails(data:any):Observable<any>{
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.OnboardingForm)
    const url = envURL +'/api/OnboardingForm/Update'
    return this.myhttp.post(url,data,{headers : new HttpHeaders({ 'Content-Type': 'application/json'
    ,'Authorization' : "Bearer "+localStorage.getItem("idToken") })});    
  }
  GetS24Users(): Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement)
       const url = envURL+'/api/UserGroups/GetUserGroupsByRole?role=MyDeal_S24'; 
      return this.myhttp.get(url, {
          headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem("idToken")
          })
      })
  }
  GetFormDetails(formId?:string):Observable<any>{
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.OnboardingForm)
    var url;
    if(formId==undefined){
      url = envURL + '/api/OnboardingForm/GetListOnboardingForm'; 
    }
    else{
      url = envURL + '/api/OnboardingForm/GetListOnboardingForm?formID=' + formId;   
    }
    return this.myhttp.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    });
  }
}
