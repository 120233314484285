<div id="wbs-element-classification"
  class="container-fluid border container-border-color container-section"
  [formGroup]="wbsElementFormGroup">
  <div class="row bg-title">
    <span class="title-section-print ">WBS Element Classification</span>
  </div>
  <div class="content-section">
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span
          [class.highlight-warning]="highlightRequiredField('isServiceSameAsClientAddress') ||
          highlightRequiredField('serviceAtAddress')"
          [class.edited-externally]="editedExternally('isServiceSameAsClientAddress') ||
          editedExternally('serviceAtAddress')"
          [class.conflict-on-merge]="hasMergeConflict('isServiceSameAsClientAddress') ||
          hasMergeConflict('serviceAtAddress')">
            Is Service-At Address same as Client Address?
        </span>
        <span *ngIf="showAsterisk('isServiceSameAsClientAddress')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
            [value]="myDealFormGroup.get('isServiceSameAsClientAddress')?.value"
            [readonly]="isReadOnly('isServiceSameAsClientAddress')"
            (handleToggleYesNo)="onIsServiceSameAsClientAddressSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    <div class="row">
        <div class="col-xs-4 margin-top-5">
            <span class="dependent-field" [class.highlight-warning]="highlightRequiredField('serviceAtAddress')"></span>
        </div>
        <div class="col-xs-8">

            <!--Mopdified below HTML
               <input
                disabled
                [formControl]="myDealFormGroup.get('serviceAtAddress')"
                class="input-data-text full-width section-input dependent-field-input"
                [class.readonly]="isReadOnly('serviceAtAddress')"
                type="text"
                autocomplete="off"
                [placeholder]='myDealFormGroup.get("isServiceSameAsClientAddress")?.value === "NO" ? "Please enter Service-At Address" : "N/A"'> -->
                <input
                [disabled]="isDisabled"
                [formControl]="myDealFormGroup.get('serviceAtAddress')"
                class="input-data-text full-width section-input dependent-field-input"
                [class.readonly]="isReadOnly('serviceAtAddress')"
                type="text"
                autocomplete="off"
                [placeholder]='myDealFormGroup.get("isServiceSameAsClientAddress")?.value === "NO" ? "Please enter Service-At Address" : "N/A"'>
        </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('marketOffering')"
          [class.edited-externally]="editedExternally('marketOffering')"
          [class.conflict-on-merge]="hasMergeConflict('marketOffering')">
            Market Offering
        </span>
        <span *ngIf="showAsterisk('marketOffering')" class="red-asterik"></span>
        <app-popover
            [tooltipText]="getTooltipText('MarketOffering')"
            [keepOpen]="true">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <input
        [formControl]="myDealFormGroup.get('marketOffering')"
        class="input-data-text full-width section-input"
        [class.readonly]="isReadOnly('marketOffering')"
        type="text"
        autocomplete="off">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('material')"
          [class.edited-externally]="editedExternally('material')"
          [class.conflict-on-merge]="hasMergeConflict('material')">
            Material
        </span>
        <span *ngIf="showAsterisk('material')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <input
          disabled
          [value]="getControlName('material')?.value"
          class="input-data-text full-width section-input"
          [class.readonly]="isReadOnly('material')"
          type="text"
          autocomplete="off">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('opportunityID')"
          [class.edited-externally]="editedExternally('opportunityID')"
          [class.conflict-on-merge]="hasMergeConflict('opportunityID')">
            Opportunity ID (if applicable)
        </span>
        <span *ngIf="showAsterisk('opportunityID')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-numeric
          [type]="'number'"
          [readonly]="isReadOnly('opportunityID')"
          [value]="getControlName('opportunityID')?.value"
          (selectedValue)="onOpportunityIDChange($event)">
        </app-numeric>
      </div>
    </div>
    </div>
</div>
