<div id="archive-cmr"
  class="container-fluid border container-border-color container-section"
  [formGroup]="archiveCmrFormGroup">
  <div class="row bg-title">
    <span class="title-section-print">Archive/CMR</span>
  </div>
  <div class="content-section">
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('isEngagementAudit')"
          [class.edited-externally]="editedExternally('isEngagementAudit')"
          [class.conflict-on-merge]="hasMergeConflict('isEngagementAudit')">
            Is the engagement an Audit or <br/> Other Attest Services?
        </span>
        <span *ngIf="showAsterisk('isEngagementAudit')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="getControlName('isEngagementAudit')?.value"
          [readonly]="isReadOnly('isEngagementAudit')"
          (handleToggleYesNo)="onIsThisEngagementAuditSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
          <span [class.highlight-warning]="highlightRequiredField('previousAuditor')"
                [class.edited-externally]="editedExternally('previousAuditor')"
                [class.conflict-on-merge]="hasMergeConflict('previousAuditor')">Previous Auditor</span>
          <span *ngIf="showAsterisk('previousAuditor')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
          <input
            [formControl]="myDealFormGroup.get('previousAuditor')"
            [disabled]="getControlName('previousAuditor')?.disabled"
            class="input-data-text full-width section-input"
            [class.readonly]="isReadOnly('attestStatusParent')"
            type="text"
            autocomplete="off">
      </div>
    </div>
    <div class="row">
        <div class="col-xs-4 section-label">
            <span [class.highlight-warning]="highlightRequiredField('currentAuditor')"
                  [class.edited-externally]="editedExternally('currentAuditor')"
                  [class.conflict-on-merge]="hasMergeConflict('currentAuditor')">Current Auditor</span>
            <span *ngIf="showAsterisk('currentAuditor')" class="red-asterik"></span>
        </div>
        <div class="col-xs-8">
            <input
                [formControl]="myDealFormGroup.get('currentAuditor')"
                [disabled]="getControlName('currentAuditor')?.disabled"
                class="input-data-text full-width section-input"
                [class.readonly]="isReadOnly('currentAuditor')"
                type="text"
                autocomplete="off">
        </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('isArchiveRequired')"
          [class.edited-externally]="editedExternally('isArchiveRequired')"
          [class.conflict-on-merge]="hasMergeConflict('isArchiveRequired')">
            Is an archive required for the official records of the engagement?
          </span>
        <span *ngIf="showAsterisk('isArchiveRequired')" class="red-asterik"></span>
        <app-popover
        [tooltipText]="getTooltipText('IsArchiveRequired')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="getControlName('isArchiveRequired')?.value"
          [readonly]="isReadOnly('isArchiveRequired')"
          (handleToggleYesNo)="onIsThisArchiveRequiredSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('wasCMRPerformed')"
          [class.edited-externally]="editedExternally('wasCMRPerformed')"
          [class.conflict-on-merge]="hasMergeConflict('wasCMRPerformed')">
            Was CMR performed? (if applicable)
        </span>
        <span *ngIf="showAsterisk('wasCMRPerformed')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="getControlName('wasCMRPerformed')?.value"
          [readonly]="isReadOnly('wasCMRPerformed')"
          (handleToggleYesNo)="onIsThisWasCMRPerformedSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
  </div>
</div>
