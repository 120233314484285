import { DatePipe } from "@angular/common";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ComplianceSharepointMapper } from "../shared/utils/compliance-sharepoint";
import { MainService } from "../../../shared/services/main.service";
import { AppSettings } from "../../../shared/app-settings";
import { CurrentUser } from "../../../shared/models/current-user-data";
import { ComplianceService } from "../shared/services/compliance-service";
import { CommentItem } from "../../../shared/models/comments-item";
import { catchError, finalize, from, map } from "rxjs";
import { DateFormatterService } from "src/shared/utils/utils-date";
import { DateService } from "src/app/services/date.service";
import { environment } from "src/environments/environment";
import { SpinnerService } from "src/shared/services/spinner.service";
import { ClosingChecklistNotifyPopupComponent } from "src/shared/components/alerts/closing-checklist-notify-popup/closing-checklist-notify-popup.component";


@Component({
    selector: 'app-additional-commentary',
    templateUrl: './additional-commentary.component.html',
    styleUrls: ['./additional-commentary.component.scss']
  })
export class AdditionalCommentaryComponent implements OnInit {
    
    sharePointMapper = new ComplianceSharepointMapper();
    isShowComments: boolean;
    additionalCommentaryFormGroup:FormGroup;
    userCommentsData:Array<CommentItem>=[];
    comment:string='';
    toggleClass: boolean = false;
    @Input() formId:number;
    @Input() readOnly:boolean
    @Input() currentUser: CurrentUser;
    @Input() formSubmitted: boolean;
    @Input() formGroups: Array<FormGroup>;
    @Input() maxNumOfCharacters: number;
    @ViewChild(ClosingChecklistNotifyPopupComponent, { static: true }) notifypopupComponent: ClosingChecklistNotifyPopupComponent;
    constructor(private route: ActivatedRoute,private datePipe: DatePipe
        ,private ComplianceService: ComplianceService,private mainService:MainService,private dateFormatterService: DateFormatterService,private dateService: DateService, private spinnerService: SpinnerService,){

    }
    ngOnInit() {        
      this.additionalCommentaryFormGroup = this.formGroups[0];
      this.additionalCommentarySection();       
    }
 additionalCommentarySection() {
  if (this.formId != 0)
    this.formId = Number(this.route.snapshot.queryParams.id);
    //this.formId = 397;
  this.ComplianceService.getDocumentComments(AppSettings.closingChecklistAdditionalComments, this.formId)
    .subscribe((response: any) => {
      // Ensure responseFormItem is always treated as an array
      const responseFormItem = Array.isArray(response) ? response : [response];
      this.userCommentsData.length = 0;
      responseFormItem.forEach((user: any) => {
        let CommentData = {
          Comment: user.Comment,
          CommentAuthorId: user.CommentAuthorEmail,
          CommentAuthorName: user.CommentAuthorName,
          CommentId: user.CommentId,
          Created: this._formatAdminReviewDate(user.Created),
          FormId: user.FormID
        }
        this.userCommentsData.push(CommentData);
      });
      this.isShowComments = this.userCommentsData.length > 0 ? true : false;
      this.userCommentsData.reverse();
    })
}
    setSharepointMapper(mapper: ComplianceSharepointMapper) {
        this.sharePointMapper = mapper;
     }
    saveComment() {
        if (this.comment.trim()) {
            const jsonItems = {
                Comment: this.comment,
                CommentAuthorEmail: this.currentUser.email,
                CommentAuthorName: this.currentUser.title,
                CreatedBy: this.currentUser.title,
                FormId: this.formId.toString()
            };
            this.mainService.createCommentListItem_Observables(AppSettings.reviewCommentsList, jsonItems).subscribe(
                {
                    next: (dataComment: any) => {
                        let CommentData = {
                            Comment: dataComment.Comment,
                            CommentAuthorId: dataComment.CommentAuthorEmail,
                            CommentAuthorName: dataComment.CommentAuthorName,
                            CommentId: dataComment.ID,
                            Created: this._formatAdminReviewDate(dataComment.Created),
                            FormId: dataComment.FormID
                        }
                        // this.userCommentsData.push(CommentData);
                        this.userCommentsData.unshift(CommentData);
                        this.comment='';
                        this.toggleClass = false;
                        this.isShowComments = this.userCommentsData.length > 0 ? true : false;
                        //this.additionalCommentarySection();
                    },
                    error: (err: any) => {
                        this.spinnerService.hideAutoSaveSpinner();
                        console.log("Error: " + err);
                        if (err.status == 403 && err.error.stringKey == AppSettings.tokenExpiredText) {
                            this._openSessionExpiredModal();
                        }
                    }
                }
            );
    
            // from(this.mainService.createCommentListItem_Observables(AppSettings.reviewCommentsList, jsonItems))
            //   .pipe(
            //     finalize(() => {
            //       this.comment = ''; // Clear comment here to ensure it's always cleared
            //       this.toggleClass = false; // Assuming you want to reset this as well
            //     })
            //   )
            //   .subscribe(([dataComment]: any) => {
            //     let CommentData = {
            //       Comment: dataComment.d.Comment,
            //       CommentAuthorId: dataComment.d.CommentAuthorId,
            //       CommentAuthorName: dataComment.d.CommentAuthorName,
            //       CommentId: dataComment.d.Id,
            //       Created: this._formatAdminReviewDate(dataComment.d.Created),
            //       FormId: dataComment.d.FormId
            //     }
            //     this.userCommentsData.push(CommentData);
            //     this.userCommentsData.unshift(CommentData);
            //     this.isShowComments = this.userCommentsData.length > 0 ? true : false;
            //     this.additionalCommentarySection();
            //   });
        }
    }
     onTextAreaChanged(){
       if(this.comment.length > this.maxNumOfCharacters){
        this.comment = this.comment.substring(0,this.maxNumOfCharacters)
       }
       environment.CCLComments=this.comment;
     }
     clearComment(){
         this.comment='';
         environment.CCLComments=this.comment;
     }
    private _formatAdminReviewDate(adminApprovalDate: string): string {
         let dateObject = new Date(adminApprovalDate);
         dateObject=this.dateService.ConvertDateFormatToUTC(dateObject,adminApprovalDate);
       return (this.datePipe.transform(dateObject, 'dd MMM y hh:mm a', 'CDT') + " CST");
       // return `${this.dateFormatterService.formatDateToCDT(dateObject)} CST`;

       //Enable below for Actual CST
      //  const dateObject = this.dateFormatterService.formatDateOnRetrieve(adminApprovalDate);
      // return `${this.dateService._FormatDateCCL(dateObject.toString())}`;
      
      }
      private _openSessionExpiredModal() {
        this.notifypopupComponent.openPopup(AppSettings.tokenExpiredText);
      }
      
}
