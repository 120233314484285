import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppSettings } from 'src/shared/app-settings';
import { CommonHelperService } from 'src/app/commonHelper/common-helper.service';

@Component({
  selector: 'app-new-hire-form-popup',
  templateUrl: './new-hire-form-popup.component.html',
  styleUrls: ['./new-hire-form-popup.component.scss']
})
export class NewHireFormPopupComponent implements OnInit {

  displayStyle = "none";
  isUserDelete:any=false;
  isHardDelete:any=false;
  MessageHeader:any="";
  Msg:any;messageType:any;
  hideOKButton:boolean=false;
  sessionExpired:boolean = false;
  @Input() formID:any;
  @Input() UserCurrentAction: string;
  @Output()  addNotifyDetails:EventEmitter<any>=new EventEmitter<any>();
  @Output()  deleteConfirmDetails:EventEmitter<any>=new EventEmitter<any>();
  @Output()  activateConfirmDetails:EventEmitter<any>=new EventEmitter<any>();
 
  
  constructor(private commonHelperService:CommonHelperService) { }

  ngOnInit(): void {
  }

  openPopup(msg:any,msgType:any="",validationMsg?:any) { 
    document.body.style.overflow="hidden"
    this.MessageHeader=msg;
    this.messageType=msgType;
    this.isUserDelete=false;
    this.sessionExpired = false;
    if (msg == "newhire") {
      document.body.style.overflow="hidden";
      if (msgType == "SubmitSuccess") 
      {
        this.Msg = "The form has been submitted.";
      }
      else if (msgType == "NoUpdate") 
      {
        this.Msg = "Please update the form.";
      }
      else if (msgType == "SaveSuccess")
      {
        this.Msg = "The data has been successfully saved.";
      }
      else if (msgType == "InvalidForm") {
        this.hideOKButton=true;
        this.Msg = " Form is Invalid.";
      }
      else if (msgType == "PermissionError") {
        this.Msg = "Error while getting User Permissions!";
      }
      else if (msgType == "InValidUser") {
        this.hideOKButton=true;
        if(this.formID!="Dashboard"){ this.Msg = "You are not allowed to access this form";}else{ this.Msg = "You are not allowed to access this Dashboard";}
      }
      else if (msgType == "UpdateSuccess") {
        this.Msg = "This form has been updated.";
      }
      else if (msgType == "retrieve") {
        this.Msg = "The data has been successfully retrieved.";
      }
      else if (msgType == "Info")
       {
        this.Msg = "There are required fields which are missing.";
      }
      else if (msgType == "FormGroupValidation") {
        this.Msg = "Group Mapped with existed Records, Not allowed to Delete!";
      }
      else if (msgType == "DeleteSuccess") {
        this.Msg = "Record deleted Successfully";
      }
      else if(msgType == "user-left-dcf-member"){
        this.isUserDelete=false;        
        this.Msg="The user is no longer with DCF group";
      }
      else if (msgType == "DeleteError") {
        if(validationMsg!=null)
        {
          this.Msg = validationMsg;
        }
        else{
          this.Msg = "Error occured while deleting the Record!";
        }        
      }
      else if (msgType == "SuccessError") {
        this.Msg = "Error occured while saving the Record!";
      }
      else if (msgType == "UpdateError") {
        this.Msg = "Error occured while updating the Record!";
      }
      else if (msgType == "isNotUpdated") {
        this.Msg = "There was no change since last update, please try again!";
      }
    }
    else if(msg==AppSettings.tokenExpiredText)
      {
        this.isUserDelete=false;        
        this.Msg=AppSettings.sessionExpiredMessage; 
        this.sessionExpired = true;
      }
    else if(msg=="Dashboard"){
      this.Msg = "You are not allowed to access this form";
    }
    else if(msg=="DashboardRedirect"){
      this.Msg = "You are not allowed to access this form";
    }   
    else if(msg=="DeleteOperation") {
      if(msgType == "Info"){
        this.isUserDelete=true;
        this.isHardDelete=false;
        this.Msg="Are you sure, you want to delete the Record?"
      }
      else if(msgType == "HardDelete")
      {
        this.isUserDelete=true;
        this.isHardDelete=true;
        this.Msg="Are you sure you want to permanently delete this item? This action cannot be undone."
      }
     else if (msgType == "Success") {
        this.Msg = " Record has been Deleted Successfully!";
      }
      else {
        this.Msg = "Error in Deletion!";
      }
    }
    
    this.displayStyle = "block";
   }
   closePopup() {
    if(this.sessionExpired)
      {
        this.commonHelperService.isTokenExpired();
      }
    else{     
    this.ApplyBrowserScroll();
    this.displayStyle = "none";

    if(this.messageType=="SubmitSuccess"){
      this.redirectToOnboardingDashboard();
    }

    if(this.messageType=="SaveSuccess" || this.messageType=="UpdateSuccess"){
      this.LoadURLWithFormID();
    }

    if(this.messageType=="Info"){
      document.getElementById("dashboardLink")?.scrollIntoView({behavior:'smooth'})
    }
    this.addNotifyDetails.emit(this.MessageHeader);
   }
  }

   redirectToOnboardingDashboard(){
    window.location.href = AppSettings.onboardingFormDashboard;
  }

  LoadURLWithFormID(){
    let url = window.location.href;
    if(!url.includes("?")){
      if(this.formID!=undefined){document.location.href=url+"?id="+this.formID;}
    }
  }


   ConfirmToDelete(){
    this.ApplyBrowserScroll();
    this.displayStyle = "none";
    if(this.UserCurrentAction=="Activate")
    this.activateConfirmDetails.emit(true);
    else
    this.deleteConfirmDetails.emit(true);
   }
   ConfirmToCancel(){
    this.ApplyBrowserScroll();
    this.displayStyle = "none";
    if(this.UserCurrentAction=="Activate")
    this.activateConfirmDetails.emit(false);
    else
    this.deleteConfirmDetails.emit(false); 
   }
   ApplyBrowserScroll(){
    document.body.style.overflow="auto";
  }
   showMsgs(msg: any, msgType: any,validationMsg?:any) {
    this.openPopup(msg, msgType,validationMsg)
  }
    
}
