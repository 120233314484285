import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DropdownItem } from 'src/shared/models/dropdown-item';
import { PeoplepickerDynamicTableItem } from 'src/shared/models/peoplepicker-dynamic-table-item';
import { DynamicTableHeader } from 'src/shared/models/dynamic-table-header';
import { DynamicTableDropdownItemList } from 'src/shared/models/dynamic-table-dropdown-item-list';
import { AppSettings } from 'src/shared/app-settings';
//import { DropdownService } from 'src/app/shared/services/dropwdown.service';
import { PeoplePickerSelectedRow } from 'src/shared/components/peoplepicker-dynamic-table/people-picker-selected-row.interface';
import { ModalComponent } from 'src/shared/components/modal/modal.component';
import { PeoplepickerDynamicTableComponent } from 'src/shared/components/peoplepicker-dynamic-table/peoplepicker-dynamic-table.component';
import { PeoplepickerService } from 'src/shared/services/peoplepicker.service';
//import { NewDealSetupService } from '../shared/services/new-deal-setup.service';
import { MainService } from 'src/shared/services/main.service';
import { DynamicTableAction } from 'src/shared/models/dynamic-table-action';
import { ComplianceService } from '../shared/services/compliance-service';
import { DropdownService } from 'src/shared/services/dropwdown.service';
import { ClosingChecklistNotifyPopupComponent } from "src/shared/components/alerts/closing-checklist-notify-popup/closing-checklist-notify-popup.component";
import { environment } from 'src/environments/environment';
import { SpinnerService } from 'src/shared/services/spinner.service';
import { CommonHelperService } from 'src/app/commonHelper/common-helper.service';
import { NewdealService } from 'src/app/services/newdeal.service';
import { ClosingCheckListService } from 'src/app/services/closingchecklist.service';
import { Observable, from, map } from 'rxjs';

const modalTypes  = {
  dealTeamRowAdded: 'deal-team-row-added',
  swiftRoleValid: 'swift-role-valid',
  dealTeamRowDeleteValid : 'deal-team-row-delete-valid'
}

@Component({
  selector: 'app-deal-team-table',
  templateUrl: './deal-team-table.component.html',
  styleUrls: ['./deal-team-table.component.scss']
})
export class DealTeamTableComponent implements OnInit {

  dealTeamRoleList: Array<DropdownItem> = [];
  dealTeamPursuitRoleList: Array<DropdownItem> = [];
  dealTeamSwiftRoleList: Array<DropdownItem> = [];
  dealTeamUserList: Array<PeoplepickerDynamicTableItem> = [];
  dealTeamNameHeader: DynamicTableHeader = new DynamicTableHeader();
  dealTeamRoleHeader: DynamicTableHeader = new DynamicTableHeader();
  dealTeamSwiftRoleHeader: DynamicTableHeader = new DynamicTableHeader();
  dealTeamPursuitRoleHeader: DynamicTableHeader = new DynamicTableHeader();
  dealTeamRoleColumn: DynamicTableDropdownItemList = new DynamicTableDropdownItemList();
  dealTeamPursuitRoleColumn: DynamicTableDropdownItemList = new DynamicTableDropdownItemList();
  dealTeamSwiftRoleColumn: DynamicTableDropdownItemList = new DynamicTableDropdownItemList();
  sicCodesTooltip: string;
  rowToAdd: PeoplePickerSelectedRow;
  modalType: string;

  

  @Input()
  readonly = false;

  @Input()
  editable =true;
  @Input()
  firstColumnVisible = true;

  @Input()
  secondColumnVisible = true;

  @Input()
  thirdColumnVisible = true;

  @Input()
  allowToDuplicateUser = false;

  @Input()
  dealTeamValues: Array<PeoplepickerDynamicTableItem> = [];

  @Input()
  dealTeamTableFormGroup: any;

  @Output()
  dealTeamRowToStore: EventEmitter<PeoplePickerSelectedRow> = new EventEmitter<PeoplePickerSelectedRow>();

  @ViewChild('Modal',{static :true})
  modal: ModalComponent;

  @ViewChild('DealTeam',{static :true})
  dealTeam: PeoplepickerDynamicTableComponent;

  @ViewChild(ClosingChecklistNotifyPopupComponent, { static: true }) notifypopupComponent: ClosingChecklistNotifyPopupComponent;


  constructor(private dropdownService: DropdownService,
              private peoplepickerService: PeoplepickerService,
              private complianceService: ComplianceService,
              private spinnerService:SpinnerService,
              private commonHelperService:CommonHelperService,
              private mainService: MainService,private newDealService: NewdealService,private CCLService:ClosingCheckListService,
             ) { }

  ngOnInit() {
    this.LoadDealTeamDropdowns();
  
    this.dealTeamNameHeader.header = 'Name';
    this.dealTeamRoleHeader.header = 'Role';
    this.dealTeamPursuitRoleHeader.header = 'Mercury Pursuit Role';
    this.dealTeamSwiftRoleHeader.header = 'SWIFT Role';
    this.dealTeamPursuitRoleHeader.toolTip = 'Primary Role receives Deal Credit, Support Role does not';

    this.dealTeamRoleColumn.header = this.dealTeamRoleHeader;
    this.dealTeamPursuitRoleColumn.header = this.dealTeamPursuitRoleHeader;
    this.dealTeamSwiftRoleColumn.header = this.dealTeamSwiftRoleHeader;
  }
  // For Deal Team Dropdowns
  LoadDealTeamDropdowns(){
    this.dropdownService.fillLookupOptions_DealWBS(AppSettings.dealTeamRole, 'Role').subscribe(
      {
        next: (response: any) => {          
          this.dealTeamRoleList = response;
         this.mainService.dealTeamRoleList = this.dealTeamRoleList;
          this.dealTeamRoleColumn.itemList = this.dealTeamRoleList;
          this._setRoleNA();
        },
        error: (err: any) => {
          this.spinnerService.hideAutoSaveSpinner();
          if(err.status==0&&err.statusText=="Unknown Error"){this.commonHelperService.refreshToken();}  
          console.log("Error" + err)
        }
      })
      this.dropdownService.fillLookupOptions_DealWBS(AppSettings.dealTeamPursuitRole, 'Role').subscribe(  
        {
          next: (response: any) => {          
            this.dealTeamPursuitRoleList = response;
            this.mainService.dealTeamPursuitRoleList = this.dealTeamPursuitRoleList;
            this.dealTeamPursuitRoleColumn.itemList = this.dealTeamPursuitRoleList;
            this._setPursuitRoleNA();
          },
          error: (err: any) => {
            this.spinnerService.hideAutoSaveSpinner();
            if(err.status==0&&err.statusText=="Unknown Error"){this.commonHelperService.refreshToken();}  
            console.log("Error" + err)
          }
        })
        this.dropdownService.fillLookupOptions_DealWBS(AppSettings.dealTeamSwiftRole, 'Role').subscribe(
          {
            next: (response: any) => {          
              this.dealTeamSwiftRoleList = response;
              this.mainService.dealTeamSwiftRoleList = this.dealTeamSwiftRoleList;
              this.dealTeamSwiftRoleColumn.itemList = this.dealTeamSwiftRoleList;
              this._setSwiftRoleNA();
            },
            error: (err: any) => {
              this.spinnerService.hideAutoSaveSpinner();
              if(err.status==0&&err.statusText=="Unknown Error"){this.commonHelperService.refreshToken();}  
              console.log("Error" + err)
            }
          }) 
  }
  
  private _setPursuitRoleNA() {
    AppSettings.pursuitRoleNA = Number(this.dealTeamPursuitRoleList.filter(item => item.value === 'Other')[0].key);
  }

  private _setSwiftRoleNA() {
    AppSettings.swiftRoleNA = Number(this.dealTeamSwiftRoleList.filter(item => item.value === 'N/A')[0].key);
  }
  

  private _setRoleNA() {
    AppSettings.roleNA = Number(this.dealTeamRoleList.filter(item => item.value === 'N/A')[0].key);
  }

  setItemId(itemId: number, index: number) {
    this.dealTeam.setItemId(itemId, index);
  }

  //Add selected series24 as dealteam user
  addSeries24Row(series24User:any)
  {
    this.dealTeam.addSeries24Row(series24User);
  }
  
  //Add current user in dealteam table
  addUserDealTeamRow(currentUser:any)
  {
    this.dealTeam.addUserDealTeamRow(currentUser);
  }
  onDealTeamRowSelection(dealTeamRow: PeoplePickerSelectedRow) {
    if (dealTeamRow.actionType === DynamicTableAction.Add) {
      this._handlerNewRow(dealTeamRow);
    } else {
      const dealTeamUsers: PeoplepickerDynamicTableItem[] = this.dealTeamTableFormGroup.value;
      const arrayWithoutRowToDelete = dealTeamUsers.filter(user => user.itemId !== dealTeamRow.item.itemId);
      this.dealTeamTableFormGroup.setValue([...arrayWithoutRowToDelete]);
      this.dealTeamRowToStore.emit(dealTeamRow);
    }
  }

  validateSwiftRole(valid : boolean)
  {
    if (valid) {
      this.modalType = modalTypes.swiftRoleValid;
      this.notifypopupComponent.openPopup("ClosingChecklist",this.modalType);
    }
  }
  validateDealTeamMemberCount(valid : boolean)
  {
    if (valid) {
      this.modalType = modalTypes.dealTeamRowDeleteValid;
      this.notifypopupComponent.openPopup("ClosingChecklist",this.modalType);
    }
  }
  confirmModal() {
    this._setNewRow();
    this.modal.close();
  }

  cancelModal() {
    if (!this.rowToAdd.item.itemId) {
      this.dealTeam.deleteRow(this.rowToAdd.item.index);
    } else {
      const index = this.dealTeam.rowIndex(this.rowToAdd.item.usersSelected[0]);
      this.dealTeam.removeUserFromRow(
        this.rowToAdd.item.usersSelected[0],
        index
      );
    }
    this.modal.close();
  }

 
  private _handlerNewRow(dealTeamRow: PeoplePickerSelectedRow) {
      this.rowToAdd = dealTeamRow;
      const usersSelected = this.rowToAdd.item.usersSelected[0];
      // this.peoplepickerService.getUserId(usersSelected.EntityData.Email)
      this.CCLService.GetUserRoleGroups(this.rowToAdd.item.usersSelected[0].mail)
          .subscribe((idUser:any) => {
                  const itemIdInTable = this._isUserInTable();
                  itemIdInTable.subscribe(idInTable => {
                      if (idInTable > 0) {
                          const itemId = (this.rowToAdd.item.itemId as any)?.ID || this.rowToAdd.item.itemId;
                          const sameRow = idInTable === itemId;
                          this.rowToAdd.item.idUsersSelected = [usersSelected.id];
  
                          if (sameRow) {
                              this._setNewRow();
                          } else {
                              const index = itemId ? this.dealTeam.findIndexByItem(this.rowToAdd.item): this.rowToAdd.item.index;
                              if (this.dealTeam.checkDuplicateDealTeam(this.rowToAdd.item)) {
                                  this.dealTeam.removeUserFromRow(
                                      usersSelected,
                                      index
                                  );
                                  this.dealTeam.showError();
                              } else {
                                  this.rowToAdd.item.idUsersSelected = [usersSelected.id];
                                  this.modalType = modalTypes.dealTeamRowAdded;
                                  this.notifypopupComponent.openPopup("ClosingChecklist",this.modalType);
                              }
                          }
                      } else {
                          this.rowToAdd.item.idUsersSelected = [usersSelected.id];
                          if(localStorage.getItem('silentnofifyDealTeamRowAdded'))
                            {
                              // if(!this.dealTeam.isUserExistInDealTeam(this.rowToAdd.item.usersSelected[0])) 
                              this._setNewRow();
                              localStorage.removeItem('silentnofifyDealTeamRowAdded');
                          } else{
                              this.modalType = modalTypes.dealTeamRowAdded;
                              this.notifypopupComponent.openPopup("ClosingChecklist",this.modalType);
                          }
                      }
                  });
              },
              (err: any) => {
                  console.log("Error: " + err);
                  if (err.status == 403 && err.error.stringKey == AppSettings.tokenExpiredText) {
                      this._openSessionExpiredModal();
                  }
              }
          );
  }
  private _openSessionExpiredModal() {
    this.notifypopupComponent.openPopup(AppSettings.tokenExpiredText);
  }
  deleteConfirmDetails(event:any){
    if (event.isOkayClicked) {
      this.confirmModal();
    }
    else if (event.isCancelClicked) {
      this.cancelModal();
  }
    else if (event.isCancelClicked) {
      this.cancelModal();
    }
  }

  private _setNewRow() {
    const itemIdToInsert = this.rowToAdd.item.itemId;
    const dealTeamRows: PeoplepickerDynamicTableItem[] = this.dealTeamTableFormGroup.value;
    const isDuplicate = this.dealTeam.checkDuplicateDealTeam(this.rowToAdd.item);
    if (!isDuplicate) {
      if (!itemIdToInsert) {
        this.dealTeamTableFormGroup.setValue([...dealTeamRows, this.rowToAdd.item]);
      } else if (!isDuplicate) {
        const arrayWithoutRowToUpdate = dealTeamRows.filter(user => user.itemId !== this.rowToAdd.item.itemId);
        this.dealTeamTableFormGroup.setValue([...arrayWithoutRowToUpdate, this.rowToAdd.item]);
      }
      this.dealTeamRowToStore.emit(this.rowToAdd);
    // } else {
    //   const usersSelected = this.rowToAdd.item.usersSelected[0];
    //   /* index is beling set only for new row else undefined
    //      itemId available for existing rows. */
         
    //   const index = itemIdToInsert ? this.dealTeam.findIndexByItem(this.rowToAdd.item): this.rowToAdd.item.index; 
    //   this.dealTeam.removeUserFromRow(usersSelected, index);
    //   this.dealTeam.showError();
    }
  }

  private _isUserInTable(): Observable<any> {
    const defaultVal:any=0;
      const email = this.rowToAdd.item.usersSelected[0].mail;
      const formId = this.mainService.formId;
      const mydealFormId = this.mainService.myDealFormId;
      if (formId) {
       return from( this.complianceService.getDealTeamUserByEmail_CCL(mydealFormId, email))
       .pipe(
        map(response => {
        if (response.length) {
          return (response[0].ID);
        } else {
          return (defaultVal);
        }
      })
    )
  } else {
    return from(defaultVal);
  }
}

  // onRowSelection(dealTeamRow: PeoplePickerSelectedRow) {
  //   if (dealTeamRow.actionType === DynamicTableAction.Add) {
  //     this._handlerNewRow(dealTeamRow);
  //   } else {
  //     const dealTeamUsers: PeoplepickerDynamicTableItem[] = this.project.value;
  //     const arrayWithoutRowToDelete = dealTeamUsers.filter(user => user.itemId !== dealTeamRow.item.itemId);
  //     this.ppmdTableFormGroup.setValue([...arrayWithoutRowToDelete]);
  //     this.ppmdRowToStore.emit(dealTeamRow);
  //   }
  // }
}
