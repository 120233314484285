<div class="row">
  <div class="col-xs-6">
    <div class="headerManagement">Onboarding Registration Checklist</div>
  </div>
</div>

<form [formGroup]="newhireFormGroup" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">
  <div id="newhire-form" class="container-fluid border container-border-color container-section">
    <button 
      type="button" 
      class="btn btn-success"
      name="AuditFormButton"
      id="AuditFormButton" 
      (click)="getTerminationFormAuditDetials()" 
      [disabled]="isExportEnabled">Audit Details</button> 
    <div class="row bg-title">
      <span>Onboarding Form</span>
    </div>
    
      <div>
      <div id="newhire-form-section" class="content-section">
        <span><p class="bolded">New Hire Information</p></span>
        <div class="row">
          <div class="col-xs-4 section-label">
            <span [class.highlight-warning]="highlightRequiredField('lastName')">Last Name </span>
            <span style="color:red">*</span>
          </div>
          <div class="col-xs-6 input-width input-align">
            <input formControlName="lastName" 
              class="input-data-text full-width section-input" 
              type="text"
              autocomplete="off"
              maxlength="255">
          </div>
        </div>
      
        <div class="row">
          <div class="col-xs-4 section-label">
            <span [class.highlight-warning]="highlightRequiredField('firstName')">First Name </span>
            <span style="color:red">*</span>
          </div>
          <div class="col-xs-6 input-width input-align">
            <input formControlName="firstName" 
              class="input-data-text full-width section-input" 
              type="text"
              autocomplete="off"
              maxlength="255">
          </div>
        </div>
    
        <div class="row">
          <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('dateOfHire')" >Date of Hire</span>
            <span style="color:red">*</span>
          </div>
          <div class="col-xs-7 input-align">
            <app-date-picker 
              [selectedDate]="newhireFormGroup.get('dateOfHire')?.value"
              (dateSelected)="eventDateSelected($event,'dateOfHire')">
            </app-date-picker>
            <span class="numeric-validation-msg"></span>
          </div>
        </div>
    
        <div class="row">
          <div class="col-xs-4">
          <span [class.highlight-warning]="highlightRequiredField('RegStatus') || (newhireFormGroup.get('RegStatus')?.value=='Registered' && highlightRequiredField('RegisteredStatus'))
                      ||(newhireFormGroup.get('RegStatus')?.value=='NRF' && highlightRequiredField('NRFStatus'))
                      || (newhireFormGroup.get('RegStatus')?.value=='NRF' && newhireFormGroup.get('NRFStatus')?.value=='Other' && highlightRequiredField('otherNRFStatusReason')) ">Registration Status: </span>
          <span style="color:red">*</span>
          </div>
          <div class="col-xs-4 radio-button-align">
          <input class="radioStyle" type="radio" formControlName="RegStatus" value="Registered" (change)="onRSChange($event)"> Registered
          <input class="radioStyle" type="radio" formControlName="RegStatus" value="NRF" (change)="onRSChange($event)"> NRF
          </div>  
          <div class="col-xs-8" style="float: right;">
          <div class="col-xs-8" style="margin-left: 3.5%;" *ngIf="isRegisteredStatus">
                        Supervisory <input class="radioStyle" type="radio" formControlName="RegisteredStatus"  value="Yes" > Yes
          <input class="radioStyle" type="radio" formControlName="RegisteredStatus"  value="No" > No
          </div>
           
                      <div class="col-xs-8" style="margin-left: 0.88%;" *ngIf="isNRFStatus">
          <input class="radioStyle" type="radio" formControlName="NRFStatus"  value="Intern" (change)="onNRFStatusChange($event)"> Intern
          <input class="radioStyle" type="radio" formControlName="NRFStatus"  value="USI" (change)="onNRFStatusChange($event)"> USI
          <input class="radioStyle" type="radio" formControlName="NRFStatus"  value="BDA Contractor" (change)="onNRFStatusChange($event)"> BDA Contractor
          <input class="radioStyle" type="radio" formControlName="NRFStatus"  value="Other" (change)="onNRFStatusChange($event)"> Other
          </div> 
          <textarea style="width: 55%;margin-left: 3.5%;" cols="2" *ngIf="isOtherNRFStatus&& !isRegisteredStatus"
                          type="text"
                          rows="3"
                          formControlName="otherNRFStatusReason"
                          placeholder="Reason"
                          class="input-data-text text-area-style"
          ></textarea>
          </div>     
          </div>
    
        <div class="row">
          <div class="col-xs-4 section-label">
            <span [class.highlight-warning]="highlightRequiredField('supervisoryBranchOffice')">Supervisory Branch Office:</span>
            <span style="color:red"> *</span>
          </div>
          <div class="col-xs-6 input-width input-align">
            <app-dropdown 
              [optionsList]="supervisoryBranchOfficeList"
              [keySelected]="newhireFormGroup.get('supervisoryBranchOffice')?.value"
              (optionSelected)="onsupervisoryBranchOfficeChange($event)">
            </app-dropdown>
          </div>
        </div>
    
        <div class="row">
          <div class="col-xs-4">
            <span [class.highlight-warning]="highlightRequiredField('NonRegisteredBranchOffice')
            || (newhireFormGroup.get('NonRegisteredBranchOffice')?.value=='Yes' && highlightRequiredField('NonRegisteredBranchOfficeComments') )">Will this individual be located at a non-registered branch office? </span>
            <span style="color:red"> *</span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <input class="radioStyle" type="radio" formControlName="NonRegisteredBranchOffice" value="Yes" (change)="onNonRegisteredBranchOfficeChange($event)"> Yes
            <input class="radioStyle" type="radio" formControlName="NonRegisteredBranchOffice" value="No" (change)="onNonRegisteredBranchOfficeChange($event)"> No
            <textarea cols="2" style="margin-left:4%" *ngIf="isNonRegisteredBranchOffice"
              type="text"
              formControlName="NonRegisteredBranchOfficeComments"
              rows="3"
              placeholder="Comment"
              class="input-data-text text-area-style"></textarea>
          </div>
        </div>
    
        <div class="row">
          <div class="col-xs-4 section-label">
            <span [class.highlight-warning]="highlightRequiredField('series24BranchSupervisor')">Series 24 Branch Supervisor:</span>
            <span style="color:red"> *</span>
          </div>
          <div class="col-xs-6 input-width input-align">
            <app-dropdown 
              [optionsList]="series24BranchSupervisorList"
              [keySelected]="newhireFormGroup.get('series24BranchSupervisor')?.value"
              (optionSelected)="onseries24BranchSupervisorChange($event)">
            </app-dropdown>
          </div>
        </div>
    
        <div class="row">
          <div class="col-xs-4">
            <span [class.highlight-warning]="highlightRequiredField('series24BranchSupervisorNotified')
            || (newhireFormGroup.get('series24BranchSupervisorNotified')?.value=='No' && highlightRequiredField('series24BranchSupervisorNotifiedComments') )">Series 24 Branch Supervisor notified of new employee under their supervision? </span>
            <span style="color:red"> *</span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <input class="radioStyle" type="radio" formControlName="series24BranchSupervisorNotified" value="Yes" (change)="onSeries24BranchSupervisorNotifiedChange($event)"> Yes
            <input class="radioStyle" type="radio" formControlName="series24BranchSupervisorNotified" value="No" (change)="onSeries24BranchSupervisorNotifiedChange($event)"> No
            <textarea cols="2" style="margin-left:4%" *ngIf="isseries24BranchSupervisorNotified"
              type="text"
              formControlName="series24BranchSupervisorNotifiedComments"
              rows="3"
              placeholder="Comment"
              class="input-data-text text-area-style"></textarea>
          </div>
        </div>
    
        <div class="row">
          <div class="col-xs-4">
            <span [class.highlight-warning]="highlightRequiredField('returningHire')
            || (newhireFormGroup.get('returningHire')?.value=='No' && highlightRequiredField('returningHire') )">Returning hire?</span>
            <span style="color:red"> *</span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <input class="radioStyle" type="radio" formControlName="returningHire" value="Yes"> Yes
            <input class="radioStyle" type="radio" formControlName="returningHire" value="No"> No
          </div>
        </div>
    
        <div class="row">
          <div class="col-xs-4">
            <span>Additional Comments:</span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <textarea cols="2" style="margin-left: 4%;" 
              type="text"
              name="additionalCommentsNewHireInfo"
              [ngModelOptions]="{standalone: true}"
              [(ngModel)]="additionalCommentsNewHireInfo"
              rows="3"
              placeholder="Comments"
              class="input-data-text text-area-style"></textarea>
          </div>
        </div>
      </div>
    
      <div>
        <span><p class="bolded">Onboarding Documentation and DCF Systems and Platforms Access</p></span>
    
        <div class="row">
          <div class="col-xs-4">
            <span [class.highlight-warning]="highlightRequiredField('welcomeEmail')
            || (newhireFormGroup.get('welcomeEmail')?.value=='No' && highlightRequiredField('welcomeEmailComments') )">Welcome Email sent? </span>
            <span style="color:red"> *</span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <input class="radioStyle" type="radio" formControlName="welcomeEmail" value="Yes" (change)="onwelcomeEmailChange($event)"> Yes
            <input class="radioStyle" type="radio" formControlName="welcomeEmail" value="No" (change)="onwelcomeEmailChange($event)"> No
            <textarea cols="2" style="margin-left:4%" *ngIf="iswelcomeEmailSent"
              type="text"
              formControlName="welcomeEmailComments"
              rows="3"
              placeholder="Comment"
              class="input-data-text text-area-style"></textarea>
          </div>
        </div>
    
        <div class="row">
          <div class="col-xs-4">
            <span [class.highlight-warning]="highlightRequiredField('backgroundCheck')
            || (newhireFormGroup.get('backgroundCheck')?.value=='No' && highlightRequiredField('backgroundCheckComments') )">Background check received, reviewed, and saved to employee file? </span>
            <span style="color:red"> *</span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <input class="radioStyle" type="radio" formControlName="backgroundCheck" value="Yes" (change)="onbackgroundCheckChange($event)"> Yes
            <input class="radioStyle" type="radio" formControlName="backgroundCheck" value="No" (change)="onbackgroundCheckChange($event)"> No
            <textarea cols="2" style="margin-left:4%" *ngIf="isbackgroundCheck"
              type="text"
              formControlName="backgroundCheckComments"
              rows="3"
              placeholder="Comment"
              class="input-data-text text-area-style"></textarea>
          </div>
        </div>
    
        <div class="row">
          <div class="col-xs-4">
            <span [class.highlight-warning]="highlightRequiredField('deloitteApplication')
            || (newhireFormGroup.get('deloitteApplication')?.value=='No' && highlightRequiredField('deloitteApplicationComments') )">Deloitte application received, reviewed, and saved to employee file? </span>
            <span style="color:red"> *</span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <input class="radioStyle" type="radio" formControlName="deloitteApplication" value="Yes" (change)="ondeloitteApplicationChange($event)"> Yes
            <input class="radioStyle" type="radio" formControlName="deloitteApplication" value="No" (change)="ondeloitteApplicationChange($event)"> No
            <textarea cols="2" style="margin-left:4%" *ngIf="isdeloitteApplication"
              type="text"
              formControlName="deloitteApplicationComments"
              rows="3"
              placeholder="Comment"
              class="input-data-text text-area-style"></textarea>
          </div>
        </div>
    
        <div class="row">
          <div class="col-xs-4">
            <span [class.highlight-warning]="highlightRequiredField('LexisNexis')
            || (newhireFormGroup.get('LexisNexis')?.value=='No' && highlightRequiredField('LexisNexisComments') )">Lexis Nexis reviewed and saved to employee file? </span>
            <span style="color:red"> *</span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <input class="radioStyle" type="radio" formControlName="LexisNexis" value="Yes" (change)="onLexisNexisChange($event)"> Yes
            <input class="radioStyle" type="radio" formControlName="LexisNexis" value="No" (change)="onLexisNexisChange($event)"> No
            <input class="radioStyle" type="radio" formControlName="LexisNexis" value="N/A" (change)="onLexisNexisChange($event)"> N/A
            <textarea cols="2" style="margin-left:4%" *ngIf="isLexisNexis"
              type="text"
              formControlName="LexisNexisComments"
              rows="3"
              placeholder="Comment"
              class="input-data-text text-area-style"></textarea>
          </div>
        </div>
    
        <div class="row">
          <div class="col-xs-4">
            <span [class.highlight-warning]="highlightRequiredField('socialmediaCheck')
            || (newhireFormGroup.get('socialmediaCheck')?.value=='No' && highlightRequiredField('socialmediaCheckComments') )">Social media (Google/LinkedIn) check performed and saved to employee file? </span>
            <span style="color:red"> *</span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <input class="radioStyle" type="radio" formControlName="socialmediaCheck" value="Yes" (change)="onSocialmediaCheckChange($event)"> Yes
            <input class="radioStyle" type="radio" formControlName="socialmediaCheck" value="No" (change)="onSocialmediaCheckChange($event)"> No
            <textarea cols="2" style="margin-left:4%" *ngIf="isSocialmediaCheck"
              type="text"
              formControlName="socialmediaCheckComments"
              rows="3"
              placeholder="Comment"
              class="input-data-text text-area-style"></textarea>
          </div>
        </div>
        
        <div>
        <div class="row">
          <div class="col-xs-4">
            <span>
              Added to DCF systems and platforms?
            </span>
            
          </div>
        </div>
        
        <div class="row">
          <div class="col-xs-4">
            <span class="sub-question" [class.highlight-warning]="highlightRequiredField('ActiveDirectory')
            || (newhireFormGroup.get('ActiveDirectory')?.value=='No' && highlightRequiredField('ActiveDirectoryComments') )">Active Directory</span>
            <span style="color:red"> *</span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <input class="radioStyle" type="radio" formControlName="ActiveDirectory" value="Yes" (change)="onActiveDirectoryChange($event)"> Yes
            <input class="radioStyle" type="radio" formControlName="ActiveDirectory" value="No" (change)="onActiveDirectoryChange($event)"> No
            <textarea cols="2" style="margin-left:4%" *ngIf="isActiveDirectory"
              type="text"
              formControlName="ActiveDirectoryComments"
              rows="3"
              placeholder="Comment"
              class="input-data-text text-area-style"></textarea>
          </div>
        </div>
        
        <div class="row">
          <div class="col-xs-4">
            <span class="sub-question"  [class.highlight-warning]="highlightRequiredField('KnowledgeCenter')
            || (newhireFormGroup.get('KnowledgeCenter')?.value=='No' && highlightRequiredField('KnowledgeCenterComments') )">Knowledge Center</span>
            <span style="color:red">*</span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <input class="radioStyle" type="radio" formControlName="KnowledgeCenter" value="Yes" (change)="onKnowledgeCenterChange($event)"> Yes
            <input class="radioStyle" type="radio" formControlName="KnowledgeCenter" value="No" (change)="onKnowledgeCenterChange($event)"> No
            <textarea cols="2" style="margin-left:4%" *ngIf="isKnowledgeCenter"
              type="text"
              formControlName="KnowledgeCenterComments"
              rows="3"
              placeholder="Comment"
              class="input-data-text text-area-style"></textarea>
          </div>
        </div>
        
        <div class="row">
          <div class="col-xs-4">
            <span class="sub-question"  [class.highlight-warning]="highlightRequiredField('DFlowPermissionsSpreadsheet')|| (newhireFormGroup.get('DFlowPermissionsSpreadsheet')?.value=='No' && highlightRequiredField('DFlowPermissionsSpreadsheetComments'))" >DFlow Permissions Spreadsheet</span>
            <span style="color:red">*</span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <input class="radioStyle" type="radio" formControlName="DFlowPermissionsSpreadsheet" value="Yes" (change)="onDFlowPermissionsSpreadsheetChange($event)"> Yes
            <input class="radioStyle" type="radio" formControlName="DFlowPermissionsSpreadsheet" value="No" (change)="onDFlowPermissionsSpreadsheetChange($event)"> No
            <input class="radioStyle" type="radio" formControlName="DFlowPermissionsSpreadsheet" value="N/A" (change)="onDFlowPermissionsSpreadsheetChange($event)"> N/A
            <textarea cols="2" style="margin-left:4%" *ngIf="isDFlowPermissionsSpreadsheet"
              type="text"
              formControlName="DFlowPermissionsSpreadsheetComments"
              rows="3"
              placeholder="Comment"
              class="input-data-text text-area-style"></textarea>
          </div>
        </div>
        
        <div class="row">
          <div class="col-xs-4">
            <span class="sub-question" [class.highlight-warning]="highlightRequiredField('SupervisoryOrgChart')|| (newhireFormGroup.get('SupervisoryOrgChart')?.value=='No' && highlightRequiredField('SupervisoryOrgChartComments'))">Supervisory Org Chart</span>
            <span style="color:red">*</span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <input class="radioStyle" type="radio" formControlName="SupervisoryOrgChart" value="Yes" (change)="onSupervisoryOrgChartChange($event)"> Yes
            <input class="radioStyle" type="radio" formControlName="SupervisoryOrgChart" value="No" (change)="onSupervisoryOrgChartChange($event)"> No
            <textarea cols="2" style="margin-left:4%" *ngIf="isSupervisoryOrgChart"
              type="text"
              formControlName="SupervisoryOrgChartComments"
              rows="3"
              placeholder="Comment"
              class="input-data-text text-area-style"></textarea>
          </div>
        </div>
        
        <div class="row">
          <div class="col-xs-4">
            <span class="sub-question"  [class.highlight-warning]="highlightRequiredField('CSI')|| (newhireFormGroup.get('CSI')?.value=='No' && highlightRequiredField('CSIComments'))">CSI</span>
            <span style="color:red">*</span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <input class="radioStyle" type="radio" formControlName="CSI" value="Yes" (change)="onCSIChange($event)"> Yes
            <input class="radioStyle" type="radio" formControlName="CSI" value="No" (change)="onCSIChange($event)"> No
            <textarea cols="2" style="margin-left:4%" *ngIf="isCSI"
              type="text"
              formControlName="CSIComments"
              rows="3"
              placeholder="Comment"
              class="input-data-text text-area-style"></textarea>
          </div>
        </div>
        
        <div class="row">
          <div class="col-xs-4">
            <span class="sub-question" [class.highlight-warning]="highlightRequiredField('Quest')|| (newhireFormGroup.get('Quest')?.value=='No' && highlightRequiredField('QuestComments'))">Quest</span>
            <span style="color:red">*</span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <input class="radioStyle" type="radio" formControlName="Quest" value="Yes" (change)="onQuestChange($event)"> Yes
            <input class="radioStyle" type="radio" formControlName="Quest" value="No" (change)="onQuestChange($event)"> No
            <textarea cols="2" style="margin-left:4%" *ngIf="isQuest"
              type="text"
              formControlName="QuestComments"
              rows="3"
              placeholder="Comment"
              class="input-data-text text-area-style"></textarea>
          </div>
        </div>
      </div>
     
    
        <div class="row">
          <div class="col-xs-4">
            <span>Additional Comments </span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <textarea cols="2" style="margin-left: 4%;" 
              type="text"
              name="additionalCommentsOnboardingDocumentation"
              [ngModelOptions]="{standalone: true}"
              [(ngModel)]="additionalCommentsOnboardingDocumentation"
              rows="3"
              placeholder="Comments"
              class="input-data-text text-area-style"></textarea>
          </div>
        </div>
      </div>
    
      <div>
        <span><p class="bolded">Fingerprinting, FINRA Licensing and Exams</p></span>
    
        <div class="row">
          <div class="col-xs-4">
            <span [class.highlight-warning]="highlightRequiredField('bigEmailOrHardcopy')|| (newhireFormGroup.get('bigEmailOrHardcopy')?.value=='No' && highlightRequiredField('bigEmailOrHardcopyComments'))">BIG email or hardcopy fingerprinting cards sent? </span>
            <span style="color:red">*</span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <input class="radioStyle" type="radio" formControlName="bigEmailOrHardcopy" value="Yes" (change)="onbigEmailOrHardcopyChange($event)"> Yes
            <input class="radioStyle" type="radio" formControlName="bigEmailOrHardcopy" value="No" (change)="onbigEmailOrHardcopyChange($event)"> No
            <textarea cols="2" style="margin-left:4%" *ngIf="isbigEmailOrHardcopy"
              type="text"
              formControlName="bigEmailOrHardcopyComments"
              rows="3"
              placeholder="Comment"
              class="input-data-text text-area-style"></textarea>
          </div>
        </div>
    
        <div class="row">
          <div class="col-xs-4">
            <span [class.highlight-warning]="highlightRequiredField('fingerprintsClearedWithFINRA')|| (newhireFormGroup.get('fingerprintsClearedWithFINRA')?.value=='No' && highlightRequiredField('fingerprintsClearedWithFINRAComments'))">Fingerprints cleared with FINRA? </span>
            <span style="color:red">*</span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <input class="radioStyle" type="radio" formControlName="fingerprintsClearedWithFINRA" value="Yes" (change)="onfingerprintsClearedWithFINRAChange($event)"> Yes
            <input class="radioStyle" type="radio" formControlName="fingerprintsClearedWithFINRA" value="No" (change)="onfingerprintsClearedWithFINRAChange($event)"> No
            <textarea cols="2" style="margin-left:4%" *ngIf="isfingerprintsClearedWithFINRA"
              type="text"
              formControlName="fingerprintsClearedWithFINRAComments"
              rows="3"
              placeholder="Comment"
              class="input-data-text text-area-style"></textarea>
          </div>
        </div>
    
        <div>
          <div class="row">
            <div class="col-xs-4">
              <span [class.highlight-warning]="highlightRequiredField('FinraLicense') || 
                (newhireFormGroup.get('FinraLicense')?.value == 'No' && highlightRequiredField('FinraLicense')) || 
                (newhireFormGroup.get('FinraLicense')?.value == 'Yes' && 
                  (highlightRequiredField('BrokerCheck') || 
                  (newhireFormGroup.get('BrokerCheck')?.value == 'No' && highlightRequiredField('BrokerCheckComments')) || 
                  highlightRequiredField('FormU5') || 
                  (newhireFormGroup.get('FormU5')?.value == 'No' && highlightRequiredField('FormU5Comments')) || 
                  highlightRequiredField('FinraCEInactive') || 
                  (newhireFormGroup.get('FinraCEInactive')?.value == 'No' && highlightRequiredField('FinraCEInactive')) || 
                  (newhireFormGroup.get('FinraCEInactive')?.value == 'Yes' && highlightRequiredField('OutstandingFinraCE'))))">
                Does this individual currently hold or has this individual previously held FINRA licenses?
              </span>
              <span style="color:red"> *</span>
            </div>
            <div class="col-xs-4 radio-button-align">
              <input class="radioStyle" type="radio" formControlName="FinraLicense" value="Yes" (change)="onFinraLicenseChange($event)"> Yes
              <input class="radioStyle" type="radio" formControlName="FinraLicense" value="No" (change)="onFinraLicenseChange($event)"> No
            </div>
          </div>

          <div *ngIf="isFinraLicense">
            <div class="row ">
              <div class="col-xs-4 subsection">
                <span [class.highlight-warning]="highlightRequiredField('BrokerCheck')|| (newhireFormGroup.get('BrokerCheck')?.value=='No' && highlightRequiredField('BrokerCheckComments'))">BrokerCheck reviewed and saved to employee file?</span>
                <span style="color:red"> *</span>
              </div>
              <div class="col-xs-4 radio-button-align subsection-radio">
                <input class="radioStyle" type="radio" formControlName="BrokerCheck" value="Yes" (change)="onBrokerCheckChange($event)"> Yes
                <input class="radioStyle" type="radio" formControlName="BrokerCheck" value="No" (change)="onBrokerCheckChange($event)"> No
                <textarea cols="2" style="margin-left:4%" *ngIf="isBrokerCheck"
                  type="text"
                  formControlName="BrokerCheckComments"
                  rows="3"
                  placeholder="Comment"
                  class="input-data-text text-area-style"></textarea>
              </div>
            </div>
          
            <div class="row">
              <div class="col-xs-4 subsection">
                <span [class.highlight-warning]="highlightRequiredField('FormU5')|| (newhireFormGroup.get('FormU5')?.value=='No' && highlightRequiredField('FormU5Comments'))">Received Form U5 from prior employer?</span>
                <span style="color:red"> *</span>
              </div>
              <div class="col-xs-4 radio-button-align subsection-radio">
                <input class="radioStyle" type="radio" formControlName="FormU5" value="Yes" (change)="onFormU5Change($event)"> Yes
                <input class="radioStyle" type="radio" formControlName="FormU5" value="No" (change)="onFormU5Change($event)"> No
                <input class="radioStyle" type="radio" formControlName="FormU5" value="N/A" (change)="onFormU5Change($event)"> N/A
                <textarea cols="2" style="margin-left:4%" *ngIf="isFormU5"
                  type="text"
                  formControlName="FormU5Comments"
                  rows="3"
                  placeholder="Comment"
                  class="input-data-text text-area-style"></textarea>
              </div>
            </div>
          
            <div class="row">
              <div class="col-xs-4 subsection">
                <span [class.highlight-warning]="highlightRequiredField('FinraCEInactive')|| (newhireFormGroup.get('FinraCEInactive')?.value=='No' && highlightRequiredField('FinraCEInactive'))">FINRA CE Inactive?</span>
                <span style="color:red"> *</span>
              </div>
              <div class="col-xs-4 radio-button-align subsection-radio">
                <input class="radioStyle" type="radio" formControlName="FinraCEInactive" value="Yes" (change)="onFinraCEInactiveChange($event)"> Yes
                <input class="radioStyle" type="radio" formControlName="FinraCEInactive" value="No" (change)="onFinraCEInactiveChange($event)"> No
                <input class="radioStyle" type="radio" formControlName="FinraCEInactive" value="N/A" (change)="onFinraCEInactiveChange($event)"> N/A
              </div>
            </div>
          
            <div *ngIf="newhireFormGroup.get('FinraCEInactive')?.value === 'Yes'">
              <div class="row">
                <div class="col-xs-4" style="margin-left:7%;">
                  <span [class.highlight-warning]="highlightRequiredField('OutstandingFinraCE')|| (newhireFormGroup.get('OutstandingFinraCE')?.value=='No' && highlightRequiredField('OutstandingFinraCE'))">Outstanding FINRA CE Completed?</span>
                  <span style="color:red"> *</span>
                </div>
                <div class="col-xs-4 radio-button-align" style="margin-left:-6%">
                  <input class="radioStyle" type="radio" formControlName="OutstandingFinraCE" value="Yes"> Yes
                  <input class="radioStyle" type="radio" formControlName="OutstandingFinraCE" value="No"> No
                </div>
              </div>
            </div>
    
            <div class="row">
              <div class="col-xs-4 subsection">
                <span>Additional Comments </span>
              </div>
              <div class="col-xs-4 radio-button-align">
                  <textarea cols="2" style="margin-left: -4%;" 
                  type="text"
                  name="additionalCommentsFinraLicenses1"
                  [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="additionalCommentsFinraLicenses1"
                  rows="3"
                  placeholder="Comments"
                  class="input-data-text text-area-style"></textarea>
              </div>
            </div>
          </div>
          
                    <div class="row">
            <div class="col-xs-4 section-label">
              <span [class.highlight-warning]="highlightRequiredField('finraLicenses') || (isOtherSelected && highlightRequiredField('otherFinraLicense'))">
                FINRA Licenses required:
              </span>
              <span style="color:red"> *</span>
            </div>
            <div class="col-xs-6 input-width input-align">
              <div class="checkbox-container-horizontal" style="margin-left:1%">
                <div class="checkbox-item">
                  <input
                    type="checkbox"
                    [value]="'N/A'"
                    (change)="onFinraLicenseCheckboxChange($event)"
                    [checked]="newhireFormGroup.get('finraLicenses')?.value?.includes('N/A')"
                  />
                  <label>N/A</label>
                </div>
                <div class="checkbox-item">
                  <input
                    type="checkbox"
                    [value]="'SIE'"
                    (change)="onFinraLicenseCheckboxChange($event)"
                    [checked]="newhireFormGroup.get('finraLicenses')?.value?.includes('SIE')"
                  />
                  <label>SIE</label>
                </div>
                <div class="checkbox-item">
                  <input
                    type="checkbox"
                    [value]="'S7'"
                    (change)="onFinraLicenseCheckboxChange($event)"
                    [checked]="newhireFormGroup.get('finraLicenses')?.value?.includes('S7')"
                  />
                  <label>S7</label>
                </div>
                <div class="checkbox-item">
                  <input
                    type="checkbox"
                    [value]="'S79'"
                    (change)="onFinraLicenseCheckboxChange($event)"
                    [checked]="newhireFormGroup.get('finraLicenses')?.value?.includes('S79')"
                  />
                  <label>S79</label>
                </div>
                <div class="checkbox-item">
                  <input
                    type="checkbox"
                    [value]="'S63'"
                    (change)="onFinraLicenseCheckboxChange($event)"
                    [checked]="newhireFormGroup.get('finraLicenses')?.value?.includes('S63')"
                  />
                  <label>S63</label>
                </div>
                <div class="checkbox-item">
                  <input
                    type="checkbox"
                    [value]="'S24'"
                    (change)="onFinraLicenseCheckboxChange($event)"
                    [checked]="newhireFormGroup.get('finraLicenses')?.value?.includes('S24')"
                  />
                  <label>S24</label>
                </div>
                <div class="checkbox-item">
                  <input
                    type="checkbox"
                    [value]="'Other'"
                    (change)="onFinraLicenseCheckboxChange($event)"
                    [checked]="newhireFormGroup.get('finraLicenses')?.value?.includes('Other')"
                  />
                  <label>Other</label>
                </div>
              </div>
              <!-- Textarea for "Other" -->
              <textarea
                cols="2"
                style="margin-left:1%; margin-top: 10px; width: 58%;"
                *ngIf="isOtherSelected"
                formControlName="otherFinraLicense"
                rows="3"
                placeholder="Comment"
                class="input-data-text text-area-style"
              ></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-4">
              <span [class.highlight-warning]="highlightRequiredField('studyMaterialsOrdered')|| (newhireFormGroup.get('studyMaterialsOrdered')?.value=='No' && highlightRequiredField('studyMaterialsOrderedComments'))">Study Materials ordered? </span>
              <span style="color:red"> *</span>
            </div>
            <div class="col-xs-4 radio-button-align">
              <input class="radioStyle" type="radio" formControlName="studyMaterialsOrdered" value="Yes" (change)="onStudyMaterialsOrderedChange($event)"> Yes
              <input class="radioStyle" type="radio" formControlName="studyMaterialsOrdered" value="No" (change)="onStudyMaterialsOrderedChange($event)"> No
              <input class="radioStyle" type="radio" formControlName="studyMaterialsOrdered" value="N/A" (change)="onStudyMaterialsOrderedChange($event)"> N/A
              <textarea cols="2" style="margin-left:4%" *ngIf="isStudyMaterialsOrdered"
                type="text"
                formControlName="studyMaterialsOrderedComments"
                rows="3"
                placeholder="Comment"
                class="input-data-text text-area-style"></textarea>
            </div>
          </div>
    
          <div class="row">
            <div class="col-xs-4">
              <span [class.highlight-warning]="highlightRequiredField('initialFormU4NRFFilingComplete')|| (newhireFormGroup.get('initialFormU4NRFFilingComplete')?.value=='No' && highlightRequiredField('initialFormU4NRFFilingCompleteComments'))">Initial Form U4 / NRF Filing complete? </span>
              <span style="color:red"> *</span>
            </div>
            <div class="col-xs-4 radio-button-align">
              <input class="radioStyle" type="radio" formControlName="initialFormU4NRFFilingComplete" value="Yes" (change)="onInitialFormU4NRFFilingCompleteChange($event)"> Yes
              <input class="radioStyle" type="radio" formControlName="initialFormU4NRFFilingComplete" value="No" (change)="onInitialFormU4NRFFilingCompleteChange($event)"> No
              <input class="radioStyle" type="radio" formControlName="initialFormU4NRFFilingComplete" value="N/A" (change)="onInitialFormU4NRFFilingCompleteChange($event)"> N/A
              <textarea cols="2" style="margin-left:4%" *ngIf="isInitialFormU4NRFFilingComplete"
                type="text"
                formControlName="initialFormU4NRFFilingCompleteComments"
                rows="3"
                placeholder="Comment"
                class="input-data-text text-area-style"></textarea>
            </div>
          </div>
    
          <div class="row">
            <div class="col-xs-4">
              <span [class.highlight-warning]="highlightRequiredField('statutorilyDisqualified')|| (newhireFormGroup.get('statutorilyDisqualified')?.value=='Yes' && highlightRequiredField('statutorilyDisqualifiedComments'))">Is the employee Statutorily Disqualified (“SD”) or subject to Heightened Supervision (“HS”) (e.g. this can be caused by certain “yes” answers to Questions within the Form U4)? </span>
              <span style="color:red">*</span>
            </div>
            <div class="col-xs-4 radio-button-align">
              <input class="radioStyle" type="radio" formControlName="statutorilyDisqualified" value="Yes" (change)="onStatutorilyDisqualifiedChange($event)"> Yes
              <input class="radioStyle" type="radio" formControlName="statutorilyDisqualified" value="No" (change)="onStatutorilyDisqualifiedChange($event)"> No
              <textarea cols="2" style="margin-left:4%" *ngIf="isStatutorilyDisqualified"
                type="text"
                formControlName="statutorilyDisqualifiedComments"
                rows="3"
                placeholder="Comment"
                class="input-data-text text-area-style"></textarea>
            </div>
          </div>
          
          <div class="row">
            <div class="col-xs-4">
              <span [class.highlight-warning]="highlightRequiredField('stateRegistrationMemoSent')|| (newhireFormGroup.get('stateRegistrationMemoSent')?.value=='No' && highlightRequiredField('stateRegistrationMemoSentComments'))">State registration memo sent? </span>
              <span style="color:red">*</span>
            </div>
            <div class="col-xs-4 radio-button-align">
              <input class="radioStyle" type="radio" formControlName="stateRegistrationMemoSent" value="Yes" (change)="onStateRegistrationMemoSentChange($event)"> Yes
              <input class="radioStyle" type="radio" formControlName="stateRegistrationMemoSent" value="No" (change)="onStateRegistrationMemoSentChange($event)"> No
              <input class="radioStyle" type="radio" formControlName="stateRegistrationMemoSent" value="N/A" (change)="onStateRegistrationMemoSentChange($event)"> N/A
              <textarea cols="2" style="margin-left:4%" *ngIf="isStateRegistrationMemoSent"
                type="text"
                formControlName="stateRegistrationMemoSentComments"
                rows="3"
                placeholder="Comment"
                class="input-data-text text-area-style"></textarea>
            </div>
          </div>
    
          <div class="row">
            <div class="col-xs-4">
              <span>Additional Comments </span>
            </div>
            <div class="col-xs-4 radio-button-align">
              <textarea cols="2" style="margin-left: 4%;" 
              type="text"
              name="additionalCommentsFinraLicenses2"
              [ngModelOptions]="{standalone: true}"
              [(ngModel)]="additionalCommentsFinraLicenses2"
              rows="3"
              placeholder="Comments"
              class="input-data-text text-area-style"></textarea>
            </div>
          </div>
        </div>
    
        <span><p class="bolded">Communications</p></span>
        <div class="row">
          <div class="col-xs-4">
            <span [class.highlight-warning]="highlightRequiredField('TheEmployeeAddedSupervision')
              || (newhireFormGroup.get('TheEmployeeAddedSupervision')?.value=='No' && highlightRequiredField('TheEmployeeAddedSupervisionComment') )">Added to Smarsh and Smarsh supervision hierarchy?</span>
            <span style="color:red">*</span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <input class="radioStyle" type="radio" formControlName="TheEmployeeAddedSupervision" value="Yes" (change)="onTheEmployeeAddedSupervisionChange($event)"> Yes
            <input class="radioStyle" type="radio" formControlName="TheEmployeeAddedSupervision" value="No" (change)="onTheEmployeeAddedSupervisionChange($event)"> No
            <textarea style="margin-left: 4%;" cols="2" *ngIf="isTheEmployeeAddedSupervisionComment"
              type="text"
              rows="3"
              formControlName="TheEmployeeAddedSupervisionComment"
              placeholder="Comment"
              class="input-data-text text-area-style"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-4">
            <span [class.highlight-warning]="highlightRequiredField('ConfirmedCommunications')
              || (newhireFormGroup.get('ConfirmedCommunications')?.value=='No' && highlightRequiredField('ConfirmedCommunicationsComment') )">Confirmed communications are appearing in Smarsh? </span>
            <span style="color:red">*</span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <input class="radioStyle" type="radio" formControlName="ConfirmedCommunications" value="Yes" (change)="onConfirmedCommunicationsChange($event)"> Yes
            <input class="radioStyle" type="radio" formControlName="ConfirmedCommunications" value="No" (change)="onConfirmedCommunicationsChange($event)"> No
            <textarea style="margin-left: 4%;" cols="2" *ngIf="isConfirmedCommunicationsComment"
              type="text"
              rows="3"
              formControlName="ConfirmedCommunicationsComment"
              placeholder="Comment"
              class="input-data-text text-area-style"></textarea>
          </div>
        </div>
                <div class="row">
            <div class="col-xs-4">
                <span [class.highlight-warning]="highlightRequiredField('DeloitteMobileSmarsh') || (newhireFormGroup.get('DeloitteMobileSmarsh')?.value=='No' && highlightRequiredField('DeloitteMobileSmarshComment'))
                    ||(newhireFormGroup.get('DeloitteMobileSmarsh')?.value=='Yes' && (highlightRequiredField('NAStatus') || highlightRequiredField('DeloittePhoneNumber')))">Deloitte mobile phone provisioned in Smarsh? </span>
                <span style="color:red">*</span>
            </div>
            <div class="col-xs-4 radio-button-align">
                <input class="radioStyle" type="radio" formControlName="DeloitteMobileSmarsh" value="Yes" (change)="onDeloitteMobileSmarshChange($event)"> Yes
                <input class="radioStyle" type="radio" formControlName="DeloitteMobileSmarsh" value="No" (change)="onDeloitteMobileSmarshChange($event)"> No
                <input class="radioStyle" type="radio" formControlName="DeloitteMobileSmarsh" value="N/A" (change)="onDeloitteMobileSmarshChange($event)"> N/A
            </div>
            <textarea style="margin-left: 36%;width: 38%;" cols="2" *ngIf="isDeloitteMobileSmarshComment"
                type="text"
                rows="3"
                formControlName="DeloitteMobileSmarshComment"
                placeholder="Comment"
                class="input-data-text text-area-style"></textarea>
        </div>  
        <div class="col-xs-8" style="float: right;">
            <div class="col-xs-8" style="margin-left: 3.5%;" *ngIf="isNAStatus">
              <span [class.highlight-warning]="highlightRequiredField('NAStatus')">Mobile carrier: </span>
              <input class="radioStyle" type="radio" formControlName="NAStatus" value="ATT" style="margin-right: 5px;">ATT
              <input class="radioStyle" type="radio" formControlName="NAStatus" value="Verizon" style="margin-right: 5px;">Verizon
              <input class="radioStyle" type="radio" formControlName="NAStatus" value="T-Mobile" style="margin-right: 5px;">T-Mobile
            </div>
            <div class="row" *ngIf="isNAStatus">
                <div class="col-xs-4 section-label" style="margin-left: 3%;">
                    <span [class.highlight-warning]="highlightRequiredField('DeloittePhoneNumber')">Deloitte phone number: </span>
                </div>
                <div class="col-xs-6 input-width input-align" style="margin-left: -8%;">
                    <input formControlName="DeloittePhoneNumber"
                        class="input-data-text full-width section-input"
                        type="text"
                        autocomplete="off"
                        maxlength="255">
                </div>
            </div>
        </div>  
        <div class="row">
          <div class="col-xs-4">
            <span [class.highlight-warning]="highlightRequiredField('LegalHold')
              || (newhireFormGroup.get('LegalHold')?.value=='No' && highlightRequiredField('LegalHoldComment') )">Added Legal Hold on Teams chat? </span>
            <span style="color:red">*</span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <input class="radioStyle" type="radio" formControlName="LegalHold" value="Yes" (change)="onLegalHoldChange($event)"> Yes
            <input class="radioStyle" type="radio" formControlName="LegalHold" value="No" (change)="onLegalHoldChange($event)"> No
            <textarea style="margin-left: 4%;" cols="2" *ngIf="isLegalHoldComment"
              type="text"
              rows="3"
              formControlName="LegalHoldComment"
              placeholder="Comment"
              class="input-data-text text-area-style"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-4">
            <span>Additional Comments: </span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <textarea cols="2" style="margin-left: 4%;"
              type="text"
              name="additionalCommentsCommunication"
              [ngModelOptions]="{standalone: true}"
              [(ngModel)]="additionalCommentsCommunication"
              rows="3"
              placeholder="Comments"
              class="input-data-text text-area-style"></textarea>
          </div>
        </div>
        <span><p class="bolded">Brokerage Accounts, OBAs & PSTs, and Other Disclosures</p></span>
        <div class="row">
          <div class="col-xs-4">
            <span [class.highlight-warning]="highlightRequiredField('BrokerageAccount')|| (newhireFormGroup.get('BrokerageAccount')?.value=='No' && highlightRequiredField('BrokerageAccountComment'))">
              Brokerage account disclosure process complete?</span>
            <span style="color:red">*</span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <input class="radioStyle" type="radio" formControlName="BrokerageAccount" value="Yes" (change)="onBrokerageAccountChange($event)"> Yes
            <input class="radioStyle" type="radio" formControlName="BrokerageAccount" value="No" (change)="onBrokerageAccountChange($event)"> No
            <input class="radioStyle" type="radio" formControlName="BrokerageAccount" value="N/A, no brokerage accounts disclosed."
              (change)="onBrokerageAccountChange($event)"> N/A, no brokerage accounts disclosed.
            <textarea style="margin-left: 4%;" cols="2" *ngIf="isBrokerageAccountComment"
              type="text"
              rows="3"
              formControlName="BrokerageAccountComment"
              placeholder="Comment"
              class="input-data-text text-area-style"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-4">
            <span [class.highlight-warning]="highlightRequiredField('OBAdisclosure')|| (newhireFormGroup.get('OBAdisclosure')?.value=='No' && highlightRequiredField('OBAdisclosureComment'))">
              OBA disclosure process complete?</span>
            <span style="color:red">*</span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <input class="radioStyle" type="radio" formControlName="OBAdisclosure" value="Yes" (change)="onOBAdisclosureChange($event)"> Yes
            <input class="radioStyle" type="radio" formControlName="OBAdisclosure" value="No" (change)="onOBAdisclosureChange($event)"> No
            <input class="radioStyle" type="radio" formControlName="OBAdisclosure" value="N/A, no OBAs disclosed."
              (change)="onOBAdisclosureChange($event)"> N/A, no OBAs disclosed.
            <textarea style="margin-left: 4%;" cols="2" *ngIf="isOBAdisclosureComment"
              type="text"
              rows="3"
              formControlName="OBAdisclosureComment"
              placeholder="Comment"
              class="input-data-text text-area-style"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-4">
            <span [class.highlight-warning]="highlightRequiredField('PSTdisclosure')|| (newhireFormGroup.get('PSTdisclosure')?.value=='No' && highlightRequiredField('PSTdisclosureComment'))">
              PST disclosure process complete? </span>
            <span style="color:red">*</span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <input class="radioStyle" type="radio" formControlName="PSTdisclosure" value="Yes" (change)="onPSTdisclosureChange($event)"> Yes
            <input class="radioStyle" type="radio" formControlName="PSTdisclosure" value="No" (change)="onPSTdisclosureChange($event)"> No
            <input class="radioStyle" type="radio" formControlName="PSTdisclosure" value="N/A, no PSTs disclosed."
              (change)="onPSTdisclosureChange($event)"> N/A, no PSTs disclosed.
            <textarea style="margin-left: 4%;" cols="2" *ngIf="isPSTdisclosureComment"
              type="text"
              rows="3"
              formControlName="PSTdisclosureComment"
              placeholder="Comment"
              class="input-data-text text-area-style"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-4">
            <span>Additional Comments: </span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <textarea cols="2" style="margin-left: 4%;" 
              type="text"
              name="additionalCommentsBrokarage"
              [ngModelOptions]="{standalone: true}"
              [(ngModel)]="additionalCommentsBrokarage"
              rows="3"
              placeholder="Comments"
              class="input-data-text text-area-style"></textarea>
          </div>
        </div>
        <span><p class="bolded">Compliance New Hire Training, CE and Attestations:</p></span>
        <div class="row">
          <div class="col-xs-4">
            <span [class.highlight-warning]="highlightRequiredField('CSIAttestations')
              || (newhireFormGroup.get('CSIAttestations')?.value=='No' && highlightRequiredField('CSIAttestationsComment') )">CSI Attestations completed, reviewed, and saved to employee file?</span>
            <span style="color:red">*</span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <input class="radioStyle" type="radio" formControlName="CSIAttestations" value="Yes" (change)="onCSIAttestationsChange($event)"> Yes
            <input class="radioStyle" type="radio" formControlName="CSIAttestations" value="No" (change)="onCSIAttestationsChange($event)"> No
            <textarea style="margin-left: 4%;" cols="2" *ngIf="isCSIAttestationsComment"
              type="text"
              rows="3"
              formControlName="CSIAttestationsComment"
              placeholder="Comment"
              class="input-data-text text-area-style"></textarea>
          </div> 
        </div>
        <div class="row">
          <div class="col-xs-4">
            <span [class.highlight-warning]="highlightRequiredField('NewhireQuestCE')
              || (newhireFormGroup.get('NewhireQuestCE')?.value=='No' && highlightRequiredField('NewhireQuestCEComment') )">New Hire Quest CE (Firm Element) completed and record saved to employee file?</span>
            <span style="color:red">*</span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <input class="radioStyle" type="radio" formControlName="NewhireQuestCE" value="Yes" (change)="onNewhireQuestCEChange($event)"> Yes
            <input class="radioStyle" type="radio" formControlName="NewhireQuestCE" value="No" (change)="onNewhireQuestCEChange($event)"> No
            <textarea style="margin-left: 4%;" cols="2" *ngIf="isNewhireQuestCEComment"
              type="text"
              rows="3"
              formControlName="NewhireQuestCEComment"
              placeholder="Comment"
              class="input-data-text text-area-style"></textarea>
          </div> 
        </div>
        <div class="row">
          <div class="col-xs-4 margin-top-5">
            <span [class.highlight-warning]="highlightRequiredField('dateOfComplianceTraining')">Date of Compliance New Hire Training: </span>
            <span style="color:red">*</span>
          </div>
          <div class="col-xs-7 input-align" style="margin-left: 2.5%;">
            <app-date-picker 
              [selectedDate]="newhireFormGroup.get('dateOfComplianceTraining')?.value"
              (dateSelected)="eventDateSelected($event,'dateOfComplianceTraining')">
            </app-date-picker>
            <span class="numeric-validation-msg"></span>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-4">
            <span>Additional Comments: </span>
          </div>
          <div class="col-xs-4 radio-button-align">
            <textarea cols="2" style="margin-left: 4%;" 
              type="text"
              name="additionalCommentsComplianceTraining"
              [ngModelOptions]="{standalone: true}"
              [(ngModel)]="additionalCommentsComplianceTraining"
              rows="3"
              placeholder="Comments"
              class="input-data-text text-area-style"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="submit-buttons floatStyle">
    <button *ngIf="actionType!='Submit'" type="submit" class="btn btn-primary submit-btn" (click)="onSubmit()">Submit</button>
    <button type="button" (click)="OnSaveForm($event)" name="save" class="btn btn-outline-primary save-rework-cancel-btn margin-left-10">Save</button>
  </div>
  </form>

<app-new-hire-form-popup [formID]="formID"></app-new-hire-form-popup>


