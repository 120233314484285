<div id="pricing-details"
  [formGroup]="formGroup"
  class="container-fluid border container-border-color container-section">
  <div class="row bg-title">
    <span class="title-section-print ">Pricing Details</span>
  </div>
  <div id="pricing-details-section" class="content-section">
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('contractType')"
          [class.edited-externally]="editedExternally('contractType')"
          [class.conflict-on-merge]="hasMergeConflict('contractType')">
            Contract Type
        </span>
        <span *ngIf="showAsterisk('contractType')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('ContractType')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <input
          formControlName="contractType"
          class="input-data-text full-width section-input"
          [class.readonly]="isReadOnly('contractType')"
          type="text"
          autocomplete="off">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('resourceRateType')"
          [class.edited-externally]="editedExternally('resourceRateType')"
          [class.conflict-on-merge]="hasMergeConflict('resourceRateType')">
            Resource Rate Type
        </span>
        <span *ngIf="showAsterisk('resourceRateType')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('ResourceRateType')">
        </app-popover>
      </div>
      <div class="col-xs-8 common-width">
        <app-dropdown
          [optionsList]="resourceRateTypeOptions"
          [keySelected]="formGroup.get('resourceRateType')?.value"
          [disabled]="formGroup.get('resourceRateType')?.disabled"
          [readonly]="isReadOnly('resourceRateType')"
          (optionSelected)="onResourceRateTypeSelection($event)">
        </app-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('usiResourceRateType')"
          [class.edited-externally]="editedExternally('usiResourceRateType')"
          [class.conflict-on-merge]="hasMergeConflict('usiResourceRateType')">
            USI Resource Rate Type
        </span>
        <span *ngIf="showAsterisk('usiResourceRateType')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('USIResourceRateType')">
        </app-popover>
      </div>
      <div class="col-xs-8 common-width">
        <app-dropdown
          [optionsList]="usiResourceRateTypeOptions"
          [keySelected]="formGroup.get('usiResourceRateType')?.value"
          [disabled]="formGroup.get('usiResourceRateType')?.disabled"
          [readonly]="isReadOnly('usiResourceRateType')"
          (optionSelected)="onUSIResourceRateTypeSelection($event)">
        </app-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('eventIndicator')"
          [class.edited-externally]="editedExternally('eventIndicator')"
          [class.conflict-on-merge]="hasMergeConflict('eventIndicator')">
            Event Indicator
        </span>
        <span *ngIf="showAsterisk('eventIndicator')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="formGroup.get('eventIndicator')?.value"
          [readonly]="isReadOnly('eventIndicator')"
          (handleToggleYesNo)="onEventIndicatorSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('budgetGroup')"
          [class.edited-externally]="editedExternally('budgetGroup')"
          [class.conflict-on-merge]="hasMergeConflict('budgetGroup')">
            Budget Group
        </span>
        <span *ngIf="showAsterisk('budgetGroup')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8 common-width">
        <app-dropdown
          [optionsList]="budgetGroupOptions"
          [keySelected]="formGroup.get('budgetGroup')?.value"
          [disabled]="formGroup.get('usiResourceRateType')?.disabled"
          [readonly]="isReadOnly('budgetGroup')"
          (optionSelected)="onBudgetGroupSelection($event)">
        </app-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('plannedNetRevenue')"
          [class.edited-externally]="editedExternally('plannedNetRevenue')"
          [class.conflict-on-merge]="hasMergeConflict('plannedNetRevenue')">
            Planned Net Revenue
        </span>
        <span *ngIf="showAsterisk('plannedNetRevenue')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('PlannedNetRevenue')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <app-numeric
          [type]="'decimal'"
          [format]="'currency'"
          [readonly]="isReadOnly('plannedNetRevenue')"
          [value]="formGroup.get('plannedNetRevenue')?.value"
          (selectedValue)="onPlannedNetRevenueChange($event)">
        </app-numeric>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('discount')"
          [class.edited-externally]="editedExternally('discount')"
          [class.conflict-on-merge]="hasMergeConflict('discount')">
            Discount %
        </span>
        <span *ngIf="showAsterisk('discount')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-numeric
          [type]="'decimal'"
          [format]="'percentage'"
          [readonly]="isReadOnly('discount')"
          [disabled]="formGroup.get('discount')?.disabled"
          [value]="formGroup.get('discount')?.value"
          (selectedValue)="onDiscountChange($event)">
        </app-numeric>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('plannedMargin')"
          [class.edited-externally]="editedExternally('plannedMargin')"
          [class.conflict-on-merge]="hasMergeConflict('plannedMargin')">
            Planned Margin %
        </span>
        <span *ngIf="showAsterisk('plannedMargin')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-numeric
          [type]="'decimal'"
          [format]="'percentage'"
          [readonly]="isReadOnly('plannedMargin')"
          [disabled]="formGroup.get('plannedMargin')?.disabled"
          [value]="formGroup.get('plannedMargin')?.value"
          (selectedValue)="onPlannedMarginChange($event)">
        </app-numeric>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('plannedCost')"
          [class.edited-externally]="editedExternally('plannedCost')"
          [class.conflict-on-merge]="hasMergeConflict('plannedCost')">
            Planned Cost
        </span>
        <span *ngIf="showAsterisk('plannedCost')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('PlannedCost')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <app-numeric
          [type]="'decimal'"
          [format]="'currency'"
          [readonly]="isReadOnly('plannedCost')"
          [value]="formGroup.get('plannedCost')?.value"
          (selectedValue)="onPlannedCostChange($event)">
        </app-numeric>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('totalPlannedHours')"
          [class.edited-externally]="editedExternally('totalPlannedHours')"
          [class.conflict-on-merge]="hasMergeConflict('totalPlannedHours')">
            Total Planned Hours
        </span>
        <span *ngIf="showAsterisk('totalPlannedHours')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-numeric
          [type]="'number'"
          [readonly]="isReadOnly('totalPlannedHours')"
          [value]="formGroup.get('totalPlannedHours').value"
          (selectedValue)="onTotalPlannedHoursChange($event)">
        </app-numeric>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('timeRevenueCap')"
          [class.edited-externally]="editedExternally('timeRevenueCap')"
          [class.conflict-on-merge]="hasMergeConflict('timeRevenueCap')">
            Time Revenue Cap
        </span>
        <span *ngIf="showAsterisk('timeRevenueCap')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-numeric
          [type]="'number'"
          [readonly]="isReadOnly('timeRevenueCap')"
          [disabled]="formGroup.get('timeRevenueCap')?.disabled"
          [value]="formGroup.get('timeRevenueCap')?.value"
          (selectedValue)="onTimeRevenueCapChange($event)">
        </app-numeric>
      </div>
    </div>
  </div>
</div>
