import { HttpClient,HttpHeaders,HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs"; 
import { environment } from "src/environments/environment"; 
import { AppSettings } from "src/shared/app-settings";
import 'moment-timezone';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateService {

    isDayLightSavingOn : boolean = false;

    constructor(){}

    ConvertUTCToIST(utcDate: any) {
        var date = new Date(utcDate);
        date.setHours(date.getHours() + 5);
        date.setMinutes(date.getMinutes() + 30);
        return date;
    }
    GetCSTDateTimeString(date:any,isISTTIme:any):string
    {
        var dateCST = (isISTTIme == true ? this.GetCSTDateFromIST(date):this.GetCSTDate(date));

        var blank = " ";
        var maxHours = 12;
        var day = ("0" + dateCST.getDate()).slice(-2);
        var year = dateCST.getFullYear();
        var originalHour = dateCST.getHours();
        var shortHourFormat = originalHour % maxHours || maxHours;
        var hours = ("0" + shortHourFormat).slice(-2);
        var minutes = ("0" + dateCST.getMinutes()).slice(-2);
        
        var ampm =this.getAMPMValues(originalHour,minutes);
        return day + blank + this.GetMonthName(dateCST) + blank + year + blank + [hours,minutes].join(":") + blank + ampm + blank + "CST";
    }
    GetCSTDateTimeSecondString(date:any,isISTTIme:any):string
    {
        var dateCST = (isISTTIme == true ? this.GetCSTDateFromIST(date):this.GetCSTDate(date));

        var blank = " ";
        var maxHours = 12;
        var day = ("0" + dateCST.getDate()).slice(-2);
        var year = dateCST.getFullYear();
        var originalHour = dateCST.getHours();
        var shortHourFormat = originalHour % maxHours || maxHours;
        var hours = ("0" + shortHourFormat).slice(-2);
        var minutes = ("0" + dateCST.getMinutes()).slice(-2);
        var seconds = ("0" + dateCST.getSeconds()).slice(-2);

        var ampm =this.getAMPMValues(originalHour,minutes);
        return day + blank + this.GetMonthName(dateCST) + blank + year + blank + [hours,minutes,seconds].join(":") + blank + ampm + blank + "CST";
    }
    
    GetAuditCSTDateTimeSecondString(date:any,isISTTIme:any):string
    {
        var dateCST = (isISTTIme == true ? this.GetCSTDateFromIST(date):this.GetCSTDate(date));

        var blank = " ";
        var maxHours = 12;
        var day = ("0" + dateCST.getDate()).slice(-2);
        var year = dateCST.getFullYear();
        var month = dateCST.getMonth()+1;
        var originalHour = dateCST.getHours();
        var shortHourFormat = originalHour % maxHours || maxHours;
        var hours = ("0" + shortHourFormat).slice(-2);
        var minutes = ("0" + dateCST.getMinutes()).slice(-2);
        var seconds = ("0" + dateCST.getSeconds()).slice(-2);

        var ampm =this.getAMPMValues(originalHour,minutes);
        return day + blank + month + blank + year + blank + [hours,minutes,seconds].join(":") + blank + ampm + blank + "CST";
    }
    GetCSTDate(date: any): any {    
        var dateInDayLightSaving = this.isDateInDayLightSavingRange(date, false);
        var dateInMilisecondsInLocalTime = new Date(date).getTime();
        var offsetToUTC = new Date().getTimezoneOffset() * 60 * 1000;
        var offsetToCST =  60 * 60 * 1000 * (dateInDayLightSaving?environment.cstToUtcDSTOffset:environment.cstToUtcOffset);
        var totalMiliseconds = dateInMilisecondsInLocalTime + offsetToUTC - offsetToCST;
        return new Date(totalMiliseconds);       
    }    
    getAMPMValues(originalHour:any,minutes:any){
      let meridian:any="";
       if(originalHour==12){
         meridian=minutes>0?'PM':'AM';
       }
       else{
          meridian= originalHour >= 12 ? 'PM' : 'AM';
       }
      return meridian;
    }
    GetCSTDateFromIST(date:any):any {    
        var dateInDayLightSaving = this.isDateInDayLightSavingRange(date,true);
        var dateInMilisecondsInLocalTime = new Date(date).getTime();
        var offsetToUTC = (-330) * 60 * 1000;
        var offsetToCST =  60 * 60 * 1000 * (dateInDayLightSaving?environment.cstToUtcDSTOffset:environment.cstToUtcOffset);
        var totalMiliseconds = dateInMilisecondsInLocalTime + offsetToUTC - offsetToCST;
        return new Date(totalMiliseconds);       
    }
    isDateInDayLightSavingRange(inputdate:any,isIST:any):boolean
    {
      let utcDate = new Date(inputdate);
      let dateInDayLightRange:boolean = false; 
      if(isIST)
      {
        utcDate.setHours(utcDate.getHours() - 5);
        utcDate.setMinutes(utcDate.getMinutes() - 30);
      }
      dateInDayLightRange = moment.utc(utcDate).tz("America/Chicago").isDST();
      return dateInDayLightRange;
    }
    GetMonthName (date:any) {
        var objDate = new Date(date),
        locale = "en-us",
        month = objDate.toString().split(" ")[1];
        return month;
    }
    FormatDate(date: string): string {
        try {
          var isIstTime:any = false;
          if(!date.includes("T")){
            date = this.ConvertUTCToIST(date).toString();
            isIstTime = true;
          }
          if(date.includes("GMT")){
            date = this.ConvertUTCToIST(date).toString();
            isIstTime = true;
          }
          if (date != "" && date != null && date != undefined) {
            const dateObject = new Date(date);
    
            return this.GetCSTDateTimeString(dateObject,isIstTime);
          }
          else {
            return date;
          }
        }
        catch (e) {
          return date;
        }
    }
    _FormatDate(date: string): string {
      try {
        var isIstTime:any = false;
        if(!date.includes("T")){
          date = this.ConvertUTCToIST(date).toString();
          isIstTime = true;
        }
        date = this.ConvertUTCToIST(date).toString();
          isIstTime = true;
        if (date != "" && date != null && date != undefined) {
          const dateObject = new Date(date);
  
          return this.GetCSTDateTimeSecondString(dateObject,isIstTime);
        }
        else {
          return date;
        }
      }
      catch (e) {
        return date;
      }
  }
  _FormatDateAuditUM(date: string): string {
    try {
      var isIstTime:any = false;
      // if(!date.includes("T")){
      //   date = this.ConvertUTCToIST(date).toString();
      //   isIstTime = true;
      // }
      if(date.includes("GMT")){
        date = this.ConvertUTCToIST(date).toString();
        isIstTime = true;
      }
      if (date != "" && date != null && date != undefined) {
        const dateObject = new Date(date);

        return this.GetCSTDateTimeSecondString(dateObject,isIstTime);
      }
      else {
        return date;
      }
    }
    catch (e) {
      return date;
    }
    }
  _FormatDateEHO(date: string): string {
    try {
      var isIstTime:any = false;
      if(!date.includes("T")){
        date = this.ConvertUTCToIST(date).toString();
        isIstTime = true;
      }
      if(date.includes("GMT")){
        date = this.ConvertUTCToIST(date).toString();
        isIstTime = true;
      }
      if (date != "" && date != null && date != undefined) {
        const dateObject = new Date(date);

        return this.GetCSTDateTimeSecondString(dateObject,isIstTime);
      }
      else {
        return date;
      }
    }
    catch (e) {
      return date;
    }
    }
    _FormatDateCCL(date: string): string {
      try {
        var isIstTime:any = false;
        if(!date.includes("T")){
          date = this.ConvertUTCToIST(date).toString();
          isIstTime = true;
        }
        if(date.includes("GMT")){
          date = this.ConvertUTCToIST(date).toString();
          isIstTime = true;
        }
        if (date != "" && date != null && date != undefined) {
          const dateObject = new Date(date);
  
          return this.GetCSTDateTimeString(dateObject,isIstTime);
        }
        else {
          return date;
        }
      }
      catch (e) {
        return date;
      }
      }
      _FormatDateCCLForContent(date: string): string {
        try {
          var isIstTime:any = false;
          if(!date.includes("T")){
            date = this.ConvertUTCToIST(date).toString();
            isIstTime = true;
          }
          else if(date.includes("GMT")){
            date = this.ConvertUTCToIST(date).toString();
            isIstTime = true;
          }

          if (date != "" && date != null && date != undefined) {
            const dateObject = new Date(date);
    
            return this.GetCSTDateTimeString(dateObject,isIstTime);
          }
          else {
            return date;
          }
        }
        catch (e) {
          return date;
        }
        }

    formatDateToDDMMYYYY(date: string): string {
        try {
            var isIstTime: any = false;
            if (!date.includes("T")) {
                date = this.ConvertUTCToIST(date).toString();
                isIstTime = true;
            }
            if (date != "" && date != null && date != undefined) {
                const dateObject = new Date(date);
                const day = String(dateObject.getDate()).padStart(2, '0');
                const month = String(dateObject.getMonth() + 1).padStart(2, '0');
                const year = dateObject.getFullYear();

                return `${day}/${month}/${year}`;
            } else {
                return date;
            }
        } catch (e) {
            console.error(e);
            return date;
        }
    }

    formatDateToMMDDYYYY(date: string): string {
      try {
        var isIstTime: any = false;
        if (!date.includes("T")) {
          date = this.ConvertUTCToIST(date).toString();
          isIstTime = true;
        }
        if (date != "" && date != null && date != undefined) {
          const dateObject = new Date(date);
          const day = String(dateObject.getDate()).padStart(2, '0');
          const month = String(dateObject.getMonth() + 1).padStart(2, '0'); 
          const year = dateObject.getFullYear();
    
          return `${month}/${day}/${year}`;
        } else {
          return date;
        }
      } catch (e) {
        console.error(e);
        return date;
      }
    }



  _AuditFormatDate(date: string): string {
    try {
      var isIstTime:any = false;
      if(!date.includes("T")){
        date = this.ConvertUTCToIST(date).toString();
        isIstTime = true;
      }
      if (date != "" && date != null && date != undefined) {
        const dateObject = new Date(date);

        return this.GetAuditCSTDateTimeSecondString(dateObject,isIstTime);
      }
      else {
        return date;
      }
    }
    catch (e) {
      return date;
    }
}
    ConvertDateFormatToUTC(date:any,approvalDate:any){
      if(!approvalDate.includes("T")){
        let isDateinDayLightRange=this.isDateInDayLightSavingRange(approvalDate,false);
      if(isDateinDayLightRange){date.setHours(date.getHours() - 5);}else{date.setHours(date.getHours() - 6);}
     // date.setMinutes(date.getMinutes() + 30);
      }
      return date;
    }
}
