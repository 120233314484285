<div [formGroup]="buyerDealMarketingFormGroup" id="buyer-deal-marketing" class="container-fluid border container-border-color container-section">
    <div class="row bg-title">
        <span class="title-section-print">Buyer/Deal Marketing</span>
        <span class="glyphicon glyphicon-question-sign title-question-sign tooltip-dcf"></span>
        <span class="tooltiptext-dcf visibility-hidden">
            <p>This should be pretty straightforward, just focused on buyer type and the deal marketing process.</p>
            <p>
                <span class="tooltiptext-dcf-bold">Preemptive Bidder
                </span>
                - Answer yes if there was a formal preemptive offer or serious preemptive interest that influenced the process 
            (i.e. not just that a company once mentioned they may be interested and we made sure they were on the buyer's list).
            </p>
            <p>
                <span class="tooltiptext-dcf-bold">Type of Marketing Process
                </span>
                - Did we run a full process, fireside chats only, just negotiate with a preemptive?
             If there was a preemptive offer but we then ran a broad process, choose Full Process not Preemptive Only.
            </p>
        </span>
    </div>
    <div id="buyer-deal-section" class="content-section">
        <div class="row">
            <div class="col-xs-4 required-div section-label">
                <span [class.highlight-warning]="highlightRequiredField('buyerName')"
                    [class.edited-externally]="editedExternally('buyerName')"
                    [class.conflict-on-merge]="hasMergeConflict('buyerName')">Buyer Name</span>
                <span *ngIf="showAsterisk('buyerName')" class="red-asterik"></span>
                <br>
            </div>
            <div class="col-xs-8">
                <input
                [disabled]="myDealFormGroup.get('buyerName')?.disabled"
                [formControl]="myDealFormGroup.get('buyerName')"              
                class="input-data-text full-width section-input"
                type="text"
                autocomplete="off">
            </div>

        </div>
        <div class="row">
            <div class="col-xs-4 required-div section-label">
                <span [class.highlight-warning]="highlightRequiredField('buyerParentCompany')"
                    [class.edited-externally]="editedExternally('buyerParentCompany')"
                    [class.conflict-on-merge]="hasMergeConflict('buyerParentCompany')">Buyer Parent Company / PEG Owner</span>
                <span *ngIf="showAsterisk('buyerParentCompany')" class="red-asterik"></span>
                <br>
            </div>
            <div class="col-xs-8">
                <input
                [disabled]=" buyerDealMarketingFormGroup.get('buyerParentCompany').disabled"
                formControlName="buyerParentCompany"
                class="input-data-text full-width section-input"
                type="text"
                autocomplete="off">
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 required-div section-label">
                <span [class.highlight-warning]="highlightRequiredField('buyerType')"
                    [class.edited-externally]="editedExternally('buyerType')"
                    [class.conflict-on-merge]="hasMergeConflict('buyerType')">Buyer Type</span>
                <span *ngIf="showAsterisk('buyerType')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <!-- <select name="BuyerType" id="BuyerType" class="input-data-select input-select section-select enableDependencyInput"
                    disable-target-input="StockConsideration"
                    disable-target-value-for-stockconsideration="Private Equity Group,Bank Lender,Non-Bank Lender"
                    value-when-input-disabled-for-stockconsideration="">
                    <option value="0" disabled selected>Select your option</option>
                </select> -->
                <app-dropdown
                [disabled]=" buyerDealMarketingFormGroup.get('buyerType')?.disabled"
                [optionsList]="buyerTypeOptions"
                [keySelected]="buyerDealMarketingFormGroup.get('buyerType')?.value"
                [readonly]="isReadOnly('buyerType')"
                (optionSelected)="onBuyerTypeSelection($event)">
                </app-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 margin-top-5">
                <div class="col-xs-4 required-div div-three-toggle-input">
                    <span [class.highlight-warning]="highlightRequiredField('internationalBuyer')"
                    [class.edited-externally]="editedExternally('internationalBuyer')"
                    [class.conflict-on-merge]="hasMergeConflict('internationalBuyer')">International Buyer?</span>
                    <span *ngIf="showAsterisk('internationalBuyer')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <div name="InternationalBuyer" id="InternationalBuyer" class="btn-group btn-group-xs input-data-toggle-group input-toggle-group" role="group">
                        <button type="button" class="btn btn-default btn-xs three-toggle-button" value="YES">YES</button>
                        <button type="button" class="btn btn-default btn-xs three-toggle-button" value="NO">NO</button>
                        <button type="button" class="btn btn-default btn-xs three-toggle-button" value="NA">N/A</button>
                    </div> -->
                    <app-toggle-yes-no  #InternationalBuyer
                   
                    [value]="buyerDealMarketingFormGroup.get('internationalBuyer')?.value"
                    [disabled]=" buyerDealMarketingFormGroup.get('internationalBuyer')?.disabled"
                    [readonly]="isReadOnly('internationalBuyer')"
                    (handleToggleYesNo)="onInternationalBuyer($event)"
                    [notApplicableEnabled]="notApplicableEnabled"
                    class="buttons-app-yes-no">
                    </app-toggle-yes-no>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 required-div section-label">
                <span class="dependent-field"
                [class.highlight-warning]="highlightRequiredField('buyerCountry')"
                    [class.edited-externally]="editedExternally('buyerCountry')"
                    [class.conflict-on-merge]="hasMergeConflict('buyerCountry')">If yes, country?</span>
                <span *ngIf="showAsterisk('buyerCountry')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <!-- <select name="BuyerCountryId" id="BuyerCountry" class="chosen input-data-select-lookup section-select lookup" disabled>
                    <option value="0" disabled selected>N/A</option>
                </select> -->
                <app-dropdown
                [optionsList]="closingCheckListCountryOptions"
                [keySelected]="buyerDealMarketingFormGroup.get('buyerCountry')?.value"
                [readonly]="isReadOnly('buyerCountry')"
                [disabled]="buyerDealMarketingFormGroup.get('buyerCountry')?.disabled"
                (optionSelected)="onBuyerCountrySelection($event)">
                </app-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 margin-top-5">
                <div class="col-xs-4 required-div div-three-toggle-input">
                    <span [class.highlight-warning]="highlightRequiredField('identifiedTierPitch')"
                    [class.edited-externally]="editedExternally('identifiedTierPitch')"
                    [class.conflict-on-merge]="hasMergeConflict('identifiedTierPitch')">Identified as Tier 1 in Pitch?</span>
                    <span *ngIf="showAsterisk('identifiedTierPitch')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <div name="IdentifiedTierPitch" id="IdentifiedTierPitch" class="btn-group btn-group-xs input-data-toggle-group input-toggle-group" role="group">
                        <button type="button" class="btn btn-default btn-xs three-toggle-button" value="YES">YES</button>
                        <button type="button" class="btn btn-default btn-xs three-toggle-button" value="NO">NO</button>
                        <button type="button" class="btn btn-default btn-xs three-toggle-button" value="NA">N/A</button>
                    </div> -->
                    <app-toggle-yes-no  #IdentifiedTierPitch
                    [value]="buyerDealMarketingFormGroup.get('identifiedTierPitch')?.value"
                    [disabled]=" buyerDealMarketingFormGroup.get('identifiedTierPitch')?.disabled"
                    [readonly]="isReadOnly('identifiedTierPitch')"
                    (handleToggleYesNo)="onIdentifiedTierPitch($event)"
                    [notApplicableEnabled]="notApplicableEnabled"
                    class="buttons-app-yes-no">
                    </app-toggle-yes-no>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 required-div section-label">
                <span [class.highlight-warning]="highlightRequiredField('buyerRelationship')"
                    [class.edited-externally]="editedExternally('buyerRelationship')"
                    [class.conflict-on-merge]="hasMergeConflict('buyerRelationship')">Buyer Relationship</span>
                <span *ngIf="showAsterisk('buyerRelationship')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <!-- <select name="BuyerRelationship" id="BuyerRelationship" class="input-data-select input-select section-select">
                    <option value="0" disabled selected>Select your option</option>
                </select> -->
                <app-dropdown
                [disabled]=" buyerDealMarketingFormGroup.get('buyerRelationship')?.disabled"
                [optionsList]="buyerRelationshipOptions"
                [keySelected]="buyerDealMarketingFormGroup.get('buyerRelationship')?.value"
                [readonly]="isReadOnly('buyerRelationship')"
                (optionSelected)="onBuyerRelationshipSelection($event)">
                </app-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 margin-top-5">
                <div class="col-xs-4 required-div required-div">
                    <span [class.highlight-warning]="highlightRequiredField('deloitteLCSP')"
                    [class.edited-externally]="editedExternally('deloitteLCSP')"
                    [class.conflict-on-merge]="hasMergeConflict('deloitteLCSP')">Deloitte (non-DCF) LCSP</span>
                    <span *ngIf="showAsterisk('deloitteLCSP')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <div name="DeloitteLCSP" id="DeloitteLCSP" class="btn-group btn-group-xs input-data-toggle-group input-toggle-group btn-group-two-options" role="group">
                        <button type="button" class="btn btn-default btn-xs two-toggle-button" value="YES">YES</button>
                        <button type="button" class="btn btn-default btn-xs two-toggle-button" value="NO">NO</button>
                    </div> -->
                    <app-toggle-yes-no  #DeloitteLCSP
                    [disabled]=" buyerDealMarketingFormGroup.get('deloitteLCSP')?.disabled"
                    [value]="buyerDealMarketingFormGroup.get('deloitteLCSP')?.value"
                    [readonly]="isReadOnly('deloitteLCSP')"
                    (handleToggleYesNo)="onDeloitteLCSP($event)">
                    </app-toggle-yes-no>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 margin-top-5">
                <div class="col-xs-4 required-div">
                    <span [class.highlight-warning]="highlightRequiredField('preemptiveBidder')"
                    [class.edited-externally]="editedExternally('preemptiveBidder')"
                    [class.conflict-on-merge]="hasMergeConflict('preemptiveBidder')">Any preemptive bidder?</span>
                    <span *ngIf="showAsterisk('preemptiveBidder')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <div name="PreemptiveBidder" id="PreemptiveBidder" class="btn-group btn-group-xs input-data-toggle-group input-toggle-group btn-group-two-options" role="group">
                        <button type="button" class="btn btn-default btn-xs two-toggle-button enableDependencyInputButtonGroup" value="YES"
                            disable-target-input="PreemptiveFinalPurchaser,PreemptiveOffer"
                            disable-target-value-for-preemptivefinalpurchaser="NO"
                            disable-target-value-for-preemptiveoffer="NO"
                            value-when-input-disabled-for-preemptivefinalpurchaser=""
                            value-when-input-disabled-for-preemptiveoffer="N/A">
                            YES
                        </button>
                        <button type="button" class="btn btn-default btn-xs two-toggle-button enableDependencyInputButtonGroup" value="NO"
                            disable-target-input="PreemptiveFinalPurchaser,PreemptiveOffer"
                            disable-target-value-for-preemptivefinalpurchaser="NO"
                            disable-target-value-for-preemptiveoffer="NO"
                            value-when-input-disabled-for-preemptivefinalpurchaser=""
                            value-when-input-disabled-for-preemptiveoffer="N/A">
                            NO
                        </button>
                    </div> -->
                    <app-toggle-yes-no  #PreemptiveBidder
                    [disabled]=" buyerDealMarketingFormGroup.get('preemptiveBidder')?.disabled"
                    [value]="buyerDealMarketingFormGroup.get('preemptiveBidder')?.value"
                    [readonly]="isReadOnly('preemptiveBidder')"
                    (handleToggleYesNo)="onPreemtiveBidder($event)">
                    </app-toggle-yes-no>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 margin-top-5">
                <div class="col-xs-4 required-div div-three-toggle-input">
                    <span [class.highlight-warning]="highlightRequiredField('preemptiveFinalPurchaser')"
                    [class.edited-externally]="editedExternally('preemptiveFinalPurchaser')"
                    [class.conflict-on-merge]="hasMergeConflict('preemptiveFinalPurchaser')">Was preemptive the final purchaser?</span>
                    <span *ngIf="showAsterisk('preemptiveFinalPurchaser')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <div name="PreemptiveFinalPurchaser" id="PreemptiveFinalPurchaser" class="btn-group btn-group-xs input-data-toggle-group input-toggle-group" role="group">
                        <button type="button" class="btn btn-default btn-xs three-toggle-button" value="YES" disabled>YES</button>
                        <button type="button" class="btn btn-default btn-xs three-toggle-button" value="NO" disabled>NO</button>
                        <button type="button" class="btn btn-default btn-xs three-toggle-button" value="NA" disabled>N/A</button>
                    </div> -->
                    <app-toggle-yes-no  #PreemptiveFinalPurchaser
                    [value]="buyerDealMarketingFormGroup.get('preemptiveFinalPurchaser')?.value"
                    [disabled]="buyerDealMarketingFormGroup.get('preemptiveFinalPurchaser')?.disabled"
                    [readonly]="isReadOnly('preemptiveFinalPurchaser')"
                    (handleToggleYesNo)="onPreemptiveFinalPurchaser($event)"
                    [notApplicableEnabled]="notApplicableEnabled"
                    class="buttons-app-yes-no">
                    </app-toggle-yes-no>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 required-div section-label">
                <span [class.highlight-warning]="highlightRequiredField('preemptiveOffer')"
                    [class.edited-externally]="editedExternally('preemptiveOffer')"
                    [class.conflict-on-merge]="hasMergeConflict('preemptiveOffer')">Preemptive Offer (Total Consideration)</span>
                <span *ngIf="showAsterisk('preemptiveOffer')" class="red-asterik"></span>
                <br>
            </div>
            <div class="col-xs-8">
                <app-numeric class="custom"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [placeholder]="buyerDealMarketingFormGroup.get('preemptiveOffer')?.disabled ? 'N/A' : ''"
                        [readonly]="isReadOnly('preemptiveOffer')"
                        [value]="buyerDealMarketingFormGroup.get('preemptiveOffer')?.value"
                        [disabled]=" buyerDealMarketingFormGroup.get('preemptiveOffer')?.disabled"
                        (selectedValue)="onSelectedValue($event,'preemptiveOffer')">
                </app-numeric>

                <!-- <input formControlName="preemptiveOffer" class="input-data-number input-number non-decimal-input full-width section-input" name="PreemptiveOffer" id="PreemptiveOffer" type="text" operations="PremiumOverInitialOffer" placeholder="N/A" disabled> -->
                <br>
                <span class="numeric-validation-msg"></span>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 required-div section-label">
                <span [class.highlight-warning]="highlightRequiredField('premiumOverInitialOffer')"
                    [class.edited-externally]="editedExternally('premiumOverInitialOffer')"
                    [class.conflict-on-merge]="hasMergeConflict('premiumOverInitialOffer')">Premium Over Initial Offer</span>
                <span *ngIf="showAsterisk('premiumOverInitialOffer')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-numeric class="custom"
                        [type]="'decimal2'"
                        [format]="'percentage'"
                        [placeholder]="buyerDealMarketingFormGroup.get('premiumOverInitialOffer')?.disabled ? 'N/A' : ''"
                        [readonly]="isReadOnly('premiumOverInitialOffer')"
                        [value]="buyerDealMarketingFormGroup.get('premiumOverInitialOffer')?.value"
                        [disabled]=" buyerDealMarketingFormGroup.get('premiumOverInitialOffer')?.disabled">
                </app-numeric>

                <!-- <input
                    class="input-data-number input-number percentage-input full-width section-input"
                    name="PremiumOverInitialOffer"
                    id="PremiumOverInitialOffer"
                    type="text"
                    formula="( ( TotalConsideration ) - ( PreemptiveOffer ) ) / ( ( PreemptiveOffer ) ) * ( 100 )"
                    format="percentage"
                    disabled> -->
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 required-div section-label">
                <span [class.highlight-warning]="highlightRequiredField('marketingProcessType')"
                    [class.edited-externally]="editedExternally('marketingProcessType')"
                    [class.conflict-on-merge]="hasMergeConflict('marketingProcessType')">Type of Marketing Process</span>
                <span *ngIf="showAsterisk('marketingProcessType')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <!-- <select name="MarketingProcessType" id="MarketingProcessType" class="input-data-select input-select section-select">
                    <option value="0" disabled selected>Select your option</option>
                </select> -->
                <app-dropdown
                [disabled]="buyerDealMarketingFormGroup.get('marketingProcessType')?.disabled"
                [optionsList]="marketProcessTypeOptions"
                [keySelected]="buyerDealMarketingFormGroup.get('marketingProcessType')?.value"
                [readonly]="isReadOnly('marketingProcessType')"
                (optionSelected)="onMarketingProcessSelection($event)">
                </app-dropdown>
            </div>
        </div>
        <div id="buyer-count-table" class="numeric-input-table">
            <div class="row">
                <div class="col-xs-12">
                    <div class="col-xs-2"><span><b>Buyer Count</b></span></div>
                    <div class="col-xs-10 buyer-count-table-title">
                        <div class="col-xs-2"><span><b>U.S. Strategic</b></span></div>
                        <div class="col-xs-2"><span><b>Int'l Strategic</b></span></div>
                        <div class="col-xs-2"><span><b>PEG (No PortCo)</b></span></div>
                        <div class="col-xs-2"><span><b>PEG (PortCo)</b></span></div>
                        <div class="col-xs-2"><span><b>Total</b></span></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <div class="col-xs-2  section-label">
                        <span class="span-to-highlight"
                        [class.highlight-warning]="highlightRequiredFieldCheck('approachedUSStrategic','approachedIntlStrategic','approachedPEGNoPortCo','approachedPEG')"
                    [class.edited-externally]="editedExternallyCheck('approachedUSStrategic','approachedIntlStrategic','approachedPEGNoPortCo','approachedPEG')"
                    [class.conflict-on-merge]="hasMergeConflictCheck('approachedUSStrategic','approachedIntlStrategic','approachedPEGNoPortCo','approachedPEG')"
                    >Approached</span>
                        <span class="red-asterik"></span>
                    </div>
                    <div class="col-xs-10 buyer-count-table-title">
                        <div class="col-xs-2 ">
                        <app-numeric class="customValuation"
                        [disabled]="buyerDealMarketingFormGroup.get('approachedUSStrategic')?.disabled" 
                        [type]="'decimal2'"
                        [format]="'number'"
                        [value]="buyerDealMarketingFormGroup.get('approachedUSStrategic')?.value"
                        (selectedValue)="onBuyerCount($event,'approachedUSStrategic')">
                    </app-numeric>
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                        [disabled]="buyerDealMarketingFormGroup.get('approachedIntlStrategic')?.disabled" 
                        [type]="'decimal2'"
                        [format]="'number'"
                        [value]="buyerDealMarketingFormGroup.get('approachedIntlStrategic')?.value"
                        (selectedValue)="onBuyerCount($event,'approachedIntlStrategic')"
                        >
                    </app-numeric>                    
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                            [disabled]="buyerDealMarketingFormGroup.get('approachedPEGNoPortCo')?.disabled"
                        [type]="'decimal2'"
                        [format]="'number'"
                        [value]="buyerDealMarketingFormGroup.get('approachedPEGNoPortCo')?.value"
                        (selectedValue)="onBuyerCount($event,'approachedPEGNoPortCo')"
                        >
                    </app-numeric>
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                            [disabled]="buyerDealMarketingFormGroup.get('approachedPEG')?.disabled"
                        [type]="'decimal2'"
                        [format]="'number'"
                        [value]="buyerDealMarketingFormGroup.get('approachedPEG')?.value"
                        (selectedValue)="onBuyerCount($event,'approachedPEG')"
                        >
                    </app-numeric>
                        </div>
                        <div class="col-xs-2 ">
                        <app-numeric class="customValuation"
                        [disabled]="buyerDealMarketingFormGroup.get('approachedTotal')?.disabled"
                        [type]="'decimal2'"
                        [format]="'number'"
                        [placeholder]="'0'"
                        [value]="buyerDealMarketingFormGroup.get('approachedTotal')?.value"                        
                        >
                    </app-numeric>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <div class="col-xs-2  section-label">
                        <span class="span-to-highlight"
                        [class.highlight-warning]="highlightRequiredFieldCheck('sentCIMUSStrategic','sentCIMIntlStrategic','sentCIMPEGNoPortCo','sentCIMPEG')"
                    [class.edited-externally]="editedExternallyCheck('sentCIMUSStrategic','sentCIMIntlStrategic','sentCIMPEGNoPortCo','sentCIMPEG')"
                    [class.conflict-on-merge]="hasMergeConflictCheck('sentCIMUSStrategic','sentCIMIntlStrategic','sentCIMPEGNoPortCo','sentCIMPEG')"
                     >Sent CIM</span>
                        <span class="red-asterik"></span>
                    </div>
                    <div class="col-xs-10 buyer-count-table-title">
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                            [disabled]="buyerDealMarketingFormGroup.get('sentCIMUSStrategic')?.disabled"  
                        [type]="'decimal2'"
                        [format]="'number'"
                        [value]="buyerDealMarketingFormGroup.get('sentCIMUSStrategic')?.value"
                        (selectedValue)="onBuyerCount($event,'sentCIMUSStrategic')"
                        >
                    </app-numeric>
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                            [disabled]="buyerDealMarketingFormGroup.get('sentCIMIntlStrategic')?.disabled"
                        [type]="'decimal2'"
                        [format]="'number'"
                        [value]="buyerDealMarketingFormGroup.get('sentCIMIntlStrategic')?.value"
                        (selectedValue)="onBuyerCount($event,'sentCIMIntlStrategic')"
                        >
                    </app-numeric>
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                            [disabled]="buyerDealMarketingFormGroup.get('sentCIMPEGNoPortCo')?.disabled"
                        [type]="'decimal2'"
                        [format]="'number'"
                        [value]="buyerDealMarketingFormGroup.get('sentCIMPEGNoPortCo')?.value"
                        (selectedValue)="onBuyerCount($event,'sentCIMPEGNoPortCo')"
                        >
                    </app-numeric>
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                            [disabled]="buyerDealMarketingFormGroup.get('sentCIMPEG')?.disabled"
                        [type]="'decimal2'"
                        [format]="'number'"
                        [value]="buyerDealMarketingFormGroup.get('sentCIMPEG')?.value"
                        (selectedValue)="onBuyerCount($event,'sentCIMPEG')"
                        >
                    </app-numeric>
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'number'"
                        [placeholder]="'0'"
                        [value]="buyerDealMarketingFormGroup.get('sentCIMTotal')?.value"
                        [disabled]="buyerDealMarketingFormGroup.get('sentCIMTotal')?.disabled"
                        >
                    </app-numeric>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <div class="col-xs-2  section-label">
                        <span class="span-to-highlight"
                        [class.highlight-warning]="highlightRequiredFieldCheck('submittedIOIUSStrategic','submittedIOIIntlStrategic','submittedIOIPEGNoPortCo','submittedIOIPEG')"
                    [class.edited-externally]="editedExternallyCheck('submittedIOIUSStrategic','submittedIOIIntlStrategic','submittedIOIPEGNoPortCo','submittedIOIPEG')"
                    [class.conflict-on-merge]="hasMergeConflictCheck('submittedIOIUSStrategic','submittedIOIIntlStrategic','submittedIOIPEGNoPortCo','submittedIOIPEG')"
                    >Submitted IOI</span>
                        <span class="red-asterik"></span>
                    </div>
                    <div class="col-xs-10 buyer-count-table-title">
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                            [disabled]="buyerDealMarketingFormGroup.get('submittedIOIUSStrategic')?.disabled"
                        [type]="'decimal2'"
                        [format]="'number'"
                        [value]="buyerDealMarketingFormGroup.get('submittedIOIUSStrategic')?.value"
                        (selectedValue)="onBuyerCount($event,'submittedIOIUSStrategic')"
                        >
                    </app-numeric>
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                            [disabled]="buyerDealMarketingFormGroup.get('submittedIOIIntlStrategic')?.disabled"
                        [type]="'decimal2'"
                        [format]="'number'"
                        [value]="buyerDealMarketingFormGroup.get('submittedIOIIntlStrategic')?.value"
                        (selectedValue)="onBuyerCount($event,'submittedIOIIntlStrategic')"
                        >
                    </app-numeric>
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                            [disabled]="buyerDealMarketingFormGroup.get('submittedIOIPEGNoPortCo')?.disabled"
                        [type]="'decimal2'"
                        [format]="'number'"
                        [value]="buyerDealMarketingFormGroup.get('submittedIOIPEGNoPortCo')?.value"
                        (selectedValue)="onBuyerCount($event,'submittedIOIPEGNoPortCo')"
                        >
                    </app-numeric>
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                            [disabled]="buyerDealMarketingFormGroup.get('submittedIOIPEG')?.disabled"
                        [type]="'decimal2'"
                        [format]="'number'"
                        [value]="buyerDealMarketingFormGroup.get('submittedIOIPEG')?.value"
                        (selectedValue)="onBuyerCount($event,'submittedIOIPEG')"
                        >
                    </app-numeric>
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                        [type]="'decimal2'"
                        [format]="'number'"
                        [placeholder]="'0'"
                        [value]="buyerDealMarketingFormGroup.get('submittedIOITotal')?.value"
                        [disabled]="buyerDealMarketingFormGroup.get('submittedIOITotal')?.disabled"
                        >
                    </app-numeric>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <div class="col-xs-2  section-label">
                        <span class="span-to-highlight"
                        [class.highlight-warning]="highlightRequiredFieldCheck('attendedMPUSStrategic','attendedMPIntlStrategic','attendedMPPEGNoPortCo','attendedMPPEG')"
                        [class.edited-externally]="editedExternallyCheck('attendedMPUSStrategic','attendedMPIntlStrategic','attendedMPPEGNoPortCo','attendedMPPEG')"
                        [class.conflict-on-merge]="hasMergeConflictCheck('attendedMPUSStrategic','attendedMPIntlStrategic','attendedMPPEGNoPortCo','attendedMPPEG')"
                        >Attended MP</span>
                        <span class="red-asterik"></span>
                    </div>
                    <div class="col-xs-10 buyer-count-table-title">
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                            [disabled]="buyerDealMarketingFormGroup.get('attendedMPUSStrategic')?.disabled"
                            [type]="'decimal2'"
                            [format]="'number'"
                            [value]="buyerDealMarketingFormGroup.get('attendedMPUSStrategic')?.value"
                            (selectedValue)="onBuyerCount($event,'attendedMPUSStrategic')"
                            >
                        </app-numeric>
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                            [disabled]="buyerDealMarketingFormGroup.get('attendedMPIntlStrategic')?.disabled"
                            [type]="'decimal2'"
                            [format]="'number'"
                            [value]="buyerDealMarketingFormGroup.get('attendedMPIntlStrategic')?.value"
                            (selectedValue)="onBuyerCount($event,'attendedMPIntlStrategic')"
                            >
                        </app-numeric>
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                            [disabled]="buyerDealMarketingFormGroup.get('attendedMPPEGNoPortCo')?.disabled"
                            [type]="'decimal2'"
                            [format]="'number'"
                            [value]="buyerDealMarketingFormGroup.get('attendedMPPEGNoPortCo')?.value"
                            (selectedValue)="onBuyerCount($event,'attendedMPPEGNoPortCo')"
                            >
                        </app-numeric>
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                            [disabled]="buyerDealMarketingFormGroup.get('attendedMPPEG')?.disabled"
                            [type]="'decimal2'"
                            [format]="'number'"
                            [value]="buyerDealMarketingFormGroup.get('attendedMPPEG')?.value"
                            (selectedValue)="onBuyerCount($event,'attendedMPPEG')"
                            >
                        </app-numeric>
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                            [type]="'decimal2'"
                            [format]="'number'"
                            [placeholder]="'0'"
                            [value]="buyerDealMarketingFormGroup.get('attendedMPTotal')?.value"
                            [disabled]="buyerDealMarketingFormGroup.get('attendedMPTotal')?.disabled"
                            >
                        </app-numeric>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <div class="col-xs-2  section-label">
                        <span class="span-to-highlight"
                        [class.highlight-warning]="highlightRequiredFieldCheck('submittedLOIUSStrategic','submittedLOIIntlStrategic','submittedLOIPEGNoPortCo','submittedLOIPEG')"
                        [class.edited-externally]="editedExternallyCheck('submittedLOIUSStrategic','submittedLOIIntlStrategic','submittedLOIPEGNoPortCo','submittedLOIPEG')"
                        [class.conflict-on-merge]="hasMergeConflictCheck('submittedLOIUSStrategic','submittedLOIIntlStrategic','submittedLOIPEGNoPortCo','submittedLOIPEG')"
                        >Submitted LOI</span>
                        <span class="red-asterik"></span>
                    </div>
                    <div class="col-xs-10 buyer-count-table-title">
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                            [disabled]="buyerDealMarketingFormGroup.get('submittedLOIUSStrategic')?.disabled"
                            [type]="'decimal2'"
                            [format]="'number'"
                            [value]="buyerDealMarketingFormGroup.get('submittedLOIUSStrategic')?.value"
                            (selectedValue)="onBuyerCount($event,'submittedLOIUSStrategic')"
                            >
                        </app-numeric>
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                            [disabled]="buyerDealMarketingFormGroup.get('submittedLOIIntlStrategic')?.disabled"
                            [type]="'decimal2'"
                            [format]="'number'"
                            [value]="buyerDealMarketingFormGroup.get('submittedLOIIntlStrategic')?.value"
                            (selectedValue)="onBuyerCount($event,'submittedLOIIntlStrategic')"
                            >
                        </app-numeric>
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                            [disabled]="buyerDealMarketingFormGroup.get('submittedLOIPEGNoPortCo')?.disabled"
                            [type]="'decimal2'"
                            [format]="'number'"
                            [value]="buyerDealMarketingFormGroup.get('submittedLOIPEGNoPortCo')?.value"
                            (selectedValue)="onBuyerCount($event,'submittedLOIPEGNoPortCo')"
                            >
                        </app-numeric>
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                            [disabled]="buyerDealMarketingFormGroup.get('submittedLOIPEG')?.disabled"
                            [type]="'decimal2'"
                            [format]="'number'"
                            [value]="buyerDealMarketingFormGroup.get('submittedLOIPEG')?.value"
                            (selectedValue)="onBuyerCount($event,'submittedLOIPEG')"
                            >
                        </app-numeric>
                        </div>
                        <div class="col-xs-2 ">
                            <app-numeric class="customValuation"
                            [type]="'decimal2'"
                            [format]="'number'"
                            [placeholder]="'0'"
                            [value]="buyerDealMarketingFormGroup.get('submittedLOITotal')?.value"
                            [disabled]="buyerDealMarketingFormGroup.get('submittedLOITotal')?.disabled"
                            >
                        </app-numeric>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 required-div section-label">
                <span [class.highlight-warning]="highlightRequiredField('describeDealMarketing')"
                    [class.edited-externally]="editedExternally('describeDealMarketing')"
                    [class.conflict-on-merge]="hasMergeConflict('describeDealMarketing')">Describe Deal Marketing Process/Strategy</span>
                <span *ngIf="showAsterisk('describeDealMarketing')" class="red-asterik"></span>
                 <app-popover
                    [tooltipText]="getTooltipText('DescribeDealMarketing')">
                  </app-popover>
            </div>
            <div class="col-xs-8">
                <textarea 
                [disabled]="buyerDealMarketingFormGroup.get('describeDealMarketing')?.disabled"
                formControlName="describeDealMarketing"
                type="text" rows="3" 
                placeholder="You can add comments here" 
                class="input-data-text text-area-style" 
                name="DescribeDealMarketing"></textarea>
            </div>
        </div>
    </div>
</div>
