<link rel=”stylesheet” href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css">
<div [ngClass]="isAddingUser?'fullscreen-New': 'fullscreen-Old'">
<div class="header">
    <div class="headerManagement">Content Management</div> 
  </div>
  <div class="mainBody" style="display: flex;width: 100%;margin-top: 1%;">
    <div class="drop-down-container">
    <div class="dropdown-item">
      <label>Form Type</label>
        <select id="ddlFormType" (change)="onFormTypeChange()"
        [(ngModel)]="selectedFormType"
         class="form-select form-control formtypewidth">
            <option value="" selected disabled>Form Type</option>
            <option *ngFor="let title of formTypes" [value]="title.Name">
  {{title.Name}}
</option>
          </select>
    </div>
    <div class="dropdown-item userGroupmargin">
      <label>Content Type</label>     
        <select  id="ddlContentType" (change)="onContentTypeChange()"
        [(ngModel)]="selectedContentType"
         class="form-select form-control formtypewidth" [disabled]="isContentDisabled">
            <option value= ""selected disabled>Select Content Type</option>
            <option *ngFor="let title of contentTypes" [value]="title.Name">
  {{title.Name}}
</option>
          </select>
  </div>
  <div class="dropdown-item userGroupmargin">
    <label>Table</label>     
      <select  id="ddlTable" (change)="onTableChange()"
      [(ngModel)]="selectedTableName"
       class="form-select form-control formtypewidth" [disabled]="isTableDisabled" 
       [style.width]="selectedContentType=='Master Data' ? '100%' : '104% !important'">
          <option value= ""selected disabled>Select Table</option>
          <option *ngFor="let title of TablesList" [value]="title.TableName">
{{title.TableName}}
</option>
        </select>
</div>
</div>
<div class="buttons-container">
<div *ngIf="isButtonsDisplay&&selectedContentType=='Master Data'" class="userGroupmargins btn1" >
    <button class="btn btn-primary btnAddUser" type="button"  (click)="openAddContentPopup($event)" [disabled]="isDisableUserAdd">Add New</button>
</div>
<div *ngIf="isButtonsDisplay" class="userGroupmargins btn2" [style.margin-left]="selectedContentType=='Master Data' ? '0%' : '15% !important'">
    <button class="btn btn-primary btnAddUser" type="button"  (click)="openAuditPopup($event)" [disabled]="isDisableUserAudit">Audit Trail</button>
</div>

<div *ngIf="isButtonsDisplay" class="userGroupmargins btn3">    
  <button type="button" class="clear-filters-button" (click)="clearAllFilters($event)">
    Clear Filters
  </button>
</div> 
</div>
</div>
  <!--<div style="display: inline-flex;">
 <div class="divFormType" style="margin-left: 119%;
margin-top: 1%;">
    <button class="btn btn-primary btnAddUser" type="button"  (click)="openAddContentPopup($event)" [disabled]="isTableSelected">Add New</button>
</div>
<div class="divFormType" style="margin-top: 1%;" >
    <button class="btn btn-primary btnAddUser" type="button"  (click)="openAddContentPopup($event)" [disabled]="isTableSelected">Audit Trail</button>
</div> 
</div>-->
    
 <div class="divTable" *ngIf="showTable" >   
<table id="engagement-table" class="table-striped">
  <thead style="height: 60px;">
    <tr>    
      <th scope="col" *ngIf="selectedTableName=='CrossBorderIndustries'">
        <span
          class="th-label"
          sortable="Industry"
          (sort)="sortTable($event)">
           Industry
        </span>
       <app-popover  #popoverName
          [active]="isFilterApply('Industry')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('Industry')">      
       </app-popover>   
      </th>
      <th scope="col" *ngIf="selectedTableName=='CrossBorderIndustries'">
        <span
          class="th-label"
          sortable="Representative"
          (sort)="sortTable($event)">
          Representative
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('Representative')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('Representative')">
        </app-popover>
       
      </th>

      <th scope="col" *ngIf="selectedTableName=='ValidationFieldsCrossBorder'" >
        <span
          class="th-label"
          sortable="RequiredGroupFields"
          (sort)="sortTable($event)">
           Required Group Fields
        </span>
       <app-popover  #popoverName
          [active]="isFilterApply('RequiredGroupFields')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('RequiredGroupFields')">      
       </app-popover>   
      </th>
      <th scope="col" *ngIf="selectedTableName=='ValidationFieldsCrossBorder'">
        <span
          class="th-label"
          sortable="RequiredFields"
          (sort)="sortTable($event)">
          Required Fields
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('RequiredFields')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('RequiredFields')">
        </app-popover>
       
      </th>
      <th scope="col" *ngIf="selectedTableName=='CrossBorderDocumentTypes'">
        <span
          class="th-label"
          sortable="DocumentTypeTitle"
          (sort)="sortTable($event)">
           Document Type Title
        </span>
       <app-popover  #popoverName
          [active]="isFilterApply('DocumentTypeTitle')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('DocumentTypeTitle')">      
       </app-popover>   
      </th>
      <th scope="col" *ngIf="selectedTableName=='CrossBorderDocumentTypes'">
        <span
          class="th-label"
          sortable="Guidance"
          (sort)="sortTable($event)">
          Guidance Text
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('Guidance')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('Guidance')">
        </app-popover>
       
      </th>

      <th scope="col" *ngIf="selectedTableName=='CrossBorderDocumentTypes'">
        <span
          class="th-label"
          sortable="DocumentTypeOrder"
          (sort)="sortTable($event)">Document Type Order
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('DocumentTypeOrder')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('DocumentTypeOrder')">
        </app-popover>
       
      </th>

      <th scope="col" *ngIf="selectedTableName=='CrossBorderCountries'">
        <span
          class="th-label"
          sortable="Title"
          (sort)="sortTable($event)">
          Country
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('Title')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('Title')">
        </app-popover>
       
      </th>
      <th scope="col" *ngIf="selectedTableName=='CrossBorderForm'">
        <span
          class="th-label"
          sortable="ID"
          (sort)="sortTable($event)">
          Form ID
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('ID')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('ID')">
        </app-popover>
      </th>
      <th scope="col" *ngIf="selectedTableName=='CrossBorderAdditionalDocumentTypes'||selectedTableName=='CrossBorderAttachments'||selectedTableName=='CrossBorderComments'||selectedTableName=='CrossBorderRecommendedTemplates'|| selectedTableName=='CrossBorderApprovalReviewStatus'">
        <span
          class="th-label"
          sortable="FormID"
          (sort)="sortTable($event)">
          Form ID
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('FormID')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('FormID')">
        </app-popover>
       
      </th>
      <th scope="col" *ngIf="selectedTableName=='CrossBorderForm'">
        <span
          class="th-label"
          sortable="ProjectName"
          (sort)="sortTable($event)">
          Project Name
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('ProjectName')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('ProjectName')">
        </app-popover>
       
      </th>
      <th scope="col" *ngIf="selectedTableName=='CrossBorderForm' || selectedTableName=='CrossBorderApprovalReviewStatus'">
        <span
          class="th-label"
          sortable="Status"
          (sort)="sortTable($event)">
          Form Status
        </span>
       <app-popover  #popoverTitle *ngIf="selectedTableName!='CrossBorderApprovalReviewStatus'"
          [active]="isFilterApply('Status')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('Status')">
        </app-popover>       
      </th>
      <th scope="col" *ngIf="selectedTableName=='CrossBorderTemplates'||selectedTableName=='CrossBorderAdditionalDocumentTypes'||selectedTableName=='CrossBorderAttachments'||selectedTableName=='CrossBorderComments'||selectedTableName=='CrossBorderRecommendedTemplates'||selectedTableName=='CrossBorderRedLineAttachments'||selectedTableName=='CrossBorderApprovalReviewStatus'">
        <span
          class="th-label"
          sortable="DocumentType"
          (sort)="sortTable($event)">
          Document Type
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('DocumentType')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('DocumentType')">
        </app-popover>
       
      </th>

      <th scope="col" *ngIf="selectedTableName=='CrossBorderTemplates'||selectedTableName=='CrossBorderAttachments'||selectedTableName=='CrossBorderRecommendedTemplates'">
        <span
          class="th-label"
          sortable="Title"
          (sort)="sortTable($event)">
          Document Title
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('Title')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('Title')">
        </app-popover>
       
      </th>
      <th scope="col" *ngIf="selectedTableName=='CrossBorderTemplates'||selectedTableName=='CrossBorderAttachments'||selectedTableName=='CrossBorderRecommendedTemplates'||selectedTableName=='CrossBorderRedLineAttachments'">
        <span
          class="th-label"
          sortable="Name"
          (sort)="sortTable($event)">
          Doc Name
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('Name')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('Name')">
        </app-popover>
       
      </th>

      <th scope="col" *ngIf="selectedTableName=='FormType'">
        <span
          class="th-label"
          sortable="Name"
          (sort)="sortTable($event)">
          Form Type
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('Name')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('Name')">
        </app-popover>
       
      </th>
      <th scope="col" *ngIf="selectedTableName=='FormGroups'">
        <span
          class="th-label"
          sortable="Name"
          (sort)="sortTable($event)">
          Form Group
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('Name')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('Name')">
        </app-popover>
       
      </th>
      <th scope="col" *ngIf="selectedTableName=='CrossBorderComments'">
        <span
          class="th-label"
          sortable="CommentAuthorName"
          (sort)="sortTable($event)">
          Comment Author
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('CommentAuthorName')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('CommentAuthorName')">
        </app-popover>
       
      </th>
      <th scope="col" *ngIf="selectedTableName=='CrossBorderComments'">
        <span
          class="th-label"
          sortable="CommentType"
          (sort)="sortTable($event)">
          Comment Type
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('CommentType')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('CommentType')">
        </app-popover>
       
      </th>
      <th scope="col" *ngIf="selectedTableName=='CrossBorderComments'">
        <span
          class="th-label"
          sortable="Comment"
          (sort)="sortTable($event)">
          Comment
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('Comment')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('Comment')">
        </app-popover>
       
      </th>
      <th scope="col" *ngIf="selectedTableName=='CrossBorderComments'">
        <span
          class="th-label"
          sortable="ReviewStatus"
          (sort)="sortTable($event)">
          Review Status
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('ReviewStatus')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('ReviewStatus')">
        </app-popover>
       
      </th>
      <th scope="col" *ngIf="selectedTableName=='UserGroups'">
        <span
          class="th-label"
          sortable="Role"
          (sort)="sortTable($event)">
          User Groups
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('Role')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('Role')">
        </app-popover>
       
      </th>


      <!-- <th scope="col" *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
        <span
          class="th-label"
          sortable="BusinessReviewDate"
          (sort)="sortTable($event)">
          Business Review Date
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('BusinessReviewDate')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('BusinessReviewDate')">
        </app-popover>
       
      </th> -->
      <th scope="col" *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
        <span
          class="th-label"
          sortable="BusinessReviewer"
          (sort)="sortTable($event)">
          Business Reviewer
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('BusinessReviewer')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('BusinessReviewer')">
        </app-popover>
       
      </th>
      <th scope="col" *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
        <span
          class="th-label"
          sortable="BusinessReviewStatus"
          (sort)="sortTable($event)">
          Business Review Status
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('BusinessReviewStatus')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('BusinessReviewStatus')">
        </app-popover>
       
      </th>
      <th scope="col" *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
        <span
          class="th-label"
          sortable="QRMReviewer"
          (sort)="sortTable($event)">
          QRM Reviewer
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('QRMReviewer')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('QRMReviewer')">
        </app-popover>
       
      </th>
      <th scope="col" *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
        <span
          class="th-label"
          sortable="QRMReviewStatus"
          (sort)="sortTable($event)">
          QRM Review Status
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('QRMReviewStatus')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('QRMReviewStatus')">
        </app-popover>
       
      </th>
      <!-- <th scope="col" *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
        <span
          class="th-label"
          sortable="ComplianceReviewDate"
          (sort)="sortTable($event)">
          Compliance Review Date
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('ComplianceReviewDate')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('ComplianceReviewDate')">
        </app-popover>
       
      </th> -->
      <th scope="col" *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
        <span
          class="th-label"
          sortable="ComplianceReviewer"
          (sort)="sortTable($event)">
          Compliance Reviewer
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('ComplianceReviewer')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('ComplianceReviewer')">
        </app-popover>
       
      </th>
      <th scope="col" *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
        <span
          class="th-label"
          sortable="ComplianceReviewStatus"
          (sort)="sortTable($event)">
          Compliance Review Status
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('ComplianceReviewStatus')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('ComplianceReviewStatus')">
        </app-popover>
       
      </th>
      <!-- <th scope="col" *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
        <span
          class="th-label"
          sortable="QRMReviewDate"
          (sort)="sortTable($event)">
          QRM Review Date
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('QRMReviewDate')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('QRMReviewDate')">
        </app-popover>
       
      </th> -->   
      <th scope="col" *ngIf="selectedTableName=='AppConfigurations'">
        <span
          class="th-label"
          sortable="Title"
          (sort)="sortTable($event)">
          Config Title
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('Title')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('Title')">
        </app-popover>
       
      </th>
      <th scope="col" *ngIf="selectedTableName=='AppConfigurations'">
        <span
          class="th-label"
          sortable="Value"
          (sort)="sortTable($event)">
          Config Value
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('Value')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('Value')">
        </app-popover>       
      </th>      
      <th scope="col" *ngIf="selectedTableName!='CrossBorderAdditionalDocumentTypes' 
                             && selectedTableName!='CrossBorderAttachments'">
        <span
          class="th-label"
          sortable="ModifiedBy"
          (sort)="sortTable($event)">
          Last Modified By
        </span>
       <app-popover  #popoverTitle
          [active]="isFilterApply('ModifiedBy')"
          [keepOpen]="true"
          [openOnFocus]="false"
          [tooltipTemplate]="popContent"
          [tooltipIcon]="'fas fa-filter th-filter'"
          [triggers]="'manual'"
          [autoClose]="'outside'"
          (click)="openFilterPopover('ModifiedBy')">
        </app-popover>
      </th>
      <th scope="col" *ngIf="selectedTableName =='CrossBorderApprovalReviewStatus'">
          <span
            class="th-label"
            sortable="Modified"
            (sort)="sortTable($event)">
            Last Modified Date
          </span>
         <app-popover  #popoverTitle
            [active]="isFilterApply('Modified')"
            [keepOpen]="true"
            [openOnFocus]="false"
            [tooltipTemplate]="popContent"
            [tooltipIcon]="'fas fa-filter th-filter'"
            [triggers]="'manual'"
            [autoClose]="'outside'"
            (click)="openFilterPopover('Modified')">
          </app-popover>         
        </th>
        <th scope="col" *ngIf="selectedTableName!='CrossBorderAdditionalDocumentTypes' &&
                               selectedTableName!='CrossBorderAttachments' && 
                               selectedTableName !='CrossBorderApprovalReviewStatus'">
          <span
            class="th-label"
            sortable="Modified"
            (sort)="sortTable($event)">
            Last Modified Date
          </span>
         <app-popover  #popoverTitle
            [active]="isFilterApply('Modified')"
            [keepOpen]="true"
            [openOnFocus]="false"
            [tooltipTemplate]="popContent"
            [tooltipIcon]="'fas fa-filter th-filter'"
            [triggers]="'manual'"
            [autoClose]="'outside'"
            (click)="openFilterPopover('Modified')">
          </app-popover>         
        </th>
        <th scope="col" *ngIf="selectedTableName=='CrossBorderAdditionalDocumentTypes' ||
                               selectedTableName=='CrossBorderAttachments'">
          <span
            class="th-label"
            sortable="CreatedBy"
            (sort)="sortTable($event)">
            Created By
          </span>
         <app-popover  #popoverTitle
            [active]="isFilterApply('CreatedBy')"
            [keepOpen]="true"
            [openOnFocus]="false"
            [tooltipTemplate]="popContent"
            [tooltipIcon]="'fas fa-filter th-filter'"
            [triggers]="'manual'"
            [autoClose]="'outside'"
            (click)="openFilterPopover('CreatedBy')">
          </app-popover>
         
        </th>
        <th scope="col" *ngIf="selectedTableName=='CrossBorderAdditionalDocumentTypes'">
          <span
            class="th-label"
            sortable="Created"
            (sort)="sortTable($event)">
            Created Date
          </span>
         <app-popover  #popoverTitle
            [active]="isFilterApply('Created')"
            [keepOpen]="true"
            [openOnFocus]="false"
            [tooltipTemplate]="popContent"
            [tooltipIcon]="'fas fa-filter th-filter'"
            [triggers]="'manual'"
            [autoClose]="'outside'"
            (click)="openFilterPopover('Created')">
          </app-popover>
         
        </th>
        <th scope="col" *ngIf="selectedTableName=='CrossBorderAttachments'">
          <span
            class="th-label"
            sortable="Modified"
            (sort)="sortTable($event)">
            Created Date
          </span>
         <app-popover  #popoverTitle
            [active]="isFilterApply('Modified')"
            [keepOpen]="true"
            [openOnFocus]="false"
            [tooltipTemplate]="popContent"
            [tooltipIcon]="'fas fa-filter th-filter'"
            [triggers]="'manual'"
            [autoClose]="'outside'"
            (click)="openFilterPopover('Modified')">
          </app-popover>
        </th>
      <th *ngIf="selectedTableName!='CrossBorderAttachments'" scope="col" style="width: 7%;">
        <span
          class="th-label">       
          Action
        </span>
    </th> 
    <th *ngIf="selectedTableName=='CrossBorderDocumentTypes'&&isITSAdmin" scope="col" style="width: 7%;">
      <span
        class="th-label">       
        Permissions
      </span>
  </th> 
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let report of contentManagementPerPage; let i = index">
    <tr *ngIf="report.isActive!='No'">     
       <td *ngIf="selectedTableName=='CrossBorderIndustries'">
        <span>{{report.Industry}}</span>
      </td>
      <td *ngIf="selectedTableName=='CrossBorderIndustries'">
        <span>
          {{report.Representative}}
        </span>
      </td> 
      <td *ngIf="selectedTableName=='ValidationFieldsCrossBorder'">
        <span>
          {{report.RequiredGroupFields}}
        </span>
      </td> 
      <td *ngIf="selectedTableName=='ValidationFieldsCrossBorder'">
        <span>
          {{report.RequiredFields}}
        </span>
      </td> 
      <td *ngIf="selectedTableName=='CrossBorderDocumentTypes'">
        <span>
          {{report.DocumentTypeTitle}}
        </span>
      </td> 
      <td *ngIf="selectedTableName=='CrossBorderDocumentTypes'">
        <span>
          {{report.Guidance}}
        </span>
      </td> 
      <td *ngIf="selectedTableName=='CrossBorderDocumentTypes'">
        <span>
          {{report.DocumentTypeOrder}}
        </span>
      </td> 
      <td *ngIf="selectedTableName=='CrossBorderCountries'">
        <span>
          {{report.Title}}
        </span>
      </td> 
      <td *ngIf="selectedTableName=='CrossBorderForm'">
        <span>
          {{report.ID}}
        </span>
      </td> 
      <td *ngIf="selectedTableName=='CrossBorderAdditionalDocumentTypes'||selectedTableName=='CrossBorderAttachments'||selectedTableName=='CrossBorderComments'||selectedTableName=='CrossBorderRecommendedTemplates'||selectedTableName=='CrossBorderRedLineAttachments'||selectedTableName=='CrossBorderApprovalReviewStatus'">
        <span>
          {{report.FormID}}
        </span>
      </td> 
      <td *ngIf="selectedTableName=='CrossBorderForm'">
        <span>
          {{report.ProjectName}}
        </span>
      </td> 
      <td *ngIf="selectedTableName=='CrossBorderForm' || selectedTableName=='CrossBorderApprovalReviewStatus'">
        <span *ngIf="selectedTableName!='CrossBorderApprovalReviewStatus'">
          {{report.Status}}
        </span>
        <span *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
          Review in progress
        </span>
      </td> 
      <td *ngIf="selectedTableName=='CrossBorderTemplates'||selectedTableName=='CrossBorderAdditionalDocumentTypes'||selectedTableName=='CrossBorderAttachments'||selectedTableName=='CrossBorderComments'||selectedTableName=='CrossBorderRecommendedTemplates'||selectedTableName=='CrossBorderRedLineAttachments'||selectedTableName=='CrossBorderApprovalReviewStatus'">
        <span>
          {{report.DocumentType}}
        </span>
      </td> 
      <td *ngIf="selectedTableName=='CrossBorderTemplates'||selectedTableName=='CrossBorderAttachments'||selectedTableName=='CrossBorderRecommendedTemplates'">
        <span>
          {{report.Title}}
        </span>
      </td> 
      <td *ngIf="selectedTableName=='CrossBorderTemplates'||selectedTableName=='CrossBorderAttachments'||selectedTableName=='CrossBorderRecommendedTemplates'||selectedTableName=='CrossBorderRedLineAttachments'||selectedTableName=='FormType'||selectedTableName=='FormGroups'">
        <span>
          {{report.Name}}
        </span>
      </td> 
    
      <td *ngIf="selectedTableName=='CrossBorderComments'">
        <span>
          {{report.CommentAuthorName}}
        </span>
      </td> 
      <td *ngIf="selectedTableName=='CrossBorderComments'">
        <span>
          {{report.CommentType}}
        </span>
      </td> 
      <td *ngIf="selectedTableName=='CrossBorderComments'">
        <span>
          {{report.Comment}}
        </span>
      </td> 
      <td *ngIf="selectedTableName=='CrossBorderComments'">
        <span>
          {{report.ReviewStatus}}
        </span>
      </td> 
      <td *ngIf="selectedTableName=='UserGroups'">
        <span>
          {{report.Role}}
        </span>
      </td> 
      <!-- <td *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
        <span>
          {{report.BusinessReviewDate}}
        </span>
      </td>  -->
      <td *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
        <span>
          {{report.BusinessReviewer}}
        </span>
      </td> 
      <td *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
        <span>
          {{report.BusinessReviewStatus}}
        </span>
      </td> 
      <!-- <td *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
        <span>
          {{report.ComplianceReviewDate}}
        </span>
      </td>  -->
      <td *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
        <span>
          {{report.QRMReviewer}}
        </span>
      </td> 
      <td *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
        <span>
          {{report.QRMReviewStatus}}
        </span>
      </td> 
      <td *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
        <span>
          {{report.ComplianceReviewer}}
        </span>
      </td> 
      <td *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
        <span>
          {{report.ComplianceReviewStatus}}
        </span>
      </td> 
      <!-- <td *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
        <span>
          {{report.QRMReviewDate}}
        </span>
      </td>  -->      
      <td *ngIf="selectedTableName=='CrossBorderAdditionalDocumentTypes'">
        <span>
          {{report.CreatedBy}}
        </span>
      </td> 
      <td *ngIf="selectedTableName=='CrossBorderAdditionalDocumentTypes'">
        <span>
          {{report.Created}}
        </span>
      </td> 
      <td *ngIf="selectedTableName=='AppConfigurations'">
        <span>
          {{report.Title}}
        </span>
      </td> 
      <td *ngIf="selectedTableName=='AppConfigurations'">
        <span>
          {{report.Value}}
        </span>
      </td> 
      <td *ngIf="selectedTableName!='CrossBorderAdditionalDocumentTypes'">
        <span>
          {{report.ModifiedBy}}
        </span>
      </td> 
      <td *ngIf="selectedTableName!='CrossBorderAdditionalDocumentTypes'">
        <span>
          {{report.Modified}}
        </span>
      </td> 
      <td *ngIf="selectedTableName!='CrossBorderAttachments'&&selectedTableName!='CrossBorderForm'">
        <span *ngIf="canUpdateAndDelete">
        <img *ngIf="selectedTableName!='CrossBorderCountries' && selectedTableName!='CrossBorderAdditionalDocumentTypes'" style="margin-left:10px;height: 20px;cursor: pointer;" src="assets/img/edit.png" (click)="EditUser(report)"/> 
        <img *ngIf="selectedTableName!='FormType' && selectedTableName!='ValidationFieldsCrossBorder' && selectedTableName!='AppConfigurations' " style="margin-left:10px;height: 16px;cursor: pointer;" (click)="DeleteUser(report)" src="assets/img/Vector.png"/>
      </span>
      <span *ngIf="!canUpdateAndDelete">
        <img *ngIf="selectedTableName!='CrossBorderCountries' && selectedTableName!='CrossBorderAdditionalDocumentTypes'" style="margin-left:10px;height: 20px;pointer-events: none;" src="assets/img/edit.png" (click)="EditUser(report)"/> 
        <img *ngIf="selectedTableName!='FormType' && selectedTableName!='ValidationFieldsCrossBorder'" style="margin-left:10px;height: 16px;pointer-events: none;" (click)="DeleteUser(report)" src="assets/img/Vector.png"/>
      </span>
    </td>
    <td *ngIf="selectedTableName=='CrossBorderForm'">
      <span *ngIf="canUpdateAndDelete&&report.isActive!='No'">
      <img  style="margin-left:10px;height: 20px;cursor: pointer;" src="assets/img/edit.png" (click)="EditUser(report)"/> 
      <img  style="margin-left:10px;height: 16px;cursor: pointer;" (click)="DeleteUser(report)" src="assets/img/Vector.png"/>
    </span>
    <span *ngIf="canUpdateAndDelete&&report.isActive=='No'">
      <img *ngIf="isContentManagementAdmin&&!isITSAdmin" style="margin-left:10px;height: 20px;pointer-events: none;" src="assets/img/edit.png" (click)="EditUser(report)"/> 
      <img *ngIf="isContentManagementAdmin&&!isITSAdmin" style="margin-left:10px;height: 16px;pointer-events: none;" (click)="DeleteUser(report)" src="assets/img/Vector.png"/>
      <button *ngIf="isITSAdmin" class="btn btn-primary btnApproved" type="button" aria-placeholder="Activate"  (click)="ActivateForm(report)" >Activate</button>  
     
    </span>
    <span *ngIf="!canUpdateAndDelete">
      <img style="margin-left:10px;height: 20px;pointer-events: none;" src="assets/img/edit.png" (click)="EditUser(report)"/> 
      <img  style="margin-left:10px;height: 16px;pointer-events: none;" (click)="DeleteUser(report)" src="assets/img/Vector.png"/>
    </span>
  </td>
    <td *ngIf="selectedTableName=='CrossBorderDocumentTypes'&&isITSAdmin">
      <span *ngIf="report.isApproved=='No'&&report.isRejected=='No'">
        <button class="btn btn-primary btnApproved" type="button"  (click)="isITSVerified(report)" >Verify</button>  
    </span>
   
  </td>
      </tr>
 <!-- Disable Form Style Logic Start -->
      <tr *ngIf="report.isActive=='No'" style="background: lightsteelblue;">     
        <td *ngIf="selectedTableName=='CrossBorderIndustries'">
         <span>{{report.Industry}}</span>
       </td>
       <td *ngIf="selectedTableName=='CrossBorderIndustries'">
         <span>
           {{report.Representative}}
         </span>
       </td> 
       <td *ngIf="selectedTableName=='ValidationFieldsCrossBorder'">
         <span>
           {{report.RequiredGroupFields}}
         </span>
       </td> 
       <td *ngIf="selectedTableName=='ValidationFieldsCrossBorder'">
         <span>
           {{report.RequiredFields}}
         </span>
       </td> 
       <td *ngIf="selectedTableName=='CrossBorderDocumentTypes'">
         <span>
           {{report.DocumentTypeTitle}}
         </span>
       </td> 
       <td *ngIf="selectedTableName=='CrossBorderDocumentTypes'">
         <span>
           {{report.Guidance}}
         </span>
       </td> 
       <td *ngIf="selectedTableName=='CrossBorderDocumentTypes'">
         <span>
           {{report.DocumentTypeOrder}}
         </span>
       </td> 
       <td *ngIf="selectedTableName=='CrossBorderCountries'">
         <span>
           {{report.Title}}
         </span>
       </td> 
       <td *ngIf="selectedTableName=='CrossBorderForm'">
         <span>
           {{report.ID}}
         </span>
       </td> 
       <td *ngIf="selectedTableName=='CrossBorderAdditionalDocumentTypes'||selectedTableName=='CrossBorderAttachments'||selectedTableName=='CrossBorderComments'||selectedTableName=='CrossBorderRecommendedTemplates'||selectedTableName=='CrossBorderRedLineAttachments'||selectedTableName=='CrossBorderApprovalReviewStatus'">
         <span>
           {{report.FormID}}
         </span>
       </td> 
       <td *ngIf="selectedTableName=='CrossBorderForm'">
         <span>
           {{report.ProjectName}}
         </span>
       </td> 
       <td *ngIf="selectedTableName=='CrossBorderForm' || selectedTableName=='CrossBorderApprovalReviewStatus'">
         <span *ngIf="selectedTableName!='CrossBorderApprovalReviewStatus'">
           {{report.Status}}
         </span>
         <span *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
           Review in progress
         </span>
       </td> 
       <td *ngIf="selectedTableName=='CrossBorderTemplates'||selectedTableName=='CrossBorderAdditionalDocumentTypes'||selectedTableName=='CrossBorderAttachments'||selectedTableName=='CrossBorderComments'||selectedTableName=='CrossBorderRecommendedTemplates'||selectedTableName=='CrossBorderRedLineAttachments'||selectedTableName=='CrossBorderApprovalReviewStatus'">
         <span>
           {{report.DocumentType}}
         </span>
       </td> 
       <td *ngIf="selectedTableName=='CrossBorderTemplates'||selectedTableName=='CrossBorderAttachments'||selectedTableName=='CrossBorderRecommendedTemplates'">
         <span>
           {{report.Title}}
         </span>
       </td> 
       <td *ngIf="selectedTableName=='CrossBorderTemplates'||selectedTableName=='CrossBorderAttachments'||selectedTableName=='CrossBorderRecommendedTemplates'||selectedTableName=='CrossBorderRedLineAttachments'||selectedTableName=='FormType'||selectedTableName=='FormGroups'">
         <span>
           {{report.Name}}
         </span>
       </td> 
     
       <td *ngIf="selectedTableName=='CrossBorderComments'">
         <span>
           {{report.CommentAuthorName}}
         </span>
       </td> 
       <td *ngIf="selectedTableName=='CrossBorderComments'">
         <span>
           {{report.CommentType}}
         </span>
       </td> 
       <td *ngIf="selectedTableName=='CrossBorderComments'">
         <span>
           {{report.Comment}}
         </span>
       </td> 
       <td *ngIf="selectedTableName=='CrossBorderComments'">
         <span>
           {{report.ReviewStatus}}
         </span>
       </td> 
       <td *ngIf="selectedTableName=='UserGroups'">
         <span>
           {{report.Role}}
         </span>
       </td> 
       <!-- <td *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
         <span>
           {{report.BusinessReviewDate}}
         </span>
       </td>  -->
       <td *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
         <span>
           {{report.BusinessReviewer}}
         </span>
       </td> 
       <td *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
         <span>
           {{report.BusinessReviewStatus}}
         </span>
       </td> 
       <!-- <td *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
         <span>
           {{report.ComplianceReviewDate}}
         </span>
       </td>  -->
       <td *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
         <span>
           {{report.QRMReviewer}}
         </span>
       </td> 
       <td *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
         <span>
           {{report.QRMReviewStatus}}
         </span>
       </td> 
       <td *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
         <span>
           {{report.ComplianceReviewer}}
         </span>
       </td> 
       <td *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
         <span>
           {{report.ComplianceReviewStatus}}
         </span>
       </td> 
       <!-- <td *ngIf="selectedTableName=='CrossBorderApprovalReviewStatus'">
         <span>
           {{report.QRMReviewDate}}
         </span>
       </td>  -->      
       <td *ngIf="selectedTableName=='CrossBorderAdditionalDocumentTypes'">
         <span>
           {{report.CreatedBy}}
         </span>
       </td> 
       <td *ngIf="selectedTableName=='CrossBorderAdditionalDocumentTypes'">
         <span>
           {{report.Created}}
         </span>
       </td> 
       <td *ngIf="selectedTableName!='CrossBorderAdditionalDocumentTypes'">
         <span>
           {{report.ModifiedBy}}
         </span>
       </td> 
       <td *ngIf="selectedTableName!='CrossBorderAdditionalDocumentTypes'">
         <span>
           {{report.Modified}}
         </span>
       </td> 
       <td *ngIf="selectedTableName!='CrossBorderAttachments'&&selectedTableName!='CrossBorderForm'">
         <span *ngIf="canUpdateAndDelete">
         <img *ngIf="selectedTableName!='CrossBorderCountries' && selectedTableName!='CrossBorderAdditionalDocumentTypes'" style="margin-left:10px;height: 20px;cursor: pointer;" src="assets/img/edit.png" (click)="EditUser(report)"/> 
         <img *ngIf="selectedTableName!='FormType' && selectedTableName!='ValidationFieldsCrossBorder'" style="margin-left:10px;height: 16px;cursor: pointer;" (click)="DeleteUser(report)" src="assets/img/Vector.png"/>
       </span>
       <span *ngIf="!canUpdateAndDelete">
         <img *ngIf="selectedTableName!='CrossBorderCountries' && selectedTableName!='CrossBorderAdditionalDocumentTypes'" style="margin-left:10px;height: 20px;pointer-events: none;" src="assets/img/edit.png" (click)="EditUser(report)"/> 
         <img *ngIf="selectedTableName!='FormType' && selectedTableName!='ValidationFieldsCrossBorder'" style="margin-left:10px;height: 16px;pointer-events: none;" (click)="DeleteUser(report)" src="assets/img/Vector.png"/>
       </span>
     </td>
     <td *ngIf="selectedTableName=='CrossBorderForm'">
       <span *ngIf="canUpdateAndDelete&&report.isActive!='No'">
       <img  style="margin-left:10px;height: 20px;cursor: pointer;" src="assets/img/edit.png" (click)="EditUser(report)"/> 
       <img  style="margin-left:10px;height: 16px;cursor: pointer;" (click)="DeleteUser(report)" src="assets/img/Vector.png"/>
     </span>
     <span *ngIf="canUpdateAndDelete&&report.isActive=='No'">
       <img *ngIf="isContentManagementAdmin&&!isITSAdmin" style="margin-left:2px;height: 20px;pointer-events: none;" src="assets/img/edit.png" (click)="EditUser(report)"/> 
       <img *ngIf="isContentManagementAdmin&&!isITSAdmin" style="margin-left:10px;height: 16px;pointer-events: none;" (click)="DeleteUser(report)" src="assets/img/Vector.png"/>
       <button *ngIf="isITSAdmin" class="btn btn-primary btnApproved" type="button" aria-placeholder="Activate"  (click)="ActivateForm(report)" >Activate</button>  
      
     </span>
     <span *ngIf="!canUpdateAndDelete">
       <img style="margin-left:10px;height: 20px;pointer-events: none;" src="assets/img/edit.png" (click)="EditUser(report)"/> 
       <img  style="margin-left:10px;height: 16px;pointer-events: none;" (click)="DeleteUser(report)" src="assets/img/Vector.png"/>
     </span>
   </td>
     <td *ngIf="selectedTableName=='CrossBorderDocumentTypes'&&isITSAdmin">
       <span *ngIf="report.isApproved=='No'&&report.isRejected=='No'">
         <button class="btn btn-primary btnApproved" type="button"  (click)="isITSVerified(report)" >Verify</button>  
     </span>
    
   </td>
       </tr>
       <!-- Disable Form Style Logic End -->
      </ng-container>
  </tbody>
</table>

<span *ngIf="contentManagements && contentManagements.length === 0" style="margin-left: 380px;" class="no-matches">No records matched your search.</span>
<br/>
<div class="row">
  <div class="col-md-5" style="margin-left: -6%;">
<app-pagination #pagination
*ngIf="contentManagements" 
[page]="commonHelperService.state.page"
[pageSize]="commonHelperService.state.pageSize"
[paginationListValues]="pageSizeListValues"
[listElementsSize]="contentManagements.length"
(paginationValues)="onChangePaginationValues($event)">
</app-pagination>
</div>
<div class="col-md-5">
<div class="divPageSizeType">
    <select id="ddlPageSize" (change)="onPageSizeValueChange($event)"
    [(ngModel)]="selectedPageSize"
     class="form-select form-control"
     [disabled]="pageDropdownDisable">
        <option value="" selected disabled>Select your option</option>
        <option *ngFor="let pages of pageSizeListValues" [value]="pages.key">
{{pages.value}}
</option>
      </select>
    </div>
</div>
</div>
<ng-template #popContent>
  <div class="filter-container">
    <div class="filter-body">
      <div
        class="row sort-row"
        (click)="sortTableOnFilterModal(targetField, 'asc')">
          <i class="fas fa-sort-alpha-down sort-icon"></i>
          <span>A on Top</span>
      </div>
      <div
        class="row sort-row"
        (click)="sortTableOnFilterModal(targetField, 'desc')">
          <i class="fas fa-sort-alpha-down-alt sort-icon"></i>
          <span>Z on Top</span>
      </div>
      <hr>
      <div class="row">
        <div
          [ngClass]="{'clear-filter-container-enable': isFilterApply(targetField)}"
          (click)="clearFilters()">
            <i class="fas fa-filter filter-icon"></i>
            <span
              class="filter-icon-span"
              [ngClass]="{'filter-icon-span-enable': !isFilterApply(targetField)}">
                Clear Filters from Value
            </span>
        </div>
      </div>
      <div *ngFor="let value of fieldOptionValues"
        class="row">
        <label class="checkbox-label">
          <input
            type="checkbox"
            class="checkbox-option-field"
            id="{{value}}"
            [checked]="isChecked(value)"
            (click)="onClickFilterCheckbox($event)">
          {{value}}
        </label>
      </div>
    </div>
  </div>
</ng-template>
</div>  
</div>
  <app-add-content-popup  (SaveAndUpdateContentDetails)="SaveAndUpdateContentDetails($event)" (CloseContentDetails)="CloseContentDetails($event)" [TableName]="selectedTableName" [ContentType]="selectedContentType" [initialContentManagementList]="initialCompareContentManagementList"></app-add-content-popup>
  <app-audit-trail [TableName]="selectedTableName" [ContentType]="selectedContentType" (CloseAuditDetails)="CloseAuditDetails($event)" ></app-audit-trail>
  <app-notify-popup (addNotifyDetails)="addNotifyDetails($event)" (deleteConfirmDetails)="deleteConfirmDetails($event)" (activateConfirmDetails)="activateConfirmDetails($event)" [UserCurrentAction]="UserCurrentAction"></app-notify-popup>
