import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { SearchState } from "../models/userManagement-search";
import { UserManagementItem } from "../models/userManagementItem";
import { UserManagementSort } from "../models/userManagement-sort";
import { UserManagementTableFilterItem } from "../models/userManagement-table-filter-item";
import * as moment from 'moment';
import { AppSettings } from "src/shared/app-settings";
@Injectable()
export class ContentService {
 
  private header = new HttpHeaders({ 'content-type': 'application/json' });
  constructor(private myhttp: HttpClient) { }

  GetUserGroups(groupID: any, ascedingOrder: any): Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement)
    const url = envURL + '/api/UserGroups/GetDataByFormGroupID?groupID=' + groupID + '&ascedingOrder=' + ascedingOrder;
    return this.myhttp.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }
  GetPeoplepicker(term: any): Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement)
    const url = envURL + '/api/UserGroups/GetProfiles?criteria=' + term + '&accessToken=' + localStorage.getItem("accessToken");
    return this.myhttp.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }
  GetFormTypes(): Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement)
    const url = envURL + '/api/FormType/GetFormList';
    return this.myhttp.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }
  GetIndustryRepresentativeGroups(): Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.CrossBorder)
    const url = envURL + '/api/CrossBorderIndustries/GetList';
    return this.myhttp.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }
  GetFormGroups(formID: any): Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement)
    const url = envURL + '/api/FormGroup/GetFormGroup?formID=' + formID;
    return this.myhttp.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }
  DeleteUser(partitionKeyValue: any): Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement)
    const url = envURL + '/api/UserGroups/Delete?partitionKeyValue=' + partitionKeyValue;
    return this.myhttp.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }
  AddUser(data: any): Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement)
    const url = envURL + '/api/UserGroups/Add';
    return this.myhttp.post(url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
        , 'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }
  GetUserRoleGroups(userId: any): Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.RoleManagement)
    const url = envURL + '/api/UserGroups/GetUserGroupsByEmail?email=' + userId;
    return this.myhttp.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }

  GetContentDetails(appendedURL: any,queryStringParam:any=''): Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.CrossBorderDashboard)
    let url = envURL + '/' + appendedURL + '/CM_GetList';
    if(queryStringParam!="")
    {
        url = url + queryStringParam;
    }
    return this.myhttp.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }
  AddContentDetails(appendedURL: any, data: any): Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.CrossBorderDashboard)
    const url = envURL + '/' + appendedURL + '/CM_Add';
    return this.myhttp.post(url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
        , 'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }
  DeleteContentDetails(appendedURL: any, partitionKeyValue: any): Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.CrossBorderDashboard)
    const url = envURL + '/' + appendedURL + '/CM_Delete?partitionKeyValue=' + partitionKeyValue;
    return this.myhttp.delete(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })

  }
  DeleteAdditionalDocType(appendedURL: any, partitionKeyValue: any,documentType:any,formID:any): Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.CrossBorderDashboard)
    const url = envURL + '/' + appendedURL + '/CM_Delete?partitionKeyValue=' + partitionKeyValue+
    "&&documentType="+documentType+"&&formID="+formID;
    return this.myhttp.delete(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })

  }
  UpdateContentDetails(appendedURL: any, data: any): Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.CrossBorderDashboard)
    const url = envURL + '/' + appendedURL + '/CM_Update';
    return this.myhttp.post(url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
        , 'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }

  ContentUploadDetails(data: any): Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.DocumentManager)
    const url = envURL +'/api/DocumentManager/UploadFile';
    return this.myhttp.post(url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
        , 'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }
  isDocumentEncrypted(data: any): Observable<any> {
    // const url = environment.apiUrl + '/api/DocumentManager/UploadFile';
     const url = AppSettings.docEncryptionUrl;
     return this.myhttp.post(url, data, {})
   }
  ContentUploadPresignedURLDetails(data: any,file:any): Observable<any> {
    const url = data.Url;
    return this.myhttp.put(url, file, {
      headers: new HttpHeaders({
        'Content-Type' : 'binary/octet-stream',
        'x-amz-server-side-encryption': 'aws:kms',
        'x-amz-server-side-encryption-aws-kms-key-id':data.KMSArn
      })
    })
  }
  
  ContentDeleteUploadDetails(data: any): Observable<any> {
    var envURL = AppSettings.GetEnvironmentURL(environment.environmentType,environment.DocumentManager)
    const url = envURL+'/api/DocumentManager/DeleteFile?formID=' + data.ID+'&filename='+data.Name;
    return this.myhttp.delete(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem("idToken")
      })
    })
  }
  getAppendURL(table: string) {
    let url: any = "";
    switch (table) {
      case "ValidationFieldsCrossBorder":
        return url = "api/CrossBorderValidationFields";
        break;
      case "CrossBorderTemplates":
        return url = "api/CrossBorderTemplates";
        break;
      case "CrossBorderIndustries":
        return url = "api/CrossBorderIndustries";
        break;
      case "CrossBorderDocumentTypes":
        return url = "api/CrossBorderDocumentTypes";
        break;
      case "CrossBorderCountries":
        return url = "api/CrossBorderCountries";
        break;
      case "CrossBorderForm":
        return url = "api/CrossBorderForm";
        break;
      case "CrossBorderAdditionalDocumentTypes":
        return url = "api/CrossBorderAdditionalDocumentTypes";
        break;
      case "CrossBorderApprovalReviewStatus":
        return url = "api/CrossBorderApprovalReviewStatus";
        break;
      case "CrossBorderAttachments":
        return url = "api/CrossBorderAttachments";
        break;
      case "CrossBorderComments":
        return url = "api/CrossBorderComments";
        break;
      case "CrossBorderRecommendedTemplates":
        return url = "api/CrossBorderRecommendedTemplates";
        break;
      case "CrossBorderRedLineAttachments":
        return url = "api/CrossBorderRedLineAttachments";
        break;
      case "UserGroups":
        return url = "api/UserGroups";
        break;
        case "FormGroups":
          return url = "api/FormGroup";
          break;
        case "FormType":
          return url = "api/FormType";
          break;
        case "AppConfigurations":
          return url = "api/AppConfigurations";
          break;

      default:
        return "";
        break;
    }
  }
}
