<div id="participation"
  [formGroup]="participationFormGroup"
  class="container-fluid border container-border-color container-section">
  <div class="row bg-title">
    <span class="title-section-print ">Participation</span>
  </div>
  <div id="participation-section" class="content-section">
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('participatingPartner')"
          [class.edited-externally]="editedExternally('participatingPartner')"
          [class.conflict-on-merge]="hasMergeConflict('participatingPartner')">
            Participating Partner
        </span>
        <span *ngIf="showAsterisk('participatingPartner')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8 common-width">
        <app-dropdown
          [optionsList]="participationPartnerOptions"
          [readonly]="isReadOnly('participatingPartner') || !hasPermission('AddSeries24')"
          [keySelected]="myDealFormGroup.get('participatingPartner')?.value"
          [userLeftDCF]="participationPartnerLeftDCF"
          (optionSelected)="onParticipatingPartnerSelection($event)">
        </app-dropdown>
      </div>
  </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('participatingManager')"
          [class.edited-externally]="editedExternally('participatingManager')"
          [class.conflict-on-merge]="hasMergeConflict('participatingManager')">
            Participating Manager
        </span>
        <span *ngIf="showAsterisk('participatingManager')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-peoplepicker
          [disabled]="participationFormGroup.get('participatingManager')?.disabled"
          [dealTeamTable]="true"
          [peoplePickerUserList]="participatingManager"
          [excludedUsers]="participatingManager"
          [readonly]="isReadOnly('participatingManager')"
          (selectedPeoplePickerUser)="onSelectedPeoplePickerUser($event, 'participatingManager')"
          (removedPeoplePickerUser)="onRemovedPeoplePickerUser($event, 'participatingManager')"
          [allowToDuplicateUser]="allowToDuplicateUser">
        </app-peoplepicker>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span
          [class.highlight-warning]="highlightRequiredField('derivedAtAddressSameClientAddres') ||
          highlightRequiredField('derivedAtAddress')"
          [class.edited-externally]="editedExternally('derivedAtAddressSameClientAddres') ||
          editedExternally('derivedAtAddress')"
          [class.conflict-on-merge]="hasMergeConflict('derivedAtAddressSameClientAddres') ||
          hasMergeConflict('derivedAtAddress')">
          Is Benefit Derived-at Address same as Client Address?
        </span>
        <span *ngIf="showAsterisk('derivedAtAddressSameClientAddres')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="participationFormGroup.get('derivedAtAddressSameClientAddres')?.value"
          [readonly]="isReadOnly('derivedAtAddressSameClientAddres')"
          (handleToggleYesNo)="onDerivedAtAddressSameClientAddresSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label"></div>
      <div class="col-xs-8">
        <input
          formControlName="derivedAtAddress"
          class="input-data-text full-width section-input dependent-field-input"
          [class.readonly]="isReadOnly('derivedAtAddress')"
          type="text"
          autocomplete="off"
          [placeholder]='participationFormGroup.get("derivedAtAddressSameClientAddres")?.value === "NO" ? "Please enter Benefit Derived-at Address" : "N/A"'>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('consultingMarket')"
          [class.edited-externally]="editedExternally('consultingMarket')"
          [class.conflict-on-merge]="hasMergeConflict('consultingMarket')">
            Consulting Market/Sector Offering
        </span>
        <span *ngIf="showAsterisk('consultingMarket')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <input
          formControlName="consultingMarket"
          class="input-data-text full-width section-input"
          [class.readonly]="isReadOnly('consultingMarket')"
          type="text"
          autocomplete="off">
      </div>
      </div> -->
  </div>
</div>

