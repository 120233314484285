export class NewHireFormItem{
    lastName: string;
    firstName: string;
    dateOfHire: string;
    supervisoryBranchOffice:string;
    NonRegisteredBranchOffice:string;
    NonRegisteredBranchOfficeComments:string;
    series24BranchSupervisor:string;
    series24BranchSupervisorNotified:string;
    series24BranchSupervisorNotifiedComments:string;
    returningHire:string;
    additionalCommentsNewHireInfo:string;
    welcomeEmail:string;
    welcomeEmailComments:string;
    backgroundCheck:string;
    backgroundCheckComments:string;
    deloitteApplication:string;
    deloitteApplicationComments:string;
    LexisNexis:string;
    LexisNexisComments:string;
    socialmediaCheck:string;
    socialmediaCheckComments:string;
    ActiveDirectory:string;
    ActiveDirectoryComments:string;
    KnowledgeCenter:string;
    KnowledgeCenterComments:string;
    DFlowPermissionsSpreadsheet:string;
    DFlowPermissionsSpreadsheetComments:string;
    SupervisoryOrgChart:string;
    SupervisoryOrgChartComments:string;
    CSI:string;
    CSIComments:string;
    Quest:string;
    QuestComments:string;
    additionalCommentsOnboardingDocumentation:string;
    bigEmailOrHardcopy:string;
    bigEmailOrHardcopyComments:string;
    fingerprintsClearedWithFINRA:string;
    fingerprintsClearedWithFINRAComments:string;
    FinraLicense:string;
    BrokerCheck:string;
    BrokerCheckComments:string;
    FormU5:string;
    FormU5Comments:string;
    FinraCEInactive:string;
    OutstandingFinraCE:string;
    additionalCommentsFinraLicenses1:string;
    finraLicenses:string;
    otherFinraLicense:string;
    studyMaterialsOrdered:string;
    studyMaterialsOrderedComments:string;
    initialFormU4NRFFilingComplete:string;
    initialFormU4NRFFilingCompleteComments:string;
    statutorilyDisqualified:string;
    statutorilyDisqualifiedComments:string;
    stateRegistrationMemoSent:string;
    stateRegistrationMemoSentComments:string;
    additionalCommentsFinraLicenses2:string;
    TheEmployeeAddedSupervision:string;
    TheEmployeeAddedSupervisionComment:string;
    ConfirmedCommunications:string;
    ConfirmedCommunicationsComment:string;
    DeloitteMobileSmarsh:string;
    DeloitteMobileSmarshComment:string;
    DeloittePhoneNumber:string;
    NAStatus:string;
    additionalCommentsCommunication:string;
    BrokerageAccount:string;
    BrokerageAccountComment:string;
    OBAdisclosure:string;
    OBAdisclosureComment:string;
    PSTdisclosure:string;
    PSTdisclosureComment:string;
    additionalCommentsBrokarage:string;
    CSIAttestations:string;
    CSIAttestationsComment:string;
    NewhireQuestCE:string;
    NewhireQuestCEComment:string;
    dateOfComplianceTraining:string;
    additionalCommentsComplianceTraining:string;
    RegStatus: string;
    RegisteredStatus:string;
    NRFStatus:string;
    otherNRFStatusReason:string;
    LegalHold:string;
    LegalHoldComment:string;

}
