<div id="client-overview" [formGroup]="clientOverviewFormGroup"
    class="container-fluid border container-border-color container-section">
    <div class="row bg-title">
        <span class="title-section-print">Client Overview</span>
    </div>
    <div class="content-section">       
        <div class="row">
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('clientType')"
                    [class.edited-externally]="editedExternally('clientType')"
                    [class.conflict-on-merge]="hasMergeConflict('clientType')">
                    Client Type
                </span>
                <span *ngIf="showAsterisk('clientType')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-dropdown [optionsList]="clientTypeOptions" [keySelected]="myDealFormGroup.get('clientType')?.value"
                    [readonly]="isReadOnly('clientType')" (optionSelected)="onClientTypeSelection($event)">
                </app-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('clientLegalName')"
                    [class.edited-externally]="editedExternally('clientLegalName')"
                    [class.conflict-on-merge]="hasMergeConflict('clientLegalName')">
                    Client Legal Name
                </span>
                <span *ngIf="showAsterisk('clientLegalName')" class="red-asterik"></span>
                <app-popover [tooltipText]="getTooltipText('ClientLegalName')">
                </app-popover>
            </div>
            <div class="col-xs-8">
                <input [formControl]="$any(myDealFormGroup).get('clientLegalName')"
                    class="input-data-text full-width section-input" [class.readonly]="isReadOnly('clientLegalName')"
                    type="text" autocomplete="off" maxlength="255">
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('doingBusinessAs')"
                [class.edited-externally]="editedExternally('doingBusinessAs')"
                [class.conflict-on-merge]="hasMergeConflict('doingBusinessAs')">Doing Business As</span>
                <span *ngIf="showAsterisk('doingBusinessAs')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <input formControlName="doingBusinessAs" class="input-data-text full-width section-input"
                    [class.readonly]="isReadOnly('doingBusinessAs')" type="text" autocomplete="off" maxlength="255">
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('streetAddress')"
                [class.edited-externally]="editedExternally('streetAddress')"
                [class.conflict-on-merge]="hasMergeConflict('streetAddress')">Street Address</span>
                <span *ngIf="showAsterisk('streetAddress')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <input [formControl]="$any(myDealFormGroup).get('streetAddress')"
                    class="input-data-text full-width section-input" [class.readonly]="isReadOnly('streetAddress')"
                    type="text" autocomplete="off" maxlength="255">
            </div>
        </div>        
        <div class="row">
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('city')"
                [class.edited-externally]="editedExternally('city')"
                [class.conflict-on-merge]="hasMergeConflict('city')">City</span>
                <span *ngIf="showAsterisk('city')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <input [formControl]="$any(myDealFormGroup).get('city')" class="input-data-text full-width section-input"
                    [class.readonly]="isReadOnly('city')" type="text" autocomplete="off" maxlength="255">
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('state')"
                [class.edited-externally]="editedExternally('state')"
                [class.conflict-on-merge]="hasMergeConflict('state')">State / Province / Region /
                    Country</span>
                <span *ngIf="showAsterisk('state')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <input [formControl]="$any(myDealFormGroup).get('state')" class="input-data-text full-width section-input"
                    [class.readonly]="isReadOnly('state')" type="text" autocomplete="off" maxlength="255">
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('postalCode')"
                [class.edited-externally]="editedExternally('postalCode')"
                [class.conflict-on-merge]="hasMergeConflict('postalCode')">Postal Code</span>
                <span *ngIf="showAsterisk('postalCode')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <input [formControl]="$any(myDealFormGroup).get('postalCode')" class="input-data-text full-width section-input"
                    [class.readonly]="isReadOnly('postalCode')" type="text" autocomplete="off" maxlength="255">
            </div>
        </div>
        <div class="row">
           <!--Telephone number renamed to MAIN COMPANY PHONE #:-->
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('telephone')"
                [class.edited-externally]="editedExternally('telephone')"
                [class.conflict-on-merge]="hasMergeConflict('telephone')">Main Company Phone #</span>
                <span *ngIf="showAsterisk('telephone')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-numeric [type]="'number'" [value]="myDealFormGroup.get('telephone')?.value"
                    [readonly]="isReadOnly('telephone')" (selectedValue)="onTelephoneSelection($event)">
                </app-numeric>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('leadClientServicePartner')"
                [class.edited-externally]="editedExternally('leadClientServicePartner')"
                    [class.conflict-on-merge]="hasMergeConflict('leadClientServicePartner')">Lead Client Service
                    Partner (LCSP)</span>
                <span *ngIf="showAsterisk('leadClientServicePartner')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-peoplepicker [peoplePickerUserList]="leadClientServicePartner"
                    [readonly]="isReadOnly('leadClientServicePartner')"
                    [dealTeamTable]="false"
                    (selectedPeoplePickerUser)="onSelectedPeoplePickerUser($event, 'leadClientServicePartner')"
                    (removedPeoplePickerUser)="onRemovedPeoplePickerUser($event, 'leadClientServicePartner')">
                </app-peoplepicker>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('leadBusinessPartner')"
                 [class.edited-externally]="editedExternally('leadBusinessPartner')"
                [class.conflict-on-merge]="hasMergeConflict('leadBusinessPartner')">Lead Business
                    Partner</span>
                <span *ngIf="showAsterisk('leadBusinessPartner')" class="red-asterik"></span>
                <app-popover [tooltipText]="getTooltipText('LeadBusinessPartnerId')">
                </app-popover>
            </div>
            <div class="col-xs-8">
                <app-peoplepicker [peoplePickerUserList]="leadBusinessPartner"
                    [readonly]="isReadOnly('leadBusinessPartner')"
                    [dealTeamTable]="false"
                    (selectedPeoplePickerUser)="onSelectedPeoplePickerUser($event, 'leadBusinessPartner')"
                    (removedPeoplePickerUser)="onRemovedPeoplePickerUser($event, 'leadBusinessPartner')">
                </app-peoplepicker>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 margin-top-5">
                <span [class.highlight-warning]="highlightRequiredField('globalLCSP')"
                [class.edited-externally]="editedExternally('globalLCSP')"
                [class.conflict-on-merge]="hasMergeConflict('globalLCSP')">Global LCSP</span>
                <span *ngIf="showAsterisk('globalLCSP')" class="red-asterik"></span>
                <app-popover [tooltipText]="getTooltipText('GlobalLCSPId')">
                </app-popover>
            </div>
            <div class="col-xs-8">
                <app-peoplepicker [peoplePickerUserList]="globalLCSP" [readonly]="isReadOnly('globalLCSP')"
                [dealTeamTable]="false"
                    (selectedPeoplePickerUser)="onSelectedPeoplePickerUser($event, 'globalLCSP')"
                    (removedPeoplePickerUser)="onRemovedPeoplePickerUser($event, 'globalLCSP')">
                </app-peoplepicker>
            </div>
        </div>          
        <!-- <div class="row">
            <div class="col-xs-4 section-label">
                <span [class.highlight-warning]="highlightRequiredField('industry')"
                [class.edited-externally]="editedExternally('industry')"
                [class.conflict-on-merge]="hasMergeConflict('industry')">Industry</span>
                <span *ngIf="showAsterisk('industry')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <app-dropdown [optionsList]="industryOptions" [keySelected]="myDealFormGroup.get('industry').value"
                    [readonly]="isReadOnly('industry')" (optionSelected)="onIndustrySelection($event)">
                </app-dropdown>
            </div>
        </div> -->
    </div>
</div>