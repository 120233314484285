export class TerminationFormItem{
    lastName:string;
    firstName:string;
    dateOfTermination:string;
    voluntaryTermination:string;
   // voluntaryTerminationReason:string;
    StatusofIndividual:string;
    RegisteredStatus:string;
  //  emailSent:string;
    NRFStatus:string;
    otherNRFStatusReason:string;
    series24LicensedSupervisor:string;
    TerminationReason:string;
    TerminationReasonComments:string;
    ThreatTeamContact:string;
    threatTeamContactReason:string;
    OpenInternalThreat:string;
    openInternalThreatReason:string;
    dateTheEmployeeRemoved:string;
   // jakiePolsonNotifiedDate:string;
   // jakiePolsonNotifiedDateNA:string
    AllowedToTermedDate:string;
   // allowedToTermedDateNA:string;
    PstToTermedClosedDeniedDate:string;
   // pstToTermedClosedDeniedDateNA:string;
    dateTheEmployeeRemovedDCFKnowledge:string;
    dateTheEmployeeRemovedFlowPerms:string;
    dateTheEmployeeRemovedFlowPermsReason:string;
    dateSupportTicketOpened:string;
    //dateSupportTicketOpenedNA:string;
    dateSupportTicketOpenedReason:string;
    terminatedIndividualRemovedEmployee:string;
    terminatedIndividualRemovedEmployeeNA:string;
    terminatedIndividualRemovedEmployeeReason:string;
    dateTheEmployeeRetired:string;
    SmarshEmail:string;
    SmarshEmailComment:string;
    RetiredFromQuestCE:string;
    RetiredFromQuestCEComment:string;
    ZipToDcfArchive:string;
    ZipToDcfArchiveComment:string;
    LinkedInRemovedFromSmarsh:string;
    LinkedInRemovedFromSmarshComment:string;
    dateTheEmployeeRemovedSupervision:string;
    DateTheEmployeeDeloitteMobile:string;
    //dateTheEmployeeDeloitteMobileNA:string;
    DateTheNewHiresTermedCompliance:string;
    DateTheNewHiresTermedComplianceNA:string;
    DateTheNewHiresTermedComplianceReason:string;
    DateTheEmployeeRemovedSupervisorOrganization:string;
    notifiedBrokerageFirmsDate:string;
   // notifiedBrokerageFirmsDateNA:string;
    SentSignedCopy:string;
    NRF:string;
    SentSignedCopyOtherText:string;
    trackingNumber:string;
    ShippingMethodUsed:string;
    SignatureDate:string;
    Created:string;
    CreatedBy:string;
    Modified:string;
    ModifiedBy:string;
    AdditionalComments:string;
    // After changing the model, we need to update the form model as well for toggle buttons.
    JakiePolsonNotifiedDate:string;
    JakiePolsonNotifiedDateComment:string;
    AllowedToTermedDateComment:string;
    PstToTermedClosedDeniedDateComment:string;
    dateTheEmployeeRemovedDCFKnowledgeComment:string;
    dateTheEmployeeRemovedFlowPermsComment:string;
    dateSupportTicketOpenedComment:string;
    dateTheEmployeeRetiredComment:string;
    dateTheEmployeeRemovedComment:string;
    dateTheEmployeeRemovedSupervisionComment:string;
    DateTheEmployeeDeloitteMobileComment:string;
    DateTheEmployeeRemovedSupervisorOrganizationComment:string;
    notifiedBrokerageFirmsDateComment:string;
    voluntaryTerminationDischarged:string;
    voluntaryTerminationPermittedToResign:string;
    voluntaryTerminationOther:string;



}
