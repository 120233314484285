import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgbdSortableHeaderDirective, SortDirection, SortEvent } from 'src/shared/directives/sorteable.directive';
import { PaginationComponent } from 'src/shared/components/pagination/pagination.component';
import { DropdownItem } from 'src/shared/models/dropdown-item';
import { PaginationValues } from 'src/shared/models/pagination-values';
import { AuditManagementSort } from '../../models/auditManagement-sort';
import { AuditManagementTableFilterItem } from '../../models/auditManagement-table-filter-item';
import { AuditManagementItem } from '../../models/auditManagementItem';
import { DatePipe } from '@angular/common';
import { AuditTableFilterItem } from '../../models/audit-table-filter-item';
import { AuditService } from 'src/app/services/audit.service';
import { DateService } from 'src/app/services/date.service';
import { AdminService } from 'src/app/services/admin.service';
import { SpinnerService } from 'src/shared/services/spinner.service';
import { CommonHelperService } from 'src/app/commonHelper/common-helper.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-audit-trail-user-management',
  templateUrl: './audit-trail-user-management.component.html',
  styleUrls: ['./audit-trail-user-management.component.scss']
})
export class AuditTrailUserManagementComponent implements OnInit {
  contentTypes:any=[];  
  formTypes:any=[];
  formTypeValues:any=[];
  AuditReportData: Array<any> = [];
  selectedTableName:any;
  selectedContentType:any;
  formType:any;
  formTypeValue:any;
  displayStyle = "none";
  @Input() TableName: string;
  @Input() ContentType: string;
  @Input() FormType: string;
  @Input() FormTypeValue: string;
  @Output() CloseAuditDetails: EventEmitter<any> = new EventEmitter<any>();

  selectedPageSize:any;
    auditManagements: Array<AuditManagementItem>;
    auditManagementPerPage : Array<AuditManagementItem>;
    
    filterList: Array<AuditManagementTableFilterItem> = [];
    targetField: string;
    targetFilter:any;
    fieldOptionValues: Array<string> = [];
    initialAuditManagementList: Array<AuditManagementItem> = [];
    pageSizeListValues: Array<any> = [];
    @ViewChildren(NgbdSortableHeaderDirective) headers: QueryList<NgbdSortableHeaderDirective>; 
    @ViewChild('pagination', {static: false}) pagination: PaginationComponent;
    auditManagementExportToExcel:Array<any>=[];
    auditManagementPageDetails:Array<any>=[];
    showDisable:boolean;
    pageDropdownDisable: boolean;
  constructor(public auditService:AuditService,public dateService:DateService,public adminService:AdminService,
    public commonHelperService: CommonHelperService, private spinnerService:SpinnerService) { }

  ngOnInit(): void {
    this.spinnerService.showAutoSaveSpinner();
    this.LoadDefaultValues();
    this.spinnerService.hideAutoSaveSpinner();
  }
  LoadDefaultValues() {
    this.contentTypes.push({
      'Id': 1,
      'Name': this.ContentType
    })
    this.formTypes.push({
      'Id': 1,
      'Name': this.FormType
    })
    this.formTypeValues.push({
      'Id': 1,
      'Name': this.FormTypeValue
    })
    this.selectedContentType = this.ContentType;
    this.selectedTableName = this.TableName
    this.formType=this.FormType;
    this.formTypeValue=this.FormTypeValue;
  }
  closePopup() {
    this.ApplyBrowserScroll();
    this.CloseAuditDetails.emit("true");
    this.displayStyle = "none";
  }
  openPopup(operationType:any) {
    this.spinnerService.showAutoSaveSpinner();
   document.body.style.overflow="hidden";
    this.LoadDefaultValues();
    this.clearAllFilters(true);
    this._resetSortIcons();
    this.getAuditDetails();
    this.displayStyle = "block";
    this.clearText();
      
  }
  clearText(){

  }
  ApplyBrowserScroll(){
    document.body.style.overflow="auto";
  }
  
  getAuditDetails() {
    this.spinnerService.showAutoSaveSpinner();
    this.auditService.GetAuditDetailsforUserManagement(this.formType).subscribe(
      {
        next: (result: any) => {
          result.forEach((v: any) => {
            // if (v.ModifiedBy != "" || v.ModifiedBy != undefined) {
            //   let userName = v.ModifiedBy.split(' ');v.ModifiedBy="";
            //   userName.forEach((element:any) => {
            //     v.ModifiedBy=v.ModifiedBy+element+", ";
            //   });
            //   v.ModifiedBy=v.ModifiedBy.slice(0,-2);
            // }
            if(v.Description!=null)
            v.Description=v.Description.includes("#;")?this.formatDescription(v.Description):v.Description;
            v.ModifiedOn=v.ModifiedOn==null?'-':this.dateService._FormatDateAuditUM(v.ModifiedOn);
          });
          this.initialAuditManagementList = result;
          this.setAuditManagement(this.initialAuditManagementList, this.pageSizeListValues);
        },
        error: (err: any) => {
          this.spinnerService.hideAutoSaveSpinner();
          // this.duplicateUserRestrictFlag=false;
          console.log("Error" + err)
          if (err.status == 403 && err.error.stringKey=="tokenexpired") { this.commonHelperService.isTokenExpired(); }
        }
      })
  } 
 
  formatDescription(desc:any){
   return desc=desc.split("#;").join("\n");
  }
  
  //custom logic
  onChangePaginationValues(paginationValues: PaginationValues) {
    this.commonHelperService.state.page = paginationValues.page;
    this.commonHelperService.state.pageSize = paginationValues.pageSize;
    this._setUsersToCurrentPage();
    this.setMultiSelectedCheckBoxPages(paginationValues.page,paginationValues.pageSize);
    //Suvishna Commented
    //this.setMultiSelectedCheckBoxPages(paginationValues.page,paginationValues.pageSize);
    // When selected the multiple selected text box,
    //  only the forms specific to the particular page must be selected,
    //   even if the items per page increased, 
    //   it should select only the forms in that relevant page.
    //    If the user navigates to the other page , 
    //    no forms should have to be selected until that page multi select is clicked .
    //     Until the relevant page is not deselected , 
    //     it should remain selected only.
    //      The following selected files should be able to exported in the excel sheet

}
private setMultiSelectedCheckBoxPages(pageNumber:any,pageSize:any) {
 
  if(this.auditManagementPageDetails.length>0)
  {
    if(this.auditManagementPageDetails.find(s => s.PageSize!=pageSize && s.IsMultiSelect==true))
    {
      this.auditManagementExportToExcel.length=0;
    }
    this.auditManagementPageDetails.forEach(s => {
      if(s.IsMultiSelect==true && s.PageSize!=pageSize)
      {
        s.PageSize=pageSize
        const page = s.PageNumber;
        const pageSize1 = s.PageSize;
        this.auditManagementPerPage =
        this.auditManagements.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
        this.auditManagementPerPage.forEach(Item => {
          if(!this.auditManagementExportToExcel.find(e => e.ID == Item.ID))
          {
            let auditDashboardItem = {
              ID:Item.ID,
              ModifiedBy: Item.ModifiedBy,
              Status:Item.Status,
              ModifiedOn:Item.ModifiedOn,
              TableName:Item.TableName,
              ContentType:Item.ContentType,
              Description:Item.Description
              
            };
          this.auditManagementExportToExcel.push(auditDashboardItem);
          }
    });
      }
       
    });
    this._setUsersToCurrentPage();
  }
  const checkbox = document.getElementById(
    'chk_MultiCheckBox',
  ) as HTMLInputElement | null;
 // checkbox.checked = false;
  if(this.auditManagements.find(e => e.Status == true))
  {
  this.auditManagementPageDetails.forEach(v => {
    if(v.IsMultiSelect==true && v.PageNumber==pageNumber)
    {
      //checkbox.checked = true;
    }
  });
}

}
private _setUsersToCurrentPage() {
    const page = this.commonHelperService.state.page;
    const pageSize = this.commonHelperService.state.pageSize;
    this.auditManagementPerPage =
      this.auditManagements.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
}
setAuditManagement(
  auditList: Array<AuditManagementItem>,
  pageSizeListValues: Array<any>) {
    pageSizeListValues = [{ id: 0, value: "All" },{ id: 1, value: 10 }, { id: 2, value: 25 }, { id: 3, value: 50 }, { id: 4, value: 100 }];
    let filteredauditsList = this._filterClosedDeals(auditList);
    this.clearAllFilters(true);
   // this.initialauditDashboardList = auditDashboardList;
    this.commonHelperService.state.sortData ={sortColumn: 'ModifiedOn', sortDirection: 'desc'};
    this.commonHelperService.state.page = 1;
    this.commonHelperService.state.pageSize = 25;
       const sortData = this.commonHelperService.state.sortData;
    const filterData = this.commonHelperService.state.filterDataList;
    filteredauditsList = this._getEngagementsMatchsFilterCriteria(filteredauditsList, filterData);
    this.auditManagements = this.commonHelperService.sort(
        sortData.sortColumn, sortData.sortDirection, auditList);
    this._updateSortArrow(sortData.sortColumn, sortData.sortDirection);
    this._setUsersToCurrentPage();
    this._setPaginationValues(pageSizeListValues);
   // this.duplicateUserRestrictFlag=false;
    this.pageDropdownDisable = auditList.length > 0 ? false : true;
    this.spinnerService.hideAutoSaveSpinner();
}
private _getEngagementsMatchsFilterCriteria(
  AuditDashboard: Array<AuditManagementItem>,
  filterList: Array<AuditTableFilterItem>): Array<AuditManagementItem> {
  let newauditDashboardList: Array<AuditManagementItem> = [];
  if (filterList.length > 0) {
    AuditDashboard.forEach(eng => {
      if (this._isEngagementMatchFilterCriteria(eng, filterList)) {
        newauditDashboardList.push(eng);
      }
    });
  } else {
    newauditDashboardList = AuditDashboard;
  }
  return newauditDashboardList;
}
private _isEngagementMatchFilterCriteria(
  AuditDashboard: any,
  filterList: Array<AuditTableFilterItem>): boolean {
  let match = true;
  filterList.forEach(filter => {
    const field = filter.field;
    const values = filter.valueList;
     if (values.length && !values.find((v:any) => v === AuditDashboard[field].toString())) {
      
      match = false;
     // return false;
    }
  });
  return match;
}
private _filterClosedDeals(auditsList: Array<AuditManagementItem>): Array<AuditManagementItem> {
  let filteredauditsList;
  filteredauditsList = auditsList;
  
  return filteredauditsList;
}
private _updateSortArrow(sortColumn: string, sortDirection: SortDirection) {
 //this._resetSortIcons(sortColumn);
  //this._showSortDirectionArrow(sortColumn, sortDirection);
}
private _setPaginationValues(values:any) {
  
  this.pageSizeListValues = values.map((element: any) => {
    const parseValue = element.value.toString();
    const dropdownItem: DropdownItem = {
      key:'',
      value:''
    };
    if(parseValue=="All"){
       dropdownItem.key=parseValue,
       dropdownItem.value=parseValue+' Items'
    
    }
    else{
      dropdownItem.key=parseValue,
      dropdownItem.value=parseValue+' items per page'
  }
    return dropdownItem;
  });
  this.selectedPageSize =  this.commonHelperService.state.pageSize!=CommonHelperService.pageSizeDefault?this.commonHelperService.state.pageSize:CommonHelperService.pageSizeDefault;
  
}
onPageSizeValueChange(event:any){
if(this.selectedPageSize=="All")
{
const values: PaginationValues = {
  page: this.commonHelperService.state.page,
  pageSize: parseInt(this.initialAuditManagementList.length.toString())
};
this.onChangePaginationValues(values);
}
else{
const values: PaginationValues = {
  page: this.commonHelperService.state.page,
  pageSize: parseInt(this.selectedPageSize)
};
this.onChangePaginationValues(values);
}
}
sortTable({column}: SortEvent) {
  this.auditManagementPageDetails.length=0;
  const newDirection = this._getSortDirection(column);
  this._resetSortIcons(column);
  this.auditManagements = this.commonHelperService.sort(column, newDirection, this.auditManagements);
  this._setUsersToCurrentPage();
  this._setSortOptions(column, newDirection);
  this.showDisable=true;
  this.auditManagementExportToExcel.length=0;
  const checkbox = document.getElementById(
    'chk_MultiCheckBox',
  ) as HTMLInputElement | null;
  //checkbox.checked = false;

}
private _getSortDirection(column: string): SortDirection {
  const header = this._getHeader(column);
  return header.direction === 'asc' ? 'desc' : 'asc';
}
isFilterApply(field: string) {
  let filterApply = false;
  this.filterList.forEach(filter => {
    if (filter.field === field && filter.valueList.length) {
      filterApply = true;
    }
  });
  return filterApply;
}
  clearFilters(){
      if (this.targetFilter) {
          this.targetFilter.valueList = [];
          this._updateUsersList();
          this.showDisable=true;
      this.auditManagementExportToExcel.length=0;
      const checkbox = document.getElementById(
        'chk_MultiCheckBox',
      ) as HTMLInputElement | null;
      //checkbox.checked = false;
    
    this.auditManagementPageDetails.length=0
        }
  }
  clearAllFilters(FromSearchForm:any) {
      this._resetSortIcons();
      this.filterList = [];
      this.targetField = "";
      this.auditManagementExportToExcel.length = 0;
      this.auditManagementPageDetails.length=0;
      const checkbox = document.getElementById(
        'chk_MultiCheckBox',
      ) as HTMLInputElement | null;
     // checkbox.checked = false;
      this.showDisable=true;
     
      if(FromSearchForm==true)
      {
        this.commonHelperService.searchTerm = '';
      }
      this.commonHelperService.state.sortData ={sortColumn: 'fullName', sortDirection: 'asc'};
      
      this.commonHelperService.filterDataList = [];
      this.commonHelperService.state.page = 1;
      this.commonHelperService.state.pageSize = 25;
      // this.showDealClosedState.emit(false);
     // this.currentEngagementList = this._filterClosedDeals(false, this.initialEngagementsList);
      this.auditManagements = this._filterClosedDeals(this.initialAuditManagementList);
      this._updateSortArrow(this.commonHelperService.state.sortData.sortColumn, this.commonHelperService.state.sortData.sortDirection);
      this._setUsersToCurrentPage();
      this.auditManagements.sort((a, b) => new Date(b.ModifiedOn).getTime() - new Date(a.ModifiedOn).getTime());
  }
  private _updateUsersList() {
      this.auditManagements = this._isSomeFilterApply() ?
        this._getUserMatchsFilterCriteria(this.initialAuditManagementList, this.filterList) :
        this.initialAuditManagementList;
  
      const sortData = this.commonHelperService.state.sortData;
      this.auditManagements = this.commonHelperService.sort(
        sortData.sortColumn, sortData.sortDirection, this.auditManagements);
      this._setUsersToCurrentPage();
    }
  openFilterPopover(field: string) {
      this.targetField = field;
      this.targetFilter = this._getFilter(field);
      this.fieldOptionValues = this._mapUserListWithFilterList(this.initialAuditManagementList, field);
  }
  audit:any;
  private _getFilter(field: string): any {
    
    // if(this.filterList.length){
    //   this.audit=this.filterList.filter(f => f.field === field)[0];
    // }
    // return this.audit;
      return this.filterList.length ? this.filterList.filter(f => f.field === field)[0] : null;
  }
  private _mapUserListWithFilterList(
      listaudits: Array<AuditManagementItem>,
      field: string): Array<string> {
      const arrayOptionsList:any=[];
      const sortData = this.commonHelperService.state.sortData;
      listaudits.forEach((audit:any) => {
        const value = audit[field];
        if (this._isValidValue(arrayOptionsList, value, field, listaudits)) {
          arrayOptionsList.push(audit[field]);
        }
      });

      if(field==="FormID"){
        if(sortData.sortColumn===field && sortData.sortDirection==="asc")
          return arrayOptionsList.sort((a: number ,b: number) =>  a-b);
        return arrayOptionsList.sort((a: number ,b: number) =>  b-a);
      }

      if(field==="ModifiedOn"){
        if(sortData.sortColumn===field && sortData.sortDirection==='asc')
          return arrayOptionsList.sort((a: string ,b: string) => new Date(a).getTime()-new Date(b).getTime());
        return arrayOptionsList.sort((a: string ,b: string) => new Date(b).getTime()-new Date(a).getTime());
      }
  
      if(sortData.sortColumn===field && sortData.sortDirection==="desc")
        return arrayOptionsList.sort((a : string, b : string) => b.toUpperCase().localeCompare(a.toUpperCase()));;
      return arrayOptionsList.sort((a : string, b : string) => a.toUpperCase().localeCompare(b.toUpperCase()));

  }
  private _isValidValue(
      arrayOptionsList: Array<string>,
      value: string,
      field: string,
      listaudits: Array<AuditManagementItem>): boolean {
        return (value !== '' && value !== null &&
              !this._inArray(arrayOptionsList, value) &&
              this._existUserForValue(listaudits, field, value));
  }
  private _inArray(optionFields: Array<string>, option: string): boolean {
      return Boolean(optionFields.find(o => o === option));
  }
  private _existUserForValue(
      listaudits: Array<AuditManagementItem>,
      field: string,
      value: string): boolean {
      const filterArrayClone = [...this.filterList];
      const filterRemovedCurrentTarget = filterArrayClone.filter(f => f.field !== field);
      const filteredValues = this._getUserMatchsFilterCriteria(listaudits, filterRemovedCurrentTarget);
      const searchValue = filteredValues.find((v:any) => v[field] === value) ? true : false;
      return searchValue;
  }
  private _getUserMatchsFilterCriteria(
      audits: Array<AuditManagementItem>,
      filterList: Array<AuditManagementTableFilterItem>): Array<AuditManagementItem> {
      let newauditList: Array<AuditManagementItem> = [];
      if (filterList.length > 0) {
          audits.forEach(rep => {
          if (this._isUserMatchFilterCriteria(rep, filterList)) {
              newauditList.push(rep);
             
          }
        });
      } else {
          newauditList = audits;
      }
      return newauditList;
  }
  private _isUserMatchFilterCriteria(
      audit: any,
      filterList: Array<AuditManagementTableFilterItem>): boolean {
      let match = true;
      filterList.forEach((filter:any) => {
        const field = filter.field;
        const values = filter.valueList;
        if (values.length && !values.find((v:any) => v === audit[field].toString())) {
        
          match = false;
         // return false;
        }
      });
      return match;
  } 
  sortTableOnFilterModal(column: string, direction: SortDirection) {
      this._resetSortIcons(column);
      this._showSortDirectionArrow(column, direction);
      this._setUsersToCurrentPage();
      this.auditManagements = this.commonHelperService.sort(column, direction, this.auditManagements);
      this._setSortOptions(column, direction);
  }
  private _setSortOptions(column: string, direction: SortDirection) {
      const sortItem: AuditManagementSort = {
        sortColumn: column,
        sortDirection: direction
      };
      this.commonHelperService.sortData = sortItem;
  }
  private _resetSortIcons(column?: string) {
      this.headers.forEach(header => {
        if ((column && header.sortable !== column) || !column) {
          header.direction = '';
        }
      });
  }
  private _showSortDirectionArrow(sortColumn: string, sortDirection: SortDirection) {
      const header = this._getHeader(sortColumn);
      header.direction = sortDirection;
      header.rotate();
  }
  isChecked(value: string): boolean {
      let checked = false;
      if (this.targetFilter) {
        this.targetFilter.valueList.forEach((v:any) => {
          if (v === value.toString()) {
            checked = true;
          }
        });
      }
      return checked;
  }
  private _getHeader(column: string): NgbdSortableHeaderDirective {
      return this.headers.filter(h => h.sortable === column)[0];
  }
  onClickFilterCheckbox(event:any) {
      const checkbox = event.currentTarget;
      const valueSelected = checkbox.id;
      const isChecked = checkbox.checked;
  
      this._updateFilterList(valueSelected, isChecked);
      this._updateUserList();
  }
  private _updateUserList(){
          this.auditManagements = this._isSomeFilterApply() ?
            this._getUserMatchsFilterCriteria(this.initialAuditManagementList, this.filterList) :
            this.initialAuditManagementList;
      
          const sortData = this.commonHelperService.state.sortData;
          this.auditManagements = this.commonHelperService.sort(
            sortData.sortColumn, sortData.sortDirection, this.auditManagements);
          this._setUsersToCurrentPage();
  }
  private _isSomeFilterApply() {
      let filterApply = false;
      this.filterList.forEach(filter => {
        if (filter.valueList.length) {
          filterApply = true;
        }
      });
      return filterApply;
  }
  
  private _updateFilterList(valueSelected: string, isChecked: string) {
      const existFilter = this._getFilter(this.targetField);
      if (isChecked) {
        this._addFilterItem(existFilter, valueSelected);
      } else {
        this._removeFilterItem(existFilter, valueSelected);
      }
      this.showDisable=true;
      this.auditManagementExportToExcel.length=0;
      const checkbox = document.getElementById(
        'chk_MultiCheckBox',
      ) as HTMLInputElement | null;
      //checkbox.checked = false;
   
    this.auditManagementPageDetails.length=0
      this._setTargetFilter(existFilter);
  }
  
    private _setTargetFilter(existFilter: AuditManagementTableFilterItem) {
      this.targetFilter = existFilter;
      this.commonHelperService.filterDataList = this.filterList;
  
  }
  private _addFilterItem(existFilter: AuditManagementTableFilterItem, valueSelected: string) {
      if (existFilter) {
        existFilter.valueList.push(valueSelected);
      } else {
        const newFilter = this._createFilter(valueSelected);
        this.filterList.push(newFilter);
        existFilter = newFilter;
      }
  }
  
    private _removeFilterItem(existFilter: AuditManagementTableFilterItem, valueSelected: string) {
      if (existFilter) {
        existFilter.valueList = this._removeFilter(existFilter.valueList, valueSelected);
      }
  }
  private _removeFilter(valueList: Array<string>, valueSelected: string): Array<string> {
      return valueList.filter(v => v !== valueSelected);
  }
  private _createFilter(valueSelected: string): AuditManagementTableFilterItem {
      const newFilter = {
        field: this.targetField,
        valueList: [valueSelected]
      };
      return newFilter;
  }
  downloadAuditReport() {
    this.spinnerService.showAutoSaveSpinner();
        this.AuditReportData = [];
        if (this.auditManagements != null && this.auditManagements.length > 0) {
          this.auditManagements.forEach((item: any) => {
            let AuditDetail = {
              ModifiedBy: item.ModifiedBy,
              ModifiedOn: item.ModifiedOn,
              Description: item.Description,
            };
            this.AuditReportData.push(AuditDetail);
          }); 
          if (this.AuditReportData.length > 0) {
            this.generateExcel(this.AuditReportData);
          }
        }
        this.spinnerService.hideAutoSaveSpinner();
  }
    public generateExcel(jsonData: any[]): void {
      jsonData = jsonData.map(doc => Object.values(doc));
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
      worksheet['A1'].v = "ModifiedBy";
      worksheet['B1'].v = "ModifiedOn";
      worksheet['C1'].v = "Description";
      
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data: Blob = new Blob([excelBuffer], { type: "xlsx" });
      FileSaver.saveAs(data, 'AuditReportForUserManagement_' + new Date().getTime() + ".xlsx");
    }
}
