<div *ngIf="dealTeamSignoffsSection" id="deal-team-signoffs" class="container-fluid border container-border-color container-section">
    <div class="row bg-title">
        <span class="title-section-print">Deal Team Signoffs</span>
        <span class="glyphicon glyphicon-question-sign title-question-sign tooltip-dcf"></span>
        <span class="tooltiptext-dcf visibility-hidden">
            <p>
                Each member of the deal team should type in their name once they have reviewed and signed off on the
                form (no need for e-signatures).
                Analysts should not be the only ones to review this form.
            </p>
        </span>
    </div>
    <div id="deal-team-section" class="content-section">
        <div class="row div-signoff" *ngFor="let dealTeamSignOff of dealTeamSignOffs;let i = index">
            <div class="administrator-section-border">
                <div>
                    <div class="approval-signature">
                        <i>{{dealTeamSignOff.role}}</i>
                        <div><b><span id="DealTeamSignature" name="DealTeamSignature" class="full-width signature-color"
                                    userId="dealTeamSignOff.userId">{{dealTeamSignOff.userName}}</span></b></div>
                        <hr class="border-hr-deal-team">
                    </div>
                    <div class="approval-date" *ngIf="dealTeamSignOff.status">
                        <span class="full-width"
                            [ngClass]="{' fa fa-check-circle reviewed-icon-color ': dealTeamSignOff.icon == 'Reviewed','glyphicon glyphicon-log-out review-na-icon-color':dealTeamSignOff.icon == 'N/A' }"></span>
                        <span class="DCFDateApproval"><b>{{dealTeamSignOff.content}}</b></span>
                    </div>
                    <div class="reviewStatusButtonsSignOff" *ngIf="!dealTeamSignOff.status && hasDealTeamSignoffApprovalAccess">
                        <button id="i" type="button" class="btn btn-success btn-approve  actions-buttons"
                            data-submitType="Reviewed" status="Reviewed" (click)="dealTeamApproval(dealTeamSignOffs[i])">Approve</button>
                    </div>
                </div>
                <div class="div-comments-signature"
                     *ngIf="!(dealTeamSignOff.status && dealTeamSignOff.comments=='')">
                  <div class="col-xs-2">Comments:</div>
                  <div class="col-xs-10">
                    <textarea rows="3" type="text"
                              class="text-area-style DealTeamComments"
                              [(ngModel)]="dealTeamSignOff.comments"
                              [readonly]="!hasDealTeamSignoffApprovalAccess"
                              [disabled]="dealTeamSignOff.disabled">{{dealTeamSignOff.comments}}</textarea>
                  </div>
                </div>
            </div>
        </div>

    </div>
    <div class="row float-right save-button">
        <button type="button" class="btn btn-outline-primary save-rework-cancel-btn draftsub margin-right-10"
            data-submittype="Saved" data-actiongroup="" id="saveButton" *ngIf="savePermission" (click)="save()">Save</button>
        <button type="button"
            class="btn btn-outline-primary reject save-rework-cancel-btn return margin-left-10 actions-buttons return-button"
            data-submittype="Rejected" data-actiongroup="" *ngIf="!(formStatus == 'Discontinued' || formStatus == 'Completed')" (click)="returnForRework()">Return for rework</button>
        <button type="button" class="btn btn-outline-primary discontinue-button save-rework-cancel-btn margin-left-10"
            data-submittype="Discontinued" data-actiongroup="" id="discontinueButton" *ngIf="discontinuePermission && formActionGroup != 'Series24'" (click)="discontinue()">Discontinue</button>
    </div>
</div>
