import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { UserManagementComponent } from './admin/usermanagement/usermanagement.component';
import { CrosbocrossborderformComponent } from './crosbocrossborderform/crosbocrossborderform.component';
import { CrossborderDashboardDetailsComponent } from './crossborder-dashboard-details/crossborder-dashboard-details.component';
import { MsalGuard } from '@azure/msal-angular';
import { ContentManagementComponent } from './contentManagement/content-management/content-management.component';
import { NewDealSetupComponent } from './newdeal/new-deal-setup/new-deal-setup.component';
import { NewDealComponent } from './newdeal/new-deal/new-deal.component';
import { ActiveNewDealComponent } from './newdeal/active-new-deal/active-new-deal.component';
import { AdminReviewComponent } from './newdeal/admin-review/admin-review.component';
import { ClientOverviewComponent } from './newdeal/client-overview/client-overview.component';
import { EngagementDashboardComponent } from './engagementDashboard/engagement-dashboard/engagement-dashboard.component';
import { EngagementHealthObjectComponent } from './engagement-health-object/engagement-health-object-setup/engagement-health-object.component';
import { DealWbsSetupComponent } from './deal-wbs-setup/deal-wbs-setup/deal-wbs-setup.component';
import { ClosingChecklistModule } from './closing-checklist-form/closing-checklist-form.module';
import { ClosingChecklistFormComponent } from './closing-checklist-form/closing-checklist-setup/closing-checklist-form.component';
import { TerminationFormComponent } from './termination-form/termination-form/termination-form.component';
import { TerminationDashboardComponent } from './termination-dashboard/termination-dashboard.component';
import { ClosingChecklistDashboardBoardComponent } from './closingChecklistDashboard/closing-checklist-dashboard/closing-checklist-dashboard.component';
import { NewHireFormComponent } from './new-hire-form/new-hire-form/new-hire-form.component';
import { NewHireFormDashboardComponent } from './new-hire-form-dashboard/new-hire-form-dashboard.component';
const routes: Routes = [
  {path:"crossborderform?item_id",component:CrosbocrossborderformComponent,canActivate: [MsalGuard]
  },
  {path:"newdeal?id",component:NewDealSetupComponent,canActivate: [MsalGuard]
  },
  {path:"dealWbsForm?id",component:DealWbsSetupComponent,canActivate: [MsalGuard]
  },
  {path:"terminationForm?id",component:TerminationFormComponent,canActivate: [MsalGuard]
  },
  { path: 'closingCheckListForm?id', component: ClosingChecklistFormComponent, canActivate: [MsalGuard] },
  { path: 'onboardingForm?id', component: NewHireFormComponent, canActivate: [MsalGuard] },
  {
    path: '',
    component:EngagementDashboardComponent,
    pathMatch: 'full', canActivate: [MsalGuard]
  },    
  {path:'home',component:HomeComponent},
  {path:'admin',component:UserManagementComponent, canActivate: [MsalGuard],title:"Pages - User Management"},
  {path:'crossborderform',component:CrosbocrossborderformComponent, canActivate: [MsalGuard],title:"Pages - CrossBorder Form"},
  {path:'Dashboard',component:CrossborderDashboardDetailsComponent,canActivate: [MsalGuard],title:"Pages - CrossBorder Dashboard"},
  {path:'content',component:ContentManagementComponent,canActivate:[MsalGuard],title:"Pages - Content Management"},
  {path:'newdeal',component:NewDealSetupComponent,canActivate:[MsalGuard],title:"Pages - New Deal Setup Form"},
  {path:'engagementDashboard',component:EngagementDashboardComponent,canActivate:[MsalGuard],title:"Pages - Engagement Dashboard"},
  {path:'engagementHealthObject',component:EngagementHealthObjectComponent,canActivate:[MsalGuard],title:"Pages - Engagement Health Object"},
  {path:'dealWbsForm',component:DealWbsSetupComponent,canActivate:[MsalGuard],title:"Pages - Deal WBS Form"},
  { path: 'closingCheckListForm', component: ClosingChecklistFormComponent, canActivate: [MsalGuard], title: "Pages - Closing Checklist Form" },
  { path: 'terminationForm', component: TerminationFormComponent, canActivate: [MsalGuard], title: "Pages - Termination Form" },
  { path: 'terminationFormDashboard', component: TerminationDashboardComponent, canActivate: [MsalGuard], title: "Pages - Termination Form Dashboard" },
  { path: 'closingChecklistDashboard', component: ClosingChecklistDashboardBoardComponent, canActivate: [MsalGuard], title: "Pages - ClosingChecklistFormS24ApprovalReport" },
  { path: 'onboardingForm', component: NewHireFormComponent, canActivate: [MsalGuard], title: "Pages - Onboarding Form" },
  { path: 'onboardingFormDashboard', component: NewHireFormDashboardComponent, canActivate: [MsalGuard], title: "Pages - Onboarding Form Dashboard" } 
  
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
