<div id="client-information"
  class="container-fluid border container-border-color container-section"
  [formGroup]="clientInformationFormGroup">
  <div class="row bg-title">
    <span class="title-section-print ">Client Information</span>
  </div>
  <div class="content-section">
    <div class="row">
        <div class="col-xs-4 section-label">
          <span [class.highlight-warning]="highlightRequiredField('clientName')"
            [class.edited-externally]="editedExternally('clientName')"
            [class.conflict-on-merge]="hasMergeConflict('clientName')">
            Client Name
          </span>
          <span *ngIf="showAsterisk('clientName')" class="red-asterik"></span>
        </div>
        <div class="col-xs-8">
          <input
          [formControl]="myDealFormGroup.get('clientName')"
          class="input-data-text full-width section-input"
          [class.readonly]="isReadOnly('clientName')"
          type="text"
          autocomplete="off">
        </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('isThisCrossBorder')"
          [class.edited-externally]="editedExternally('isThisCrossBorder')"
          [class.conflict-on-merge]="hasMergeConflict('isThisCrossBorder')">
          Is this a Cross-Border Engagement
        </span>
        <span *ngIf="showAsterisk('isThisCrossBorder')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('IsThisCrossBorder')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="getControlName('isThisCrossBorder')?.value"
          [readonly]="isReadOnly('isThisCrossBorder')"
          (handleToggleYesNo)="onIsThisCrossBorderSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('memberFirm')"
          [class.edited-externally]="editedExternally('memberFirm')"
          [class.conflict-on-merge]="hasMergeConflict('memberFirm')">
          Member Firm 
        </span>
        <span *ngIf="showAsterisk('memberFirm')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('MemberFirm')"
          [keepOpen]="true"
          >
        </app-popover>
      </div>
      <div class="col-xs-8">
        <input
        formControlName="memberFirm"
        class="input-data-text full-width section-input"
        [class.readonly]="isReadOnly('memberFirm')"
        type="text"
        autocomplete="off">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('memberFirmClientID')"
          [class.edited-externally]="editedExternally('memberFirmClientID')"
          [class.conflict-on-merge]="hasMergeConflict('memberFirmClientID')">
          Member Firm  Client #
        </span>
        <span *ngIf="showAsterisk('memberFirmClientID')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('MemberFirmClientID')"
          [keepOpen]="true">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <!-- <app-numeric
          [type]="'number'"
          [readonly]="isReadOnly('memberFirmClientID')"
          [disabled]="getControlName('memberFirmClientID').disabled"
          [value]="getControlName('memberFirmClientID').value"
          (selectedValue)="onmemberFirmClientIDChange($event)">
        </app-numeric> -->
        <input
        formControlName="memberFirmClientID"
        class="input-data-text full-width section-input"
        [class.readonly]="isReadOnly('memberFirmClientID')"
        type="text"
        autocomplete="off">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('memberFirmContract')"
          [class.edited-externally]="editedExternally('memberFirmContract')"
          [class.conflict-on-merge]="hasMergeConflict('memberFirmContract')">
          Member Firm Contact
        </span>
        <span *ngIf="showAsterisk('memberFirmContract')" class="red-asterik"></span>
        <app-popover [tooltipText]="getTooltipText('MemberFirmContract')"> </app-popover>
      </div>
      <div class="col-xs-8">
        <input
        formControlName="memberFirmContract"
        class="input-data-text full-width section-input"
        [class.readonly]="isReadOnly('memberFirmContract')"
        type="text"
        autocomplete="off">
      </div>
    </div>
    <div class="row">
      <!-- Member Firm Reference # has renamed to  DTTL Member Firm Engagement Code -->
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('memberFirmReferenceID')"
          [class.edited-externally]="editedExternally('memberFirmReferenceID')"
          [class.conflict-on-merge]="hasMergeConflict('memberFirmReferenceID')">
          DTTL Member Firm Engagement Code
        </span>
        <span *ngIf="showAsterisk('memberFirmReferenceID')" class="red-asterik"></span>
        <app-popover [tooltipText]="getTooltipText('MemberFirmReferenceID')"> </app-popover>
      </div>
      <div class="col-xs-8">
        <!-- <app-numeric
          [type]="'number'"
          [readonly]="isReadOnly('memberFirmReferenceID')"
          [disabled]="getControlName('memberFirmReferenceID').disabled"
          [value]="getControlName('memberFirmReferenceID').value"
          (selectedValue)="onMemberFirmReferenceIDChange($event)">
        </app-numeric> -->
        <input
        formControlName="memberFirmReferenceID"
        class="input-data-text full-width section-input"
        [class.readonly]="isReadOnly('memberFirmReferenceID')"
        type="text"
        autocomplete="off">
      </div>
    </div>
  </div>
</div>
