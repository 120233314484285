import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CustomValidators } from 'src/shared/custom/custom-validators';
import { FormValidations } from 'src/shared/utils/form-validations';
import { NewHireFormPopupComponent } from '../new-hire-form-popup/new-hire-form-popup.component';
import { NewHireFormModel } from '../models/NewHireFormModel';
import { NewHireFormItem } from '../models/NewHireFormItems';
import { DropdownItem } from 'src/shared/models/dropdown-item';
import { AppSettings } from 'src/shared/app-settings';
import { SpinnerService } from 'src/shared/services/spinner.service';
import { NewHireFormService } from 'src/app/services/newhireform.service';
import { DateService } from 'src/app/services/date.service';
import { GlobalHelperService } from 'src/app/globalHelper/global-helper.service';
import { CommonHelperService } from 'src/app/commonHelper/common-helper.service';
import { DateFormatterService } from 'src/shared/utils/utils-date';
import { DatePipe } from "@angular/common";

@Component({
  selector: 'app-new-hire-form',
  templateUrl: './new-hire-form.component.html',
  styleUrls: ['./new-hire-form.component.scss']
})
export class NewHireFormComponent implements OnInit {
  newhireFormGroup: FormGroup;
  isExportEnabled: boolean = true;
  isRegisteredStatus: boolean = false;
  isNRFStatus: boolean = false;
  isOtherNRFStatus: boolean = false;
  additionalCommentsFinraLicenses1: any = "";
  additionalCommentsFinraLicenses2: any = "";
  additionalCommentsCommunication: any = "";
  additionalCommentsNewHireInfo: any = "";
  additionalCommentsOnboardingDocumentation: any = "";
  isTheEmployeeAddedSupervisionComment:boolean = false;
  isConfirmedCommunicationsComment:boolean = false;
  isDeloitteMobileSmarshComment:boolean = false;
  isBrokerageAccountComment:boolean = false;
  isOBAdisclosureComment:boolean = false;
  isPSTdisclosureComment:boolean = false;
  isCSIAttestationsComment:boolean = false;
  isNewhireQuestCEComment:boolean = false;
  isNAStatus:boolean = false;
  isLegalHoldComment:boolean = false;
  formID:any;
  initialNewHireList:any;
  additionalCommentsBrokarage:any = "";
  additionalCommentsComplianceTraining:any = "";
  actionType:any="";
  submitted:boolean = false;
  isSaveAndUpdate:boolean=false;
  initialSubmit:boolean=false;
  supervisoryBranchOfficeList: Array<DropdownItem> = [];
  series24BranchSupervisorList: Array<DropdownItem> = [];
  isOtherSelected: boolean = false;
  isNonRegisteredBranchOffice: boolean = false;
  iswelcomeEmailSent: boolean = false;
  isbackgroundCheck: boolean = false;
  isdeloitteApplication: boolean = false;
  isActiveDirectory: boolean = false;
  isKnowledgeCenter: boolean = false;
  isDFlowPermissionsSpreadsheet: boolean = false;
  isSupervisoryOrgChart: boolean = false;
  isCSI: boolean = false;
  isQuest: boolean = false;
  isbigEmailOrHardcopy: boolean = false;
  isfingerprintsClearedWithFINRA: boolean = false;
  isStudyMaterialsOrdered: boolean = false;
  isInitialFormU4NRFFilingComplete: boolean = false;
  isStatutorilyDisqualified: boolean = false;
  isStateRegistrationMemoSent: boolean = false;
  isSocialmediaCheck: boolean = false;
  isFinraLicense: boolean = false;
  isFinraCEInactive: boolean = false;
  isBrokerCheck: boolean = false;
  isFormU5: boolean = false;
  isLexisNexis: boolean = false;
  isseries24BranchSupervisorNotified: boolean = false;
  series24LeftDCF:any;
  rangeKey:any="";
  signatureDate:any="";
  signatureData:any=[];
  signatureName:any;
  adGroupMember: any;
  isADGroupUser:boolean|null=false;
  currentUser:any;
  userRoles:any;
  series24DisplayName:any="";
 
  @ViewChild(NewHireFormPopupComponent, { static: true }) newHireFormPopupComponent: NewHireFormPopupComponent;

  constructor(private fb: FormBuilder,private spinnerService: SpinnerService,private newhireFormService: NewHireFormService,private dateService:DateService,
    public globalHelperService: GlobalHelperService,private commonHelperService: CommonHelperService,private dateFormatterService: DateFormatterService,private datePipe:DatePipe,
  ) {}

  ngOnInit(): void { 
    this.UIActions(); 
    this.spinnerService.showAutoSaveSpinner();
    localStorage.setItem('CurrentAccessPage',"onboardingForm")
    this.globalHelperService.currentAccessPage="onboardingForm";
    this.createForm();
    this.loadDropdownOptions();
    this.commonHelperService.getUserGroupMembership().subscribe((res:any)=> {
     if(res!=null && res!=undefined){
       this.isADGroupUser = res?.isADGroupUser;
 
       this.getAdGroup();  
     }    
   });      
   }
   getAdGroup(){
    if(this.isADGroupUser)
      {
        this.ValidateUserAccess();        
      }
      else{
        this.spinnerService.hideAutoSaveSpinner();
        this.showMsgs("newhire", "InValidUser");
      }
}
ValidateUserAccess() { 
  this.VerifyNewHireFormAdmin();  
  }
  VerifyNewHireFormAdmin() {
    this.commonHelperService.getUserData().subscribe(
      {
        next: (user: any) => {
          if(user.length!=0)
          {
            this.currentUser = user;
            let userGroups = user.groups;
            this.userRoles = userGroups;
            var isTFAdmin = this.isInTFAdminGroup(userGroups);
            if(isTFAdmin)
            {
              this.LoadNHForm();
            }
            else
            {
              this.spinnerService.hideAutoSaveSpinner();
              this.showMsgs("newhire", "InValidUser");
            }
          }
        },
        error: (err: any) => {
          this.spinnerService.hideAutoSaveSpinner();
          console.log("Error" + err);
          if (err.status == 403 && err.error.stringKey == AppSettings.tokenExpiredText) {
            this._openSessionExpiredModal();
          }
        }
      }
    );
  }
    isInTFAdminGroup(userGroups: any) {
      let isAdmin_flag = false;
      userGroups.forEach((u: any) => {
        if (u == "On/Offboarding Administrators") {
          isAdmin_flag = true;
        }
      });
      return isAdmin_flag;
    }
    LoadNHForm() {
      this.BindSignature();
      this.loadForm(); 
      //this.BindSignatureTableAndEnableExport();
    }
    BindSignature(){
      this.signatureName= localStorage.getItem("AuditorName")?.toString();
    }
    private loadForm() {
      this.UIActions();
      const url = window.location.href;
        const formID = url.split('?')[1]?.split("=")[1];
        if(formID != undefined){
          this.spinnerService.showAutoSaveSpinner();
          this.formID = formID;
          this.globalHelperService.formID=this.formID.toString();
          this.newhireFormService.GetFormDetails(formID).subscribe({
            next:(res:any)=>{
              if (res.length == 0) {
                this.spinnerService.hideAutoSaveSpinner();
                this.showMsgs("newhire", "InvalidForm");
                return;
              }
              this.BindSeries24DisplayName(res[0]);
              this.initialNewHireList = res[0];
              if(!res.length){
                this.spinnerService.hideAutoSaveSpinner();
              }
             this.LoadComparedObjectFields();
              this.MapToFormGroup();
              if(this.initialNewHireList.FormStatus=="Completed"){this.actionType="Submit";}
              this.showMsgs("newhire", "retrieve");
            },
            error: error=>{
              console.log("Error" + error);
              this.spinnerService.hideAutoSaveSpinner();
            }
          })
        }
        else{
          this.spinnerService.hideAutoSaveSpinner();
        }
    }
    BindSeries24DisplayName(res:any){
      this.series24DisplayName =  res.Series24 ? (res.Series24.includes("#;")?res.Series24.split("#;")[0]:""):"";
    }
  updateFinraLicenseHighlighting() {
    const checkboxes = document.querySelectorAll('.checkbox-container-horizontal input[type="checkbox"]');
    const anyChecked = Array.from(checkboxes).some((checkbox: any) => checkbox.checked);

    if (anyChecked) {
        this.newhireFormGroup.get('FinraLicense')?.setValue('Yes');
    } else {
        this.newhireFormGroup.get('FinraLicense')?.setValue('');
    }
  }
  createForm(): void {
    this.newhireFormGroup = this.fb.group({
      lastName: new FormControl('', Validators.required),
      firstName: new FormControl('', Validators.required),
      dateOfHire: new FormControl('', Validators.required),
      RegisteredStatus: new FormControl('',Validators.required),
      NRFStatus: new FormControl('',Validators.required),
      otherNRFStatusReason: new FormControl('',Validators.required),
      supervisoryBranchOffice: new FormControl('', Validators.required),
      NonRegisteredBranchOffice: new FormControl('', Validators.required),
      NonRegisteredBranchOfficeComments: new FormControl('',Validators.required),
      series24BranchSupervisor: new FormControl('', Validators.required),
      series24BranchSupervisorNotified: new FormControl('', Validators.required),
      series24BranchSupervisorNotifiedComments: new FormControl(''),
      returningHire: new FormControl('', Validators.required),
      welcomeEmail: new FormControl('', Validators.required),
      welcomeEmailComments: new FormControl('',Validators.required),
      backgroundCheck: new FormControl('', Validators.required),
      backgroundCheckComments: new FormControl('',Validators.required),
      deloitteApplication: new FormControl('', Validators.required),
      deloitteApplicationComments: new FormControl(''),
      LexisNexis: new FormControl('', Validators.required),
      LexisNexisComments: new FormControl('',Validators.required),
      socialmediaCheck: new FormControl('', Validators.required),
      socialmediaCheckComments: new FormControl('',Validators.required),
      ActiveDirectory: new FormControl('', Validators.required),
      ActiveDirectoryComments: new FormControl('',Validators.required),
      KnowledgeCenter: new FormControl('', Validators.required),
      KnowledgeCenterComments: new FormControl('',Validators.required),
      DFlowPermissionsSpreadsheet: new FormControl('', Validators.required),
      DFlowPermissionsSpreadsheetComments: new FormControl('',Validators.required),
      SupervisoryOrgChart: new FormControl('', Validators.required),
      SupervisoryOrgChartComments: new FormControl('',Validators.required),
      CSI: new FormControl('', Validators.required),
      CSIComments: new FormControl('',Validators.required),
      Quest: new FormControl('', Validators.required),
      QuestComments: new FormControl('',Validators.required),
      bigEmailOrHardcopy: new FormControl('', Validators.required),
      bigEmailOrHardcopyComments: new FormControl('',Validators.required),
      fingerprintsClearedWithFINRA: new FormControl('', Validators.required),
      fingerprintsClearedWithFINRAComments: new FormControl('',Validators.required),
      FinraLicense: new FormControl('', Validators.required),
      BrokerCheck: new FormControl('', Validators.required),
      BrokerCheckComments: new FormControl('',Validators.required),
      FormU5: new FormControl('', Validators.required),
      FormU5Comments: new FormControl('',Validators.required),
      FinraCEInactive: new FormControl('', Validators.required),
      OutstandingFinraCE: new FormControl('', Validators.required),
      studyMaterialsOrdered: new FormControl('', Validators.required),
      studyMaterialsOrderedComments: new FormControl(''),
      initialFormU4NRFFilingComplete: new FormControl('', Validators.required),
      initialFormU4NRFFilingCompleteComments: new FormControl(''),
      statutorilyDisqualified: new FormControl('', Validators.required),
      statutorilyDisqualifiedComments: new FormControl(''),
      stateRegistrationMemoSent: new FormControl('', Validators.required),
      stateRegistrationMemoSentComments: new FormControl(''),
      finraLicenses: new FormControl('', Validators.required),
      otherFinraLicense: new FormControl('', Validators.required),
      TheEmployeeAddedSupervision: new FormControl('', Validators.required),
      TheEmployeeAddedSupervisionComment: new FormControl('', Validators.required),
      ConfirmedCommunications: new FormControl('', Validators.required),
      ConfirmedCommunicationsComment: new FormControl('', Validators.required),
      DeloitteMobileSmarsh: new FormControl('', Validators.required),
      DeloitteMobileSmarshComment: new FormControl('', Validators.required),
      NAStatus: new FormControl('', Validators.required),
      BrokerageAccount: new FormControl('', Validators.required),
      BrokerageAccountComment: new FormControl('', Validators.required),
      OBAdisclosure: new FormControl('', Validators.required),
      OBAdisclosureComment: new FormControl('', Validators.required),
      PSTdisclosure: new FormControl('', Validators.required),
      PSTdisclosureComment: new FormControl('', Validators.required),
      CSIAttestations: new FormControl('', Validators.required),
      CSIAttestationsComment: new FormControl('', Validators.required),
      NewhireQuestCE: new FormControl('', Validators.required),
      NewhireQuestCEComment: new FormControl('', Validators.required),
      dateOfComplianceTraining: new FormControl('', Validators.required),
      DeloittePhoneNumber: new FormControl('', Validators.required),
      RegStatus: new FormControl('', Validators.required),
      LegalHold: new FormControl('', Validators.required),
      LegalHoldComment: new FormControl('', Validators.required),
      
      
    });
  }

  loadDropdownOptions() {
    // Load options for dropdowns
    this.supervisoryBranchOfficeList = AppSettings.SupervisoryBranchOfficeList;
    this.series24BranchSupervisorList = AppSettings.Series24BranchSupervisorList;
  }

  onSubmit(){
    this.isSaveAndUpdate=false;
    this.initialSubmit=false;
 if(this.IsNewHireFormValid()){
  this.actionType="Submit";
  this.initialSubmit=true;
  this.OnSaveForm(event);
 }
 else{
  this.actionType="";
//Mandatory Fields Popup
this.showMsgs("newhire", "Info");

 }
 this.submitted = true;
}
  createFormObject(): NewHireFormModel{
    var newhireFormObj:any = new NewHireFormItem();
    Object.keys(this.newhireFormGroup.controls).forEach(formControlName => {
      if (formControlName) {
        newhireFormObj[formControlName]=this.newhireFormGroup.get(formControlName)?.value==null?
        "":this.newhireFormGroup.get(formControlName)?.value;
      }
    });
      // Convert finraLicenses array to a comma-separated string
  if (Array.isArray(newhireFormObj.finraLicenses)) {
    newhireFormObj.finraLicenses = newhireFormObj.finraLicenses.join(',');
  }
    return this.MapToNewHireFormModel(newhireFormObj);
  }
  MapToNewHireFormModel(newhireFormObj:NewHireFormItem): any{
      var newHireFormModel: NewHireFormModel;
       return newHireFormModel = {
        ID:this.formID==undefined?"":this.formID,
        LastName: newhireFormObj.lastName,
        FirstName: newhireFormObj.firstName,
        dateOfHire: newhireFormObj.dateOfHire==""?"":this.datePipe.transform(new Date(newhireFormObj.dateOfHire), 'yyyy-MM-dd')?.toString(),
        RegStatus: newhireFormObj.RegStatus,
        StatusOfIndividualSupervisory:newhireFormObj.RegStatus!="NRF"?newhireFormObj.RegisteredStatus:"",
        StatusOfIndividualNRF:newhireFormObj.RegStatus=="NRF"? newhireFormObj.NRFStatus:"",
        StatusOfIndividualNRFOther: newhireFormObj.NRFStatus=="Other"?newhireFormObj.otherNRFStatusReason:"",
        supervisoryBranchOffice:newhireFormObj.supervisoryBranchOffice,
        NonRegisteredBranchOffice:newhireFormObj.NonRegisteredBranchOffice,
        NonRegisteredBranchOfficeComments: newhireFormObj.NonRegisteredBranchOffice=="Yes"?newhireFormObj.NonRegisteredBranchOfficeComments:"",
        series24BranchSupervisor:newhireFormObj.series24BranchSupervisor,
        series24BranchSupervisorNotified:newhireFormObj.series24BranchSupervisorNotified,
        series24BranchSupervisorNotifiedComments: newhireFormObj.series24BranchSupervisorNotified=="No"?newhireFormObj.series24BranchSupervisorNotifiedComments:"",
        returningHire:newhireFormObj.returningHire,
        additionalCommentsNewHireInfo:this.additionalCommentsNewHireInfo==undefined?"":this.additionalCommentsNewHireInfo,
        welcomeEmail:newhireFormObj.welcomeEmail,
        welcomeEmailComments: newhireFormObj.welcomeEmail=="No"?newhireFormObj.welcomeEmailComments:"",
        backgroundCheck:newhireFormObj.backgroundCheck,
        backgroundCheckComments:newhireFormObj.backgroundCheck=="No"?newhireFormObj.backgroundCheckComments:"",
        deloitteApplication:newhireFormObj.deloitteApplication,
        deloitteApplicationComments:newhireFormObj.deloitteApplication=="No"?newhireFormObj.deloitteApplicationComments:"",
        LexisNexis:newhireFormObj.LexisNexis,
        LexisNexisComments:newhireFormObj.LexisNexis=="No"?newhireFormObj.LexisNexisComments:"",
        socialmediaCheck:newhireFormObj.socialmediaCheck,
        socialmediaCheckComments:newhireFormObj.socialmediaCheck=="No"?newhireFormObj.socialmediaCheckComments:"",
        ActiveDirectory:newhireFormObj.ActiveDirectory,
        ActiveDirectoryComments:newhireFormObj.ActiveDirectory=="No"?newhireFormObj.ActiveDirectoryComments:"",
        KnowledgeCenter:newhireFormObj.KnowledgeCenter,
        KnowledgeCenterComments:newhireFormObj.KnowledgeCenter=="No"?newhireFormObj.KnowledgeCenterComments:"",
        DFlowPermissionsSpreadsheet:newhireFormObj.DFlowPermissionsSpreadsheet,
        DFlowPermissionsSpreadsheetComments:newhireFormObj.DFlowPermissionsSpreadsheet=="No"?newhireFormObj.DFlowPermissionsSpreadsheetComments:"",
        SupervisoryOrgChart:newhireFormObj.SupervisoryOrgChart,
        SupervisoryOrgChartComments:newhireFormObj.SupervisoryOrgChart=="No"?newhireFormObj.SupervisoryOrgChartComments:"",
        CSI:newhireFormObj.CSI,
        CSIComments:newhireFormObj.CSI=="No"?newhireFormObj.CSIComments:"",
        Quest:newhireFormObj.Quest,
        QuestComments:newhireFormObj.Quest=="No"?newhireFormObj.QuestComments:"",
        additionalCommentsOnboardingDocumentation:this.additionalCommentsOnboardingDocumentation==undefined?"":this.additionalCommentsOnboardingDocumentation,
        bigEmailOrHardcopy:newhireFormObj.bigEmailOrHardcopy,
        bigEmailOrHardcopyComments:newhireFormObj.bigEmailOrHardcopy=="No"?newhireFormObj.bigEmailOrHardcopyComments:"",
        fingerprintsClearedWithFINRA:newhireFormObj.fingerprintsClearedWithFINRA,
        fingerprintsClearedWithFINRAComments:newhireFormObj.fingerprintsClearedWithFINRA=="No"?newhireFormObj.fingerprintsClearedWithFINRAComments:"",
        FinraLicense:newhireFormObj.FinraLicense,
        BrokerCheck:newhireFormObj.BrokerCheck,
        BrokerCheckComments:newhireFormObj.BrokerCheck=="No"?newhireFormObj.BrokerCheckComments:"",
        FormU5:newhireFormObj.FormU5,
        FormU5Comments:newhireFormObj.FormU5=="No"?newhireFormObj.FormU5Comments:"",
        FinraCEInactive:newhireFormObj.FinraCEInactive,
        OutstandingFinraCE:newhireFormObj.OutstandingFinraCE,
        additionalCommentsFinraLicenses1:this.additionalCommentsFinraLicenses1==undefined?"":this.additionalCommentsFinraLicenses1,
        finraLicenses:newhireFormObj.finraLicenses,
        otherFinraLicense: newhireFormObj.finraLicenses?.includes("Other") ? newhireFormObj.otherFinraLicense : "",        
        studyMaterialsOrdered:newhireFormObj.studyMaterialsOrdered,
        studyMaterialsOrderedComments:newhireFormObj.studyMaterialsOrdered=="No"?newhireFormObj.studyMaterialsOrderedComments:"",
        initialFormU4NRFFilingComplete:newhireFormObj.initialFormU4NRFFilingComplete,
        initialFormU4NRFFilingCompleteComments:newhireFormObj.initialFormU4NRFFilingComplete=="No"?newhireFormObj.initialFormU4NRFFilingCompleteComments:"",
        statutorilyDisqualified:newhireFormObj.statutorilyDisqualified,
        statutorilyDisqualifiedComments:newhireFormObj.statutorilyDisqualified=="Yes"?newhireFormObj.statutorilyDisqualifiedComments:"",
        stateRegistrationMemoSent:newhireFormObj.stateRegistrationMemoSent,
        stateRegistrationMemoSentComments:newhireFormObj.stateRegistrationMemoSent=="No"?newhireFormObj.stateRegistrationMemoSentComments:"",
        additionalCommentsFinraLicenses2:this.additionalCommentsFinraLicenses2==undefined?"":this.additionalCommentsFinraLicenses2,
        TheEmployeeAddedSupervision:newhireFormObj.TheEmployeeAddedSupervision,
        TheEmployeeAddedSupervisionComment:newhireFormObj.TheEmployeeAddedSupervision=="No"?newhireFormObj.TheEmployeeAddedSupervisionComment:"",
        LegalHold:newhireFormObj.LegalHold,
        LegalHoldComment:newhireFormObj.LegalHold=="No"?newhireFormObj.LegalHoldComment:"",        
        ConfirmedCommunications:newhireFormObj.ConfirmedCommunications,
        ConfirmedCommunicationsComment:newhireFormObj.ConfirmedCommunications=="No"?newhireFormObj.ConfirmedCommunicationsComment:"",
        DeloitteMobileSmarsh:newhireFormObj.DeloitteMobileSmarsh,
        DeloitteMobileSmarshComment:newhireFormObj.DeloitteMobileSmarsh=="No"?newhireFormObj.DeloitteMobileSmarshComment:"",
        DeloittePhoneNumber:newhireFormObj.DeloitteMobileSmarsh=="Yes"?newhireFormObj.DeloittePhoneNumber:"",
        NAStatus:newhireFormObj.DeloitteMobileSmarsh=="Yes"?newhireFormObj.NAStatus:"",
        additionalCommentsCommunication:this.additionalCommentsCommunication==undefined?"":this.additionalCommentsCommunication,
        BrokerageAccount:newhireFormObj.BrokerageAccount,
        BrokerageAccountComment:newhireFormObj.BrokerageAccount=="No"?newhireFormObj.BrokerageAccountComment:"",
        OBAdisclosure:newhireFormObj.OBAdisclosure,
        OBAdisclosureComment:newhireFormObj.OBAdisclosure=="No"?newhireFormObj.OBAdisclosureComment:"",
        PSTdisclosure:newhireFormObj.PSTdisclosure,
        PSTdisclosureComment:newhireFormObj.PSTdisclosure=="No"?newhireFormObj.PSTdisclosureComment:"",
        additionalCommentsBrokarage:this.additionalCommentsBrokarage==undefined?"":this.additionalCommentsBrokarage,
        CSIAttestations:newhireFormObj.CSIAttestations,
        CSIAttestationsComment:newhireFormObj.CSIAttestations=="No"?newhireFormObj.CSIAttestationsComment:"",
        NewhireQuestCE:newhireFormObj.NewhireQuestCE,
        NewhireQuestCEComment:newhireFormObj.NewhireQuestCE=="No"?newhireFormObj.NewhireQuestCEComment:"",
        dateOfComplianceTraining:newhireFormObj.dateOfComplianceTraining==""?"":this.datePipe.transform(new Date(newhireFormObj.dateOfComplianceTraining), 'yyyy-MM-dd')?.toString(),
        additionalCommentsComplianceTraining:this.additionalCommentsComplianceTraining==undefined?"":this.additionalCommentsComplianceTraining,
        FormStatus: this.actionType=="Submit"?"Completed":"Pending",
        
        SignatureDate: this.actionType=="Submit"?this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss')?.toString():"",
        SignatureName:this.actionType=="Submit"?localStorage.getItem("AuditorName")?.toString():"",
      }
    }
    MapToFormGroup(){
      // this.formID = 
      this.newhireFormGroup.controls['lastName'].setValue(this.initialNewHireList.LastName)
      this.newhireFormGroup.controls['firstName'].setValue(this.initialNewHireList.FirstName)
      this.newhireFormGroup.controls['dateOfHire'].setValue(this.initialNewHireList.dateOfHire==null?"":this.dateFormatterService.formatDateOnRetrieve(this.initialNewHireList.dateOfHire))
      this.newhireFormGroup.controls['RegStatus'].setValue(this.initialNewHireList.RegStatus)
      this.newhireFormGroup.controls['RegisteredStatus'].setValue(this.initialNewHireList.StatusOfIndividualSupervisory)
      this.newhireFormGroup.controls['NRFStatus'].setValue(this.initialNewHireList.StatusOfIndividualNRF)
      this.newhireFormGroup.controls['otherNRFStatusReason'].setValue(this.initialNewHireList.StatusOfIndividualNRFOther)
      this.newhireFormGroup.controls['supervisoryBranchOffice'].setValue(this.initialNewHireList.supervisoryBranchOffice)
      this.newhireFormGroup.controls['NonRegisteredBranchOffice'].setValue(this.initialNewHireList.NonRegisteredBranchOffice)
      this.newhireFormGroup.controls['NonRegisteredBranchOfficeComments'].setValue(this.initialNewHireList.NonRegisteredBranchOfficeComments)
      this.newhireFormGroup.controls['series24BranchSupervisor'].setValue(this.initialNewHireList.series24BranchSupervisor)
      this.newhireFormGroup.controls['series24BranchSupervisorNotified'].setValue(this.initialNewHireList.series24BranchSupervisorNotified)
      this.newhireFormGroup.controls['series24BranchSupervisorNotifiedComments'].setValue(this.initialNewHireList.series24BranchSupervisorNotifiedComments)
      this.newhireFormGroup.controls['returningHire'].setValue(this.initialNewHireList.returningHire)
      this.additionalCommentsNewHireInfo = this.initialNewHireList.additionalCommentsNewHireInfo;
      this.newhireFormGroup.controls['welcomeEmail'].setValue(this.initialNewHireList.welcomeEmail)
      this.newhireFormGroup.controls['welcomeEmailComments'].setValue(this.initialNewHireList.welcomeEmailComments) 
      this.newhireFormGroup.controls['backgroundCheck'].setValue(this.initialNewHireList.backgroundCheck)
      this.newhireFormGroup.controls['backgroundCheckComments'].setValue(this.initialNewHireList.backgroundCheckComments)
      this.newhireFormGroup.controls['deloitteApplication'].setValue(this.initialNewHireList.deloitteApplication)
      this.newhireFormGroup.controls['deloitteApplicationComments'].setValue(this.initialNewHireList.deloitteApplicationComments)
      this.newhireFormGroup.controls['LexisNexis'].setValue(this.initialNewHireList.LexisNexis)
      this.newhireFormGroup.controls['LexisNexisComments'].setValue(this.initialNewHireList.LexisNexisComments)
      this.newhireFormGroup.controls['socialmediaCheck'].setValue(this.initialNewHireList.socialmediaCheck)
      this.newhireFormGroup.controls['socialmediaCheckComments'].setValue(this.initialNewHireList.socialmediaCheckComments)
      this.newhireFormGroup.controls['ActiveDirectory'].setValue(this.initialNewHireList.ActiveDirectory)
      this.newhireFormGroup.controls['ActiveDirectoryComments'].setValue(this.initialNewHireList.ActiveDirectoryComments)
      this.newhireFormGroup.controls['KnowledgeCenter'].setValue(this.initialNewHireList.KnowledgeCenter)
      this.newhireFormGroup.controls['KnowledgeCenterComments'].setValue(this.initialNewHireList.KnowledgeCenterComments)
      this.newhireFormGroup.controls['DFlowPermissionsSpreadsheet'].setValue(this.initialNewHireList.DFlowPermissionsSpreadsheet)
      this.newhireFormGroup.controls['DFlowPermissionsSpreadsheetComments'].setValue(this.initialNewHireList.DFlowPermissionsSpreadsheetComments)
      this.newhireFormGroup.controls['SupervisoryOrgChart'].setValue(this.initialNewHireList.SupervisoryOrgChart)
      this.newhireFormGroup.controls['SupervisoryOrgChartComments'].setValue(this.initialNewHireList.SupervisoryOrgChartComments)
      this.newhireFormGroup.controls['CSI'].setValue(this.initialNewHireList.CSI)
      this.newhireFormGroup.controls['CSIComments'].setValue(this.initialNewHireList.CSIComments)
      this.newhireFormGroup.controls['Quest'].setValue(this.initialNewHireList.Quest)
      this.newhireFormGroup.controls['QuestComments'].setValue(this.initialNewHireList.QuestComments)
      this.additionalCommentsOnboardingDocumentation = this.initialNewHireList.additionalCommentsOnboardingDocumentation;
      this.newhireFormGroup.controls['bigEmailOrHardcopy'].setValue(this.initialNewHireList.bigEmailOrHardcopy)
      this.newhireFormGroup.controls['bigEmailOrHardcopyComments'].setValue(this.initialNewHireList.bigEmailOrHardcopyComments)
      this.newhireFormGroup.controls['fingerprintsClearedWithFINRA'].setValue(this.initialNewHireList.fingerprintsClearedWithFINRA)
      this.newhireFormGroup.controls['fingerprintsClearedWithFINRAComments'].setValue(this.initialNewHireList.fingerprintsClearedWithFINRAComments)
      this.newhireFormGroup.controls['FinraLicense'].setValue(this.initialNewHireList.FinraLicense)
      this.newhireFormGroup.controls['BrokerCheck'].setValue(this.initialNewHireList.BrokerCheck==null?"":this.initialNewHireList.BrokerCheck)
      this.newhireFormGroup.controls['BrokerCheckComments'].setValue(this.initialNewHireList.BrokerCheckComments==null?"":this.initialNewHireList.BrokerCheckComments)
      this.newhireFormGroup.controls['FormU5'].setValue(this.initialNewHireList.FormU5==null?"":this.initialNewHireList.FormU5)
      this.newhireFormGroup.controls['FormU5Comments'].setValue(this.initialNewHireList.FormU5Comments==null?"":this.initialNewHireList.FormU5Comments)
      this.newhireFormGroup.controls['FinraCEInactive'].setValue(this.initialNewHireList.FinraCEInactive==null?"":this.initialNewHireList.FinraCEInactive)
      this.newhireFormGroup.controls['OutstandingFinraCE'].setValue(this.initialNewHireList.OutstandingFinraCE==null?"":this.initialNewHireList.OutstandingFinraCE)
      this.additionalCommentsFinraLicenses1 = this.initialNewHireList.additionalCommentsFinraLicenses1;
      // Convert finraLicenses string to an array
      const finraLicensesArray =
      this.initialNewHireList.finraLicenses && typeof this.initialNewHireList.finraLicenses === 'string'
        ? this.initialNewHireList.finraLicenses.split(',').filter((item: string) => item.trim() !== '')
        : [];
      this.newhireFormGroup.controls['finraLicenses'].setValue(finraLicensesArray);

      // Set otherFinraLicense value
      this.newhireFormGroup.controls['otherFinraLicense'].setValue(this.initialNewHireList.otherFinraLicense);

      this.newhireFormGroup.controls['studyMaterialsOrdered'].setValue(this.initialNewHireList.studyMaterialsOrdered)
      this.newhireFormGroup.controls['studyMaterialsOrderedComments'].setValue(this.initialNewHireList.studyMaterialsOrderedComments)
      this.newhireFormGroup.controls['initialFormU4NRFFilingComplete'].setValue(this.initialNewHireList.initialFormU4NRFFilingComplete)
      this.newhireFormGroup.controls['initialFormU4NRFFilingCompleteComments'].setValue(this.initialNewHireList.initialFormU4NRFFilingCompleteComments)
      this.newhireFormGroup.controls['statutorilyDisqualified'].setValue(this.initialNewHireList.statutorilyDisqualified)
      this.newhireFormGroup.controls['statutorilyDisqualifiedComments'].setValue(this.initialNewHireList.statutorilyDisqualifiedComments)
      this.newhireFormGroup.controls['stateRegistrationMemoSent'].setValue(this.initialNewHireList.stateRegistrationMemoSent)
      this.newhireFormGroup.controls['stateRegistrationMemoSentComments'].setValue(this.initialNewHireList.stateRegistrationMemoSentComments)
      this.additionalCommentsFinraLicenses2 = this.initialNewHireList.additionalCommentsFinraLicenses2;
      this.newhireFormGroup.controls['TheEmployeeAddedSupervision'].setValue(this.initialNewHireList.TheEmployeeAddedSupervision)
      this.newhireFormGroup.controls['TheEmployeeAddedSupervisionComment'].setValue(this.initialNewHireList.TheEmployeeAddedSupervisionComment)
      this.newhireFormGroup.controls['LegalHold'].setValue(this.initialNewHireList.LegalHold)
      this.newhireFormGroup.controls['LegalHoldComment'].setValue(this.initialNewHireList.LegalHoldComment)
      this.newhireFormGroup.controls['ConfirmedCommunications'].setValue(this.initialNewHireList.ConfirmedCommunications)
      this.newhireFormGroup.controls['ConfirmedCommunicationsComment'].setValue(this.initialNewHireList.ConfirmedCommunicationsComment)
      this.newhireFormGroup.controls['DeloitteMobileSmarsh'].setValue(this.initialNewHireList.DeloitteMobileSmarsh)
      this.newhireFormGroup.controls['DeloitteMobileSmarshComment'].setValue(this.initialNewHireList.DeloitteMobileSmarshComment)
      this.newhireFormGroup.controls['DeloittePhoneNumber'].setValue(this.initialNewHireList.DeloittePhoneNumber)
      this.newhireFormGroup.controls['NAStatus'].setValue(this.initialNewHireList.NAStatus)
      this.additionalCommentsCommunication = this.initialNewHireList.additionalCommentsCommunication;
      this.newhireFormGroup.controls['BrokerageAccount'].setValue(this.initialNewHireList.BrokerageAccount)
      this.newhireFormGroup.controls['BrokerageAccountComment'].setValue(this.initialNewHireList.BrokerageAccountComment)
      this.newhireFormGroup.controls['OBAdisclosure'].setValue(this.initialNewHireList.OBAdisclosure)
      this.newhireFormGroup.controls['OBAdisclosureComment'].setValue(this.initialNewHireList.OBAdisclosureComment)
      this.newhireFormGroup.controls['PSTdisclosure'].setValue(this.initialNewHireList.PSTdisclosure)
      this.newhireFormGroup.controls['PSTdisclosureComment'].setValue(this.initialNewHireList.PSTdisclosureComment)
      this.additionalCommentsBrokarage = this.initialNewHireList.additionalCommentsBrokarage;
      this.newhireFormGroup.controls['CSIAttestations'].setValue(this.initialNewHireList.CSIAttestations)
      this.newhireFormGroup.controls['CSIAttestationsComment'].setValue(this.initialNewHireList.CSIAttestationsComment)
      this.newhireFormGroup.controls['NewhireQuestCE'].setValue(this.initialNewHireList.NewhireQuestCE)
      this.newhireFormGroup.controls['NewhireQuestCEComment'].setValue(this.initialNewHireList.NewhireQuestCEComment)
      this.newhireFormGroup.controls['dateOfComplianceTraining'].setValue(this.initialNewHireList.dateOfComplianceTraining==null?"":this.dateFormatterService.formatDateOnRetrieve(this.initialNewHireList.dateOfComplianceTraining))
      this.additionalCommentsComplianceTraining=this.initialNewHireList.additionalCommentsComplianceTraining;
      this.signatureDate=this.initialNewHireList.SignatureDate == null ? '' : this.dateService.FormatDate(new Date(this.initialNewHireList.SignatureDate).toString());
      this.signatureName = this.initialNewHireList.SignatureName;
      this.PopulateOnchangeEvents();
      this.spinnerService.hideAutoSaveSpinner();
    }
    PopulateOnchangeEvents(){

      if(this.initialNewHireList.RegStatus=="Registered"){this.isRegisteredStatus = true;}
      if(this.initialNewHireList.RegStatus=="NRF"){this.isNRFStatus = true;}
      if(this.initialNewHireList.StatusOfIndividualNRF=="Other"){this.isOtherNRFStatus=true;}
      if(this.initialNewHireList.NonRegisteredBranchOffice=="Yes"){this.isNonRegisteredBranchOffice=true;}
      if(this.initialNewHireList.series24BranchSupervisorNotified=="No"){this.isseries24BranchSupervisorNotified=true;}
      if(this.initialNewHireList.welcomeEmail=="No"){this.iswelcomeEmailSent=true;}
      if(this.initialNewHireList.backgroundCheck=="No"){this.isbackgroundCheck=true;}
      if(this.initialNewHireList.deloitteApplication=="No"){this.isdeloitteApplication=true;}  
      if(this.initialNewHireList.LexisNexis=="No"){this.isLexisNexis=true;}
      if(this.initialNewHireList.socialmediaCheck=="No"){this.isSocialmediaCheck=true;}  
      if(this.initialNewHireList.ActiveDirectory=="No"){this.isActiveDirectory=true;} 
      if(this.initialNewHireList.KnowledgeCenter=="No"){this.isKnowledgeCenter=true;}   
      if(this.initialNewHireList.DFlowPermissionsSpreadsheet=="No"){this.isDFlowPermissionsSpreadsheet=true;}
      if(this.initialNewHireList.SupervisoryOrgChart=="No"){this.isSupervisoryOrgChart=true;}
      if (this.initialNewHireList.CSI== "No") { this.isCSI = true; }
      if (this.initialNewHireList.Quest== "No") { this.isQuest = true; }
      if(this.initialNewHireList.bigEmailOrHardcopy=="No"){this.isbigEmailOrHardcopy=true;}  
      if(this.initialNewHireList.fingerprintsClearedWithFINRA=="No"){this.isfingerprintsClearedWithFINRA=true;}  
      if(this.initialNewHireList.FinraLicense=="Yes"){this.isFinraLicense=true;} 
      if(this.initialNewHireList.BrokerCheck=="No"){this.isBrokerCheck=true;}   
      if(this.initialNewHireList.FormU5=="No"){this.isFormU5=true;} 
      if (this.initialNewHireList.finraLicenses && this.initialNewHireList.finraLicenses.includes("Other")) {
        this.isOtherSelected = true;
      }
      if(this.initialNewHireList.studyMaterialsOrdered=="No"){this.isStudyMaterialsOrdered=true;}   
      if(this.initialNewHireList.initialFormU4NRFFilingComplete=="No"){this.isInitialFormU4NRFFilingComplete=true;}   
      if(this.initialNewHireList.stateRegistrationMemoSent=="No"){this.isStateRegistrationMemoSent=true;}
      if(this.initialNewHireList.TheEmployeeAddedSupervision=="No"){this.isTheEmployeeAddedSupervisionComment=true;}
      if(this.initialNewHireList.LegalHold=="No"){this.isLegalHoldComment=true;}
      if(this.initialNewHireList.ConfirmedCommunications=="No"){this.isConfirmedCommunicationsComment=true;}
      if(this.initialNewHireList.DeloitteMobileSmarsh=="No"){this.isDeloitteMobileSmarshComment=true;}  
      if(this.initialNewHireList.DeloitteMobileSmarsh=="Yes"){this.isNAStatus=true;}
      if(this.initialNewHireList.BrokerageAccount=="No"){this.isBrokerageAccountComment=true;}  
      if(this.initialNewHireList.OBAdisclosure=="No"){this.isOBAdisclosureComment=true;} 
      if(this.initialNewHireList.PSTdisclosure=="No"){this.isPSTdisclosureComment=true;}   
      if(this.initialNewHireList.CSIAttestations=="No"){this.isCSIAttestationsComment=true;}
      if(this.initialNewHireList.NewhireQuestCE=="No"){this.isNewhireQuestCEComment=true;}
      if(this.initialNewHireList.statutorilyDisqualified=="Yes"){this.isStatutorilyDisqualified=true;}
    }
  setValidators(){
    Object.keys(this.newhireFormGroup.controls).forEach(formControlName => {
      const controlValidators: ValidatorFn = CustomValidators.InputWhithoutWhiteSpaces;
        this.newhireFormGroup.get(formControlName)?.setValidators(controlValidators);
        this.newhireFormGroup.get(formControlName)?.updateValueAndValidity();
      })
  }
  IsNewHireFormValid(): any{
    this.setValidators();
  if(this.newhireFormGroup.controls["NonRegisteredBranchOffice"].value!="Yes"){
    this.newhireFormGroup.controls["NonRegisteredBranchOfficeComments"].setErrors(null);
  }
  if (["Charlotte", "Chicago", "Dallas", "Los Angeles", "New York"].includes(this.newhireFormGroup.controls["supervisoryBranchOffice"].value)) {
    this.newhireFormGroup.controls["supervisoryBranchOffice"].setErrors(null);
  }
  if (["Blanchard, Anthony", "Flanigan, Kevan", "Frame, Will", "Gisby, Simon", "Miller, James","Preece, Matt"].includes(this.newhireFormGroup.controls["series24BranchSupervisor"].value)) {
    this.newhireFormGroup.controls["series24BranchSupervisor"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["series24BranchSupervisorNotified"].value!="No"){
    this.newhireFormGroup.controls["series24BranchSupervisorNotifiedComments"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["welcomeEmail"].value!="No"){
    this.newhireFormGroup.controls["welcomeEmailComments"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["backgroundCheck"].value!="No"){
    this.newhireFormGroup.controls["backgroundCheckComments"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["deloitteApplication"].value!="No"){
    this.newhireFormGroup.controls["deloitteApplicationComments"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["LexisNexis"].value!="No"){
    this.newhireFormGroup.controls["LexisNexisComments"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["socialmediaCheck"].value!="No"){
    this.newhireFormGroup.controls["socialmediaCheckComments"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["ActiveDirectory"].value!="No"){
    this.newhireFormGroup.controls["ActiveDirectoryComments"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["KnowledgeCenter"].value!="No"){
    this.newhireFormGroup.controls["KnowledgeCenterComments"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["DFlowPermissionsSpreadsheet"].value!="No"){
    this.newhireFormGroup.controls["DFlowPermissionsSpreadsheetComments"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["SupervisoryOrgChart"].value!="No"){
    this.newhireFormGroup.controls["SupervisoryOrgChartComments"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["CSI"].value!="No"){
    this.newhireFormGroup.controls["CSIComments"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["Quest"].value!="No"){
    this.newhireFormGroup.controls["QuestComments"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["bigEmailOrHardcopy"].value!="No"){
    this.newhireFormGroup.controls["bigEmailOrHardcopyComments"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["fingerprintsClearedWithFINRA"].value!="No"){
    this.newhireFormGroup.controls["fingerprintsClearedWithFINRAComments"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["FinraLicense"].value=="No"){
    this.newhireFormGroup.controls["BrokerCheck"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["FinraLicense"].value=="No"){
    this.newhireFormGroup.controls["FormU5"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["FinraLicense"].value=="No"){
    this.newhireFormGroup.controls["FinraCEInactive"].setErrors(null);
  }
    if(this.newhireFormGroup.controls["FinraLicense"].value=="No"){
    this.newhireFormGroup.controls["OutstandingFinraCE"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["FinraCEInactive"].value!="Yes"){
    this.newhireFormGroup.controls["OutstandingFinraCE"].setErrors(null);
  }
  // if(this.newhireFormGroup.controls["BrokerCheck"].value=="No"||this.newhireFormGroup.controls["BrokerCheck"].value==""){
  //   this.newhireFormGroup.controls["BrokerCheck"].setErrors(null);
  //   this.newhireFormGroup.controls["BrokerCheckComments"].setErrors(null);
  // }
  if(this.newhireFormGroup.controls["BrokerCheck"].value!="No"||this.newhireFormGroup.controls["BrokerCheck"].value==""){
    this.newhireFormGroup.controls["BrokerCheckComments"].setErrors(null);
  }
  // if(this.newhireFormGroup.controls["FormU5"].value=="No"||this.newhireFormGroup.controls["FormU5"].value==""){
  //   this.newhireFormGroup.controls["FormU5"].setErrors(null);
  //   this.newhireFormGroup.controls["FormU5Comments"].setErrors(null);
  // }
  if(this.newhireFormGroup.controls["FormU5"].value!="No"||this.newhireFormGroup.controls["FormU5"].value==""){
    this.newhireFormGroup.controls["FormU5Comments"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["studyMaterialsOrdered"].value!="No"){
    this.newhireFormGroup.controls["studyMaterialsOrderedComments"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["initialFormU4NRFFilingComplete"].value!="No"){
    this.newhireFormGroup.controls["initialFormU4NRFFilingCompleteComments"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["statutorilyDisqualified"].value!="Yes"){
    this.newhireFormGroup.controls["statutorilyDisqualifiedComments"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["stateRegistrationMemoSent"].value!="No"){
    this.newhireFormGroup.controls["stateRegistrationMemoSentComments"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["RegStatus"].value=="Registered"){
    this.newhireFormGroup.controls["NRFStatus"].setErrors(null);
    this.newhireFormGroup.controls["otherNRFStatusReason"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["RegStatus"].value=="NRF"){
    this.newhireFormGroup.controls["RegisteredStatus"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["NRFStatus"].value=="Intern"||this.newhireFormGroup.controls["NRFStatus"].value=="USI"||
    this.newhireFormGroup.controls["NRFStatus"].value=="BDA Contractor"){
      this.newhireFormGroup.controls["otherNRFStatusReason"].setErrors(null);
    }
  if(this.newhireFormGroup.controls["TheEmployeeAddedSupervision"].value!="No"){
    this.newhireFormGroup.controls["TheEmployeeAddedSupervisionComment"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["LegalHold"].value!="No"){
    this.newhireFormGroup.controls["LegalHoldComment"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["ConfirmedCommunications"].value!="No"){
    this.newhireFormGroup.controls["ConfirmedCommunicationsComment"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["DeloitteMobileSmarsh"].value!="No"){
    this.newhireFormGroup.controls["DeloitteMobileSmarshComment"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["DeloitteMobileSmarsh"].value!="Yes"){
    this.newhireFormGroup.controls["NAStatus"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["DeloitteMobileSmarsh"].value!="Yes"){
    this.newhireFormGroup.controls["DeloittePhoneNumber"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["BrokerageAccount"].value!="N/A"){
    this.newhireFormGroup.controls["BrokerageAccountComment"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["OBAdisclosure"].value!="No"){
    this.newhireFormGroup.controls["OBAdisclosureComment"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["PSTdisclosure"].value!="No"){
    this.newhireFormGroup.controls["PSTdisclosureComment"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["CSIAttestations"].value!="No"){
    this.newhireFormGroup.controls["CSIAttestationsComment"].setErrors(null);
  }
  if(this.newhireFormGroup.controls["NewhireQuestCE"].value!="No"){
    this.newhireFormGroup.controls["NewhireQuestCEComment"].setErrors(null);
  }
  // if (["N/A", "SIE", "S7", "S79", "S63","S24"].includes(this.newhireFormGroup.controls["finraLicenses"].value)) {
  //   this.newhireFormGroup.controls["otherFinraLicense"].setErrors(null);
  // }
  // if(this.newhireFormGroup.controls["finraLicenses"].value=="Other"){
  //   this.newhireFormGroup.controls["otherFinraLicense"].setErrors({ required: true });
  // }
  // Add validation for finraLicenses and otherFinraLicense
  const finraLicensesControl = this.newhireFormGroup.get('finraLicenses');
  const otherFinraLicenseControl = this.newhireFormGroup.get('otherFinraLicense');
  
  if (!this.isAnyFinraLicenseSelected()) {
      finraLicensesControl?.setErrors({ required: true });
      } else {
          finraLicensesControl?.setErrors(null);
      }
  
  if (this.isOtherSelected && !otherFinraLicenseControl?.value) {
      otherFinraLicenseControl?.setErrors({ required: true });
      } else {
          otherFinraLicenseControl?.setErrors(null);
      }
    
  if(this.newhireFormGroup.valid){return true;}else{return false;}
}
showMsgs(msg: any, msgType: any) {
  this.newHireFormPopupComponent.openPopup(msg, msgType)
}
OnSaveForm(event:any){
  this.isSaveAndUpdate=this.actionType=="Submit"?false:true;
  this.spinnerService.showAutoSaveSpinner();
    if(this.formID==undefined){
      this.saveForm();
    }
    else{
      this.submitted=this.initialNewHireList.FormStatus=="Completed"?true:false;
      if(this.submitted){
        this.isSaveAndUpdate=true;
        this.initialSubmit=false;
        if(this.IsNewHireFormValid()){
          let differences=this._findDifferencesBeforeSaveData();
          this._updateFormItem(differences);
        }
        else{
          this.spinnerService.hideAutoSaveSpinner();
          this.showMsgs("newhire", "Info");
        }
      }
      else{
        this.isSaveAndUpdate=false;
        let differences=this._findDifferencesBeforeSaveData();
        this._updateFormItem(differences);
      }    
    }
}
private _updateFormItem(differences:any): any {
  const objectNewDeal = differences.newHireFormDifferences;
  const newDealLength = Object.keys(objectNewDeal).length;   
  if(newDealLength==0) this.spinnerService.hideAutoSaveSpinner();     
 (objectNewDeal!=null && objectNewDeal!=undefined && newDealLength>0) ? this.updateForm(this.formID, objectNewDeal,this.rangeKey) : this.showMsgs("newhire","NoUpdate");  
}
updateForm( formId: number, currentUpdates: any,rangeKey:any,initiateWorkflow:boolean=false): any {
  const userEmail = localStorage.getItem("userProfileId");
  const userName = localStorage.getItem("AuditorName");
  currentUpdates.Email = userEmail;
  currentUpdates.ModifiedBy = userName;
  // Convert finraLicenses array to a string before sending to the backend
  if (Array.isArray(currentUpdates.finraLicenses)) {
    currentUpdates.finraLicenses = currentUpdates.finraLicenses.join(',');
  }
  const data =JSON.stringify({
    formId:formId,
    updateData:this.cleanJsonObject(currentUpdates),
    rangeKey:rangeKey,
    initiateWorkflow: initiateWorkflow,
  });
  this.newhireFormService.UpdateFormDetails(data).subscribe({
    next: (result: any) => {
      this.formID = result[0].ID;
      if(result[0].FormStatus == "Completed")
      {
        this.isExportEnabled = true;
      }
      else{
        this.isExportEnabled = false;
      }
      this.initialNewHireList=result[0];
      this.spinnerService.hideAutoSaveSpinner();
      this.LoadComparedObjectFields();
      this.MapToFormGroup();
      if(this.submitted){
        //this.BindSignatureTableAndEnableExport();
      }
      if(this.actionType=="Submit"&&!this.isSaveAndUpdate)
      {
        this.showMsgs("newhire", "SubmitSuccess");
      }
      else
      {
        this.showMsgs("newhire", "UpdateSuccess");
      }
    },
    error: (err: any) => {
      this.spinnerService.hideAutoSaveSpinner();
      console.log("Error" + err);
      if (err.status == 403 && err.error.stringKey == AppSettings.tokenExpiredText) {
        this._openSessionExpiredModal();
      }
    },
  });
}

cleanJsonObject(obj:any) {
  for (const key in obj) {
    if (obj[key] === null||obj[key] === undefined) {
      obj[key] = '';
    } else if (typeof obj[key] === 'object') {
      this.cleanJsonObject(obj[key]);
    }
  }
  return obj;
}
private _findDifferencesBeforeSaveData() {
  const currentNewHireSetupObject = this.createFormObject();
  const newHireFormDifferences = this._getDifferences(this.initialNewHireList, currentNewHireSetupObject);
  return {newHireFormDifferences};
}
private _getDifferences(initialData:any, currentData:any) {
  const differences:any = {};
  for (const input in initialData) {
    if(input != "Modified" && input !="EmailSent") //Modfied,EmailSent will be added from api side
    {
      if(initialData[input]==null&&currentData[input]==""){}
      else if (initialData[input] !== currentData[input]) {
        differences[input] = currentData[input];
      }
    }
  }
  return differences;
}
saveForm(){
  let formObj:any = this.createFormObject();
  formObj.CreatedBy=localStorage.getItem("AuditorName")?.toString();
  this.createSaveForm(formObj);
}
createSaveForm(formObj: any): any {
  const userEmail = localStorage.getItem("userProfileId");
  formObj.Email = userEmail;
  this.newhireFormService.SaveFormDetails(formObj).subscribe(
    {
      next: (result: any) => {
        this.formID = result.ID;
        this.initialNewHireList=result;
        this.LoadComparedObjectFields();
        this.MapToFormGroup();
        this.spinnerService.hideAutoSaveSpinner();
        if (this.actionType == "Submit") {
          this.showMsgs("newhire", "SubmitSuccess");
        }else{
          this.showMsgs("newhire", "SaveSuccess");
        }
      },
      error: (err: any) => {
        this.spinnerService.hideAutoSaveSpinner();
        console.log("Error" + err);
        if (err.status == 403 && err.error.stringKey == AppSettings.tokenExpiredText) {
          this._openSessionExpiredModal();
        }
      }
    }
  );
}
private _openSessionExpiredModal() {
  this.newHireFormPopupComponent.openPopup(AppSettings.tokenExpiredText);
}

LoadComparedObjectFields(){
  this.rangeKey=this.initialNewHireList.Created;
  delete this.initialNewHireList.Created;
  delete this.initialNewHireList.CreatedBy;
  delete this.initialNewHireList.Modified;
 }

private _isInAdGroup(email:any,groups:Array<any>): boolean {

  return !!groups.find(group => group.userPrincipalName.toLowerCase() === email.toLowerCase());
}

  getTerminationFormAuditDetials(): void {
    // Handle audit details
  }


  onRSChange(event:any){
    // this.formID==undefined?this.newhireFormGroup.get("RegisteredStatus")?.setValue(""):this.newhireFormGroup.get('StatusOfIndividualSupervisory')?.value==null?this.newhireFormGroup.get("RegisteredStatus")?.setValue(""):"";
    // this.formID==undefined?this.newhireFormGroup.get("NRFStatus")?.setValue(""):this.newhireFormGroup.get('StatusOfIndividualNRF')?.value==null?this.newhireFormGroup.get("NRFStatus")?.setValue(""):"";
    if(event.target.value=="Registered"){
      this.isRegisteredStatus = true;
      this.isNRFStatus = false;
    }
    else{
      this.isNRFStatus = true;
      this.isRegisteredStatus = false;
    }
    this.isOtherNRFStatus = this.formID==undefined?false:this.initialNewHireList.StatusOfIndividualNRF=="Other"&&this.newhireFormGroup.get("NRFStatus")?.value=="Other"?true:false;
    this.newhireFormGroup.get('RegStatus')?.setValue(event.target.value);
  }
  onNRFStatusChange(event:any){
    if(event.target.value=="Other"){
      this.isOtherNRFStatus = true;
      this.newhireFormGroup.controls["otherNRFStatusReason"].setErrors({required:true});
    }
    else{
      this.formID==undefined?this.newhireFormGroup.controls["otherNRFStatusReason"].setValue(""):this.initialNewHireList.StatusOfIndividualNRFOther==null?this.newhireFormGroup.controls["otherNRFStatusReason"].setValue(""):"";
      this.isOtherNRFStatus = false;
    }
    this.newhireFormGroup.get('NRFStatus')?.setValue(event.target.value);
  }
  onFinraCEInactiveChange(event: any): void {
    const value = event.target.value;
    this.isFinraCEInactive = value === 'Yes';
    if (value === 'Yes') {
      this.newhireFormGroup.controls['OutstandingFinraCE'].setValidators([Validators.required]);
    } else {
      this.newhireFormGroup.controls['OutstandingFinraCE'].clearValidators();
      this.newhireFormGroup.controls['OutstandingFinraCE'].setValue('');
    }
    this.newhireFormGroup.controls['OutstandingFinraCE'].updateValueAndValidity();
  }
  isAnyFinraLicenseSelected(): boolean {
    const selectedValues = this.newhireFormGroup.get('finraLicenses')?.value;
    return selectedValues && selectedValues.length > 0;
  }
  //    onFinraLicenseCheckboxChange(event: any): void {
  //     const inputElement = event.target as HTMLInputElement;
  //     const value = inputElement.value;
  
  //     // Ensure selectedLicenses is always an array
  //     let selectedLicenses = this.newhireFormGroup.get('finraLicenses')?.value || [];
  //     if (typeof selectedLicenses === 'string') {
  //         selectedLicenses = selectedLicenses.split(',').filter((item) => item.trim() !== '');
  //     }
  
  //     if (inputElement.checked) {
  //         selectedLicenses.push(value);
  //     } else {
  //         const index = selectedLicenses.indexOf(value);
  //         if (index > -1) {
  //             selectedLicenses.splice(index, 1);
  //         }
  //     }
  
  //     // Update the "Other" field logic
  //     this.isOtherSelected = selectedLicenses.includes('Other');
  //     if (this.isOtherSelected) {
  //         this.newhireFormGroup.get('otherFinraLicense')?.enable();
  //         this.newhireFormGroup.get('otherFinraLicense')?.setValidators([Validators.required]);
  //     } else {
  //         this.newhireFormGroup.get('otherFinraLicense')?.disable();
  //         this.newhireFormGroup.get('otherFinraLicense')?.setValue('');
  //         this.newhireFormGroup.get('otherFinraLicense')?.clearValidators();
  //     }
  
  //     // Convert the array to a comma-separated string
  //     const selectedLicensesString = selectedLicenses.join(',');
  
  //     // Update the form control with the string value
  //     this.newhireFormGroup.get('finraLicenses')?.setValue(selectedLicensesString);
  //     this.newhireFormGroup.get('finraLicenses')?.updateValueAndValidity();
  //     this.newhireFormGroup.get('otherFinraLicense')?.updateValueAndValidity();
  // }
  onFinraLicenseCheckboxChange(event: any): void {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
  
    // Ensure selectedLicenses is always an array
    let selectedLicenses = this.newhireFormGroup.get('finraLicenses')?.value || [];
    if (typeof selectedLicenses === 'string') {
      selectedLicenses = selectedLicenses.split(',').filter((item) => item.trim() !== '');
    }
  
    if (inputElement.checked) {
      // Add the selected value to the array
      if (!selectedLicenses.includes(value)) {
        selectedLicenses.push(value);
      }
    } else {
      // Remove the deselected value from the array
      const index = selectedLicenses.indexOf(value);
      if (index > -1) {
        selectedLicenses.splice(index, 1);
      }
    }
  
    // Update the "Other" field logic
    this.isOtherSelected = selectedLicenses.includes('Other');
    if (this.isOtherSelected) {
      this.newhireFormGroup.get('otherFinraLicense')?.enable();
      this.newhireFormGroup.get('otherFinraLicense')?.setValidators([Validators.required]);
    } else {
      this.newhireFormGroup.get('otherFinraLicense')?.disable();
      this.newhireFormGroup.get('otherFinraLicense')?.setValue(''); // Clear the value if "Other" is unchecked
      this.newhireFormGroup.get('otherFinraLicense')?.clearValidators();
    }
  
    // Update the form control with the array value
    this.newhireFormGroup.get('finraLicenses')?.setValue(selectedLicenses);
    this.newhireFormGroup.get('finraLicenses')?.updateValueAndValidity();
    this.newhireFormGroup.get('otherFinraLicense')?.updateValueAndValidity();
    }
  onNonRegisteredBranchOfficeChange(event: any): void {
    this.isNonRegisteredBranchOffice = event.target.value === 'Yes';
    if (this.isNonRegisteredBranchOffice) {
      this.newhireFormGroup.get('NonRegisteredBranchOfficeComments')?.setValidators([Validators.required]);
    } else {
      this.newhireFormGroup.get('NonRegisteredBranchOfficeComments')?.clearValidators();
      this.newhireFormGroup.get('NonRegisteredBranchOfficeComments')?.setValue('');
    }
    this.newhireFormGroup.get('NonRegisteredBranchOfficeComments')?.updateValueAndValidity();
  }
  onsupervisoryBranchOfficeChange(event: any): void {
    const value = event.value;
    this.newhireFormGroup.get('supervisoryBranchOffice')?.setValue(value);
    if (["Charlotte", "Chicago", "Dallas", "Los Angeles", "New York"].includes(value)) {
      this.newhireFormGroup.get('supervisoryBranchOffice')?.setErrors(null);
    }
    this.newhireFormGroup.get('supervisoryBranchOffice')?.updateValueAndValidity();
  }
  onseries24BranchSupervisorChange(event: any): void {
    const value = event.value;
    this.newhireFormGroup.get('series24BranchSupervisor')?.setValue(value);
    if (["Blanchard, Anthony", "Flanigan, Kevan", "Frame, Will", "Gisby, Simon", "Miller, James","Preece, Matt"].includes(value)) {
      this.newhireFormGroup.get('series24BranchSupervisor')?.setErrors(null);
    }
    this.newhireFormGroup.get('series24BranchSupervisor')?.updateValueAndValidity();
  }
  onSeries24BranchSupervisorNotifiedChange(event:any){
    if(event.target.value=="No"){
      this.isseries24BranchSupervisorNotified = true;
     this.newhireFormGroup.controls["series24BranchSupervisorNotifiedComments"].setErrors({required:true});
    }
    else{
      this.isseries24BranchSupervisorNotified = false;
      this.formID==undefined?this.newhireFormGroup.controls["series24BranchSupervisorNotifiedComments"].setValue(""):this.initialNewHireList.SupervisionGroupRemovedDateOther==null?this.newhireFormGroup.controls["series24BranchSupervisorNotifiedComments"].setValue(""):"";
    }
    this.newhireFormGroup.get('series24BranchSupervisorNotified')?.setValue(event.target.value);
  }

  onwelcomeEmailChange(event:any){
    if(event.target.value=="No"){
      this.iswelcomeEmailSent = true;
     this.newhireFormGroup.controls["welcomeEmailComments"].setErrors({required:true});
    }
    else{
      this.iswelcomeEmailSent = false;
      this.formID==undefined?this.newhireFormGroup.controls["welcomeEmailComments"].setValue(""):this.initialNewHireList.SupervisionGroupRemovedDateOther==null?this.newhireFormGroup.controls["welcomeEmailComments"].setValue(""):"";
    }
    this.newhireFormGroup.get('welcomeEmail')?.setValue(event.target.value);
  }

  onbackgroundCheckChange(event:any){
    if(event.target.value=="No"){
      this.isbackgroundCheck = true;
     this.newhireFormGroup.controls["backgroundCheckComments"].setErrors({required:true});
    }
    else{
      this.isbackgroundCheck = false;
      this.formID==undefined?this.newhireFormGroup.controls["backgroundCheckComments"].setValue(""):this.initialNewHireList.SupervisionGroupRemovedDateOther==null?this.newhireFormGroup.controls["backgroundCheckComments"].setValue(""):"";
    }
    this.newhireFormGroup.get('backgroundCheck')?.setValue(event.target.value);
  }

     ondeloitteApplicationChange(event:any){
    if(event.target.value=="No"){
      this.isdeloitteApplication = true;
     this.newhireFormGroup.controls["deloitteApplicationComments"].setErrors({required:true});
    }
    else{
      this.isdeloitteApplication = false;
      this.formID==undefined?this.newhireFormGroup.controls["deloitteApplicationComments"].setValue(""):this.initialNewHireList.SupervisionGroupRemovedDateOther==null?this.newhireFormGroup.controls["deloitteApplicationComments"].setValue(""):"";
    }
    this.newhireFormGroup.get('deloitteApplication')?.setValue(event.target.value);
  }

  onSocialmediaCheckChange(event:any){
    if(event.target.value=="No"){
      this.isSocialmediaCheck = true;
     this.newhireFormGroup.controls["socialmediaCheckComments"].setErrors({required:true});
    }
    else{
      this.isSocialmediaCheck = false;
      this.formID==undefined?this.newhireFormGroup.controls["socialmediaCheckComments"].setValue(""):this.initialNewHireList.SupervisionGroupRemovedDateOther==null?this.newhireFormGroup.controls["socialmediaCheckComments"].setValue(""):"";
    }
    this.newhireFormGroup.get('socialmediaCheck')?.setValue(event.target.value);
  }


  onActiveDirectoryChange(event:any){
    if(event.target.value=="No"){
      this.isActiveDirectory = true;
     this.newhireFormGroup.controls["ActiveDirectoryComments"].setErrors({required:true});
    }
    else{
      this.isActiveDirectory = false;
      this.formID==undefined?this.newhireFormGroup.controls["ActiveDirectoryComments"].setValue(""):this.initialNewHireList.SupervisionGroupRemovedDateOther==null?this.newhireFormGroup.controls["ActiveDirectoryComments"].setValue(""):"";
    }
    this.newhireFormGroup.get('ActiveDirectory')?.setValue(event.target.value);
  }


  onKnowledgeCenterChange(event:any){
    if(event.target.value=="No"){
      this.isKnowledgeCenter = true;
     this.newhireFormGroup.controls["KnowledgeCenterComments"].setErrors({required:true});
    }
    else{
      this.isKnowledgeCenter = false;
      this.formID==undefined?this.newhireFormGroup.controls["KnowledgeCenterComments"].setValue(""):this.initialNewHireList.SupervisionGroupRemovedDateOther==null?this.newhireFormGroup.controls["KnowledgeCenterComments"].setValue(""):"";
    }
    this.newhireFormGroup.get('KnowledgeCenter')?.setValue(event.target.value);
  }

  onDFlowPermissionsSpreadsheetChange(event:any){
    if(event.target.value=="No"){
      this.isDFlowPermissionsSpreadsheet = true;
     this.newhireFormGroup.controls["DFlowPermissionsSpreadsheetComments"].setErrors({required:true});
    }
    else{
      this.isDFlowPermissionsSpreadsheet = false;
      this.formID==undefined?this.newhireFormGroup.controls["DFlowPermissionsSpreadsheetComments"].setValue(""):this.initialNewHireList.SupervisionGroupRemovedDateOther==null?this.newhireFormGroup.controls["DFlowPermissionsSpreadsheetComments"].setValue(""):"";
    }
    this.newhireFormGroup.get('DFlowPermissionsSpreadsheet')?.setValue(event.target.value);
  }

  onSupervisoryOrgChartChange(event:any){
    if(event.target.value=="No"){
      this.isSupervisoryOrgChart = true;
     this.newhireFormGroup.controls["SupervisoryOrgChartComments"].setErrors({required:true});
    }
    else{
      this.isSupervisoryOrgChart = false;
      this.formID==undefined?this.newhireFormGroup.controls["SupervisoryOrgChartComments"].setValue(""):this.initialNewHireList.SupervisionGroupRemovedDateOther==null?this.newhireFormGroup.controls["SupervisoryOrgChartComments"].setValue(""):"";
    }
    this.newhireFormGroup.get('SupervisoryOrgChart')?.setValue(event.target.value);
  }


  onCSIChange(event:any){
    if(event.target.value=="No"){
      this.isCSI = true;
     this.newhireFormGroup.controls["CSIComments"].setErrors({required:true});
    }
    else{
      this.isCSI = false;
      this.formID==undefined?this.newhireFormGroup.controls["CSIComments"].setValue(""):this.initialNewHireList.SupervisionGroupRemovedDateOther==null?this.newhireFormGroup.controls["CSIComments"].setValue(""):"";
    }
    this.newhireFormGroup.get('CSI')?.setValue(event.target.value);
  }
  onQuestChange(event:any){
    if(event.target.value=="No"){
      this.isQuest = true;
     this.newhireFormGroup.controls["QuestComments"].setErrors({required:true});
    }
    else{
      this.isQuest = false;
      this.formID==undefined?this.newhireFormGroup.controls["QuestComments"].setValue(""):this.initialNewHireList.SupervisionGroupRemovedDateOther==null?this.newhireFormGroup.controls["QuestComments"].setValue(""):"";
    }
    this.newhireFormGroup.get('Quest')?.setValue(event.target.value);
  }

  onbigEmailOrHardcopyChange(event:any){
    if(event.target.value=="No"){
      this.isbigEmailOrHardcopy = true;
     this.newhireFormGroup.controls["bigEmailOrHardcopyComments"].setErrors({required:true});
    }
    else{
      this.isbigEmailOrHardcopy = false;
      this.formID==undefined?this.newhireFormGroup.controls["bigEmailOrHardcopyComments"].setValue(""):this.initialNewHireList.SupervisionGroupRemovedDateOther==null?this.newhireFormGroup.controls["bigEmailOrHardcopyComments"].setValue(""):"";
    }
    this.newhireFormGroup.get('bigEmailOrHardcopy')?.setValue(event.target.value);
  }

  onfingerprintsClearedWithFINRAChange(event:any){
    if(event.target.value=="No"){
      this.isfingerprintsClearedWithFINRA = true;
     this.newhireFormGroup.controls["fingerprintsClearedWithFINRAComments"].setErrors({required:true});
    }
    else{
      this.isfingerprintsClearedWithFINRA = false;
      this.formID==undefined?this.newhireFormGroup.controls["fingerprintsClearedWithFINRAComments"].setValue(""):this.initialNewHireList.SupervisionGroupRemovedDateOther==null?this.newhireFormGroup.controls["fingerprintsClearedWithFINRAComments"].setValue(""):"";
    }
    this.newhireFormGroup.get('fingerprintsClearedWithFINRA')?.setValue(event.target.value);
  }


    onFinraLicenseChange(event: any): void {
      const value = event.target.value;
      this.isFinraLicense = value === 'Yes';
      if (value === 'Yes') {
        this.newhireFormGroup.get('BrokerCheck')?.setValidators([Validators.required]);
        this.newhireFormGroup.get('FormU5')?.setValidators([Validators.required]);
        this.newhireFormGroup.get('FinraCEInactive')?.setValidators([Validators.required]);
      } else {
        this.newhireFormGroup.get('BrokerCheck')?.clearValidators();
        this.newhireFormGroup.get('FormU5')?.clearValidators();
        this.newhireFormGroup.get('FinraCEInactive')?.clearValidators();
      }
      this.newhireFormGroup.get('BrokerCheck')?.updateValueAndValidity();
      this.newhireFormGroup.get('FormU5')?.updateValueAndValidity();
      this.newhireFormGroup.get('FinraCEInactive')?.updateValueAndValidity();
    }
    onBrokerCheckChange(event:any){
      if(event.target.value=="No"){
        this.isBrokerCheck = true;
       this.newhireFormGroup.controls["BrokerCheckComments"].setErrors({required:true});
      }
      else{
        this.isBrokerCheck = false;
        this.formID==undefined?this.newhireFormGroup.controls["BrokerCheckComments"].setValue(""):this.initialNewHireList.BrokerCheckComments==null?this.newhireFormGroup.controls["BrokerCheckComments"].setValue(""):"";
      }
      this.newhireFormGroup.get('BrokerCheck')?.setValue(event.target.value);
    }
  
    
    onFormU5Change(event:any){
      if(event.target.value=="No"){
        this.isFormU5 = true;
       this.newhireFormGroup.controls["FormU5Comments"].setErrors({required:true});
      }
      else{
        this.isFormU5 = false;
        this.formID==undefined?this.newhireFormGroup.controls["FormU5Comments"].setValue(""):this.initialNewHireList.FormU5Comments==null?this.newhireFormGroup.controls["FormU5Comments"].setValue(""):"";
      }
      this.newhireFormGroup.get('FormU5')?.setValue(event.target.value);
    }

    onLexisNexisChange(event:any){
      if(event.target.value=="No"){
        this.isLexisNexis = true;
       this.newhireFormGroup.controls["LexisNexisComments"].setErrors({required:true});
      }
      else{
        this.isLexisNexis = false;
        this.formID==undefined?this.newhireFormGroup.controls["LexisNexisComments"].setValue(""):this.initialNewHireList.SupervisionGroupRemovedDateOther==null?this.newhireFormGroup.controls["LexisNexisComments"].setValue(""):"";
      }
      this.newhireFormGroup.get('LexisNexis')?.setValue(event.target.value);
    }

    onStudyMaterialsOrderedChange(event:any){
      if(event.target.value=="No"){
        this.isStudyMaterialsOrdered = true;
       this.newhireFormGroup.controls["studyMaterialsOrderedComments"].setErrors({required:true});
      }
      else{
        this.isStudyMaterialsOrdered = false;
        this.formID==undefined?this.newhireFormGroup.controls["studyMaterialsOrderedComments"].setValue(""):this.initialNewHireList.SupervisionGroupRemovedDateOther==null?this.newhireFormGroup.controls["studyMaterialsOrderedComments"].setValue(""):"";
      }
      this.newhireFormGroup.get('studyMaterialsOrdered')?.setValue(event.target.value);
    }

    onInitialFormU4NRFFilingCompleteChange(event:any){
      if(event.target.value=="No"){
        this.isInitialFormU4NRFFilingComplete = true;
       this.newhireFormGroup.controls["initialFormU4NRFFilingCompleteComments"].setErrors({required:true});
      }
      else{
        this.isInitialFormU4NRFFilingComplete = false;
        this.formID==undefined?this.newhireFormGroup.controls["initialFormU4NRFFilingCompleteComments"].setValue(""):this.initialNewHireList.SupervisionGroupRemovedDateOther==null?this.newhireFormGroup.controls["initialFormU4NRFFilingCompleteComments"].setValue(""):"";
      }
      this.newhireFormGroup.get('initialFormU4NRFFilingComplete')?.setValue(event.target.value);
    }

    onStatutorilyDisqualifiedChange(event:any){
      if(event.target.value=="Yes"){
        this.isStatutorilyDisqualified = true;
       this.newhireFormGroup.controls["statutorilyDisqualifiedComments"].setErrors({required:true});
      }
      else{
        this.isStatutorilyDisqualified = false;
        this.formID==undefined?this.newhireFormGroup.controls["statutorilyDisqualifiedComments"].setValue(""):this.initialNewHireList.SupervisionGroupRemovedDateOther==null?this.newhireFormGroup.controls["statutorilyDisqualifiedComments"].setValue(""):"";
      }
      this.newhireFormGroup.get('statutorilyDisqualified')?.setValue(event.target.value);
    }

    onStateRegistrationMemoSentChange(event:any){
      if(event.target.value=="No"){
        this.isStateRegistrationMemoSent = true;
       this.newhireFormGroup.controls["stateRegistrationMemoSentComments"].setErrors({required:true});
      }
      else{
        this.isStateRegistrationMemoSent = false;
        this.formID==undefined?this.newhireFormGroup.controls["stateRegistrationMemoSentComments"].setValue(""):this.initialNewHireList.SupervisionGroupRemovedDateOther==null?this.newhireFormGroup.controls["stateRegistrationMemoSentComments"].setValue(""):"";
      }
      this.newhireFormGroup.get('stateRegistrationMemoSent')?.setValue(event.target.value);
    }

   onTheEmployeeAddedSupervisionChange(event:any){
    if(event.target.value=="No"){
      this.isTheEmployeeAddedSupervisionComment = true;
     this.newhireFormGroup.controls["TheEmployeeAddedSupervisionComment"].setErrors({required:true});
    }
    else{
      this.isTheEmployeeAddedSupervisionComment = false;
      this.formID==undefined?this.newhireFormGroup.controls["TheEmployeeAddedSupervisionComment"].setValue(""):this.initialNewHireList.TheEmployeeAddedSupervisionComment==null?this.newhireFormGroup.controls["TheEmployeeAddedSupervisionComment"].setValue(""):"";
    }
    this.newhireFormGroup.get('TheEmployeeAddedSupervision')?.setValue(event.target.value);
  }
  onLegalHoldChange(event:any){
    if(event.target.value=="No"){
      this.isLegalHoldComment = true;
     this.newhireFormGroup.controls["LegalHoldComment"].setErrors({required:true});
    }
    else{
      this.isLegalHoldComment = false;
      this.formID==undefined?this.newhireFormGroup.controls["LegalHoldComment"].setValue(""):this.initialNewHireList.LegalHoldComment==null?this.newhireFormGroup.controls["LegalHoldComment"].setValue(""):"";
    }
    this.newhireFormGroup.get('LegalHold')?.setValue(event.target.value);
  }
  onConfirmedCommunicationsChange(event:any){
    if(event.target.value=="No"){
      this.isConfirmedCommunicationsComment = true;
     this.newhireFormGroup.controls["ConfirmedCommunicationsComment"].setErrors({required:true});
    }
    else{
      this.isConfirmedCommunicationsComment = false;
      this.formID==undefined?this.newhireFormGroup.controls["ConfirmedCommunicationsComment"].setValue(""):this.initialNewHireList.ConfirmedCommunicationsComment==null?this.newhireFormGroup.controls["ConfirmedCommunicationsComment"].setValue(""):"";
    }
    this.newhireFormGroup.get('ConfirmedCommunications')?.setValue(event.target.value);
  }
  onDeloitteMobileSmarshChange(event: any) {
    const value = event.target.value;
    if (value === 'Yes') {
        this.isNAStatus = true;
        this.isDeloitteMobileSmarshComment = false;
        this.newhireFormGroup.get('DeloittePhoneNumber')!.setValidators([Validators.required]);
        this.newhireFormGroup.get('NAStatus')!.setValidators([Validators.required]);
    } else if (value === 'No') {
        this.isDeloitteMobileSmarshComment = true;
        this.isNAStatus = false;
        this.newhireFormGroup.get('DeloitteMobileSmarshComment')!.setValidators([Validators.required]);
        this.newhireFormGroup.get('DeloittePhoneNumber')!.clearValidators();
        this.newhireFormGroup.get('NAStatus')!.clearValidators();
    } else {
        this.isDeloitteMobileSmarshComment = false;
        this.isNAStatus = false;
        this.newhireFormGroup.get('DeloitteMobileSmarshComment')!.clearValidators();
        this.newhireFormGroup.get('DeloittePhoneNumber')!.clearValidators();
        this.newhireFormGroup.get('NAStatus')!.clearValidators();
    }
    this.newhireFormGroup.get('DeloitteMobileSmarshComment')!.updateValueAndValidity();
    this.newhireFormGroup.get('DeloittePhoneNumber')!.updateValueAndValidity();
    this.newhireFormGroup.get('NAStatus')!.updateValueAndValidity();
}
  onBrokerageAccountChange(event:any){
    if(event.target.value=="No"){
      this.isBrokerageAccountComment = true;
      this.newhireFormGroup.controls["BrokerageAccountComment"].setErrors({required:true});
    }
    else{  
      this.isBrokerageAccountComment = false;  
      this.formID==undefined?this.newhireFormGroup.controls["BrokerageAccountComment"].setValue(""):this.initialNewHireList.BrokerageAccountComment==null?this.newhireFormGroup.controls["BrokerageAccountComment"].setValue(""):"";  
    }
    this.newhireFormGroup.get('BrokerageAccount')?.setValue(event.target.value);
  }
  onOBAdisclosureChange(event:any){
    if(event.target.value=="No"){
      this.isOBAdisclosureComment = true;
      this.newhireFormGroup.controls["OBAdisclosureComment"].setErrors({required:true});
    }
    else{  
      this.isOBAdisclosureComment = false;  
      this.formID==undefined?this.newhireFormGroup.controls["OBAdisclosureComment"].setValue(""):this.initialNewHireList.OBAdisclosureComment==null?this.newhireFormGroup.controls["OBAdisclosureComment"].setValue(""):"";  
    }
    this.newhireFormGroup.get('OBAdisclosure')?.setValue(event.target.value);
  }
  onPSTdisclosureChange(event:any){
    if(event.target.value=="No"){
      this.isPSTdisclosureComment = true;
      this.newhireFormGroup.controls["PSTdisclosureComment"].setErrors({required:true});
    }
    else{  
      this.isPSTdisclosureComment = false;  
      this.formID==undefined?this.newhireFormGroup.controls["PSTdisclosureComment"].setValue(""):this.initialNewHireList.PSTdisclosureComment==null?this.newhireFormGroup.controls["PSTdisclosureComment"].setValue(""):"";  
    }
    this.newhireFormGroup.get('PSTdisclosure')?.setValue(event.target.value);
  }
  onCSIAttestationsChange(event:any){
    if(event.target.value=="No"){
      this.isCSIAttestationsComment = true;
     this.newhireFormGroup.controls["CSIAttestationsComment"].setErrors({required:true});
    }
    else{
      this.isCSIAttestationsComment = false;
      this.formID==undefined?this.newhireFormGroup.controls["CSIAttestationsComment"].setValue(""):this.initialNewHireList.CSIAttestationsComment==null?this.newhireFormGroup.controls["CSIAttestationsComment"].setValue(""):"";
    }
    this.newhireFormGroup.get('CSIAttestations')?.setValue(event.target.value);
  }
  onNewhireQuestCEChange(event:any){
    if(event.target.value=="No"){
      this.isNewhireQuestCEComment = true;
     this.newhireFormGroup.controls["NewhireQuestCEComment"].setErrors({required:true});
    }
    else{
      this.isNewhireQuestCEComment = false;
      this.formID==undefined?this.newhireFormGroup.controls["NewhireQuestCEComment"].setValue(""):this.initialNewHireList.NewhireQuestCEComment==null?this.newhireFormGroup.controls["NewhireQuestCEComment"].setValue(""):"";
    }
    this.newhireFormGroup.get('NewhireQuestCE')?.setValue(event.target.value);
  }
  
  highlightRequiredField(formControlName: string): boolean {
    if (this.newhireFormGroup) {
      return FormValidations.highlightRequiredField(this.submitted, this.newhireFormGroup, formControlName);
    }
    return false;
  }
  eventDateSelected(event:any, formControlName: string) {
    this.newhireFormGroup.get(formControlName)?.setValue(event);
  }
  UIActions(){
    document.getElementById("dashboardLink")?.scrollIntoView({behavior:'smooth'})
    document.body.style.overflow ="hidden";
   }
}
