<!-- <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"> -->
<div class="headerManagement">Deal Wbs Setup Form</div>
<form class="formStyle" [formGroup]="appFormGroup" (keydown)="onKeydownMain($event)"(keydown.enter)="$event.preventDefault()">
  <div id="form-body">
    <div id="form-sections" class="col-xs-10">
      <button
      type="button" 
      class="btn btn-success"
      id="printFormButtonDealWBS"
      (click)="generatePDF()">Print</button> 
      <app-client-information
        [formGroups]="[appFormGroup.get(formGroups.clientInformationFormGroup), appFormGroup.get(formGroups.myDealFormGroup)]"
        [formSubmitted]="formSubmitted"
        (isCrossBorder)="crossBorderHandler($event)">
      </app-client-information>
      <app-engagement-information
        [formGroups]="[appFormGroup.get(formGroups.engagementInformationFormGroup), appFormGroup.get(formGroups.myDealFormGroup)]"
        [formSubmitted]="formSubmitted">
      </app-engagement-information>
      <app-international-work-indicators
        [formGroups]="[appFormGroup.get(formGroups.internationalWorkIndicatorsFormGroup), appFormGroup.get(formGroups.myDealFormGroup)]"
        [formSubmitted]="formSubmitted">
      </app-international-work-indicators>
      <app-archive-cmr
        [formGroups]="[appFormGroup.get(formGroups.archiveCmrFormGroup), appFormGroup.get(formGroups.myDealFormGroup)]"
        [formSubmitted]="formSubmitted">
      </app-archive-cmr>
      <!-- <app-master-engagement-details
        [formGroup]="appFormGroup.get(formGroups.masterEngagementDetailsFormGroup)"
        [formSubmitted]="formSubmitted">
      </app-master-engagement-details> -->
      <app-engagement-team
        [formGroup]="appFormGroup.get(formGroups.engagementTeamFormGroup)"
        [formSubmitted]="formSubmitted"
        (dealTeamRowToStore)="dealTeamRowHandler($event)">
      </app-engagement-team>
      <app-invoice-details-and-addresses
        [formGroups]="[appFormGroup.get(formGroups.invoiceDetailsAndAddressesFormGroup), appFormGroup.get(formGroups.myDealFormGroup)]"
        [formSubmitted]="formSubmitted">
      </app-invoice-details-and-addresses>
      <app-wbs-element-classification
        [formGroups]="[appFormGroup.get(formGroups.wbsElementClassificationFormGroup), appFormGroup.get(formGroups.myDealFormGroup)]"
        [formSubmitted]="formSubmitted">
      </app-wbs-element-classification>
      <app-participation
        [formGroups]="[appFormGroup.get(formGroups.participationFormGroup), appFormGroup.get(formGroups.myDealFormGroup)]"
        [formSubmitted]="formSubmitted"
        (Series24Name)="Series24Name($event)">
      </app-participation>
      <!-- <app-billing-schedule
      [formGroup]="appFormGroup.get(formGroups.billingScheduleFormGroup)"
      [formSubmitted]="formSubmitted">
      </app-billing-schedule> -->
      <app-pricing-details
        [formGroup]="appFormGroup.get(formGroups.pricingDetailsFormGroup)"
        [formSubmitted]="formSubmitted">
      </app-pricing-details>
      <app-documents
              [formType] ="formType" 
              [formGroup]="appFormGroup.get('documentsFormGroup')"
              [formSubmitted]="formSubmitted"
              [disabled]="!hasPermission('AttachDocuments')"
              [addAdditionalDocumentTypes]="hasPermission('AddAdditionalDocumentTypes')"
              [removeDocuments]="hasPermission('RemoveDocuments')"
              (selectedFile)="selectedFileHandler($event)"
              (additionalDocumentTypeListItemToStore)="additionalDocumentTypItemHandler($event)"
              (uploadedAttachments)="uploadedAttachmentsHandler($event)"
              [showNewDealpopup]="showNewDealpopup"
              [showDealWBSpopup]="showDealWBSpopup"
              [showCCLListpopup]="showCCLListpopup"
              >
      </app-documents>
      <app-admin-review *ngIf="checkVisibilityAdminReviewSetion()"
        [formGroup]="appFormGroup.get(formGroups.myDealFormGroup)"
        [formSubmitted]="formSubmitted"
        [saveInProgress]="saveInProgress"
        [saveButtonVisible]="saveButtonVisible"
        (adminAction)="adminActionHandler($event)">
      </app-admin-review>
      <app-active-deal-wbs *ngIf="checkVisibilityActiveDealWBSSetion()"
        [isNotificationSent]="isNotificationSent"
        (adminActiveWBSAction)="adminActiveWBSHandler($event)">
      </app-active-deal-wbs>
      <app-reactivate-form *ngIf="hasPermission('Reactivate')"
      [canceledOn]="DiscontinuedOn" [canceledBy]="DiscontinuedBy"
        (reactivateForm)=reactivateFormHandler()>
      </app-reactivate-form>
      <div class="submit-buttons floatStyles" >
        <button *ngIf="hasPermission('Submit')"
          type="submit"
          class="btn btn-primary submit-btn"
          [disabled]="saveInProgress"
          (click)="onSubmit()">
          Submit
        </button>
        <button *ngIf="saveButtonVisible && !isPendingForAdminReview()"
          class="btn btn-outline-primary save-rework-cancel-btn margin-left-10"
          [disabled]="saveInProgress"
          (click)="onSaveForm()">
          Save
        </button>
        <button *ngIf="hasPermission('Discontinue') && !isPendingForAdminReview()"
          type="submit"
          class="btn btn-outline-primary save-rework-cancel-btn margin-left-10"
          [disabled]="saveInProgress"
          (click)="onDiscontinue()">
          Discontinue
        </button>
       
      </div>
    </div>
    <div class="col-xs-2">
        <app-sidebar [isAutoSaveEnabled]="isAutoSaveEnabled" [saveButtonVisible]="saveButtonVisible" [isAdminReviewEditable]="isAdminReviewEditable"  [saveInProgress]="saveInProgress"  (SaveDealWbsFormDetails)="SaveDealWbsFormDetails($event)"></app-sidebar>
    </div>
</div>
<alert></alert> 
<app-alert-message [type]="alertMessageType" #Alert></app-alert-message>
</form>
<!-- <app-modal #Modal>
  <ng-container body>
      <ng-container
          *ngTemplateOutlet="modalBody">
      </ng-container>
  </ng-container>
  <ng-container footer>
      <ng-container
          *ngTemplateOutlet="modalFooter">
      </ng-container>
  </ng-container>
</app-modal>
<ng-template #modalBody>
  <div [ngSwitch]="modalType">
      <h3 *ngSwitchCase="'retrieve'" class="text-primary text-center text-bigger">
          The data has been successfully retrieved.
      </h3>
      <h3 *ngSwitchCase="'mandatory-fields'" class="text-primary text-center text-bigger">
          All mandatory fields need to be completed.
          <br>
          Please review them and try again.
      </h3>
      <h3 *ngSwitchCase="'submit'" class="text-primary text-center text-bigger">
          The form has been submitted.
      </h3>
      <div *ngSwitchCase="'discontinue'">
          <h3 class="text-primary text-center text-bigger">
              Are you sure you want to discontinue the form?
          </h3>
          <span>Reason:</span>
          <textarea type="text" [(ngModel)]="discontinueReason" name="DiscontinueReason" rows="3" class="input-data-text text-area-style" required></textarea>
      </div>
      <h3 *ngSwitchCase="'discontinue-confirmed'" class="text-primary text-center text-bigger">
          The form has been discontinued.
      </h3>
      <h3 *ngSwitchCase="'discontinue-reason'" class="text-primary text-center text-bigger">
          Before discontinuing the form, you need to provide a reason.
      </h3>
      <div *ngSwitchCase="'return-for-rework'">
          <h3 class="text-primary text-center text-bigger">
              Are you sure you want to return the form for rework?
          </h3>
          <span>Reason:</span>
          <textarea type="text" [(ngModel)]="returnForReworkReason" name="ReturnForReworkReason" rows="3" class="input-data-text text-area-style" required></textarea>
      </div>
      <h3 *ngSwitchCase="'return-for-rework-confirmed'" class="text-primary text-center text-bigger">
          The form has been returned for rework.
      </h3>
      <h3 *ngSwitchCase="'return-for-rework-reason'" class="text-primary text-center text-bigger">
          Before returning the form for rework, you need to provide a reason.
      </h3>
      <h3 *ngSwitchCase="'approval'" class="text-primary text-center text-bigger">
        The form has been approved.
      </h3>
      <h3 *ngSwitchCase="'no-dcf-member'" class="text-primary text-center text-bigger">
        You are not allowed to access this form.
      </h3>
      <h3 *ngSwitchCase="'access'" class="text-primary text-center text-bigger">
          You are not allowed to access this form.
      </h3>
      <h3 *ngSwitchCase="'user-left-dcf-member'" class="text-primary text-center text-bigger">
        At least one user is no longer with DCF group. Please review the Deal Team or Series 24
      </h3>
      <h3 *ngSwitchCase="'reactivate-form'" class="text-primary text-center text-bigger">
        Are you sure you want to reactivate this form?
      </h3>
      <h3 *ngSwitchCase="'reactivate-confirmed'" class="text-primary text-center text-bigger">
          The form has been reactivated.
      </h3>
      <h3 *ngSwitchCase="'error'" class="text-primary text-center text-bigger">
          There has been an error. Please reload the page and try again.
      </h3>
      <h3 *ngSwitchCase="'files-uploading'" class="text-primary text-center text-bigger">
          Please wait for the items to be uploaded.
      </h3>
      <h3 *ngSwitchCase="'save'" class="text-primary text-center text-bigger">
        The data has been successfully saved.
      </h3>
      <h3 *ngSwitchCase="'no-valid-form'" class="text-primary text-center text-bigger">
        This form is not associated to an Engagement. Please use an Engagement Health Object to create any new forms.
      </h3>
      <h3 *ngSwitchCase="'form-updated'" class="text-primary text-center text-bigger">
        This form has been updated.
      </h3>
      <h3 *ngSwitchCase="'retry-workflow'" class="text-primary text-center text-bigger">
        This workflow is being updated. Please try again later.
      </h3>
      <h3 *ngSwitchCase="'activeWBSNotification'" class="text-primary text-center text-bigger">
        Active WBS Notification Sent.
      </h3>
  </div>
</ng-template>
<ng-template #modalFooter>
  <div [ngSwitch]="modalType">
    <div *ngSwitchCase="'retrieve'">
      <button class="btn btn-primary btn-ok" (click)="scrollToSection(); modal.close()">OK</button>
    </div>
    <div *ngSwitchCase="'mandatory-fields'">
      <button class="btn btn-primary btn-ok" (click)="scrollToTop()">OK</button>
    </div>
    <div *ngSwitchCase="'submit'">
      <button class="btn btn-primary btn-ok" (click)="redirectOkModal()">OK</button>
    </div>
    <div *ngSwitchCase="'discontinue'">
      <button class="btn btn-secondary btn-sm bg-modal-button-cancel" (click)="cancelDiscontinue()">Cancel</button>
      <button class="btn btn-primary btn-ok" (click)="confirmDiscontinueReason()">OK</button>
    </div>
    <div *ngSwitchCase="'discontinue-confirmed'">
      <button class="btn btn-primary btn-ok" (click)="redirectOkModal()">OK</button>
    </div>
    <div *ngSwitchCase="'discontinue-reason'">
      <button class="btn btn-primary btn-ok" (click)="modal.close(); onDiscontinue()">OK</button>
    </div>
    <div *ngSwitchCase="'return-for-rework'">
      <button class="btn btn-secondary btn-sm bg-modal-button-cancel" (click)="cancelReturnForRework()">Cancel</button>
      <button class="btn btn-primary btn-ok" (click)="checkReasonBeforeReturn()">OK</button>
    </div>
    <div *ngSwitchCase="'return-for-rework-confirmed'">
      <button class="btn btn-primary btn-ok" (click)="redirectOkModal()">OK</button>
    </div>
    <div *ngSwitchCase="'return-for-rework-reason'">
      <button class="btn btn-primary btn-ok" (click)="modal.close(); onReturnForRework()">OK</button>
    </div>
    <div *ngSwitchCase="'approval'">
      <button class="btn btn-primary btn-ok" (click)="redirectOkModal()">OK</button>
    </div>
    <div *ngSwitchCase="'access'">
      <button class="btn btn-primary btn-ok" (click)="redirectOkModal()">OK</button>
    </div>
    <div *ngSwitchCase="'error'">
      <button class="btn btn-primary btn-ok" (click)="modal.close()">OK</button>
    </div>
    <div *ngSwitchCase="'save'">
      <button class="btn btn-primary btn-ok" (click)="modal.close()">OK</button>
    </div>
    <div *ngSwitchCase="'no-valid-form'">
      <button class="btn btn-primary btn-ok" (click)="redirectOkModal()">OK</button>
    </div>
    <div *ngSwitchCase="'no-dcf-member'">
      <button class="btn btn-primary btn-ok" (click)="redirectOkModal()">OK</button>
    </div>
    <div *ngSwitchCase="'form-updated'">
      <button class="btn btn-primary btn-ok" (click)="redirectOkModal()">OK</button>
    </div>
    <div *ngSwitchCase="'user-left-dcf-member'">
      <button class="btn btn-primary btn-ok" (click)="scrollToTop()">OK</button>
    </div>
    <div *ngSwitchCase="'reactivate-form'">
      <button class="btn btn-secondary btn-sm bg-modal-button-cancel" (click)="modal.close()">Cancel</button>
      <button class="btn btn-primary btn-ok" (click)="confirmReactivateForm()">OK</button>
    </div>
    <div *ngSwitchCase="'reactivate-confirmed'">
      <button class="btn btn-primary btn-ok" (click)="redirectOkModal()">OK</button>
    </div>
    <div *ngSwitchCase="'retry-workflow'">
      <button class="btn btn-primary btn-ok" (click)="redirectOkModal()">OK</button>
    </div>
    <div *ngSwitchCase="'activeWBSNotification'">
      <button class="btn btn-primary btn-ok" (click)="redirectOkModal()">OK</button>
    </div>
  </div>
</ng-template> -->
<deal-wbs-setup-app-notify-popup 
  (addNotifyDetails)="ConfirmDetails($event)"
  (deleteConfirmDetails)="ConfirmDetails($event)">
</deal-wbs-setup-app-notify-popup >

<!-- Below to hide the Inspect mode -->
<!-- <span class="container" (contextmenu)="onRightClick($event)" style="display: none;"></span> -->
