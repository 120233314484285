<div id="additional-commentary" class="container-fluid border container-border-color container-section">
    <div class="row bg-title">
        <span class="title-section-print">Additional Commentary</span>
        <span class="glyphicon glyphicon-question-sign title-question-sign tooltip-dcf"></span>
        <span class="tooltiptext-dcf visibility-hidden">
            <p>
                Add any other commentary regarding unique circumstances of anything you think would be helpful for
                someone reviewing this information to know.
            </p>
        </span>
    </div>
    <div id="additional-commentary-section" class="content-section">
        <div id="div-textarea-additional-comments" class="row">
            <textarea type="text" rows="3"
            [class.readonly]="readOnly"
                    [readonly]="readOnly"
             placeholder="You can add comments here" class="text-area-style"
                name="comment" [maxLength]="maxNumOfCharacters" [(ngModel)]="comment" (keyup)="onTextAreaChanged()"></textarea>
            <div class="comment-actions-section">
                <span id="add-additional-comments-button" (click)="saveComment()"
                    class="fa fa-check-circle reviewed-icon-color reviewed-comment additional-comments-button-action">
                    <button type="button"  hidden></button>
                </span>
                <span id="remove-additional-comments-button" (click)="clearComment()"
                    class="fa fa-times-circle return-for-rework-icon-color reject-comment additional-comments-button-action">
                    <button type="button"  hidden></button>
                </span>
                <div class="comment-length-section">
                    <span class="currentLengthComments">{{comment.length}}</span>
                    <span>/</span>
                    <span class="maxLengthComments">{{maxNumOfCharacters}}</span>
                </div>
            </div>
        </div>
        

            <div class="row" *ngIf="isShowComments">
                <hr id="hr-additional-comments">
                <div class="possition" id="comment-section">
                    <span id="arrow-comments" class="glyphicon  comments-down-arrow "
                    (click)="toggleClass = !toggleClass"
                    [ngClass]="{' glyphicon-chevron-down ': !toggleClass,'glyphicon-chevron-up':toggleClass }"
                    ></span>

                    <div class="comment-section">
                        <ul class="ul-additional-comment">
                            <li class="li-additional-comment" *ngFor="let item of userCommentsData;let i = index"
                                [ngClass]="{'hidden': i>=1 && !toggleClass}">
                                <div class="comments-bar-color" id="div-comment-bar">
                                </div>
                                <div class="div-comments">
                                    <span class="margin-left-4">
                                        <b>{{item.CommentAuthorName}}, {{item.Created}}</b>
                                    </span>
                                    <div class="float-right">
                                        <span><b>{{userCommentsData.length - i}} of </b></span>
                                        <span class="totalAdditionalComments"><b>{{userCommentsData.length}}</b></span>
                                    </div>
                                    <br>
                                    <textarea readonly rows="3" class="textarea-comments">{{item.Comment}}</textarea>
                                </div>
                            </li>
                        </ul>

                    </div>

                </div>
            </div>

        
    </div>
</div>
<closing-checklist-app-notify-popup #notifypopupComponent>
</closing-checklist-app-notify-popup>