import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-notify-popup',
  templateUrl: './notify-popup.component.html',
  styleUrls: ['./notify-popup.component.scss']
})
export class NotifyPopupComponent implements OnInit {

  displayStyle:any = "none";
  isUserDelete:any=false;
  isHardDelete:any=false;
  MessageHeader:any="";
  headerMsg:any=""
  Msg:any;messageType:any;
  modalType:any="";
  cancelText:any=""
  UserDeleteCancelText ="No";
  UserDeleteOkText ="Yes";
  @Input() additionalDocumentTypesAvailable: boolean;
  @Input() additionaldocumentTypesToAdd: any=[];
  @Input() maxAllowedFileNameLength: number;
  @Input() invalidFileNameCharactersList: Array<string> = [];
  @Input() UserCurrentAction: string;
  @Output()  addNotifyDetails:EventEmitter<any>=new EventEmitter<any>();
  @Output()  deleteConfirmDetails:EventEmitter<any>=new EventEmitter<any>();
  @Output()  addAdditionalDocumentClicked:EventEmitter<any>=new EventEmitter<any>();  
  @Output()  activateConfirmDetails:EventEmitter<any>=new EventEmitter<any>();
  
  constructor() { }

  ngOnInit(): void {
  }

  openPopup(msg:any,msgType:any,validationMsg?:any) { 
    this.MessageHeader=msg;
    this.headerMsg = "";
    this.messageType=msgType;
    this.isUserDelete=false;   
    this.UserDeleteCancelText ="No";
    this.UserDeleteOkText ="Yes";  
    this.cancelText="OK";
    if (msg == "Admin") {
      if (msgType == "Success") {
        this.Msg = "User added successfully!";
      }
      else if (msgType == "Info") {
        this.Msg = "Please select the User!";
      }
      else if (msgType == "Warning") {
        this.Msg = "User Already Exist!";
      }
      else if (msgType == "PermissionError") {
        this.Msg = "Error while getting User Permissions!";
      }
      else if (msgType == "PermissionInfo") {
        this.Msg = "Please select the User!";
      }
    }
   else if (msg == "Content") {
      if (msgType == "SaveSuccess") {
        this.Msg = "Record saved successfully!";
      }
      if (msgType == "UpdateSuccess") {
        this.Msg = "Record updated successfully!";
      }
      else if (msgType == "ActivateInfo") {
        this.isUserDelete=true;
        this.Msg = "Are you sure, you want to Activate the Form?";
      }
      else if (msgType == "ActivateFormSuccess") {
        this.Msg = "Form activated Successfully!";
      }
      else if (msgType == "ITSAdminRequest") {
        this.Msg = "Request sent to ITSAdmin for Verification!";
      }
      else if (msgType == "Info") {
        this.Msg = "Please pass the Mandatory Details!";
      }
      else if (msgType == "AlreadyExisted") {
        this.Msg = "Record Already Existed!";
      }
      else if (msgType == "FormGroupValidation") {
        this.Msg = "Group Mapped with existed Records, Not allowed to Delete!";
      }
      else if (msgType == "DeleteSuccess") {
        this.Msg = "Record deleted Successfully";
      }
      else if (msgType == "DeleteError") {
        if(validationMsg!=null)
        {
          this.Msg = validationMsg;
        }
        else{
          this.Msg = "Error occured while deleting the Record!";
        }        
      }
      else if (msgType == "SuccessError") {
        this.Msg = "Error occured while saving the Record!";
      }
      else if (msgType == "AuditError") {
        this.Msg = "Error occured while saving the Audit!";
      }
      else if (msgType == "ApprovedSuccess") {
        this.Msg = "ITS Admin Approved!";
      }
      else if (msgType == "RejectedSuccess") {
        this.Msg = "ITS Admin Rejected!";
      }
      else if (msgType == "ITSAdminNotVerified") {
        this.Msg = "No Action Performed, Till Previous changes approved by ITSAdmin!";
      }
      else if (msgType == "UpdateError") {
        this.Msg = "Error occured while updating the Record!";
      }
      else if (msgType == "PresignedURL") {
        this.Msg = "Error while uploading to the Pre-signed environment!";
      }
      else if (msgType == "isNotUpdated") {
        this.Msg = "There was no change since last update, please try again!";
      }
      else if (msgType == "maxFileName") {
        this.Msg = "The filename must be shorter than 128 characters.";
      }
      else if (msgType == "maxFileSize") {
        this.Msg = "The upload of a file with the invalid size";
      }
      else if (msgType == "fileEmpty") {
        this.Msg = "The file cannot be empty.";
      }
      else if (msgType == "fileNotSelected") {
        this.Msg = "The file must contain name";
      }
      else if (msgType == "fileDuplicate") {
        this.Msg = "A file with that name already exists.";
      }
      else if (msgType == "fileEncrypted") {
        this.Msg = "File can not be Encrypted.";
      }
    }
    else if(msg=="Dashboard"){
      this.Msg = "You are not allowed to access this form";
    }
    else if(msg=="DashboardRedirect"){
      this.Msg = "You are not allowed to access this form";
    }   
    else if(msg=="DeleteOperation") {
      if(msgType == "Info"){
        this.isUserDelete=true;
        this.isHardDelete=false;
        this.Msg="Are you sure, you want to delete the Record?"
      }
      else if(msgType == "HardDelete")
      {
        this.isUserDelete=true;
        this.isHardDelete=true;
        this.Msg="Are you sure you want to permanently delete this item? This action cannot be undone."
      }
     else if (msgType == "Success") {
        this.Msg = " Record has been Deleted Successfully!";
      }
      else {
        this.Msg = "Error in Deletion!";
      }
    }
    else if (msg == "EngagementDashboard") {
      if (msgType == "AccessDenied") {
        this.Msg = "You are not allowed to access Engagement Dashboard";
      }
    }
    this.displayStyle = "block";
   }
   closePopup() {    
    this.displayStyle = "none";
    const data = {
      MessageHeader:this.MessageHeader,
      isOkayClicked: true,
      modalType: this.modalType
    };
    this.addNotifyDetails.emit(data);
   }
   ConfirmToDelete(){ //OK
    this.displayStyle = "none";
    const data = {
      isOkayClicked: true,
      modalType: this.modalType,
    };       
       if (this.UserCurrentAction == "Activate")
           this.activateConfirmDetails.emit(true);
       else
           this.deleteConfirmDetails.emit(data);
   }
   ConfirmToCancel(){ // Cancel
    const data = {
      isOkayClicked: false,
      modalType: this.modalType
    };
    this.deleteConfirmDetails.emit(data);
    this.displayStyle = "none";
   }

   getModalStyles()
   {  
    var width='35%'; //defualt width
    var height='150px'; //defualt height      
    return {'width':width,'height':height};
   }

}
