<div id="project-overview"
 [formGroup]="projectOverviewFormGroup"
 class="container-fluid border container-border-color container-section">
  <div class="row bg-title">
    <span class="title-section-print">Deal / Project Overview</span>
  </div>
  <div id="project-overview-section" class="content-section">
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('engagementName')"
          [class.edited-externally]="editedExternally('engagementName')"
          [class.conflict-on-merge]="hasMergeConflict('engagementName')">Project Name</span>
        <span *ngIf="showAsterisk('engagementName')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <input
          [formControl]="myDealFormGroup.get('engagementName')"
          class="input-data-text full-width section-input" [class.readonly]="isReadOnly('engagementName')"
          type="text"
          autocomplete="off"
          maxlength="255">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('series24')"
          [class.edited-externally]="editedExternally('series24')"
          [class.conflict-on-merge]="hasMergeConflict('series24')">Series 24</span>
        <span *ngIf="showAsterisk('series24')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-dropdown
          [optionsList]="s24Options"
          [readonly]="isReadOnly('series24') || !hasPermission('AddSeries24')"
          [keySelected]="myDealFormGroup.get('series24')?.value"
          [userLeftDCF]="series24LeftDCF"
          (optionSelected)="onSeries24Selection($event)">
        </app-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <span [class.highlight-warning]="highlightRequiredField('dealteamTable')"
          [class.edited-externally]="editedExternally('dealteamTable')"
          [class.conflict-on-merge]="hasMergeConflict('dealteamTable')">Deal Team</span>
        <span *ngIf="showAsterisk('dealteamTable')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('DealTeamTable')">
        </app-popover>
      </div>
    </div>
    <div class="row">
      <app-deal-team-table #dealTeam
        [readonly]="!hasPermission('AddDealTeamMember')"
        [allowToDuplicateUser]="allowToDuplicateUser"
        [dealTeamTableFormGroup]="projectOverviewFormGroup.get('dealteamTable')"
        [dealTeamValues]="dealTeamValues"
        (dealTeamRowToStore)="onDealTeamRowSelection($event)">
      </app-deal-team-table>
    </div>
    <!-- <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('isIndiaInvolved')"
          [class.edited-externally]="editedExternally('isIndiaInvolved')"
          [class.conflict-on-merge]="hasMergeConflict('isIndiaInvolved')">Is India involved?</span>
        <span *ngIf="showAsterisk('isIndiaInvolved')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="projectOverviewFormGroup.get('isIndiaInvolved').value"
          [readonly]="isReadOnly('isIndiaInvolved')"
          (handleToggleYesNo)="onIsIndiaInvolvedSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div> -->
    <!-- <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('isDeloitteAlumniInvolved')"
          [class.edited-externally]="editedExternally('isDeloitteAlumniInvolved')"
          [class.conflict-on-merge]="hasMergeConflict('isDeloitteAlumniInvolved')">Deloitte Alumni involved?</span>
        <span *ngIf="showAsterisk('isDeloitteAlumniInvolved')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="projectOverviewFormGroup.get('isDeloitteAlumniInvolved').value"
          [readonly]="isReadOnly('isDeloitteAlumniInvolved')"
          (handleToggleYesNo)="onIsAlumniInvolvedSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span class="dependent-field" [class.highlight-warning]="highlightRequiredField('deloitteAlumniName')"
          [class.edited-externally]="editedExternally('deloitteAlumniName')"
          [class.conflict-on-merge]="hasMergeConflict('deloitteAlumniName')">Name</span>
        <span *ngIf="showAsterisk('deloitteAlumniName')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <input
          formControlName="deloitteAlumniName"
          class="input-data-text full-width section-input dependent-field-input"
          [class.readonly]="isReadOnly('deloitteAlumniName')"
          type="text"
          autocomplete="off"
          maxlength="255"
          [placeholder]="projectOverviewFormGroup.get('isDeloitteAlumniInvolved').value === 'YES' ? '' : 'N/A'">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span class="dependent-field" [class.highlight-warning]="highlightRequiredField('deloitteAlumniRole')"
          [class.edited-externally]="editedExternally('deloitteAlumniRole')"
          [class.conflict-on-merge]="hasMergeConflict('deloitteAlumniRole')">Role</span>
        <span *ngIf="showAsterisk('deloitteAlumniRole')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <input
          formControlName="deloitteAlumniRole"
          class="input-data-text full-width section-input dependent-field-input"
          [class.readonly]="isReadOnly('deloitteAlumniRole')"
          type="text"
          autocomplete="off"
          maxlength="255"
          [placeholder]="projectOverviewFormGroup.get('isDeloitteAlumniInvolved').value === 'YES' ? '' : 'N/A'">
      </div>
    </div> -->
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('engagementIndustrySICCode')"
          [class.edited-externally]="editedExternally('engagementIndustrySICCode')"
          [class.conflict-on-merge]="hasMergeConflict('engagementIndustrySICCode')">Engagement Industry (SIC Code)</span>
        <span *ngIf="showAsterisk('engagementIndustrySICCode')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('EngagementIndustrySICCode')"
          [keepOpen]="true">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <app-numeric
          [type]="'number'"
          [readonly]="isReadOnly('engagementIndustrySICCode')"
          [value]="myDealFormGroup.get('engagementIndustrySICCode')?.value"
          (selectedValue)="onEngagementIndustrySICCodeChange($event)">
        </app-numeric>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('engagementIndustrySector')"
          [class.edited-externally]="editedExternally('engagementIndustrySector')"
          [class.conflict-on-merge]="hasMergeConflict('engagementIndustrySector')">Engagement Industry Sector</span>
        <span *ngIf="showAsterisk('engagementIndustrySector')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-dropdown
          [optionsList]="engagementIndustrySectorOptions"
          [keySelected]="projectOverviewFormGroup.get('engagementIndustrySector').value"
          [readonly]="isReadOnly('engagementIndustrySector')"
          (optionSelected)="onEngagementIndustrySectorSelection($event)">
        </app-dropdown>
      </div>
    </div> -->
    <!-- Source & source name moved to new deal section -->
    <!-- <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('source')"
          [class.edited-externally]="editedExternally('source')"
          [class.conflict-on-merge]="hasMergeConflict('source')">Source (Referral if any)</span>
        <span *ngIf="showAsterisk('source')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('Source')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <app-dropdown
          [optionsList]="sourceOptions"
          [keySelected]="myDealFormGroup.get('source').value"
          [readonly]="isReadOnly('source')"
          (optionSelected)="onSourceSelection($event)">
        </app-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('sourceName')"
          [class.edited-externally]="editedExternally('sourceName')"
          [class.conflict-on-merge]="hasMergeConflict('sourceName')">Source Name</span>
        <span *ngIf="showAsterisk('sourceName')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('SourceName')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <input
          formControlName="sourceName"
          class="input-data-text full-width section-input"
          [class.readonly]="isReadOnly('sourceName')"
          type="text"
          autocomplete="off"
          maxlength="255">
      </div>
    </div> -->
    <!-- referral percentage deleted as per UAT feedback -->
    <!-- <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('referralPercentage')"
          [class.edited-externally]="editedExternally('referralPercentage')"
          [class.conflict-on-merge]="hasMergeConflict('referralPercentage')">Referral Percentage (if applicable)</span>
        <span *ngIf="showAsterisk('referralPercentage')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-numeric
          [type]="'decimal'"
          [format]="'percentage'"
          [value]="projectOverviewFormGroup.get('referralPercentage').value"
          [readonly]="isReadOnly('referralPercentage')"
          (selectedValue)="onReferralPercentageChange($event)">
        </app-numeric>
      </div>
    </div> -->
    <!-- Referral Source name & transaction type moved to new deal section -->
    <!-- <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('referralSourceName')"
          [class.edited-externally]="editedExternally('referralSourceName')"
          [class.conflict-on-merge]="hasMergeConflict('referralSourceName')">Referral Source Name</span>
        <span *ngIf="showAsterisk('referralSourceName')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <input
          [formControl]="myDealFormGroup.get('referralSourceName')"
          class="input-data-text full-width section-input"
          [class.readonly]="isReadOnly('referralSourceName')"
          type="text"
          autocomplete="off"
          maxlength="255">
      </div>
    </div> 
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('transactionType')"
          [class.edited-externally]="editedExternally('transactionType')"
          [class.conflict-on-merge]="hasMergeConflict('transactionType')">Transaction Type</span>
        <span *ngIf="showAsterisk('transactionType')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-dropdown
          [optionsList]="transactionTypeOptions"
          [keySelected]="myDealFormGroup.get('transactionType').value"
          [readonly]="isReadOnly('transactionType')"
          (optionSelected)="onTransactionTypeSelection($event)">
        </app-dropdown>
      </div>
    </div> -->
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('isOpportunityViaGlobalSharepoint')"
          [class.edited-externally]="editedExternally('isOpportunityViaGlobalSharepoint')"
          [class.conflict-on-merge]="hasMergeConflict('isOpportunityViaGlobalSharepoint')">Should this opportunity be shared via the global sharepoint?</span>
        <span *ngIf="showAsterisk('isOpportunityViaGlobalSharepoint')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <app-toggle-yes-no
          [value]="projectOverviewFormGroup.get('isOpportunityViaGlobalSharepoint')?.value"
          [readonly]="isReadOnly('isOpportunityViaGlobalSharepoint')"
          (handleToggleYesNo)="onIsOpportunityViaGlobalSharepointSelection($event)">
        </app-toggle-yes-no>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('internationalCommunity')"
          [class.edited-externally]="editedExternally('internationalCommunity')"
          [class.conflict-on-merge]="hasMergeConflict('internationalCommunity')">What is your request for the international community?</span>
        <span *ngIf="showAsterisk('internationalCommunity')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <input
          formControlName="internationalCommunity"
          class="input-data-text full-width section-input dependent-field-input"
          [class.readonly]="isReadOnly('internationalCommunity')"
          type="text"
          autocomplete="off"
          maxlength="255"
          [placeholder]="projectOverviewFormGroup.get('isOpportunityViaGlobalSharepoint')?.value === 'YES' ? '' : 'N/A'">
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span [class.highlight-warning]="highlightRequiredField('crossBusinessOffering')"
          [class.edited-externally]="editedExternally('crossBusinessOffering')"
          [class.conflict-on-merge]="hasMergeConflict('crossBusinessOffering')">Cross Business Offering (DCF M&A Transaction)</span>
        <span *ngIf="showAsterisk('crossBusinessOffering')" class="red-asterik"></span>
      </div>
      <div class="col-xs-8">
        <input
          formControlName="crossBusinessOffering"
          class="input-data-text full-width section-input"
          [class.readonly]="isReadOnly('crossBusinessOffering')"
          type="text"
          autocomplete="off"
          maxlength="255">
      </div>
    </div> -->
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('integratedMarketOffering')"
          [class.edited-externally]="editedExternally('integratedMarketOffering')"
          [class.conflict-on-merge]="hasMergeConflict('integratedMarketOffering')">Integrated Market Offering</span>
        <span *ngIf="showAsterisk('integratedMarketOffering')" class="red-asterik"></span>
        <app-popover
          [tooltipText]="getTooltipText('IntegratedMarketOffering')">
        </app-popover>
      </div>
      <div class="col-xs-8">
        <input
        [formControl]="myDealFormGroup.get('integratedMarketOffering')"
          type="text"
          class="input-data-text full-width section-input"
          [class.readonly]="isReadOnly('integratedMarketOffering')"
          autocomplete="off"
          maxlength="255">
      </div>
    </div>
  </div>
</div>
