import { Component, OnInit, ViewChild } from '@angular/core';
import { EngagementHealthObjectItem } from '../models/engagement-health-object-item';
import { FormStatusColor } from '../models/form-status-color';
import { EngagementStatusColors } from '../models/engagement-status-colors';
import { EngagementStatusValues } from '../models/engagement-status-values';
import { FormStatusValue } from 'src/shared/models/form-status-value';
import { environment } from 'src/environments/environment';
import { Spcrud } from 'src/shared/services/spcrud';
import { AvailableFormItem } from 'src/shared/models/available-form-item';
import { ModalComponent } from 'src/shared/components/modal/modal.component';
import { EngagementHealthObjectService } from '../shared/services/engagement-health-object.service';
import { AttachmentItem } from 'src/shared/models/attachment-item';
import { AppSettings } from 'src/shared/app-settings';
import { EngagementHealthObjectServiceService } from 'src/app/services/engagement-health-object-service.service';
import { EhoNotifyPopupComponent } from 'src/shared/components/alerts/eho-notify-popup/eho-notify-popup.component';
import { Observable, forkJoin, from, map, of, switchMap, tap } from 'rxjs';
import { NewdealService } from 'src/app/services/newdeal.service';
import { NewDealNotifyPopupComponent } from 'src/shared/components/alerts/newdeal-notify-popup/newdeal-notify-popup.component';

const modalTypes  = {
  addNewForm: 'add-new-form'
};

@Component({
  selector: 'app-sidebar-engagement-content-breakdown',
  templateUrl: './sidebar-engagement-content-breakdown.component.html',
  styleUrls: ['./sidebar-engagement-content-breakdown.component.scss']
})

export class SidebarEngagementContentBreakdownComponent implements OnInit {

  engagementHealthObject: EngagementHealthObjectItem;
  modalAvailableFormsList: Array<AvailableFormItem> = [];
  sidebarAvailableFormsList: Array<AvailableFormItem> = [];
  availableFormsList: Array<AvailableFormItem> = [];
  availableForms = true;
  modalType: string;
  attachmentListNewDeal: Array<AttachmentItem> = [];
  attachmentListCrossBorder: Array<AttachmentItem> = [];
  attachmentListCaf: Array<AttachmentItem> = [];
  limitAttachmentToShowNewDeal = AppSettings.initialAttachmentsToShow;
  limitAttachmentToShowCrossBorder = AppSettings.initialAttachmentsToShow;
  limitAttachmentToShowCaf = AppSettings.initialAttachmentsToShow;
  showAttachmentsNewDeal = false;
  showAttachmentsCrossBorder = false;
  showAttachmentsCaf = false;
  showEngagementArchive = false;
  dealWbsSelectedCount = 0;
  closingChecklistSelectedCount = 0;
  crossBorderSelectedCount = 0;
  availableRes:any;

  @ViewChild('Modal')
  modal: ModalComponent;
  @ViewChild(EhoNotifyPopupComponent, { static: true }) ehoModal: EhoNotifyPopupComponent;
  @ViewChild(NewDealNotifyPopupComponent, { static: true }) newDealNotifyPopupComponent: NewDealNotifyPopupComponent;

  constructor(
    private spcrud: Spcrud,private newDealService:NewdealService,
    private engagementHealthObjectService: EngagementHealthObjectService,private EHOService:EngagementHealthObjectServiceService) { }

  ngOnInit() {}

  setSidebarPropertiesNewDeal(engagementHealthObject: EngagementHealthObjectItem,
                              responseAttachmentsNewDeal: Array<AttachmentItem>) {
    this.engagementHealthObject = engagementHealthObject;
    this._setAttachmentsNewDeal(responseAttachmentsNewDeal);
  }

  setSidebarPropertiesCaf(responseAttachmentsCaf: Array<AttachmentItem>) {
    this._setAttachmentsCaf(responseAttachmentsCaf);
  }

  setSidebarPropertiesCrossBorder(responseAttachmentsCrossBorder: Array<AttachmentItem>) {
    this._setAttachmentsCrossBorder(responseAttachmentsCrossBorder);
  }

  toggleAttachmentsNewDeal() {
    this.showAttachmentsNewDeal = !!!this.showAttachmentsNewDeal;
    this.limitAttachmentToShowNewDeal = AppSettings.initialAttachmentsToShow;
  }

  toggleAttachmentsCrossborder() {
    this.showAttachmentsCrossBorder = !!!this.showAttachmentsCrossBorder;
  }

  toggleAttachmentsCaf() {
    this.showAttachmentsCaf = !!!this.showAttachmentsCaf;
  }

  toggleShowAttachmentsButtonNewDeal() {
    this.limitAttachmentToShowNewDeal = this.limitAttachmentToShowNewDeal === AppSettings.initialAttachmentsToShow
    ? this.attachmentListNewDeal.length : AppSettings.initialAttachmentsToShow;
  }

  toggleShowAttachmentsButtonCrossBorder() {
    this.limitAttachmentToShowCrossBorder = this.limitAttachmentToShowCrossBorder === AppSettings.initialAttachmentsToShow
    ? this.attachmentListCrossBorder.length : AppSettings.initialAttachmentsToShow;
  }

  toggleShowAttachmentsButtonCaf() {
    this.limitAttachmentToShowCaf = this.limitAttachmentToShowCaf === AppSettings.initialAttachmentsToShow
    ? this.attachmentListCaf.length : AppSettings.initialAttachmentsToShow;
  }

  showMoreButtonNewDeal(): boolean {
    return this.showAttachmentsNewDeal && this.attachmentListNewDeal.length > AppSettings.initialAttachmentsToShow
     && this.limitAttachmentToShowNewDeal === AppSettings.initialAttachmentsToShow;
  }

  showLessButtonNewDeal(): boolean {
    return this.showAttachmentsNewDeal && this.limitAttachmentToShowNewDeal > AppSettings.initialAttachmentsToShow;
  }

  showMoreButtonCrossBorder(): boolean {
    return this.showAttachmentsCrossBorder && this.attachmentListCrossBorder.length > AppSettings.initialAttachmentsToShow
     && this.limitAttachmentToShowCrossBorder === AppSettings.initialAttachmentsToShow;
  }

  showLessButtonCrossBorder(): boolean {
    return this.showAttachmentsCrossBorder && this.limitAttachmentToShowCrossBorder > AppSettings.initialAttachmentsToShow;
  }

  showMoreButtonCaf(): boolean {
    return this.showAttachmentsCaf && this.attachmentListCaf.length > AppSettings.initialAttachmentsToShow
     && this.limitAttachmentToShowCaf === AppSettings.initialAttachmentsToShow;
  }

  showLessButtonCaf(): boolean {
    return this.showAttachmentsCaf && this.limitAttachmentToShowCaf > AppSettings.initialAttachmentsToShow;
  }

  setAvailableForms(availableFormsList: any) {
    this.availableFormsList = availableFormsList.filter((item:any) => {
      return (item.availableFormListName!="CAFFormList"  && item.availableFormListName!="CrossBorderForm") ;
    });
    this.modalAvailableFormsList = this.availableFormsList.filter(item => {
      return item.availableFormCreated === false && (item.availableFormModal === true||item.availableFormModal === "TRUE") ;
    });
    this.sidebarAvailableFormsList = this.availableFormsList.filter(item => {
      //return item.availableFormSidebar === true;
      return item.availableFormSidebar === true && (item.availableFormListName==AppSettings.newDealSetupFormList||item.availableFormListName==AppSettings.dealWbsSetupFormList||item.availableFormListName==AppSettings.closingChecklistFormList); //add this conidition to show only new deal&DealWBS from need to remove this once other forms are developed.
    });
  }

  calculateStatusColor(status: string): string {
      switch (status) {

        case EngagementStatusValues.NotSubmitted:
          return EngagementStatusColors.NotSubmitted;
        case EngagementStatusValues.Pursuit:
          return EngagementStatusColors.Pursuit;
        case EngagementStatusValues.DealWon:
          return EngagementStatusColors.DealWon;
        case EngagementStatusValues.DealLost:
          return EngagementStatusColors.DealLost;
        case EngagementStatusValues.DealClosed:
          return EngagementStatusColors.DealClosed;
        case FormStatusValue.completed:
          return FormStatusColor.Completed;
        default:
          return FormStatusColor.Incompleted;
      }
  }

  reloadPage() {
    window.location.href = document.URL;
    window.location.reload();
  }

  openNewTab(formItemId: number, pageUrl: string, altPageUrl?: any, formListName?: any, formBaseUrl?: string) {
    this.getFormUrl(formItemId, pageUrl, altPageUrl, formListName, formBaseUrl);
  }

  getFormUrl(formItemId: number, pageUrl: string, altPageUrl: string, formListName: string, formBaseUrl?: any): any {
      if (formListName === AppSettings.crossBorderFormList) {
       this.engagementHealthObjectService.getXBRegisterFlagEHO(formItemId, formBaseUrl).subscribe({
          next:(isRegisterData:any) => {
          const isRegister = isRegisterData.FormRegistered;
          if (isRegister) {
             window.open(this.buildFormUrl(formItemId, altPageUrl));
          }
          else{
            window.open(this.buildFormUrl(formItemId, pageUrl));
          }
        },
        error:(err:any)=>{
          console.error(err); 
        }
      });
    }
    window.open(this.buildFormUrl(formItemId, pageUrl));
  
  }

  buildFormUrl(formItemId: number, pageUrl: string): string {
    if (formItemId) {
      return `${pageUrl}`.replace('{formId}', formItemId.toString());
    } else {
      return '';
    }
  }

  setAvailableFormStatus(listName: string, status: string, id: number ) {
    const index = this.availableFormsList.findIndex(item => item.availableFormListName === listName);
    if (index >= 0) {
      this.availableFormsList[index].availableFormItemId = id;
      this.availableFormsList[index].formStatus = status;
      this.availableFormsList[index].availableFormCreated = true;
      this.availableFormsList[index].availableFormModal = false;
      this.availableFormsList[index].availableFormSidebar = true;
    }
    this.setAvailableForms(this.availableFormsList);
  }
  addEHOFormDetails(event:any){
  this.addFormToEngagement(event.result);
  }
  addFormToEngagement(availableForm: AvailableFormItem) {
    const customListFormSelected = availableForm.availableFormListName;
    switch (customListFormSelected) {
      case AppSettings.dealWbsSetupFormList:
        this.dealWbsSelectedCount++;
        break;
      case AppSettings.closingChecklistFormList:
        this.closingChecklistSelectedCount++;
        break;
      case AppSettings.crossBorderFormList:
        this.crossBorderSelectedCount++;
        break;
    }
    if ((customListFormSelected === AppSettings.dealWbsSetupFormList && this.dealWbsSelectedCount === 1) ||
        (customListFormSelected === AppSettings.closingChecklistFormList && this.closingChecklistSelectedCount === 1) ||
        (customListFormSelected === AppSettings.crossBorderFormList && this.crossBorderSelectedCount === 1)) {
      this.engagementHealthObjectService.associateNewFormEHO(
          customListFormSelected, this.engagementHealthObject.myDealId, availableForm.availableFormSiteUrl)
      .subscribe({
        next:(newForm:any) => {
        availableForm.availableFormItemId = newForm.ID;
        availableForm.formStatus = newForm.Status;
        this._removeFormFromModalMenu(customListFormSelected);
        this._addFormToSidebarMenu(availableForm);
       // this.modal.close();
        this.openNewTab(availableForm.availableFormItemId, availableForm.availableFormPageUrl);
        },
        error:(err:any)=>{
          console.error();
        }  
      });
    }
  }

  onAddNewFormClicked() {
    this.setCreatedForms( this.engagementHealthObject.myDealId, this.engagementHealthObject.newDealItemId);
  }

  setCreatedForms(myDealId: number, newDealItemId: number):any {
    if (this.modalAvailableFormsList.length === 0) {
      this.availableForms=false;
      // this.engagementHealthObjectService.getAvailableForms().then(availableForms => {
      //   this.modalAvailableFormsList = availableForms;
        return this._setCreatedFormsInternal(myDealId, newDealItemId);
      // });
    } else {
      return this._setCreatedFormsInternal(myDealId, newDealItemId);
    }
  }

  collapseExpandFn() {
    const sideBar:any = document.getElementById('sideNavBox');
    const contentBox:any = document.getElementById('contentBox');

    if (sideBar.classList.contains('sidebar-open')) {
      sideBar.classList.remove('sidebar-open');
      contentBox.classList.remove('sidebar-margin');
    } else {
      sideBar.classList.add('sidebar-open');
      contentBox.classList.add('sidebar-margin');
    }
  }

  private _addFormToSidebarMenu(availableForm: AvailableFormItem) {
    this.sidebarAvailableFormsList.push(availableForm);
  }

  private _removeFormFromModalMenu(customListFormSelected: string) {
    this.modalAvailableFormsList = this.modalAvailableFormsList.filter(form => {
      return form.availableFormListName !== customListFormSelected;
    });
  }

  private _openModal(modalType: string) {
    this.modalType = modalType;
    //this.modal.open();
    this.ehoModal.openAddNewFormPopup(this.modalType);
  }

  private _setAttachmentsNewDeal(responseAttachments: Array<AttachmentItem>) {
    this.attachmentListNewDeal = responseAttachments;
  }

  private _setAttachmentsCrossBorder(responseAttachments: Array<AttachmentItem>) {
    this.attachmentListCrossBorder = responseAttachments;
  }

  private _setAttachmentsCaf(responseAttachments: Array<AttachmentItem>) {
    this.attachmentListCaf = responseAttachments;
  }
  filterImplementedForms(){
    if(this.modalAvailableFormsList.length!=0){
     this.modalAvailableFormsList= this.modalAvailableFormsList.filter((x:any)=>x.availableFormTitle=="Deal WBS Setup Form" || x.availableFormTitle=="Closing Checklist Form");
      this.availableForms=this.modalAvailableFormsList.length==0?false:this.availableForms;
    }
    
  }
  private _setCreatedFormsInternal(myDealId: number, newDealItemId: number): any {
   return from(this.getCreatedForms_EHO(this.availableFormsList, myDealId, newDealItemId))
      .subscribe(availableFormsResponse => {
          availableFormsResponse = this.BindActualAvailableResponse(newDealItemId, this.availableRes);
          this.setAvailableForms(availableFormsResponse);
          this.filterImplementedForms(); //Need to remove filter once all forms implemented
          this._openModal(modalTypes.addNewForm);
        })
     // );
  }
  getCreatedForms_EHO(availableFormsObject: Array<AvailableFormItem>, myDealId: number, newDealItemId: number): Observable<any> {
    let isDealWbsAvailable,isClosingChecklistAvailable=false;
    availableFormsObject.forEach((item:any, index:any) => {
      if (item.availableFormListName === AppSettings.dealWbsSetupFormList) {isDealWbsAvailable=true;}
      if (item.availableFormListName === AppSettings.closingChecklistFormList) {isClosingChecklistAvailable=true;}
    });
    return forkJoin([
      isDealWbsAvailable ? this.EHOService.GetFORMEHOObjects(this.newDealService.getAppendURL(AppSettings.dealWbsSetupFormList), myDealId, AppSettings.dealWbsSetupFormList) : of([]),
      isClosingChecklistAvailable ? this.EHOService.GetFORMEHOObjects(this.newDealService.getAppendURL(AppSettings.closingChecklistFormList), myDealId, AppSettings.closingChecklistFormList) : of([])
    ]).pipe(
      switchMap(([responseDealWbs, responseCCL]) => {   
        this.availableRes= {responseDealWbs, responseCCL};
          return of({responseDealWbs, responseCCL});
      })
    );
  }
  BindActualAvailableResponse(newDealItemId: any, availableFormsResponse: any): any {
    let responseAvaliableFormsList:any=this.availableFormsList;
     responseAvaliableFormsList.forEach((item: any, index: any) => {
      if (item.availableFormListName === AppSettings.newDealSetupFormList) {
        item.availableFormCreated = true;
        item.availableFormItemId = newDealItemId;
      }
      else if (item.availableFormListName === AppSettings.dealWbsSetupFormList && availableFormsResponse.responseDealWbs!=null&&availableFormsResponse.responseDealWbs?.ID!=undefined) {

        let data = {
          Id: availableFormsResponse.responseDealWbs.ID,
          Status: availableFormsResponse.responseDealWbs.Status == null ? availableFormsResponse.responseDealWbs.FormStatus : availableFormsResponse.responseDealWbs.Status
        }
        item.availableFormItemId = data.Id;
        item.availableFormCreated = true;
        item.availableFormModal = false;
        item.availableFormSidebar = true;
        item.formStatus = data.Status;
      }
      else if (item.availableFormListName === AppSettings.closingChecklistFormList && availableFormsResponse.responseCCL!=null&&availableFormsResponse.responseCCL?.ID!=undefined) {

        let data = {
          Id: availableFormsResponse.responseCCL.ID,
          Status: availableFormsResponse.responseCCL.Status == null ? availableFormsResponse.responseCCL.FormStatus : availableFormsResponse.responseCCL.Status
        }
        item.availableFormItemId = data.Id;
        item.availableFormCreated = true;
        item.availableFormModal = false;
        item.availableFormSidebar = true;
        item.formStatus = data.Status;
      }
    })
    return responseAvaliableFormsList;
  }
  DownloadAttachment(attachURL:any):any{
    let fileSrc=attachURL.split("/");
    let list="";
    if(fileSrc[1]=="NewDealAttachments"){list="NewDeal"}else{list="CrossBorder"}
    this.EHOService.downloadAttachments(fileSrc[2].split("_")[1].toString(),fileSrc[3].toString(),fileSrc[1],list).subscribe
    ({
      next: (response: any) => {
        if(response!=null && response.FileUrl!=null)
          {
              window.open(response.FileUrl, "_blank");
          }                       
      },
      error: (err: any) => {         
        console.log("Error" + err);
        if (err.status == 403 && err.error.stringKey==AppSettings.tokenExpiredText) 
          { 
            this._openSessionExpiredModal();
          }
      }
    });   
  
  }
  private _openSessionExpiredModal() {
    this.newDealNotifyPopupComponent.openPopup(AppSettings.tokenExpiredText);
  }
}

