<div class="modal " tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
  <div class="modal-dialog " role="document">
    <div class="modal-content custom-content " id="audit-content-popup" style="margin-top: 8%;overflow: auto;">
      <div class="">
        <button type="button" class="close close-size" (click)="closePopup()" data-dismiss="modal"
          aria-label="Close"><span aria-hidden="true">&times;</span>
        </button>
        <div class="header">
          <div class="headerManagement">Audit Trail </div>
        </div>

        <div style="display: inline-flex;">

          <div class="divFormType userGroupmargin" style="margin-right: -90px;">
            <label>Form Type</label>
            <select id="ddlContentType" [(ngModel)]="formTypeValue" class="form-select form-control formtypewidth"
              [disabled]="true">
              <option value="" selected disabled>Select Content Type</option>
              <option *ngFor="let title of formTypeValues" [value]="title.Name">
                {{title.Name}}
              </option>
            </select>
            <div class="col-sm-2" style="float:right;margin-top: -15.5%;">
              <button style="margin-left: 550px;width: 300%;height:6vh;" type="button" class="clear-filters-button" (click)="clearAllFilters($event)">
                  Clear Filters
              </button>
            </div>
            <div class="col-sm-2" style="float:right;">
              <button style="margin-left: 715px;width: 63%;height:6vh;margin-top: -50px;" class="btn btn-primary btnGenerateAuditReport" type="button" (click)="downloadAuditReport()" [disabled]="!(auditManagements && auditManagements.length > 0)">Export</button>
            </div>
          </div>
        </div>
        <br /><br />
        <table id="engagement-table" class="table-striped">
          <thead style="height: 60px;">
            <tr>
              <th *ngIf="selectedTableName=='CrossBorderForm' || selectedTableName=='CrossBorderAdditionalDocumentTypes'
                        || selectedTableName=='CrossBorderApprovalReviewStatus'|| selectedTableName=='MyDeal'|| selectedTableName=='NewDealSetupForm'" scope="col">
                <span class="th-label" sortable="FormID" (sort)="sortTable($event)">
                  Form ID
                </span>
                <app-popover #popoverName [active]="isFilterApply('FormID')" [keepOpen]="true" [openOnFocus]="false"
                  [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'" [triggers]="'manual'"
                  [autoClose]="'outside'" (click)="openFilterPopover('FormID')">
                </app-popover>
              </th>    
              <th scope="col">
                <span class="th-label" sortable="ModifiedBy" (sort)="sortTable($event)">
                  ModifiedBy
                </span>
                <app-popover #popoverName [active]="isFilterApply('ModifiedBy')" [keepOpen]="true" [openOnFocus]="false"
                  [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'" [triggers]="'manual'"
                  [autoClose]="'outside'" (click)="openFilterPopover('ModifiedBy')">

                </app-popover>

              </th>
              <th scope="col">
                <span class="th-label" sortable="ModifiedOn" (sort)="sortTable($event)">
                  ModifiedOn
                </span>
                <app-popover #popoverTitle [active]="isFilterApply('ModifiedOn')" [keepOpen]="true"
                  [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                  [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('ModifiedOn')">
                </app-popover>

              </th>
              <th scope="col">
                <span class="th-label" sortable="Description" (sort)="sortTable($event)">
                  Description
                </span>
                <app-popover #popoverTitle [active]="isFilterApply('Description')" [keepOpen]="true"
                  [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                  [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('Description')">
                </app-popover>

              </th>

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let report of auditManagementPerPage; let i = index">
              <td *ngIf="selectedTableName=='CrossBorderForm' || selectedTableName=='CrossBorderAdditionalDocumentTypes'
                         || selectedTableName=='CrossBorderApprovalReviewStatus'|| selectedTableName=='MyDeal'|| selectedTableName=='NewDealSetupForm'">
                <span>{{report.FormID}}</span>
              </td>
              <td>
                <span>{{report.ModifiedBy}}</span>
              </td>
              <td>
                <span>
                  {{report.ModifiedOn}}
                </span>
              </td>
              <td>
                {{report.Description}}
              </td>
            </tr>
          </tbody>
        </table>

        <span *ngIf="auditManagements && auditManagements.length === 0" style="margin-left: 380px;"
          class="no-matches">No records matched your search.</span>
        <br />
        <div class="row">
          <div class="col-md-5" style="margin-left: 1%;">
            <app-pagination #pagination *ngIf="auditManagements" [page]="commonHelperService.state.page"
              [pageSize]="commonHelperService.state.pageSize" [paginationListValues]="pageSizeListValues"
              [listElementsSize]="auditManagements.length" (paginationValues)="onChangePaginationValues($event)">
            </app-pagination>
          </div>
          <div class="col-md-5">
            <div class="divPageSizeType">
              <select id="ddlPageSize" (change)="onPageSizeValueChange($event)" [(ngModel)]="selectedPageSize"
                class="form-select form-control" [disabled]="pageDropdownDisable">
                <option value="" selected disabled>Select your option</option>
                <option *ngFor="let pages of pageSizeListValues" [value]="pages.key">
                  {{pages.value}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <ng-template #popContent>
          <div class="filter-container">
            <div class="filter-body">
              <div class="row sort-row" (click)="sortTableOnFilterModal(targetField, 'asc')">
                <i class="fas fa-sort-alpha-down sort-icon"></i>
                <span>A on Top</span>
              </div>
              <div class="row sort-row" (click)="sortTableOnFilterModal(targetField, 'desc')">
                <i class="fas fa-sort-alpha-down-alt sort-icon"></i>
                <span>Z on Top</span>
              </div>
              <hr>
              <div class="row">
                <div [ngClass]="{'clear-filter-container-enable': isFilterApply(targetField)}" (click)="clearFilters()">
                  <i class="fas fa-filter filter-icon"></i>
                  <span class="filter-icon-span" [ngClass]="{'filter-icon-span-enable': !isFilterApply(targetField)}">
                    Clear Filters from Value
                  </span>
                </div>
              </div>
              <div *ngFor="let value of fieldOptionValues" class="row">
                <label class="checkbox-label">
                  <input type="checkbox" class="checkbox-option-field" id="{{value}}" [checked]="isChecked(value)"
                    (click)="onClickFilterCheckbox($event)">
                  {{value}}
                </label>
              </div>
            </div>
          </div>
        </ng-template>

      </div>
    </div>
  </div>
</div>