<div class="col-xs-10" id="div-navigation-index" [style]="isFixed">
    <nav>
        <ul id="ul-navigation-section" class="nav">
            <li><a (click)="onclick('#project-overview')" >Deal / Project Overview</a></li>
            <li><a (click)="onclick('#client-overview')">Client Overview</a></li>
            <li><a (click)="onclick('#client-setup')">Client Setup</a></li>
            <li><a  (click)="onclick('#new-deal')">New Deal</a></li>
            <li><a (click)="onclick('#mercury-opportunity')">Jupiter Opportunity</a></li>
            <li><a  (click)="onclick('#prd')">PRD</a></li>
            <li><a  (click)="onclick('#documents')">Documents</a></li>
            <li *ngIf="showCommentSection"><a (click)="onclick('#notes-section')">Return For Re-Work Comments</a></li>
            <li *ngIf="showAdminSection"><a (click)="onclick('#admin-review')">Admin Review</a></li>
             <li *ngIf="showReactivateSection"><a (click)="onclick('#reactivate-form')">Reactivate Form</a></li>
            <li *ngIf="showActiveSection"><a (click)="onclick('#active-new-deal')">Active New Deal Section</a></li>
            <button *ngIf="!isAutoSaveEnabled&&saveButtonVisible&&isAdminReviewEditable"
            class="btn btn-outline-primary save-rework-cancel-btn margin-left-10 savebuttonstyles"
            [disabled]="saveInProgress"
            (click)="onSaveForm()">
            Save
          </button>
        </ul>
    </nav>
    <div id="back-to-top-button" class="hidden">
        <span class="glyphicon glyphicon-menu-up"></span>
    </div>
</div>
