import { baseContentManagement } from './baseContentManagement'

export class ContentManagement {
  //CrossBorderCountries, CrossBorderTemplates AND ValidationFieldsCrossBorder
  ID:string="";
  Title: string=""; 
  Value:string="";

  //CrossBorderTemplates, FormGroups AND FormType
  Name: string="";
  FormTypeID:string="";

  //CrossBorderDocumentTypes
  DocumentTypeOrder: string="";
  DocumentTypeTitle: string="";
  Required: boolean=false;
  Guidance: string="";
  BusinessReviewApplies: boolean=false;
  QRMReviewApplies: boolean=false;
  ComplianceReviewApplies: boolean=false;
  RequiresFinalVersion: boolean=false;
  DependentDocumentTypes:string="";
  EnterpriseValue:string="";
  ExpectedCompanyValueComments="";

  //CrossBorderIndustries
  IndustryTitle: string="";
  Representative: string="";

  //CrossBorderTemplates, CrossBorderApprovalReviewStatus and CrossBorderAdditionalDocumentTypes
  DocumentType: string="";
  
  //ValidationFieldsCrossBorder
  RequiredFields: string="";
  RequiredGroupFields: string="";
  CreatedBy:string="";

  //CrossBorderApprovalReviewStatus
  FormID: number;
  BusinessReviewer:string="";
  BusinessReviewStatus: string="";
  ComplianceReviewer: string="";
  ComplianceReviewStatus: string="";
  QRMReviewer: string="";
  QRMReviewStatus: string="";
  //BusinessReviewDate: string="";
  //ComplianceReviewDate: string="";
  //QRMReviewDate: string="";
  //AdditionalReviewNeeded: string="";
  //AutomaticReviewDate: string="";
  //CrossBorderreviewprocessworkflow: string="";

  //CrossBorderComments
  CommentAuthor: string="";
  CommentType: string="";
  Comment: string="";
  ReviewStatus: string="";

  Role: string="";

  
  ModifiedBy:string="";
  Created:string="";
  isActive:string="";
  isApproved:string="";
  isRejected:string="";
  VerificationDescription:string="";
  Modified:string="";
  // CrossBorder Form 
    ProjectName:string="";
    ClientName:string="";
    GeographConcentrationComments:string="";
    USCounterpartiesContactComments:string="";
    ShareholderObjectivesComments:string="";
    CompanySubsectorComments:string="";


    //My Deal Form
    Series24DisplayNames:string="";
    EngagementName:string="";
    Series24:string="";
    WbsCode:string="";
    rangeKey:string="";

    //Newdeal Form
    LeadClientServicePartner: string="";
    SwiftCode: string="";
    MyDealEngagementName: string="";
    LeadClientServicePartnerDisplayNames: string="";
    FormStatus: string="";

    
}    
