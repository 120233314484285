<div [formGroup]="qualityOfEarningsFormGroup" id="quality-of-earnings" class="container-fluid border container-border-color container-section">
    <div class="row bg-title">
        <span class="title-section-print">Quality of Earnings</span>
        <span class="glyphicon glyphicon-question-sign title-question-sign tooltip-dcf"></span>
        <span class="tooltiptext-dcf visibility-hidden">
            <p>Only for situations where the seller performs a QofE as part of a sellside process.</p>
        </span>
    </div>
    <div id="quality-of-earnings-section" class="content-section">
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div section-label">
                    <span class="span-to-highlight"
                    [class.highlight-warning]="highlightRequiredField('sellsideQofEPerformed')"
            [class.edited-externally]="editedExternally('sellsideQofEPerformed')"
            [class.conflict-on-merge]="hasMergeConflict('sellsideQofEPerformed')">Was a sellside QofE Performed?</span>
                    <span *ngIf="showAsterisk('sellsideQofEPerformed')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <app-toggle-yes-no  #SellsideQofEPerformed
                    [value]="qualityOfEarningsFormGroup.get('sellsideQofEPerformed').value"
                    [disabled]="qualityOfEarningsFormGroup.get('sellsideQofEPerformed').disabled"
                    [readonly]="isReadOnly('sellsideQofEPerformed')"
                    (handleToggleYesNo)="isQofEPerformed($event)"
                    [notApplicableEnabled]="notApplicableEnabled"
                    class="buttons-app-yes-no">
                    </app-toggle-yes-no>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div section-label">
                    <span class="span-to-highlight"
                    [class.highlight-warning]="highlightRequiredField('qofEStage')"
            [class.edited-externally]="editedExternally('qofEStage')"
            [class.conflict-on-merge]="hasMergeConflict('qofEStage')">At what stage?</span>
                    <span *ngIf="showAsterisk('qofEStage')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <app-dropdown
                    [optionsList]="stageOptions"
                    [keySelected]="qualityOfEarningsFormGroup.get('qofEStage').value"
                    [readonly]="isReadOnly('qofEStage')"
                    [disabled]="qualityOfEarningsFormGroup.get('qofEStage').disabled"
                    (optionSelected)="onQofEStageSelection($event)">
                    </app-dropdown>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 required-div section-label">
                <span class="span-to-highlight"
                [class.highlight-warning]="highlightRequiredField('whatFirmPerformed')"
            [class.edited-externally]="editedExternally('whatFirmPerformed')"
            [class.conflict-on-merge]="hasMergeConflict('whatFirmPerformed')">What firm performed it?</span>
                <span *ngIf="showAsterisk('whatFirmPerformed')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <input
                formControlName="whatFirmPerformed"
                class="input-data-text full-width section-input"
                type="text"
                [disabled]="qualityOfEarningsFormGroup.get('whatFirmPerformed').disabled"
                [placeholder]="qualityOfEarningsFormGroup.get('whatFirmPerformed').disabled ? 'N/A' : ''"
                autocomplete="off">
            </div>
        </div>
        <div class="row">
            <div class="col-xs-4 required-div section-label">
                <span class="span-to-highlight"
                [class.highlight-warning]="highlightRequiredField('mainContact')"
            [class.edited-externally]="editedExternally('mainContact')"
            [class.conflict-on-merge]="hasMergeConflict('mainContact')">Main contact</span>
                <span *ngIf="showAsterisk('mainContact')" class="red-asterik"></span>
            </div>
            <div class="col-xs-8">
                <input
                formControlName="mainContact"
                class="input-data-text full-width section-input"
                type="text"
                [disabled]="qualityOfEarningsFormGroup.get('mainContact').disabled"
                [placeholder]="qualityOfEarningsFormGroup.get('mainContact').disabled ? 'N/A' : ''"
                autocomplete="off">
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div section-label">
                    <span class="span-to-highlight"
                    [class.highlight-warning]="highlightRequiredField('qofEDeloitteBranded')"
            [class.edited-externally]="editedExternally('qofEDeloitteBranded')"
            [class.conflict-on-merge]="hasMergeConflict('qofEDeloitteBranded')">If Deloitte, was it branded?</span>
                    <span *ngIf="showAsterisk('qofEDeloitteBranded')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <app-toggle-yes-no  #QofEDeloitteBranded
                    [value]="qualityOfEarningsFormGroup.get('qofEDeloitteBranded').value"
                    [disabled]="qualityOfEarningsFormGroup.get('qofEDeloitteBranded').disabled"
                    [readonly]="isReadOnly('qofEDeloitteBranded')"
                    (handleToggleYesNo)="isQofEDeloitteBranded($event)"
                    [notApplicableEnabled]="notApplicableEnabled"
                    class="buttons-app-yes-no">
                    </app-toggle-yes-no>
                </div>
            </div>
        </div>
    </div>
</div>