<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css">
<div>
  <div class="container" style="width: 100%;">
    <div class="row">
      <div class="col-xs-6">
        <div class="headerManagement">Onboarding Form Report</div>
      </div>
      <div class="col-xs-6">
        <div id="crossborderLinkId">
          <a id="crossborderLinkId" href="/onboardingForm">OnboardingRegistrationForm</a>
        </div>
      </div>
    </div>  
    
    <div class="row">
      <div class="col-sm-3">
        <div id="search-container">
         
        </div>
      </div>
      <div class="col-sm-3">
        <div id="show-deals-container">
          <button type="button" class="clear-filters-button" >
            Clear Filters
          </button>
        </div>
      </div>
      
      <div class="col-sm-2" style="float:right ;">
        <button style="margin-left: 35%;width: 63%;height:6vh;" type="button" 
          class="engagement-dashboard-button btn_Custom" id="exportExcel">
          Export
        </button>
      </div>
      <br>
      <br>
    </div>

    <div class="table-responsive table-body heightStyles">
      <table id="termination-table">
        <thead>
          <tr>
            <th scope="col">
              <input id="chk_MultiCheckBox" style="height:13px ;width: 75px;" type="checkbox"/>
            </th>
            <th scope="col">
              <span class="th-label">
                ID
              </span>
            </th>
            <th scope="col">           
              <span class="th-label" >
                Last Name
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                First Name
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                Form Status
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                Date of Hire
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                Registration Status
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                Supervisory Branch Office
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                Series 24 Branch Supervisor
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                Supervisory Branch Office
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                Series 24 Branch Supervisor notified of new employee under their supervision? 
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                Returning hire? 
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                Welcome Email sent?  
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                Background check received, reviewed, and saved to employee file?  
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                Deloitte application received, reviewed, and saved to employee file?  
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                Lexis Nexis reviewed and saved to employee file?  
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                Social media (Google/LinkedIn) check performed and saved to employee file?  
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                Active Directory  
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                Knowledge Center  
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                DFlow Permissions Spreadsheet 
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                Supervisory Org Chart 
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                CSI
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                Quest  
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                BIG email or hardcopy fingerprinting cards sent? 
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                Fingerprints cleared with FINRA? 
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                Study Materials ordered? 
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                Initial Form U4 / NRF Filing complete? 
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                State registration memo sent? 
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                Added to Smarsh and Smarsh supervision hierarchy? 
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                Confirmed communications are appearing in Smarsh? 
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                Deloitte mobile phone provisioned in Smarsh? 
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                Brokerage account disclosure process complete? 
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                OBA disclosure process complete? 
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                PST disclosure process complete? 
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                CSI Attestations completed, reviewed, and saved to employee file? 
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                New Hire Quest CE (Firm Element) completed and record saved to employee file?  
              </span>
            </th>
            <th scope="col">
              <span class="th-label" >
                Date of Compliance New Hire Training
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
        </tbody>
      </table>
    </div>
  </div>
</div>