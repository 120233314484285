import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';

import { DropdownItem } from 'src/shared/models/dropdown-item';
import { NgbdSortableHeaderDirective, SortDirection, SortEvent } from 'src/shared/directives/sorteable.directive';
import { PaginationComponent } from 'src/shared/components/pagination/pagination.component';
import { ReportDashboardSort } from '../models/reportDashboard-sort';
import { PaginationValues } from 'src/shared/models/pagination-values';
import { DatePipe } from '@angular/common';
import { AdminService } from 'src/app/services/admin.service';
import { AppSettings } from 'src/shared/app-settings';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { environment } from 'src/environments/environment';
import { DateService } from '../services/date.service';
import { ReportDashboardTableFilterItem } from '../models/reportDashboard-table-filter-item';
import { EngagementTableFilterItem } from '../models/engagement-table-filter-item';
import { SpinnerService } from 'src/shared/services/spinner.service';
import { TerminationFormService } from '../services/terminationform.service';

import { CommonHelperService } from '../commonHelper/common-helper.service';
import { TerminationNotifyPopupComponent } from '../termination-form/termination-form-popup/termination-form-popup.component';
import { ScriptLoaderService } from '../services/script-loader.service';
import { GlobalHelperService } from '../globalHelper/global-helper.service';
import {SearchBoxComponent} from 'src/shared/components/search-box/search-box.component';

@Component({
  selector: 'app-new-hire-form-dashboard',
  templateUrl: './new-hire-form-dashboard.component.html',
  styleUrls: ['./new-hire-form-dashboard.component.scss']
})
export class NewHireFormDashboardComponent implements OnInit {
    ngOnInit(): void {
      
    }

    onSearchResult(result: any) {
      
      console.log('Search result:', event);
    }
}