<div
  id="active-deal-wbs"
  class="container-fluid border container-border-color container-section">
  <div class="row bg-title">
      <span class="title-section-print">Active Deal WBS Notification
      </span>
  </div>
  <div id="active-deal-wbs-section" class="content-section">
    <div  class="row"  *ngIf="!isNotificationSent">
      <div class="col-xs-12 submit-buttons">
         <button
            class="btn btn-primary active-button"style="align-items: center;margin-right: 36%;width: 29%;margin-left: 36%;"
            (click)="adminActiveWBS()">
            Send Deal WBS Active Notification
         </button> 
      </div>
    </div>
    <div *ngIf="isNotificationSent" class="row">
      <div>
        <span class="signature-color name-admin-review-section"><b>Active Deal WBS Notification Sent</b></span>
        <span class="fa fa-check-circle reviewed-icon-color sent-icon-color"></span>
      </div>
    </div>
  </div>
</div>
