<div class="row">
  <div class="col-xs-6">
    <div class="headerManagement">Registration Termination CheckList</div>
  </div>
</div>
<form [formGroup]="terminationFormGroup" (ngSubmit)="onSubmit()"(keydown.enter)="$event.preventDefault()">
<div id="termination-form"
 class="container-fluid border container-border-color container-section">
 <button 
            type="button" 
            class="btn btn-success"
            name="AuditFormButton"
            id="AuditFormButton" (click)="getTerminationFormAuditDetials()" [disabled]="!isExportEnabled">Audit Details</button> 
  <div class="row bg-title">
    <span>Termination Form</span>
  </div>
  <div id="termination-form-section" class="content-section">
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('lastName')" >Last Name </span>
        <span style="color:red">*</span>
      </div>
      <div class="col-xs-6 input-width input-align">
        <input formControlName="lastName" 
          class="input-data-text full-width section-input" 
          type="text"
          autocomplete="off"
          maxlength="255">
      </div>
    </div>
  
    <div class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('firstName')" >First Name </span>
        <span style="color:red">*</span>
      </div>
      <div class="col-xs-6 input-width input-align">
        <input formControlName="firstName" 
          class="input-data-text full-width section-input" 
          type="text"
          autocomplete="off"
          maxlength="255">
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 margin-top-5">
        <span  [class.highlight-warning]="highlightRequiredField('dateOfTermination')">Date of Termination </span>
        <span style="color:red">*</span>
      </div>
      <div class="col-xs-7 input-align" >
        <app-date-picker 
        [selectedDate]="terminationFormGroup.get('dateOfTermination')?.value"
        (dateSelected)="eventDateSelected($event,'dateOfTermination')">
        </app-date-picker>
        <span class="numeric-validation-msg"></span>
      </div>
    </div>
        <div class="row">
          <div class="col-xs-4 section-label">
            <span [class.highlight-warning]="highlightRequiredField('voluntaryTermination') || (terminationFormGroup.get('voluntaryTermination')?.value=='Discharged' && highlightRequiredField('voluntaryTerminationDischarged'))|| 
            (terminationFormGroup.get('voluntaryTermination')?.value=='Permitted to Resign' && highlightRequiredField('voluntaryTerminationPermittedToResign')) ||  (terminationFormGroup.get('voluntaryTermination')?.value=='Other' && highlightRequiredField('voluntaryTerminationOther')) ">Reason for Termination </span>
            <span style="color:red">*</span>
          </div>
          <div class="col-xs-6 input-width input-align">
              <app-dropdown [optionsList]="voluntaryTerminationList" [keySelected]="terminationFormGroup.get('voluntaryTermination')?.value"
                  (optionSelected)="onVoluntaryTerminationChange($event)">
              </app-dropdown>
          </div>
                  <textarea style="margin-left: 35.2%;width: 37%;" cols="2" *ngIf="isvoluntaryTerminationDischarged"
                  type="text"
                  rows="3"
                  formControlName="voluntaryTerminationDischarged"
                  placeholder="Comment"
                  class="input-data-text text-area-style"></textarea>
        
                  <textarea style="margin-left: 35.2%;width: 37%;" cols="2" *ngIf="isvoluntaryTerminationPermittedToResign"
                  type="text"
                  rows="3"
                  formControlName="voluntaryTerminationPermittedToResign"
                  placeholder="Comment"
                  class="input-data-text text-area-style signMargin"></textarea>  

                  <textarea style="margin-left: 35.2%;width: 37%;" cols="2" *ngIf="isvoluntaryTerminationOther"
                  type="text"
                  rows="3"
                  formControlName="voluntaryTerminationOther"
                  placeholder="Comment"
                  class="input-data-text text-area-style signMargin"></textarea>  
      </div>

        <div class="row">
            <div class="col-xs-4">
              <span [class.highlight-warning]="highlightRequiredField('StatusofIndividual') || (terminationFormGroup.get('StatusofIndividual')?.value=='Registered' && highlightRequiredField('RegisteredStatus'))
              ||(terminationFormGroup.get('StatusofIndividual')?.value=='NRF' && highlightRequiredField('NRFStatus'))
              || (terminationFormGroup.get('StatusofIndividual')?.value=='NRF' && terminationFormGroup.get('NRFStatus')?.value=='Other' && highlightRequiredField('otherNRFStatusReason')) ">Status of Individual </span>
              <span style="color:red">*</span>
            </div>
            <div class="col-xs-4 radio-button-align">
              <input class="radioStyle" type="radio" formControlName="StatusofIndividual" value="Registered" (change)="onStatusofIndividualChange($event)"> Registered
              <input class="radioStyle" type="radio" formControlName="StatusofIndividual" value="NRF" (change)="onStatusofIndividualChange($event)"> NRF
            </div>  
            <div class="col-xs-8" style="float: right;">
              <div class="col-xs-8" style="margin-left: 3.5%;" *ngIf="isRegisteredStatus">
                Supervisory <input class="radioStyle" type="radio" formControlName="RegisteredStatus"  value="Yes" > Yes
                <input class="radioStyle" type="radio" formControlName="RegisteredStatus"  value="No" > No
              </div> 

              <div class="col-xs-8" style="margin-left: 0.88%;" *ngIf="isNRFStatus">
                <input class="radioStyle" type="radio" formControlName="NRFStatus"  value="Intern" (change)="onNRFStatusChange($event)"> Intern
                <input class="radioStyle" type="radio" formControlName="NRFStatus"  value="USI" (change)="onNRFStatusChange($event)"> USI
                <input class="radioStyle" type="radio" formControlName="NRFStatus"  value="BDA Contractor" (change)="onNRFStatusChange($event)"> BDA Contractor
                <input class="radioStyle" type="radio" formControlName="NRFStatus"  value="Other" (change)="onNRFStatusChange($event)"> Other
              </div> 

              <!-- <div class="col-xs-8" *ngIf="isOtherNRFStatus">
                <input style="width: 60%;"
                  class="input-data-text section-input" 
                  type="text"
                  autocomplete="off"
                  maxlength="255">
              </div> -->
              <textarea style="width: 55%;margin-left: 3.5%;" cols="2" *ngIf="isOtherNRFStatus&& !isRegisteredStatus"
                  type="text"
                  rows="3"
                  formControlName="otherNRFStatusReason"
                  placeholder="Reason"
                  class="input-data-text text-area-style"
                  ></textarea>
          </div>     
          </div>
          <div class="row">
            <div class="col-xs-4 section-label">
              <span [class.highlight-warning]="highlightRequiredField('series24LicensedSupervisor')">Name of Series 24 Licensed Supervisor at the time of termination </span>
              <span style="color:red">*</span>
            </div>
            <div class="col-xs-6 input-width input-align">
              <app-dropdown
              [optionsList]="s24Options"
              [keySelected]="terminationFormGroup.get('series24LicensedSupervisor')?.value"
              [userLeftDCF]="series24LeftDCF"
              (optionSelected)="onSeries24Selection($event)">
            </app-dropdown>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-4">
            <span [class.highlight-warning]="highlightRequiredField('TerminationReason')
            || ((terminationFormGroup.get('TerminationReason')?.value=='No'|| terminationFormGroup.get('TerminationReason')?.value=='Other') && highlightRequiredField('TerminationReasonComments') )">Does the reason for termination match the reason communicated by the business and Talent? </span>
            <span style="color:red">*</span>
            </div>
            <div class="col-xs-4 radio-button-align">
            <input class="radioStyle" type="radio" formControlName="TerminationReason"  value="Yes" (change)="onTerminationMatchChange($event)"> Yes
            <input class="radioStyle" type="radio" formControlName="TerminationReason"  value="No" (change)="onTerminationMatchChange($event)"> No
            <input class="radioStyle" type="radio" formControlName="TerminationReason"  value="Other" (change)="onTerminationMatchChange($event)"> Other
            <textarea cols="2"style="margin-left:4%" *ngIf="isTerminationReason"
                  type="text"
                  formControlName="TerminationReasonComments"
                  rows="3"
                  placeholder="Reason"
                  class="input-data-text text-area-style "></textarea>
            </div>
        </div>
        <!-- <div class="row">
          <div class="col-xs-4">
          <span [class.highlight-warning]="highlightRequiredField('ThreatTeamContact')
          || ((terminationFormGroup.get('ThreatTeamContact')?.value=='No'|| terminationFormGroup.get('ThreatTeamContact')?.value=='Other') && highlightRequiredField('threatTeamContactReason') )">The insider threat team was contacted to confirm there are no open cases for the Terminated Individual. </span>
          <span style="color:red">*</span>
          </div>
          <div class="col-xs-4 radio-button-align">
          <input class="radioStyle" type="radio" formControlName="ThreatTeamContact"  value="Yes" (change)="onThreatTeamContanctChange($event)"> Yes
          <input class="radioStyle" type="radio" formControlName="ThreatTeamContact"  value="No" (change)="onThreatTeamContanctChange($event)"> No
          <input class="radioStyle" type="radio" formControlName="ThreatTeamContact"  value="Other" (change)="onThreatTeamContanctChange($event)"> Other
          <textarea style="margin-left: 4%;" cols="2" *ngIf="isThreatTeamContactReason"
                  type="text"
                  formControlName="threatTeamContactReason"
                  rows="3"
                  placeholder="Reason"
                  class="input-data-text text-area-style"></textarea>
          </div>
      </div> -->
      <div class="row">
        <div class="col-xs-4">
        <span  [class.highlight-warning]="highlightRequiredField('OpenInternalThreat')
        || ((terminationFormGroup.get('OpenInternalThreat')?.value=='Yes'|| terminationFormGroup.get('OpenInternalThreat')?.value=='Other') && highlightRequiredField('openInternalThreatReason') )">Were there any open internal threat cases for this Terminated Individual? </span>
        <span style="color:red">*</span>
        </div>
        <div class="col-xs-4 radio-button-align">
        <input class="radioStyle" type="radio" formControlName="OpenInternalThreat"  value="Yes" (change)="onOpenInternalThreatChange($event)"> Yes
        <input class="radioStyle" type="radio" formControlName="OpenInternalThreat"  value="No" (change)="onOpenInternalThreatChange($event)"> No
        <input class="radioStyle" type="radio" formControlName="OpenInternalThreat"  value="Other" (change)="onOpenInternalThreatChange($event)"> Other
        <textarea style="margin-left: 4%;" cols="2" *ngIf="isOpenInternalThreatReason"
                  type="text"
                  formControlName="openInternalThreatReason"
                  rows="3"
                  placeholder="Reason"
                  class="input-data-text text-area-style"></textarea>
        </div>
    </div>
  <div class="row">
    <div class="col-xs-4">
    <span [class.highlight-warning]="highlightRequiredField('dateTheEmployeeRemoved')
    || (terminationFormGroup.get('dateTheEmployeeRemoved')?.value=='No' && highlightRequiredField('dateTheEmployeeRemovedComment') )">Removed from US National Corporate Finance Active Directory?</span>
    <span style="color:red">*</span>
    </div>
    <div class="col-xs-4 radio-button-align">
      <input class="radioStyle" type="radio" formControlName="dateTheEmployeeRemoved"  value="Yes" (change)="onEmployeeRemovedChange($event)"> Yes
      <input class="radioStyle" type="radio" formControlName="dateTheEmployeeRemoved"  value="No" (change)="onEmployeeRemovedChange($event)"> No
      <textarea style="margin-left: 4%;" cols="2" *ngIf="isdateTheEmployeeRemovedComment"
                type="text"
                rows="3"
                formControlName="dateTheEmployeeRemovedComment"
                placeholder="Comment"
                class="input-data-text text-area-style"></textarea>
    </div> 
</div>
<div class="row">

  <div class="col-xs-4">
      <span [class.highlight-warning]="highlightRequiredField('JakiePolsonNotifiedDate')|| (terminationFormGroup.get('JakiePolsonNotifiedDate')?.value=='No' && highlightRequiredField('JakiePolsonNotifiedDateComment'))">
        For PPMDs or client-facing PPMDs, notified Jackie Polson of termination? </span>
      <span style="color:red">*</span>
  </div>
  <div class="col-xs-4 radio-button-align" >
      <input class="radioStyle" type="radio" formControlName="JakiePolsonNotifiedDate"  value="Yes" (change)="onJakiePolsonChange($event)"> Yes
      <input class="radioStyle" type="radio" formControlName="JakiePolsonNotifiedDate"  value="No" (change)="onJakiePolsonChange($event)"> No
      <input class="radioStyle" type="radio" formControlName="JakiePolsonNotifiedDate"  value="N/A – Not a PPMD or Client Facing PPMD"
      (change)="onJakiePolsonChange($event)"> N/A – Not a PPMD or Client Facing PPMD
      <!-- <span class="numeric-validation-msg"></span> -->
      <textarea style="margin-left: 4%;" cols="2" *ngIf="isJakiePolsonNotifiedDateComment"
                type="text"
                rows="3"
                formControlName="JakiePolsonNotifiedDateComment"
                placeholder="Comment"
                class="input-data-text text-area-style"></textarea>
      
  </div>
</div>

<div class="row">

  <div class="col-xs-4">
      <span [class.highlight-warning]="highlightRequiredField('AllowedToTermedDate')|| (terminationFormGroup.get('AllowedToTermedDate')?.value=='No' && highlightRequiredField('AllowedToTermedDateComment'))">
        OBA's removed from active OBA list? </span>
      <span style="color:red">*</span>
  </div>
  <div class="col-xs-4 radio-button-align" >
      <input class="radioStyle" type="radio" formControlName="AllowedToTermedDate"  value="Yes" (change)="onOBAChange($event)"> Yes
      <input class="radioStyle" type="radio" formControlName="AllowedToTermedDate"  value="No" (change)="onOBAChange($event)"> No
      <input class="radioStyle" type="radio" formControlName="AllowedToTermedDate"  value="N/A – No OBA(s)"
      (change)="onOBAChange($event)"> N/A – No OBA(s)
      <!-- <span class="numeric-validation-msg"></span> -->
      <textarea style="margin-left: 4%;" cols="2" *ngIf="isAllowedToTermedDateComment"
                type="text"
                rows="3"
                formControlName="AllowedToTermedDateComment"
                placeholder="Comment"
                class="input-data-text text-area-style"></textarea>
      
  </div>
</div>
<div class="row">

  <div class="col-xs-4">
      <span [class.highlight-warning]="highlightRequiredField('PstToTermedClosedDeniedDate')|| (terminationFormGroup.get('PstToTermedClosedDeniedDate')?.value=='No' && highlightRequiredField('PstToTermedClosedDeniedDateComment'))">
        PST's removed from active PST list? </span>
      <span style="color:red">*</span>
  </div>
  <div class="col-xs-4 radio-button-align" >
      <input class="radioStyle" type="radio" formControlName="PstToTermedClosedDeniedDate"  value="Yes" (change)="onPstChange($event)"> Yes
      <input class="radioStyle" type="radio" formControlName="PstToTermedClosedDeniedDate"  value="No" (change)="onPstChange($event)"> No
      <input class="radioStyle" type="radio" formControlName="PstToTermedClosedDeniedDate"  value="N/A – No PST(s)"
      (change)="onPstChange($event)"> N/A – No PST(s)
      <!-- <span class="numeric-validation-msg"></span> -->
      <textarea style="margin-left: 4%;" cols="2" *ngIf="isPstToTermedClosedDeniedDateComment"
                type="text"
                rows="3"
                formControlName="PstToTermedClosedDeniedDateComment"
                placeholder="Comment"
                class="input-data-text text-area-style"></textarea>
      
  </div>
</div>

    <div class="row">
      <div class="col-xs-4">
      <span [class.highlight-warning]="highlightRequiredField('dateTheEmployeeRemovedDCFKnowledge')
      || (terminationFormGroup.get('dateTheEmployeeRemovedDCFKnowledge')?.value=='No' && highlightRequiredField('dateTheEmployeeRemovedDCFKnowledgeComment') )">Removed from DCF Knowledge Center?</span>
      <span style="color:red">*</span>
      </div>
      <div class="col-xs-4 radio-button-align">
        <input class="radioStyle" type="radio" formControlName="dateTheEmployeeRemovedDCFKnowledge"  value="Yes" (change)="onEmployeeRemovedDCFKnowledgeChange($event)"> Yes
        <input class="radioStyle" type="radio" formControlName="dateTheEmployeeRemovedDCFKnowledge"  value="No" (change)="onEmployeeRemovedDCFKnowledgeChange($event)"> No
        <textarea style="margin-left: 4%;" cols="2" *ngIf="isdateTheEmployeeRemovedDCFKnowledgeComment"
                  type="text"
                  rows="3"
                  formControlName="dateTheEmployeeRemovedDCFKnowledgeComment"
                  placeholder="Comment"
                  class="input-data-text text-area-style"></textarea>
      </div> 
  </div>
    
    <div class="row">

      <div class="col-xs-4">
        <span [class.highlight-warning]="highlightRequiredField('dateTheEmployeeRemovedFlowPerms')|| (terminationFormGroup.get('dateTheEmployeeRemovedFlowPerms')?.value=='No' && highlightRequiredField('dateTheEmployeeRemovedFlowPermsComment'))|| 
        (terminationFormGroup.get('dateTheEmployeeRemovedFlowPerms')?.value == 'N/A' && highlightRequiredField('dateTheEmployeeRemovedFlowPermsReason'))">
        Removed from the Dflow permissions spreadsheet?
    </span>
          <span style="color:red">*</span>
      </div>
      <div class="col-xs-4 radio-button-align" >
          <input class="radioStyle" type="radio" formControlName="dateTheEmployeeRemovedFlowPerms"  value="Yes" (change)="onDflowChange($event)"> Yes
          <input class="radioStyle" type="radio" formControlName="dateTheEmployeeRemovedFlowPerms"  value="No" (change)="onDflowChange($event)"> No
          <input class="radioStyle" type="radio" formControlName="dateTheEmployeeRemovedFlowPerms"  value="N/A"
          (change)="onDflowChange($event)"> N/A
          <!-- <span class="numeric-validation-msg"></span> -->
          <textarea style="margin-left: 4%;" cols="2" *ngIf="isdateTheEmployeeRemovedFlowPermsComment"
                    type="text"
                    rows="3"
                    formControlName="dateTheEmployeeRemovedFlowPermsComment"
                    placeholder="Comment"
                    class="input-data-text text-area-style"></textarea>
          
        <textarea style="margin-left: 4%;" cols="2" *ngIf="isdateTheEmployeeRemovedFlowPermsReason"
        type="text"
        rows="3"
        formControlName="dateTheEmployeeRemovedFlowPermsReason"
        placeholder="Reason"
        class="input-data-text text-area-style "></textarea>       
      </div>
    </div>

    <div class="row">

      <div class="col-xs-4">
          <span [class.highlight-warning]="highlightRequiredField('dateSupportTicketOpened')|| (terminationFormGroup.get('dateSupportTicketOpened')?.value=='No' && highlightRequiredField('dateSupportTicketOpenedComment'))|| (terminationFormGroup.get('dateSupportTicketOpened')?.value=='N/A' && highlightRequiredField('dateSupportTicketOpenedReason'))">
          Support ticket submitted to remove from open DCF DFlow sites? </span>
          <span style="color:red">*</span>
      </div>
      <div class="col-xs-4 radio-button-align" >
          <input class="radioStyle" type="radio" formControlName="dateSupportTicketOpened"  value="Yes" (change)="onSupportTicketOpenedChange($event)"> Yes
          <input class="radioStyle" type="radio" formControlName="dateSupportTicketOpened"  value="No" (change)="onSupportTicketOpenedChange($event)"> No
          <input class="radioStyle" type="radio" formControlName="dateSupportTicketOpened"  value="N/A"
          (change)="onSupportTicketOpenedChange($event)"> N/A
          <!-- <span class="numeric-validation-msg"></span> -->
          <textarea style="margin-left: 4%;" cols="2" *ngIf="isdateSupportTicketOpenedComment"
                    type="text"
                    rows="3"
                    formControlName="dateSupportTicketOpenedComment"
                    placeholder="Comment"
                    class="input-data-text text-area-style"></textarea>
      <textarea style="margin-left: 4%;" cols="2" *ngIf="isdateSupportTicketOpenedReason"
            type="text"
            rows="3"
            formControlName="dateSupportTicketOpenedReason"
            placeholder="Reason"
            class="input-data-text text-area-style "></textarea>
          
      </div>
    </div>
    
    <div class="row">
      <div class="col-xs-4">
      <span [class.highlight-warning]="highlightRequiredField('dateTheEmployeeRetired')
      || (terminationFormGroup.get('dateTheEmployeeRetired')?.value=='No' && highlightRequiredField('dateTheEmployeeRetiredComment') )">Retired in CSI?</span>
      <span style="color:red">*</span>
      </div>
      <div class="col-xs-4 radio-button-align">
        <input class="radioStyle" type="radio" formControlName="dateTheEmployeeRetired"  value="Yes" (change)="onTheEmployeeRetiredChange($event)"> Yes
        <input class="radioStyle" type="radio" formControlName="dateTheEmployeeRetired"  value="No" (change)="onTheEmployeeRetiredChange($event)"> No
        <textarea style="margin-left: 4%;" cols="2" *ngIf="isdateTheEmployeeRetiredComment"
                  type="text"
                  rows="3"
                  formControlName="dateTheEmployeeRetiredComment"
                  placeholder="Comment"
                  class="input-data-text text-area-style"></textarea>
      </div> 
  </div>
    <div class="row">
      <div class="col-xs-4">
      <span [class.highlight-warning]="highlightRequiredField('RetiredFromQuestCE')
      || (terminationFormGroup.get('RetiredFromQuestCE')?.value=='No' && highlightRequiredField('RetiredFromQuestCEComment') )">Retired from Quest CE?</span>
      <span style="color:red">*</span>
      </div>
      <div class="col-xs-4 radio-button-align">
        <input class="radioStyle" type="radio" formControlName="RetiredFromQuestCE"  value="Yes" (change)="onRetiredFromQuestCEChange($event)"> Yes
        <input class="radioStyle" type="radio" formControlName="RetiredFromQuestCE"  value="No" (change)="onRetiredFromQuestCEChange($event)"> No
        <textarea style="margin-left: 4%;" cols="2" *ngIf="isRetiredFromQuestCEComment"
                  type="text"
                  rows="3"
                  formControlName="RetiredFromQuestCEComment"
                  placeholder="Comment"
                  class="input-data-text text-area-style"></textarea>
      </div> 
  </div>
    <div class="row">
      <div class="col-xs-4">
      <span [class.highlight-warning]="highlightRequiredField('SmarshEmail')
      || (terminationFormGroup.get('SmarshEmail')?.value=='No' && highlightRequiredField('SmarshEmailComment') )">Smarsh spot check (two week lookback) completed? Please note any finding in Additional Comments section below. </span>
      <span style="color:red">*</span>
      </div>
      <div class="col-xs-4 radio-button-align">
        <input class="radioStyle" type="radio" formControlName="SmarshEmail"  value="Yes" (change)="onSmarshEmailChange($event)"> Yes
        <input class="radioStyle" type="radio" formControlName="SmarshEmail"  value="No" (change)="onSmarshEmailChange($event)"> No
        <textarea style="margin-left: 4%;" cols="2" *ngIf="isSmarshEmailComment"
                  type="text"
                  rows="3"
                  formControlName="SmarshEmailComment"
                  placeholder="Comment"
                  class="input-data-text text-area-style"></textarea>
      </div> 
  </div>
    <div class="row">
      <div class="col-xs-4">
      <span [class.highlight-warning]="highlightRequiredField('dateTheEmployeeRemovedSupervision')
      || (terminationFormGroup.get('dateTheEmployeeRemovedSupervision')?.value=='No' && highlightRequiredField('dateTheEmployeeRemovedSupervisionComment') )">Removed from Smarsh supervision hierarchy?</span>
      <span style="color:red">*</span>
      </div>
      <div class="col-xs-4 radio-button-align">
        <input class="radioStyle" type="radio" formControlName="dateTheEmployeeRemovedSupervision"  value="Yes" (change)="onTheEmployeeRemovedSupervisionChange($event)"> Yes
        <input class="radioStyle" type="radio" formControlName="dateTheEmployeeRemovedSupervision"  value="No" (change)="onTheEmployeeRemovedSupervisionChange($event)"> No
        <textarea style="margin-left: 4%;" cols="2" *ngIf="isdateTheEmployeeRemovedSupervisionComment"
                  type="text"
                  rows="3"
                  formControlName="dateTheEmployeeRemovedSupervisionComment"
                  placeholder="Comment"
                  class="input-data-text text-area-style"></textarea>
      </div> 
  </div>
    <div class="row">

      <div class="col-xs-4">
          <span [class.highlight-warning]="highlightRequiredField('DateTheEmployeeDeloitteMobile')|| (terminationFormGroup.get('DateTheEmployeeDeloitteMobile')?.value=='No' && highlightRequiredField('DateTheEmployeeDeloitteMobileComment'))">
             Mobile phone submitted for removal from Smarsh text message capture? </span>
          <span style="color:red">*</span>
      </div>
      <div class="col-xs-4 radio-button-align" >
          <input class="radioStyle" type="radio" formControlName="DateTheEmployeeDeloitteMobile"  value="Yes" (change)="onTheEmployeeDeloitteMobileChange($event)"> Yes
          <input class="radioStyle" type="radio" formControlName="DateTheEmployeeDeloitteMobile"  value="No" (change)="onTheEmployeeDeloitteMobileChange($event)"> No
          <input class="radioStyle" type="radio" formControlName="DateTheEmployeeDeloitteMobile"  value="N/A – No Cell Phone"
          (change)="onTheEmployeeDeloitteMobileChange($event)"> N/A - No Cell Phone
          <!-- <span class="numeric-validation-msg"></span> -->
          <textarea style="margin-left: 4%;" cols="2" *ngIf="isDateTheEmployeeDeloitteMobileComment"
                    type="text"
                    rows="3"
                    formControlName="DateTheEmployeeDeloitteMobileComment"
                    placeholder="Comment"
                    class="input-data-text text-area-style"></textarea>
          
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4 ">
          <span [class.highlight-warning]="highlightRequiredField('LinkedInRemovedFromSmarsh')
          || (terminationFormGroup.get('LinkedInRemovedFromSmarsh')?.value=='No' && highlightRequiredField('LinkedInRemovedFromSmarshComment') )">
          LinkedIn removed from Smarsh connection (if applicable)? </span>
            <span style="color:red">*</span>
      </div>
      <div class="col-xs-4 radio-button-align ">
        <input class="radioStyle" type="radio" formControlName="LinkedInRemovedFromSmarsh"  value="Yes" (change)="onLinkedInRemovedFromSmarshChange($event)"> Yes
        <input class="radioStyle" type="radio" formControlName="LinkedInRemovedFromSmarsh"  value="No" (change)="onLinkedInRemovedFromSmarshChange($event)"> No                
        <input class="radioStyle" type="radio" formControlName="LinkedInRemovedFromSmarsh"  value="N/A - LinkedIn not subject to capture"
        (change)="onLinkedInRemovedFromSmarshChange($event)"> N/A - LinkedIn not subject to capture.
          <!-- <span class="numeric-validation-msg"></span>  -->
        <textarea style="margin-left: 4%;" cols="2" *ngIf="isLinkedInRemovedFromSmarshComment"
          type="text"
          rows="3"
          formControlName="LinkedInRemovedFromSmarshComment"
          placeholder="Comment"
          class="input-data-text text-area-style"></textarea>
      </div>
</div>    
    <div class="row">
      <div class="col-xs-4">
      <span [class.highlight-warning]="highlightRequiredField('DateTheEmployeeRemovedSupervisorOrganization')
      || (terminationFormGroup.get('DateTheEmployeeRemovedSupervisorOrganization')?.value=='No' && highlightRequiredField('DateTheEmployeeRemovedSupervisorOrganizationComment') )">Removed from Supervisory Org Chart?</span>
      <span style="color:red">*</span>
      </div>
      <div class="col-xs-4 radio-button-align">
        <input class="radioStyle" type="radio" formControlName="DateTheEmployeeRemovedSupervisorOrganization"  value="Yes" (change)="onTheEmployeeRemovedSupervisorOrganizationChange($event)"> Yes
        <input class="radioStyle" type="radio" formControlName="DateTheEmployeeRemovedSupervisorOrganization"  value="No" (change)="onTheEmployeeRemovedSupervisorOrganizationChange($event)"> No
        <textarea style="margin-left: 4%;" cols="2" *ngIf="isDateTheEmployeeRemovedSupervisorOrganizationComment"
                  type="text"
                  rows="3"
                  formControlName="DateTheEmployeeRemovedSupervisorOrganizationComment"
                  placeholder="Comment"
                  class="input-data-text text-area-style"></textarea>
      </div> 
  </div>
    <div class="row">

      <div class="col-xs-4">
          <span [class.highlight-warning]="highlightRequiredField('notifiedBrokerageFirmsDate')|| (terminationFormGroup.get('notifiedBrokerageFirmsDate')?.value=='No' && highlightRequiredField('notifiedBrokerageFirmsDateComment'))">
            Notified brokerage firm(s) and/or Terminated Individual to discontinue duplicate statements, confirmations, and, where applicable, broker data feed(s).  </span>
          <span style="color:red">*</span>
      </div>
      <div class="col-xs-4 radio-button-align" >
          <input class="radioStyle" type="radio" formControlName="notifiedBrokerageFirmsDate"  value="Yes" (change)="onnotifiedBrokerageFirmsChange($event)"> Yes
          <input class="radioStyle" type="radio" formControlName="notifiedBrokerageFirmsDate"  value="No" (change)="onnotifiedBrokerageFirmsChange($event)"> No
          <input class="radioStyle" type="radio" formControlName="notifiedBrokerageFirmsDate"  value="No Brokerage Account"
          (change)="onnotifiedBrokerageFirmsChange($event)"> No Brokerage Account
          <!-- <span class="numeric-validation-msg"></span> -->
          <textarea style="margin-left: 4%;" cols="2" *ngIf="isnotifiedBrokerageFirmsDateComment"
                    type="text"
                    rows="3"
                    formControlName="notifiedBrokerageFirmsDateComment"
                    placeholder="Comment"
                    class="input-data-text text-area-style"></textarea>
          
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-xs-4 margin-top-5">
          <span [class.highlight-warning]="highlightRequiredField('DateTheNewHiresTermedCompliance')
          || ((terminationFormGroup.get('DateTheNewHiresTermedComplianceNA')?.value=='N/A') && highlightRequiredField('DateTheNewHiresTermedComplianceReason') )">Information has been added to the Term tab of the New Hire & Termed spreadsheet in Associated Persons </span>
          <span style="color:red">*</span>
      </div>
      <div class="col-xs-7 input-align">
          <app-date-picker 
            [selectedDate]="terminationFormGroup.get('DateTheNewHiresTermedCompliance')?.value"
            (dateSelected)="eventDateSelected($event,'DateTheNewHiresTermedCompliance')">
          </app-date-picker>
          <span class="numeric-validation-msg"></span>
          <input class="radioStyle" type="radio" formControlName="DateTheNewHiresTermedComplianceNA"  value="N/A"
          (change)="onDateTheNewHiresTermedComplianceChange($event)"> N/A
      </div>
      <div class="col-xs-7" style="margin-left: 35%;">
        <textarea *ngIf="isDateTheNewHiresTermedComplianceReason"
        type="text"
        rows="3"
        formControlName="DateTheNewHiresTermedComplianceReason"
        placeholder="Reason"
        class="input-data-text text-area-style signMargin"></textarea>
    </div>      
</div> -->
<!-- <div class="row">
  <div class="col-xs-4 margin-top-5">
      <span [class.highlight-warning]="highlightRequiredField('terminatedIndividualRemovedEmployee')
      || ((terminationFormGroup.get('terminatedIndividualRemovedEmployeeNA')?.value=='N/A') && highlightRequiredField('terminatedIndividualRemovedEmployeeReason') )">Date the terminated individual was moved from the ‘Current Employees' folder to the ‘Terminated Employees’ folder in  'Associated Persons' </span>
      <span style="color:red">*</span>
    
  </div>
  <div class="col-xs-7 input-align">
      <app-date-picker 
        [selectedDate]="terminationFormGroup.get('terminatedIndividualRemovedEmployee')?.value"
        (dateSelected)="eventDateSelected($event,'terminatedIndividualRemovedEmployee')">
      </app-date-picker>
      <span class="numeric-validation-msg"></span>
      <input class="radioStyle" type="radio" formControlName="terminatedIndividualRemovedEmployeeNA"  value="N/A"
      (change)="onterminatedIndividualRemovedEmployeeChange($event)"> N/A
  </div>
  <div class="col-xs-7" style="margin-left: 35%;">
    <textarea *ngIf="isterminatedIndividualRemovedEmployeeReason"
    type="text"
    rows="3"
    formControlName="terminatedIndividualRemovedEmployeeReason"
    placeholder="Reason"
    class="input-data-text text-area-style signMargin"></textarea>
</div>
</div> -->

      <div class="row">
      <div class="col-xs-4">
        <span  [class.highlight-warning]="highlightRequiredField('SentSignedCopy')
        || (terminationFormGroup.get('SentSignedCopy')?.value=='No' && highlightRequiredField('NRF') )
        ||  (terminationFormGroup.get('SentSignedCopy')?.value=='No' && terminationFormGroup.get('NRF')?.value=='Other' && highlightRequiredField('SentSignedCopyOtherText'))" >Sent individual a signed copy of Termination Letter and Form U5 </span>
        <span style="color:red">*</span>
      </div>
      <div class="col-xs-4 radio-button-align">
        <input class="radioStyle" type="radio" formControlName="SentSignedCopy"  value="Yes" (change)="onSentSignedCopyChange($event)"> Yes
        <input class="radioStyle" type="radio" formControlName="SentSignedCopy"  value="No" (change)="onSentSignedCopyChange($event)"> No
        <br><div *ngIf="SentSignedCopy=='No'" style="padding-top:1.5%">
          <input class="radioStyle" type="radio" formControlName="NRF" value="NRF" (change)="onSentSignedCopyNRFChange($event)"> NRF
          <input class="radioStyle" type="radio" formControlName="NRF" value="Other" (change)="onSentSignedCopyNRFChange($event)"> Other
        </div>
      </div>
       
      <div class="col-xs-7" style="margin-left: 35.3%;padding-top: 10px !important;width: 64% !important;" *ngIf="SentSignedCopyNRF=='Other'">
        <textarea
        type="text"
        rows="3"
        formControlName="SentSignedCopyOtherText"
        placeholder="Reason"
        class="input-data-text text-area-style signMargin"></textarea>
        <!-- <app-date-picker 
          [selectedDate]="terminationFormGroup.get('SentSignedCopyDate')?.value"
          (dateSelected)="eventDateSelected($event,'SentSignedCopyDate')">
        </app-date-picker>
        <span class="numeric-validation-msg"></span> -->
    </div>

    </div>
    
    <div *ngIf="ShowTrackingNumber" class="row">
      <div class="col-xs-4 section-label">
        <span [class.highlight-warning]="highlightRequiredField('trackingNumber') || highlightRequiredField('ShippingMethodUsed')">Tracking number of Termination letter and Form U5 </span>
        <span style="color:red">*</span>
      </div>
      <div class="col-xs-8">
        <div class="col-xs-3 section-label alignment">
          <span [class.highlight-warning]="highlightRequiredField('trackingNumber')">Tracking Number: </span>
          <span style="color:red">*</span>
        </div>
        <div class="col-xs-3">
          <input
          class="input-data-text full-width section-input" formControlName="trackingNumber"
          type="text"
          autocomplete="off"
          maxlength="255">
        </div> <br><br>
        <div class="col-xs-3 section-label alignment">
          <span [class.highlight-warning]="highlightRequiredField('ShippingMethodUsed')">Shipping Method: </span>
          <span style="color:red">*</span>
        </div>
        <div class="col-xs-3">
          <span><input
            class="input-data-text full-width section-input" formControlName="ShippingMethodUsed"
            type="text"
            autocomplete="off"
            maxlength="255"></span>
        </div>
        
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4">
      <span [class.highlight-warning]="highlightRequiredField('ZipToDcfArchive')
      || (terminationFormGroup.get('ZipToDcfArchive')?.value=='No' && highlightRequiredField('ZipToDcfArchiveComment') )">Sent zip of employee file to DCF archive?</span>
      <span style="color:red">*</span>
      </div>
      <div class="col-xs-4 radio-button-align">
        <input class="radioStyle" type="radio" formControlName="ZipToDcfArchive"  value="Yes" (change)="onZipToDcfArchiveChange($event)"> Yes
        <input class="radioStyle" type="radio" formControlName="ZipToDcfArchive"  value="No" (change)="onZipToDcfArchiveChange($event)"> No
        <textarea style="margin-left: 4%;" cols="2" *ngIf="isZipToDcfArchiveComment"
                  type="text"
                  rows="3"
                  formControlName="ZipToDcfArchiveComment"
                  placeholder="Comment"
                  class="input-data-text text-area-style"></textarea>
      </div> 
  </div>
    <!-- <div class="row" *ngIf="actionType=='Submit'">
      <div class="col-xs-4 margin-top-5">
          <span>Signature Date </span>
          <span style="color:red">*</span>
      </div>
      <div class="col-xs-8">
        <label class="marginTop alignment">{{signatureDate}}</label>
           <app-date-picker
            [selectedDate]="terminationFormGroup.get('SignatureDate')?.value"
            (dateSelected)="eventDateSelected($event,'SignatureDate')">
          </app-date-picker>
          <span class="numeric-validation-msg"></span>
      </div>
</div> -->
        <div class="row" *ngIf="actionType=='Submit'">
          <div class="col-xs-4 section-label">
            <span>Signature Data </span>
            <span style="color:red">*</span>
          </div>
          <div class="col-xs-7" style="margin-left: 2%;">
            <termination-form-signature-table [signatureData]="signatureData"></termination-form-signature-table>
          </div>
        </div>
  </div>
  <div class="row">
    <div class="col-xs-4">
        <span>Additional Comments </span>
    </div>
    <div class="col-xs-4 radio-button-align">
        <textarea cols="2" style="margin-left: 4%;" 
                  type="text"
                  name="additionalComments"
                  [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="additionalComments"
                  rows="3"
                  placeholder="Comments"
                  class="input-data-text text-area-style"
                  ></textarea>
    </div>
  </div>
</div>

<div class="submit-buttons floatStyle">
  <button  *ngIf="actionType!='Submit'"
   type="submit"
   class="btn btn-primary submit-btn"(click)="onSubmit()">
   Submit
 </button>
 
 <button type="button" (click)="OnSaveForm($event)" name="save"
   class="btn btn-outline-primary save-rework-cancel-btn margin-left-10">
   Save
 </button>
</div>
</form>
<app-termination-notify-popup [formID]="formID"></app-termination-notify-popup>
