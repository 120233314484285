<div [formGroup]="dcfFeeStrcutureFormGroup" id="dcf-fee-structure" class="container-fluid border container-border-color container-section">
    <div class="row bg-title">
        <span class="title-section-print">DCF Fee Structure</span>
        <span class="glyphicon glyphicon-question-sign title-question-sign tooltip-dcf"></span>
        <span class="tooltiptext-dcf visibility-hidden">
            <p>
                Please describe the fee structure in the box provided and fill in the applicable boxes.
           We have tried to capture all of the different types of structures, but if the fee structure is unique just enter N/A and describe.
            </p>
        </span>
    </div>
    <div id="dcf-fee-structure-section" class="content-section">
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div fee-type">
                    <span class="span-to-highlight">Fee Type</span>
                    <span class="red-asterik visibility-hidden"></span>
                </div>
                <div class="col-xs-8">
                    <!-- <select id="FeeType" name="FeeType" class="input-data-select input-select section-select feeType enableDependencyInput my-deal-input" disable-target-input="BreakpointQuestion,BreakpointValue"
                        disable-target-value-for-breakpointquestion="Non-Contingent - Fixed,Non-Contingent - Time & Materials" disable-target-value-for-breakpointvalue="Non-Contingent - Fixed,Non-Contingent - Time & Materials" value-when-input-disabled-for-breakpointvalue="N/A">
                        <option value="0" disabled="" selected="">Select your option</option>
                    </select> -->
                    <app-dropdown
                    [optionsList]="feeTypeOptions"
                    [readonly]="isReadOnly('feeType')"
                    [keySelected]="dcfFeeStrcutureFormGroup.get('feeType')?.value"
                    (optionSelected)="onFeeTypeSelection($event,'feeType')">
                    </app-dropdown>
                    
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div div-three-toggle-input no-margin-top">
                    <span class="span-to-highlight"
                    [class.highlight-warning]="highlightRequiredField('breakpointQuestion')"
                    [class.edited-externally]="editedExternally('breakpointQuestion')"
                    [class.conflict-on-merge]="hasMergeConflict('breakpointQuestion')">Breakpoint?</span>
                    <span *ngIf="showAsterisk('breakpointQuestion')"
                     class="red-asterik"></span>

                    <app-popover
                    [tooltipText]="getTooltipText('breakpointQuestion')">
                  </app-popover>
                </div>
                <div class="col-xs-8">
                    <div  name="BreakpointQuestion" id="BreakpointQuestion" class="btn-group btn-group-xs input-data-toggle-group input-toggle-group" role="group">
                        
                        <button type="button" [disabled]="dcfFeeStrcutureFormGroup.get('breakpointQuestion')?.disabled"
                         class="btn btn-default btn-xs three-toggle-button breakpointButtonGroup" 
                         value="NO"
                         [ngClass]="{'toggle-button-active': dcfFeeStrcutureFormGroup.get('breakpointQuestion')?.value === 'NO'}" 
                         (click)="toggleButtonActive('NO','false')">No</button>
                        <button type="button" [disabled]="dcfFeeStrcutureFormGroup.get('breakpointQuestion')?.disabled"
                         class="btn btn-default btn-xs three-toggle-button breakpointButtonGroup" 
                         value="YesOne"
                         (click)="toggleButtonActive('YesOne','false')"
                         [ngClass]="{'toggle-button-active': dcfFeeStrcutureFormGroup.get('breakpointQuestion')?.value === 'YesOne'}" >One</button>
                        <button type="button" [disabled]="dcfFeeStrcutureFormGroup.get('breakpointQuestion')?.disabled"
                        (click)="toggleButtonActive('YesMultiple','false')"
                        [ngClass]="{'toggle-button-active': dcfFeeStrcutureFormGroup.get('breakpointQuestion')?.value === 'YesMultiple'}"
                        class="btn btn-default btn-xs three-toggle-button multiple breakpointButtonGroup" 
                        value="YesMultiple">Multiple</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span *ngIf="!showhighlight" class="span-to-highlight"
                      [class.highlight-warning]="highlightRequiredField('breakpointValue')"
                    [class.edited-externally]="editedExternally('breakpointValue')"
                    [class.conflict-on-merge]="hasMergeConflict('breakpointValue')"
                    >Breakpoint ($000s)</span>
                    <span *ngIf="showhighlight" class="span-to-highlight" 
                    [class.highlight-warning]="true"
                    >Breakpoint ($000s)</span>
                    <span *ngIf="showAsterisk('breakpointValue')" class="red-asterik"></span>
                    <span *ngIf="showMultiAsterisk" class="red-asterik"></span>
                </div>
                <div id="breakpoint-value-section" class="col-xs-8" *ngIf="showBreakPoint">
                    <app-numeric class="custom customText"
                    [type]="'decimal2'"
                    [format]="'currency'"
                    [readonly]="isReadOnly('breakpointValue')"
                    [value]="dcfFeeStrcutureFormGroup.get('breakpointValue')?.value"
                    (selectedValue)="onSelectedValue($event,'breakpointValue')"
                    [placeholder]="dcfFeeStrcutureFormGroup.get('breakpointValue')?.disabled ? 'N/A' : ''"
                    [disabled]="dcfFeeStrcutureFormGroup.get('breakpointValue')?.disabled">
                    </app-numeric>
                    <!-- <input type="text" id="BreakpointValue" formcontrolname="breakpointValue"
                     class="input-data-number input-number non-decimal-input feeStructureFormulas"
                      placeholder="N/A"
                      [disabled]="dcfFeeStrcutureFormGroup.get('breakpointValue').disabled"> -->
                    <span class="numeric-validation-msg"></span>
                </div>

                <div id="breakpoint-value-section" class="col-xs-8 required">
                    <table *ngIf="showBreakPointtable" id="breakpoint-multiple-table" class="col-xs-8 required">
                        
                            <tr class="row breakpoint-input-row" *ngFor="let option of breakPointValue; let i = index">
                                <div class="breakpoint-col col-xs-9">
                                    <app-numeric class="custobreak"
                        [type]="'decimal2'"
                        [format]="'currency'"
                        [readonly]="readOnly"
                        (selectedValue)="onSelectedBreakPointValue($event,i)"
                        [value]="(option.BreakpointValue)">
                        </app-numeric>
                               <!-- <input autocomplete="off" class="input-data-text" numeric="" type="text"/> -->
                    
                    </div>
                    <div class="breakpoint-col col-xs-3">
                        <span
                         class="glyphicon glyphicon-remove margin-top-5 delete-breakpoint-icon delete-breakpoint"
                         [class.readonly]="readOnly"
                          (click)="deleteBreakPointValue(option.ID,i)"
                          >
                        </span>
                    </div>
                            </tr>
                         <tr class="row last-row">
                             <div class="breakpoint-col col-xs-9">
                             <span class="breakpoint-watermark add-new-breakpoint"
                             [class.readonly]="readOnly"
                             (click)="adRow()"
                             >Add new breakpoint here...</span>
                             </div>
                            <div class="breakpoint-col col-xs-3">
    
                            </div>
                        </tr>
                    </table>
                </div>
                
            </div>
        </div>
          
        


        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-5 required-div">
                </div>
                <div class="col-xs-6 fee-structure-radio-container" related-input="BaseFee">
                    <span class="title-dcf-fee-structure-table">Fill in the applicable column below</span><br>
                    <div class="dcfFeeStructure-columns-table-Percentage">
                        <input type="radio" formControlName="baseFeeRadio"
                         value="Percentage"
                         (click)="radioBaseFeeEnableMethod('Percentage','false')"
                         [readonly]="isReadOnly('baseFeeRadio')"
                         [checked]="dcfFeeStrcutureFormGroup.get('baseFeeRadio')?.value===Percentage ? true : false"
                          class="input-data-option dcfFeeStructureRadio">
                        <span>%</span>
                    </div>
                    <div class="dcfFeeStructure-columns-table-Numerical">
                        <input type="radio" formControlName="baseFeeRadio"
                         value="Numerical"
                         [readonly]="isReadOnly('baseFeeRadio')"
                         (click)="radioBaseFeeEnableMethod('Numerical','false')"
                         [checked]="Numerical === dcfFeeStrcutureFormGroup.get('baseFeeRadio')?.value ? true : false"
                          class="input-data-option dcfFeeStructureRadio">
                        <!-- <input type="radio" formControlName="baseFeeRadio"  value="Numerical" class="input-data-option dcfFeeStructureRadio"> -->
                        <span>$000s</span>
                    </div>
                </div>
            </div>
        </div>
        <div id="numerical-table-dcf-fee-structure">
            <div class="row">
                <div class="col-xs-12">
                    <div class="col-xs-5 required-div">
                        <span class="span-to-highlight"
                    [class.highlight-warning]="highlightRequiredFieldCheck('baseFeePercentage','baseFeeNumerical')"
            [class.edited-externally]="editedExternallyCheck('baseFeePercentage','baseFeeNumerical')"
            [class.conflict-on-merge]="hasMergeConflictCheck('baseFeePercentage','baseFeeNumerical')"
           
            >
            
            Base Fee ($000s)</span>
                        <span *ngIf="showAsterisk('baseFeePercentage')" class="red-asterik"></span>
                        <span *ngIf="showAsterisk('baseFeeNumerical')" class="red-asterik"></span>
                    </div>
                    <div class="col-xs-6 base-fee-columns">
                        <div class="col-xs-6">
                            <app-numeric class="custom2"
                            [type]="'decimal2'"
                            [format]="'percentage'"
                            [value]="dcfFeeStrcutureFormGroup.get('baseFeePercentage')?.value"
                            (selectedValue)="onSelectedValue($event,'baseFeePercentage')"
                            [readonly]="isReadOnly('baseFeePercentage')"
                            [placeholder]="dcfFeeStrcutureFormGroup.get('baseFeePercentage')?.disabled ? 'N/A' : ''"
                            [disabled]="dcfFeeStrcutureFormGroup.get('baseFeePercentage')?.disabled">
                            </app-numeric>
                            <!-- <input type="text" id="BaseFeePercentage" name="BaseFeePercentage" class="input-data-number input-number percentageCellBaseFee percentage-input feeStructureFormulas"> -->
                            <br>
                            <span class="numeric-validation-msg error-message-dcf-fee-structure-table"></span>
                        </div>
                        <div class="col-xs-6">
                            <app-numeric class="custom2"
                            [type]="'decimal2'"
                            [format]="'currency'"
                            [readonly]="isReadOnly('baseFeeNumerical')"
                            [value]="dcfFeeStrcutureFormGroup.get('baseFeeNumerical')?.value"
                            (selectedValue)="onSelectedValue($event,'baseFeeNumerical')"
                            [placeholder]="dcfFeeStrcutureFormGroup.get('baseFeeNumerical')?.disabled ? 'N/A' : ''"
                            [disabled]="dcfFeeStrcutureFormGroup.get('baseFeeNumerical')?.disabled">
                            </app-numeric>
                             <br>
                            <span class="numeric-validation-msg error-message-dcf-fee-structure-table"></span> 
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <div class="col-xs-5">
                    </div>
                    <div class="col-xs-6 fee-structure-radio-container" related-input="IncentiveFee">
                        <div class="dcfFeeStructure-columns-table-Percentage">
                            <input type="radio" formControlName="incentiveFeeRadio"
                            value="Percentage"
                            [attr.disabled]="Percentage === false || null"
                            (click)="radioIncentiveFeeEnableMethod('Percentage')"
                             class="input-data-option dcfFeeStructureRadio"
                             [readonly]="isReadOnly('incentiveFeeRadio')"
                             [checked]="Percentage === dcfFeeStrcutureFormGroup.get('incentiveFeeRadio')?.value ? true : false"
                             >
                             
                            <!-- <input type="radio" name="IncentiveFeeRadio" value="Percentage" class="input-data-option dcfFeeStructureRadio"> -->
                            <span>%</span>
                        </div>
                        <div class="dcfFeeStructure-columns-table-Numerical">
                            <input type="radio" formControlName="incentiveFeeRadio"
                            [attr.disabled]="Numerical === false || null"
                           value="Numerical"
                           [readonly]="isReadOnly('incentiveFeeRadio')"
                          class="input-data-option dcfFeeStructureRadio"
                          (click)="radioIncentiveFeeEnableMethod('Numerical')"
                          [checked]="Numerical === dcfFeeStrcutureFormGroup.get('incentiveFeeRadio')?.value ? true : false"
                          >
                            <!-- <input type="radio" name="IncentiveFeeRadio" value="Numerical" class="input-data-option dcfFeeStructureRadio"> -->
                            <span>$000s</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <div class="col-xs-5 required-div">
                        <span class="span-to-highlight"
                        [class.highlight-warning]="highlightRequiredFieldCheck('incentiveFeePercentage','incentiveFeeNumerical')"
                        [class.edited-externally]="editedExternallyCheck('incentiveFeePercentage','incentiveFeeNumerical')"
                        [class.conflict-on-merge]="hasMergeConflictCheck('incentiveFeePercentage','incentiveFeeNumerical')"
                        
                > Incentive Fee ($000s)</span>
                        <span *ngIf="showAsterisk('incentiveFeePercentage')" class="red-asterik"></span>
                        <span *ngIf="showAsterisk('incentiveFeeNumerical')" class="red-asterik"></span>
                        <app-popover
                        [tooltipText]="getTooltipText('incentiveFeePercentage')">
                      </app-popover>

                    </div>
                    <div class="col-xs-6 incentive-fee-columns">
                        <div class="col-xs-6">
                            <app-numeric class="custom2"
                            [type]="'decimal2'"
                            [format]="'percentage'"
                            [readonly]="isReadOnly('incentiveFeePercentage')"
                            [value]="dcfFeeStrcutureFormGroup.get('incentiveFeePercentage')?.value"
                            (selectedValue)="onSelectedValue($event,'incentiveFeePercentage')"
                            [placeholder]="dcfFeeStrcutureFormGroup.get('incentiveFeePercentage')?.disabled ? 'N/A': ''"
                            [disabled]="dcfFeeStrcutureFormGroup.get('incentiveFeePercentage')?.disabled">
                            </app-numeric>
                            <!-- <input type="text" id="IncentiveFeePercentage" name="IncentiveFeePercentage" class="input-data-number input-number percentageCellIncentiveFee percentage-input feeStructureFormulas" value="N/A" placeholder="N/A" disabled><br> -->
                            <span class="numeric-validation-msg error-message-dcf-fee-structure-table"></span>
                        </div>
                        <div class="col-xs-6">
                            <app-numeric class="custom2"
                            [type]="'decimal2'"
                            [format]="'currency'"
                            [readonly]="isReadOnly('incentiveFeeNumerical')"
                            [value]="dcfFeeStrcutureFormGroup.get('incentiveFeeNumerical')?.value"
                            (selectedValue)="onSelectedValue($event,'incentiveFeeNumerical')"
                            [placeholder]="dcfFeeStrcutureFormGroup.get('incentiveFeeNumerical')?.disabled ? 'N/A' : '' "
                            [disabled]="dcfFeeStrcutureFormGroup.get('incentiveFeeNumerical')?.disabled">
                            </app-numeric>
                            <!-- <input type="text" id="IncentiveFeeNumerical" name="IncentiveFeeNumerical" class="input-data-number input-number numericalCellIncentiveFee non-decimal-input" format="negativeCurrency" value="N/A" placeholder="N/A" disabled><br> -->
                            <span class="numeric-validation-msg error-message-dcf-fee-structure-table"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span class="span-to-highlight">Retainer ($000s)</span>
                    <span class="red-asterik visibility-hidden"></span>
                </div>
                <div class="col-xs-8" id="breakpoint-value-section">
                    <!-- <input type="text" id="Retainer" name="Retainer" class="input-data-number input-number non-decimal-input my-deal-input"><br>
                    <span class="numeric-validation-msg"></span> -->
                    <app-numeric class="custom customText"
                    [type]="'decimal2'"
                    [format]="'currency'"
                    [readonly]="isReadOnly('retainer')"
                    [value]="dcfFeeStrcutureFormGroup.get('retainer')?.value"
                    (selectedValue)="onSelectedValue($event,'retainer')">
                    </app-numeric>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span class="span-to-highlight">Minimum Success Fee ($000s)</span>
                    <span class="red-asterik visibility-hidden"></span>
                </div>
                <div class="col-xs-8" id="breakpoint-value-section">
                    <!-- <input type="text" id="MinimumSucessFee" name="MinimumSucessFee" class="input-data-number input-number enableDependencyInput non-decimal-input" disable-target-input="MinimumFeeApply" disable-target-value-for-minimumfeeapply=""><br>
                    <span class="numeric-validation-msg"></span> -->
                    <app-numeric class="custom customText"
                    [type]="'decimal2'"
                    [format]="'currency'"
                    [readonly]="isReadOnly('minimumSucessFee')"
                    [value]="dcfFeeStrcutureFormGroup.get('minimumSucessFee')?.value"
                    (selectedValue)="onMinimumSucessFeeSelection($event,'minimumSucessFee')">
                    </app-numeric>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div div-three-toggle-input">

                    <span class="span-to-highlight"
                    [class.highlight-warning]="highlightRequiredField('minimumFeeApply')"
            [class.edited-externally]="editedExternally('minimumFeeApply')"
            [class.conflict-on-merge]="hasMergeConflict('minimumFeeApply')">Did Minimum Fee Apply?</span>
                    <span *ngIf="showAsterisk('minimumFeeApply')" class="red-asterik"></span>
                    <app-popover
                        [tooltipText]="getTooltipText('minimumFeeApply')">
                      </app-popover>
                </div>
                <div class="col-xs-8">
                    <!-- <div name="MinimumFeeApply" id="MinimumFeeApply" class="btn-group btn-group-xs input-data-toggle-group input-toggle-group" role="group">
                        <button type="button" class="btn btn-default btn-xs two-toggle-button" value="YES" disabled>YES</button>
                        <button type="button" class="btn btn-default btn-xs two-toggle-button" value="NO" disabled>NO</button>
                    </div> -->
                    <app-toggle-yes-no  #yesNoMinimumFeeApply
                    [value]="dcfFeeStrcutureFormGroup.get('minimumFeeApply')?.value"
                    (handleToggleYesNo)="onminimumFeeApply($event)"
                    [readonly]="isReadOnly('minimumFeeApply')"
                    [disabled]="dcfFeeStrcutureFormGroup.get('minimumFeeApply')?.disabled">
                  </app-toggle-yes-no>
                    
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span class="span-to-highlight">Describe Fee Structure</span>
                    <span class="red-asterik visibility-hidden"></span>
                    <app-popover
                        [tooltipText]="getTooltipText('describeFeeStructure')">
                      </app-popover>
                    
                </div>
                <div class="col-xs-8">
                  <textarea type="text" rows="3" placeholder="You can add comments here"
                            [class.readonly]="isReadOnly('describeFeeStructure')"
                            [readonly]="isReadOnly('describeFeeStructure')"
                            [disabled]="dcfFeeStrcutureFormGroup.get('describeFeeStructure').disabled"
                            formControlName="describeFeeStructure" class="input-data-text text-area-style"></textarea>
                </div>
            </div>
        </div>
    </div>
</div>
