<div class="datepicker-input">
  <div class="col-xs-7">
    <input
      class="form-control input-date-picker"
      [(ngModel)]="selectedDate"
      placeholder="mm/dd/yyyy"
      name="dp"
      ngbDatepicker
      #d="ngbDatepicker"
      (dateSelect)="getSelectedDate($event)"
      (change)="getSelectedDate($event)"
      [placement]="placement"
      [disabled]="disabled || readonly"
      [class.readonly]="readonly"
      autocomplete="off"/>
  </div>
  <div class="col-xs-5 divCalender">
    <button
      class="input-group-addon button-date-picker"
      [class.readonly]="readonly"
      [disabled]="disabled || readonly"
      (click)="d.toggle()"
      type="button">
      <span class=""><img src="assets/svg/calendar.svg" alt="Calendar Icon" width="20" height="20" /></span>
    </button>
  </div>
  <div *ngIf="invalidDateMsg" class="col-xs-12">
    <span class="msg-invalid-date">{{ invalidDateMsg }}</span>
  </div>
</div>
