<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css">
<div>
  <div class="container" style="width: 100%;">
    <div class="row">
      <div class="col-xs-6">

        <div class="headerManagement">Termination Form Report</div>
      </div>

      <div class="col-xs-6">
        <div id="crossborderLinkId">
          <a id="crossborderLinkId" href="/terminationForm">TerminationRegistrationForm</a>
        </div>
      </div>
    </div>    
    <div class="row">
      <div class="col-sm-3">
        <div id="search-container">
          <app-search-box (reportListToEmit)="onSearchResult($event)">
          </app-search-box>
        </div>
      </div>
      <div class="col-sm-3">
        <div id="show-deals-container">
          <button type="button" class="clear-filters-button" (click)="clearSearchFilters()">
            Clear Filters
          </button>
        </div>
      </div>
      
      <div class="col-sm-2" style="float:right ;">
        <button style="margin-left: 35%;width: 63%;height:6vh;" type="button" [disabled]="terminationDashboardsPerPage && 
        terminationDashboardsPerPage.length == 0"
          class="engagement-dashboard-button btn_Custom" id="exportExcel"(click)="exportToExcel()">
          Export
        </button>
      </div>
      <br>
      <br>
    </div>

    <div class="table-responsive table-body heightStyles">
      <table id="termination-table">
        <thead>
          <tr>
            <th scope="col">
              <input id="chk_MultiCheckBox" style="height:13px ;width: 75px;" type="checkbox"
              (change)="onCheckboxChange($event)"  />
            </th>
            <th scope="col">
              <span class="th-label" sortable="ID" (sort)="sortTable($event)">
                ID
              </span>
              <app-popover #popoverID [active]="isFilterApply('ID')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('ID')">
              </app-popover>
            </th>
            <th scope="col">           
                <span class="th-label" sortable="LastName" (sort)="sortTable($event)">
                  Last Name
                </span>
                <app-popover #popoverLastName [active]="isFilterApply('LastName')" [keepOpen]="true" [openOnFocus]="false"
                  [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'" [triggers]="'manual'"
                  [autoClose]="'outside'" (click)="openFilterPopover('LastName')">
                </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="FirstName" (sort)="sortTable($event)">
                First Name
              </span>
              <app-popover #popoverFirstName [active]="isFilterApply('FirstName')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('FirstName')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="FormStatus" (sort)="sortTable($event)">
                Form Status
              </span>
              <app-popover #popoverFormStatus [active]="isFilterApply('FormStatus')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('FormStatus')">
              </app-popover>
            </th>
            <!-- <th scope="col">
              <span
                class="th-label"
                sortable="clientName"
                (sort)="sortTable($event)">
                Client Name
              </span>
             <app-popover #popoverClientName
                [active]="isFilterApply('clientName')"
                [keepOpen]="true"
                [openOnFocus]="false"
                [tooltipTemplate]="popContent"
                [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'"
                [autoClose]="'outside'"
                (click)="openFilterPopover('clientName')">
              </app-popover>
            </th> -->
            <th scope="col">
              <span class="th-label" sortable="TerminationDate" (sort)="sortTable($event)">
                Date of Termination
              </span>
              <app-popover #popoverTerminationDate [active]="isFilterApply('TerminationDate')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('TerminationDate')">
              </app-popover>
            </th>
            <th>
              <span class="th-label" sortable="VoluntaryTermination" (sort)="sortTable($event)">
                Reason for Termination
              </span>
              <app-popover #popoverVoluntaryTermination [active]="isFilterApply('VoluntaryTermination')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('VoluntaryTermination')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="StatusOfIndividual" (sort)="sortTable($event)">
                Status of Individual
              </span>
              <app-popover #popoverStatusOfIndividual [active]="isFilterApply('StatusOfIndividual')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('StatusOfIndividual')">
              </app-popover>
            </th>
            <!-- <th scope="col">
              <span
                class="th-label"
                sortable="dCFRiskRating"
                (sort)="sortTable($event)">
                Risk Rating
              </span>
              <app-popover #popoverNewDealdCFRiskRating
              [active]="isFilterApply('dCFRiskRating')"
              [keepOpen]="true"
              [openOnFocus]="false"
              [tooltipTemplate]="popContent"
              [tooltipIcon]="'fas fa-filter th-filter'"
              [triggers]="'manual'"
              [autoClose]="'outside'"
              (click)="openFilterPopover('dCFRiskRating')">
           </app-popover>
            </th>
            <th scope="col">
              <span
                class="th-label"
                sortable="enterpriseValue"
                (sort)="sortTable($event)">
                Enterprise value 
            (in USD $)
              </span>
              <app-popover #popoverNewDealenterpriseValue
              [active]="isFilterApply('enterpriseValue')"
              [keepOpen]="true"
              [openOnFocus]="false"
              [tooltipTemplate]="popContent"
              [tooltipIcon]="'fas fa-filter th-filter'"
              [triggers]="'manual'"
              [autoClose]="'outside'"
              (click)="openFilterPopover('enterpriseValue')">
           </app-popover>
            </th> -->

            <th scope="col">
              <!-- <span class="th-label" sortable="Series24" (sort)="sortTable($event)" #p="ngbPopover" (mouseenter)="p.open()"
              (mouseleave)="p.close()" [ngbPopover]="getToolTipText('Series24')"> -->
              <span class="th-label" sortable="Series24" (sort)="sortTable($event)">
                S24 Supervisor at Termination
              </span>
              <app-popover #popoverSeries24 [active]="isFilterApply('Series24')"
                [keepOpen]="true" [openOnFocus]="false" [tooltipTemplate]="popContent"
                [tooltipIcon]="'fas fa-filter th-filter'" [triggers]="'manual'" [autoClose]="'outside'"
                (click)="openFilterPopover('Series24')">
              </app-popover>
              <!-- <app-popover [tooltipTitle]="getToolTipText('Series24')"
              [isToolTip]="true">
              </app-popover> -->
            </th>
            <!-- <th scope="col">
              <span
                class="th-label"
                sortable="s24ApprovalStatus"
                (sort)="sortTable($event)">
                S24 Approval Status
              </span>
              <app-popover #popoverNewDeals24ApprovalStatus
              [active]="isFilterApply('s24ApprovalStatus')"
              [keepOpen]="true"
              [openOnFocus]="false"
              [tooltipTemplate]="popContent"
              [tooltipIcon]="'fas fa-filter th-filter'"
              [triggers]="'manual'"
              [autoClose]="'outside'"
              (click)="openFilterPopover('s24ApprovalStatus')">
           </app-popover>
            </th>  -->
            <th scope="col">
              <span class="th-label" sortable="TerminationReasonMatch" (sort)="sortTable($event)">
                Does the reason for termination match the reason communicated by the business and Talent?
              </span>
              <app-popover #popoverTerminationReasonMatch [active]="isFilterApply('TerminationReasonMatch')" [keepOpen]="true" [openOnFocus]="false"
                [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'" [triggers]="'manual'"
                [autoClose]="'outside'" (click)="openFilterPopover('TerminationReasonMatch')">
              </app-popover>
              <!-- <app-popover [tooltipTitle]="getToolTipText('TerminationReasonMatch')" [isToolTip]="true">
               </app-popover> -->
            </th>
            <!-- <th scope="col">
              <span class="th-label" sortable="WasThreatContacted" (sort)="sortTable($event)">
                Insider Threat - Open Cases?
              </span>
              <app-popover #popoverWasThreatContacted [active]="isFilterApply('WasThreatContacted')" [keepOpen]="true" [openOnFocus]="false"
                [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'" [triggers]="'manual'"
                [autoClose]="'outside'" (click)="openFilterPopover('WasThreatContacted')">
              </app-popover>
              <app-popover [tooltipTitle]="getToolTipText('WasThreatContacted')" [isToolTip]="true">
              </app-popover>
            </th> -->
            <!-- <th scope="col">
              <span
                class="th-label"
                sortable="dCFDateApproval"
                (sort)="sortTable($event)">
                Date Approved
              </span>
              <app-popover #dCFDateApproval
              [active]="isFilterApply('dCFDateApproval')"
              [keepOpen]="true"
              [openOnFocus]="false"
              [tooltipTemplate]="popContent"
              [tooltipIcon]="'fas fa-filter th-filter'"
              [triggers]="'manual'"
              [autoClose]="'outside'"
              (click)="openFilterPopover('dCFDateApproval')">
           </app-popover>
              
            </th>  -->
            <th scope="col">
              <span class="th-label" sortable="InternalOpenThreat" (sort)="sortTable($event)">
                Internal Threats?
              </span>
              <app-popover #popoverInternalOpenThreat [active]="isFilterApply('InternalOpenThreat')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('InternalOpenThreat')">
              </app-popover>
              <!-- <app-popover [tooltipTitle]="getToolTipText('InternalOpenThreat')" [isToolTip]="true">
              </app-popover> -->
            </th>
            <th scope="col">
              <span class="th-label" sortable="dateTheEmployeeRemoved" (sort)="sortTable($event)">
                Removed from US National Corporate Finance Active Directory?
              </span>
              <app-popover #popoverdateTheEmployeeRemoved [active]="isFilterApply('dateTheEmployeeRemoved')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('dateTheEmployeeRemoved')">
              </app-popover>
              <!-- <app-popover [tooltipTitle]="getToolTipText('OutlookRemovedDate')" [isToolTip]="true">
              </app-popover> -->
            </th>
            <th scope="col">
              <span class="th-label" sortable="JakiePolsonNotifiedDate" (sort)="sortTable($event)">
                For PPMDs or client-facing PPMDs, notified Jackie Polson of termination?
              </span>
              <app-popover #popoverJakiePolsonNotifiedDate [active]="isFilterApply('JakiePolsonNotifiedDate')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('JakiePolsonNotifiedDate')">
              </app-popover>
              <!-- <app-popover [tooltipTitle]="getToolTipText('JakiePolsonNotifiedDate')" [isToolTip]="true">
              </app-popover> -->
            </th>
            <th scope="col">
              <span class="th-label" sortable="AllowedToTermedDate" (sort)="sortTable($event)">
                OBA's removed from active OBA list?
              </span>
              <app-popover #popoverAllowedToTermedDate [active]="isFilterApply('AllowedToTermedDate')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('AllowedToTermedDate')">
              </app-popover>
              <!-- <app-popover [tooltipTitle]="getToolTipText('AllowedToTermedDate')" [isToolTip]="true">
              </app-popover> -->
            </th>
            <th scope="col">
              <span class="th-label" sortable="PstToTermedClosedDeniedDate" (sort)="sortTable($event)">
                PST's removed from active PST list?
              </span>
              <app-popover #popoverPstToTermedClosedDeniedDate [active]="isFilterApply('PstToTermedClosedDeniedDate')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('PstToTermedClosedDeniedDate')">
              </app-popover>
              <!-- <app-popover [tooltipTitle]="getToolTipText('PSTToTermedClosedDeniedDate')" [isToolTip]="true">
              </app-popover> -->
            </th>
            <th scope="col">
              <span class="th-label" sortable="dateTheEmployeeRemovedDCFKnowledge" (sort)="sortTable($event)">
                Removed from DCF Knowledge Center?
              </span>
              <app-popover #popoverdateTheEmployeeRemovedDCFKnowledge [active]="isFilterApply('dateTheEmployeeRemovedDCFKnowledge')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('dateTheEmployeeRemovedDCFKnowledge')">
              </app-popover>
              <!-- <app-popover [tooltipTitle]="getToolTipText('DCFKCRemovedDate')" [isToolTip]="true">
              </app-popover> -->
            </th>
            <th scope="col">
              <span class="th-label" sortable="DateTheEmployeeRemovedFlowPerms" (sort)="sortTable($event)">
                Removed from the Dflow permissions spreadsheet?
              </span>
              <app-popover #popoverDateTheEmployeeRemovedFlowPerms [active]="isFilterApply('DateTheEmployeeRemovedFlowPerms')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('DateTheEmployeeRemovedFlowPerms')">
              </app-popover>
              <!-- <app-popover [tooltipTitle]="getToolTipText('DateTheEmployeeRemovedFlowPerms')" [isToolTip]="true">
              </app-popover> -->
            </th>
            <th scope="col">
              <span class="th-label" sortable="DateSupportTicketOpened" (sort)="sortTable($event)">
                Support ticket submitted to remove from open DCF DFlow sites?
              </span>
              <app-popover #popoverDateSupportTicketOpened [active]="isFilterApply('DateSupportTicketOpened')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('DateSupportTicketOpened')">
              </app-popover>
              <!-- <app-popover [tooltipTitle]="getToolTipText('DateSupportTicketOpened')" [isToolTip]="true">
              </app-popover> -->
            </th>
            
            <th scope="col">
              <span class="th-label" sortable="CSIRetiredDate" (sort)="sortTable($event)">
                Retired in CSI?
              </span>
              <app-popover #popoverCSIRetiredDate [active]="isFilterApply('CSIRetiredDate')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('CSIRetiredDate')">
              </app-popover>
              <!-- <app-popover [tooltipTitle]="getToolTipText('CSIRetiredDate')" [isToolTip]="true">
              </app-popover> -->
            </th>
            <th scope="col">
              <span class="th-label" sortable="RetiredFromQuestCEModel" (sort)="sortTable($event)">
                Retired from Quest CE?
              </span>
              <app-popover #popoverRetiredFromQuestCE [active]="isFilterApply('RetiredFromQuestCEModel')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('RetiredFromQuestCEModel')">
              </app-popover>
              <!-- <app-popover [tooltipTitle]="getToolTipText('RetiredFromQuestCEModel')" [isToolTip]="true">
              </app-popover> -->
            </th>
            <th scope="col">
              <span class="th-label" sortable="RunSmarshEmailSpotCheck" (sort)="sortTable($event)">
                Smarsh spot check (two week lookback) completed? Please note any finding in Additional Comments section below.
              </span>
              <app-popover #popoverRunSmarshEmailSpotCheck [active]="isFilterApply('RunSmarshEmailSpotCheck')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('RunSmarshEmailSpotCheck')">
              </app-popover>
              <!-- <app-popover [tooltipTitle]="getToolTipText('RunSmarshEmailSpotCheck')" [isToolTip]="true">
              </app-popover> -->
            </th>
            <th scope="col">
              <span class="th-label" sortable="SupervisionGroupRemovedDate" (sort)="sortTable($event)">
                Removed from Smarsh supervision hierarchy?
              </span>
              <app-popover #popoverSupervisionGroupRemovedDate [active]="isFilterApply('SupervisionGroupRemovedDate')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('SupervisionGroupRemovedDate')">
              </app-popover>
              <!-- <app-popover [tooltipTitle]="getToolTipText('SupervisionGroupRemovedDate')" [isToolTip]="true">
              </app-popover> -->
            </th>
            <th scope="col">
              <span class="th-label" sortable="SmarshTextCaptureRemovedDate" (sort)="sortTable($event)">
                Mobile phone submitted for removal from Smarsh text message capture?
              </span>
              <app-popover #popoverSmarshTextCaptureRemovedDate [active]="isFilterApply('SmarshTextCaptureRemovedDate')" [keepOpen]="true" [openOnFocus]="false"
                [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'" [triggers]="'manual'"
                [autoClose]="'outside'" (click)="openFilterPopover('SmarshTextCaptureRemovedDate')">
              </app-popover>
              <!-- <app-popover [tooltipTitle]="getToolTipText('SmarshTextCaptureRemovedDate')" [isToolTip]="true">
              </app-popover> -->
            </th>
            <th scope="col">
              <span class="th-label" sortable="LinkedInRemovedFromSmarshModel" (sort)="sortTable($event)">
                LinkedIn removed from Smarsh connection (if applicable)?
              </span>
              <app-popover #popoverRetiredFromQuestCE [active]="isFilterApply('LinkedInRemovedFromSmarshModel')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('LinkedInRemovedFromSmarshModel')">
              </app-popover>
              <!-- <app-popover [tooltipTitle]="getToolTipText('LinkedInRemovedFromSmarshModel')" [isToolTip]="true">
              </app-popover> -->
            </th>
            <th scope="col">
              <span class="th-label" sortable="SupervisorOrgChartRemovalDate" (sort)="sortTable($event)">
                Removed from Supervisory Org Chart?
              </span>
              <app-popover #popoverSupervisorOrgChartRemovalDate [active]="isFilterApply('SupervisorOrgChartRemovalDate')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('SupervisorOrgChartRemovalDate')">
              </app-popover>
              <!-- <app-popover [tooltipTitle]="getToolTipText('SupervisorOrgChartRemovalDate')" [isToolTip]="true">
              </app-popover> -->
            </th>
            <th scope="col">
              <span class="th-label" sortable="NotifiedBrokerageFirmsDate" (sort)="sortTable($event)">
                Brokerage Firm(s)/Employee Notified
              </span>
              <app-popover #popoverNotifiedBrokerageFirmsDate [active]="isFilterApply('NotifiedBrokerageFirmsDate')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('NotifiedBrokerageFirmsDate')">
              </app-popover>
              <!-- <app-popover [tooltipTitle]="getToolTipText('NotifiedBrokerageFirmsDate')" [isToolTip]="true">
              </app-popover> -->
            </th>
            <!-- <th scope="col">
              <span class="th-label" sortable="IdentifyIndividualTermedDate" (sort)="sortTable($event)">
                New Hire & Termed Spreadsheet Updated
              </span>
              <app-popover #popoverIdentifyIndividualTermedDate [active]="isFilterApply('IdentifyIndividualTermedDate')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('IdentifyIndividualTermedDate')">
              </app-popover>
              <app-popover [tooltipTitle]="getToolTipText('IdentifyIndividualTermedDate')" [isToolTip]="true">
              </app-popover>
            </th> -->
            <!-- <th scope="col">
              <span class="th-label" sortable="TermedEmployeeMovedDate" (sort)="sortTable($event)">
                Moved to Termed Employee Folder
              </span>
              <app-popover #popoverTermedEmployeeMovedDate [active]="isFilterApply('TermedEmployeeMovedDate')"
                [keepOpen]="true" [openOnFocus]="false" [tooltipTemplate]="popContent"
                [tooltipIcon]="'fas fa-filter th-filter'" [triggers]="'manual'" [autoClose]="'outside'"
                (click)="openFilterPopover('TermedEmployeeMovedDate')">
              </app-popover>
              <app-popover [tooltipTitle]="getToolTipText('TermedEmployeeMovedDate')" [isToolTip]="true">
              </app-popover>
            </th> -->
            <th scope="col">
              <span class="th-label" sortable="SentIndividualSignedCopy" (sort)="sortTable($event)">
                Form U5 and Term Letter Sent
              </span>
              <app-popover #popoverSentIndividualSignedCopy [active]="isFilterApply('SentIndividualSignedCopy')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('SentIndividualSignedCopy')">
              </app-popover>
              <!-- <app-popover [tooltipTitle]="getToolTipText('SentIndividualSignedCopy')" [isToolTip]="true">
              </app-popover> -->
            </th>
            <th scope="col">
              <span class="th-label" sortable="TrackingNumber" (sort)="sortTable($event)">
                Tracking Information
              </span>
              <app-popover #popoverTrackingNumber [active]="isFilterApply('TrackingNumber')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('TrackingNumber')">
              </app-popover>
              <!-- <app-popover [tooltipTitle]="getToolTipText('TrackingNumber')" [isToolTip]="true">
              </app-popover> -->
            </th>
            <th scope="col">
              <span class="th-label" sortable="ShippingMethod" (sort)="sortTable($event)">
                Shipping Method 
              </span>
              <app-popover #popoverShippingMethod [active]="isFilterApply('ShippingMethod')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('ShippingMethod')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="ZipToDcfArchiveModel" (sort)="sortTable($event)">
                Sent zip of employee file to DCF archive?
              </span>
              <app-popover #popoverRetiredFromQuestCE [active]="isFilterApply('ZipToDcfArchiveModel')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('ZipToDcfArchiveModel')">
              </app-popover>
              <!-- <app-popover [tooltipTitle]="getToolTipText('ZipToDcfArchiveModel')" [isToolTip]="true">
              </app-popover> -->
            </th>
            <th scope="col">
              <span class="th-label" sortable="SignatureName" (sort)="sortTable($event)">
                Signature Name
              </span>
              <app-popover #popoverSignatureName [active]="isFilterApply('SignatureName')" [keepOpen]="true" [openOnFocus]="false"
                [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'" [triggers]="'manual'"
                [autoClose]="'outside'" (click)="openFilterPopover('SignatureName')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="SignatureDate" (sort)="sortTable($event)">
                Signature Date
              </span>
              <app-popover #popoverSignatureDate [active]="isFilterApply('SignatureDate')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('SignatureDate')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="Created" (sort)="sortTable($event)">
                Created
              </span>
              <app-popover #popoverFirstName [active]="isFilterApply('Created')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('Created')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="CreatedBy" (sort)="sortTable($event)">
                Created By
              </span>
              <app-popover #popoverFirstName [active]="isFilterApply('CreatedBy')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('CreatedBy')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="Modified" (sort)="sortTable($event)">
                Modified
              </span>
              <app-popover #popoverFirstName [active]="isFilterApply('Modified')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('Modified')">
              </app-popover>
            </th>
            <th scope="col">
              <span class="th-label" sortable="ModifiedBy" (sort)="sortTable($event)">
                Modified By
              </span>
              <app-popover #popoverFirstName [active]="isFilterApply('ModifiedBy')" [keepOpen]="true"
                [openOnFocus]="false" [tooltipTemplate]="popContent" [tooltipIcon]="'fas fa-filter th-filter'"
                [triggers]="'manual'" [autoClose]="'outside'" (click)="openFilterPopover('ModifiedBy')">
              </app-popover>
            </th>
            

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let report of terminationDashboardsPerPage; let i = index">
            <td>
              <input style="height:13px;" id="selectCheckbox" type="checkbox" [checked]="report.isSelected" 
              (change)="onCheckboxChanged(report.ID)" />
            </td>
            <td>
              <a href="{{URL}}{{report.ID}}" target="_blank" style="color: #337AB7;">{{report.ID}}</a>
            </td>
            <td>
              <span>{{report.LastName}}</span>
            </td>
            <td>
              <span>{{report.FirstName}}</span>
            </td>
            <td>
              <span>{{report.FormStatus}}</span>
            </td>
            <!-- <td>
              <span>{{report.clientName}}</span>
            </td> -->
    
            <td>
              <span>{{report.TerminationDate }}</span>
            </td>
            <td>
              <span>
                {{report.VoluntaryTermination}}
              </span>
            </td>
            <td>
              <span>
                {{report.StatusOfIndividual}}
              </span>
            </td>
            <!-- <td>
              <span>
                {{report.dCFRiskRating}}
              </span>
            </td> 
            <td>
              <span>
                {{report.enterpriseValue}}
              </span>
            </td>  -->
            <td>
              <span>{{report.Series24}}</span>
            </td>
            <td>
              <span>
                {{report.TerminationReasonMatch}}
              </span>
            </td>
            <!-- <td>
              <span>
                {{report.s24ApprovalStatus}}
              </span>
            </td>  -->
            <!-- <td>
              <span>
                {{report.WasThreatContacted}}
              </span>
            </td> -->
            <td>
              <span>
                {{report.InternalOpenThreat}}
              </span>
            </td>
            <!-- <td>
              <span>
                {{report.dCFDateApproval}}
              </span>
            </td>  -->
            <td>
              <span>
                {{report.dateTheEmployeeRemoved}}
              </span>
            </td>
            <td>
              <span>
                {{report.JakiePolsonNotifiedDate}}
              </span>
            </td>
            <td>
              <span>
                {{report.AllowedToTermedDate}}
              </span>
            </td>
            <td>
              <span>
                {{report.PstToTermedClosedDeniedDate}}
              </span>
            </td>
            <td>
              <span>
                {{report.dateTheEmployeeRemovedDCFKnowledge}}
              </span>
            </td>
            <td>
              <span>
                {{report.DateTheEmployeeRemovedFlowPerms}}
              </span>
            </td>
            <td>
              <span>
                {{report.DateSupportTicketOpened}}
              </span>
            </td>
            
            <td>
              <span>
                {{report.CSIRetiredDate}}
              </span>
            </td>
            <td>
              <span>
                {{report.RetiredFromQuestCEModel}}
              </span>
            </td>
            <td>
              <span>
                {{report.RunSmarshEmailSpotCheck}}
              </span>
            </td>
            <td>
              <span>
                {{report.SupervisionGroupRemovedDate}}
              </span>
            </td>
            <td>
              <span>
                {{report.SmarshTextCaptureRemovedDate}}
              </span>
            </td>
            <td>
              <span>
                {{report.LinkedInRemovedFromSmarshModel}}
              </span>
            </td>
            <td>
              <span>
                {{report.SupervisorOrgChartRemovalDate}}
              </span>
            </td>
            <td>
              <span>
                {{report.NotifiedBrokerageFirmsDate}}
              </span>
            </td>
            <!-- <td>
              <span>
                {{report.IdentifyIndividualTermedDate}}
              </span>
            </td> -->
            <!-- <td>
              <span>
                {{report.TermedEmployeeMovedDate}}
              </span>
            </td> -->
            <td>
              <span>
                {{report.SentIndividualSignedCopy}}
              </span>
            </td>
            <td>
              <span>
                {{report.TrackingNumber}}
              </span>
            </td>
            <td>
              <span>
                {{report.ShippingMethod}}
              </span>
            </td>
            <td>
              <span>
                {{report.ZipToDcfArchiveModel}}
              </span>
            </td>
            <td>
              <span>
                {{report.SignatureName}}
              </span>
            </td>
            <td>
              <span>
                {{report.SignatureDate}}
              </span>
            </td>
            <td>
              <span>
                {{report.Created}}
              </span>
            </td>
            <td>
              <span>
                {{report.CreatedBy}}
              </span>
            </td>
            <td>
              <span>
                {{report.Modified}}
              </span>
            </td>
            <td>
              <span>
                {{report.ModifiedBy}}
              </span>
            </td>
            
          </tr>
        </tbody>
      </table>
      <span *ngIf="terminationDashboards && terminationDashboards.length === 0" class="no-matches">There are no Termination Forms
        to display.</span>
    </div>


    <div class="row">
      <div class="col-xs-5" style="margin-left:0%">
        <app-pagination #pagination *ngIf="terminationDashboards" [page]="commonHelperService.state.page"
          [pageSize]="commonHelperService.state.pageSize" [paginationListValues]="pageSizeListValues"
          [listElementsSize]="terminationDashboards.length" (paginationValues)="onChangePaginationValues($event)">
        </app-pagination>
      </div>
      <div class="col-md-5">
        <div class="divPageSizeType" id="divPageSizeType">
          <select id="ddlPageSize" (change)="onPageSizeValueChange($event)" [(ngModel)]="selectedPageSize"
            class="form-select form-control" [disabled]="pageDropdownDisable">
            <option value="" selected disabled>Select your option</option>
            <option *ngFor="let pages of pageSizeListValues" [value]="pages.key">
              {{pages.value}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <ng-template #popContent>
      <div class="filter-container">
        <div class="filter-body">
          <div class="row sort-row" (click)="sortTableOnFilterModal(targetField, 'asc')">
            <i class="fas fa-sort-alpha-down sort-icon"></i>
            <span>A on Top</span>
          </div>
          <div class="row sort-row" (click)="sortTableOnFilterModal(targetField, 'desc')">
            <i class="fas fa-sort-alpha-down-alt sort-icon"></i>
            <span>Z on Top</span>
          </div>
          <hr>
          <div class="row">
            <div [ngClass]="{'clear-filter-container-enable': isFilterApply(targetField)}" (click)="clearFilters()">
              <i class="fas fa-filter filter-icon"></i>
              <span class="filter-icon-span" [ngClass]="{'filter-icon-span-enable': !isFilterApply(targetField)}">
                Clear Filters from Value
              </span>
            </div>
          </div>
          <div *ngFor="let value of fieldOptionValues" class="row">
            <label class="checkbox-label">
              <input type="checkbox" class="checkbox-option-field" id="{{value}}" [checked]="isChecked(value)"
                (click)="onClickFilterCheckbox($event)">
              {{value}}
            </label>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<app-notify-popup (addNotifyDetails)="addNotifyDetails($event)"></app-notify-popup>
<app-termination-notify-popup [formID]="formID" ></app-termination-notify-popup>