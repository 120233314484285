<div [formGroup]="dealOverviewFormGroup"id="deal-overview" class="container-fluid border container-border-color container-section">
    <div class="row bg-title">
        <span class="title-section-print">Deal Overview</span>
        <span class="glyphicon glyphicon-question-sign title-question-sign tooltip-dcf"></span>
        <span class="tooltiptext-dcf visibility-hidden">
            <p>
                <span class="tooltiptext-dcf-bold">First Process Run:
                </span>
                Have we run multiple processes under the same Engagement Letter/had a failed process? For example, we wrote a CIM, went to market, received IOIs, client decided not to pursue a transaction and deal goes on hold (but not terminated).
            A year later, we go back to market and close a deal.
            </p>
            <p>
                <span class="tooltiptext-dcf-bold">First Institutional Capital:
                </span>
                As we often work with family and entrepreneur owned business, this is to track how many of our clients have never had an outside investor before and may be a helpful statistic to demonstrate DCF's experience when marketing to prospective clients.
            </p>
        </span>
    </div>
    <div id="deal-overview-section" class="content-section">
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span class="span-to-highlight"
                    [class.highlight-warning]="highlightRequiredField('dealType')"
                    [class.edited-externally]="editedExternally('dealType')"
                    [class.conflict-on-merge]="hasMergeConflict('dealType')">Deal Type</span>
                    <span *ngIf="showAsterisk('dealType')" class="red-asterik"></span> 
                </div>
                <div class="col-xs-8">
                    <app-dropdown
                    [optionsList]="dealTypeOptions"
                    [keySelected]="dealOverviewFormGroup.get('dealType')?.value"
                    [readonly]="isReadOnly('dealType')"
                    (optionSelected)="onDealTypeSelection($event)">
                    </app-dropdown>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span class="span-to-highlight"
                    [class.highlight-warning]="highlightRequiredField('crossBorderEngagement')"
                    [class.edited-externally]="editedExternally('crossBorderEngagement')"
                    [class.conflict-on-merge]="hasMergeConflict('crossBorderEngagement')">Cross-Border Engagement?</span>
                    <span *ngIf="showAsterisk('crossBorderEngagement')" class="red-asterik"></span>
                    <app-popover
                    id="tooltip-section"
                    [tooltipText]="getTooltipText('crossBorderEngagement')">
                  </app-popover>

                </div>
                <div class="col-xs-8">
                    <app-toggle-yes-no  #CrossBorderEngagement
                    [value]="dealOverviewFormGroup.get('crossBorderEngagement')?.value"
                    [readonly]="isReadOnly('crossBorderEngagement')"
                    (handleToggleYesNo)="onCrossBorderEngagement($event)">
                    </app-toggle-yes-no>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span class="span-to-highlight"
                    [class.highlight-warning]="highlightRequiredField('firstProcessRun')"
                    [class.edited-externally]="editedExternally('firstProcessRun')"
                    [class.conflict-on-merge]="hasMergeConflict('firstProcessRun')">First Process Run?</span>
                    <span *ngIf="showAsterisk('firstProcessRun')" class="red-asterik"></span>
                    <app-popover
                    [tooltipText]="getTooltipText('firstProcessRun')">
                  </app-popover>

                </div>
                <div class="col-xs-8">
                    <app-toggle-yes-no  #FirstProcessRun
                    [value]="dealOverviewFormGroup.get('firstProcessRun')?.value"
                    [readonly]="isReadOnly('firstProcessRun')"
                    (handleToggleYesNo)="onIsFirstProcessRun($event)">
                    </app-toggle-yes-no>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span class="span-to-highlight"
                    [class.highlight-warning]="highlightRequiredField('firstInstitutionalCapital')"
                    [class.edited-externally]="editedExternally('firstInstitutionalCapital')"
                    [class.conflict-on-merge]="hasMergeConflict('firstInstitutionalCapital')">First Institutional Capital?</span>
                    <span *ngIf="showAsterisk('firstInstitutionalCapital')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <app-toggle-yes-no  #FirstInstitutionalCapital
                    [value]="dealOverviewFormGroup.get('firstInstitutionalCapital')?.value"
                    [readonly]="isReadOnly('firstInstitutionalCapital')"
                    (handleToggleYesNo)="onIsFirstInstitutionalCapital($event)">
                    </app-toggle-yes-no>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span class="span-to-highlight"
                    [class.highlight-warning]="highlightRequiredField('isCompetitivePitch')"
                    [class.edited-externally]="editedExternally('isCompetitivePitch')"
                    [class.conflict-on-merge]="hasMergeConflict('isCompetitivePitch')">Competitive Pitch?</span>
                    <span *ngIf="showAsterisk('isCompetitivePitch')" class="red-asterik"></span>
                </div>
                <div class="col-xs-8">
                    <app-toggle-yes-no  #IsCompetitivePitch
                    [value]="myDealFormGroup.get('isCompetitivePitch')?.value"
                    [readonly]="isReadOnly('isCompetitivePitch')"
                    (handleToggleYesNo)="onIsCompetitivePitch($event)">
                    </app-toggle-yes-no>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-4 required-div">
                    <span class="span-to-highlight dependent-field"
                    [class.highlight-warning]="highlightRequiredField('numberOfParticipants')"
                    [class.edited-externally]="editedExternally('numberOfParticipants')"
                    [class.conflict-on-merge]="hasMergeConflict('numberOfParticipants')">Number of Participants</span>
                    <span *ngIf="showAsterisk('numberOfParticipants')" class="red-asterik"></span>
                    <app-popover
                    [tooltipText]="getTooltipText('numberOfParticipants')">
                  </app-popover>

                    
                </div>
                <div class="col-xs-8">
                    <app-dropdown
                    [optionsList]="numberOfParticipantsOptions"
                    [keySelected]="dealOverviewFormGroup.get('numberOfParticipants')?.value"
                    [readonly]="isReadOnly('numberOfParticipants')"
                    [disabled]="dealOverviewFormGroup.get('numberOfParticipants')?.disabled"
                    (optionSelected)="onNumberOfParticipantsSelection($event)">
                    </app-dropdown>
                </div>
            </div>
        </div>
    </div>
</div>
